import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Box, Button, LinearProgress } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import TextField from "@mui/material/TextField";
import { testPDSeg } from '../../../testPopupDataSegment';
import { getDataSegments, getDataSegmentIntFromString } from "../../../Home/dataSegmentApi"
import { ForceAddApi } from './ParametersAddApi';
import { testContract } from '../../../PopupContract';
import { splitSourceSystemMemberKey } from '../../../helperFunctions';


export const ParametersAdd = ({ switchTabs }) => {
    const [dataSegment, setDataSegment] = useState("");
    const [forceCaptype, setForceCaptype] = useState(Array(10).fill('PROVIDER'));
    const [sourceSystem, setSourceSystem] = useState(Array(10).fill(''));
    const [dataSegmentError, setDataSegmentError] = useState(false);
    const [dataSegments, setDataSegments] = useState(['']);
    const [contractNumber, setContractNumber] = useState(Array(10).fill(''));
    const [contractLineNumber, setContractLineNumber] = useState(Array(10).fill(''));
    const [employerGroupNumber, setemployerGroupNumber] = useState(Array(10).fill(''));
    const [benefitPlanId, setBenefitPlanId] = useState(Array(10).fill(''));
    const [memberId, setMemberId] = useState(Array(10).fill(''));
    const [reprocessIndicator, setReprocessIndicator] = useState(Array(10).fill('REPROCESS'));
    const [divPanelNetwork, setdivPanelNetwork] = useState(Array(10).fill(''));
    const [cmsContrPbpSegment, setcmsContrPbpSegment] = useState(Array(10).fill(''));
    const [beginCapPeriod, setBeginCapPeriod] = useState(Array(10).fill(''));
    const [endCapPeriod, setEndCapPeriod] = useState(Array(10).fill(''));
    const [selectedDataSegments, setSelectedDataSegments] = useState(Array(10).fill('select'));
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegments(dataList);
    }, [])

    function dataSegmentChange(event) {
        if (!dataSegment) {
            setDataSegment(event.target.value);
        }
    }
    function ForceCapTypeChange(event, index) {
        setForceCaptype([
            ...forceCaptype.slice(0, index),
            event.target.value,
            ...forceCaptype.slice(index + 1)
        ]);
    };

    const sourceSystemChange = (event, index) => {
        const newSourceSystem = [...sourceSystem]; // copy the array
        newSourceSystem[index] = event.target.value; // update the value at the specific index
        setSourceSystem(newSourceSystem); // update the state
    };

    const contractNumberChange = (event, index) => {
        const newContractNumber = [...contractNumber]; // copy the array
        newContractNumber[index] = event.target.value.replace(/[^0-9]/g, ''); // update the value at the specific index
        setContractNumber(newContractNumber); // update the state
    };

    const contractLineNumberChange = (event, index) => {
        const newContractLineNumber = [...contractLineNumber]; // copy the array
        newContractLineNumber[index] = event.target.value.replace(/[^0-9]/g, ''); // update the value at the specific index
        setContractLineNumber(newContractLineNumber); // update the state
    };

    const employerGroupNumberChange = (event, index) => {
        const newEmployerGroup = [...employerGroupNumber]; // copy the array
        newEmployerGroup[index] = event.target.value; // update the value at the specific index
        setemployerGroupNumber(newEmployerGroup); // update the state
    };
    const benefitPlanIdChange = (event, index) => {
        const newBenefitPlanId = [...benefitPlanId]; // copy the array
        newBenefitPlanId[index] = event.target.value; // update the value at the specific index
        setBenefitPlanId(newBenefitPlanId); // update the state
    };
    const memberIdChange = (event, index) => {
        const newMemberId = [...memberId]; // copy the array
        newMemberId[index] = event.target.value; // update the value at the specific index
        setMemberId(newMemberId); // update the state
    };
    const divPanelNetworkChange = (event, index) => {
        const newdivPanelNetwork = [...divPanelNetwork]; // copy the array
        newdivPanelNetwork[index] = event.target.value; // update the value at the specific index
        setdivPanelNetwork(newdivPanelNetwork); // update the state
    };

    const cmsContrPbpSegmentChange = (event, index) => {
        const newcmsContrPbpSegment = [...cmsContrPbpSegment]; // copy the array
        newcmsContrPbpSegment[index] = event.target.value; // update the value at the specific index
        setcmsContrPbpSegment(newcmsContrPbpSegment); // update the state
    };

    const beginCapPeriodChange = (event, index) => {
        const newBeginCapPeriod = [...beginCapPeriod]; // copy the array
        let value = event.target.value.replace(/[^0-9]/g, ''); // remove non-numeric characters
        if (value.length <= 6) {
            newBeginCapPeriod[index] = value; // update the value at the specific index
            setBeginCapPeriod(newBeginCapPeriod); // update the state
        }
        if (value.length === 6) {
            const regex = /^(19|20)\d\d(0[1-9]|1[012])$/; // regex for yyyymm format
            if (!regex.test(value)) {
                console.log('Invalid format. Please enter in yyyymm format.');
            }
        }
    };
    const endCapPeriodChange = (event, index) => {
        const newEndCapPeriod = [...endCapPeriod]; // copy the array
        let value = event.target.value.replace(/[^0-9]/g, ''); // remove non-numeric characters
        if (value.length <= 6) {
            newEndCapPeriod[index] = event.target.value.replace(/[^0-9]/g, ''); // update the value at the specific index
            setEndCapPeriod(newEndCapPeriod); // update the state
        } if (value.length === 6) {
            const regex = /^(19|20)\d\d(0[1-9]|1[012])$/; // regex for yyyymm format
            if (!regex.test(value)) {
                console.log('Invalid format. Please enter in yyyymm format.');
            }
        }
    };
    const reprocessIndicatorChange = (event, index) => {
        const newReprocessIndicator = [...reprocessIndicator]; // copy the array
        newReprocessIndicator[index] = event.target.value; // update the value at the specific index
        setReprocessIndicator(newReprocessIndicator); // update the state
    };

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    const dataSegmentMapping = {
        "PHSCACO": 1,
        "PHSORCO": 2,
        "PHSWACO": 3,
        "PHSTXCO": 4,
        "PHSCOCO": 5,
        "PHSNVCO": 6,
        "UHUHSM3": 7,
        "UHUHSM2": 8,
        "MAOCICO": 9,
        "MAMDICO": 10,
        "UHUHSCO": 12,
        "UHUHSMR": 13,
        "UHNHPCO": 14,
        "UHMHPMR": 15,
        "UHPCPMR": 16,
        "UHUHSCS": 17,

    };

    function openPopupContract() {
        if (dataSegment) {
            const dataSegmentValue = dataSegmentMapping[dataSegment];
            if (dataSegmentValue) {
                testContract(dataSegmentValue); // Pass the selected dataSegment value    
                window.onmessage = (event) => {
                    try {
                        setContractLineNumber(event.data);
                    } catch {
                        console.log('popup receive message error');
                    }
                };
            } else {
                alert('Invalid Data Segment selected. Please select a valid Data Segment.');
            }
        } else {
            alert('Please select a Data Segment before proceeding.');
        }
    }

    const isFieldEnabled = (field, index) => {
        if (forceCaptype[index] === 'PROVIDER') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (forceCaptype[index] === 'PROV DPN') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'divPanelNetwork' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (forceCaptype[index] === 'PROV CMS') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'cmsContrPbpSegment' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (forceCaptype[index] === 'MEMBER') {
            return field === 'memberId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (forceCaptype[index] === 'CUSTOMER') {
            return field === 'sourceSystem' || field === 'employerGroupNumber' || field === 'benefitPlanId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (forceCaptype[index] === 'BENEFIT FACTOR') {
            return field === 'sourceSystem' || field === 'employerGroupNumber' || field === 'benefitPlanId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        return false;
    }

    const handleSave = () => {
        var tempErrorMessages = [];
        setErrorMessages([]);
        var providerRequestBody;
        var memberRequestBody;
        var customerRequestBody;
        var benefitRequestBody;
        var cmsRequestBody;
        var dpnRequestBody;
        var data = Array(10);
        var userId = localStorage.getItem('sub');
        var rows = [];
        for (let i = 0; i < 10; i++) {
            var temp = {
                "DATA_SEG_ID": "",
                "FORCE_CAP_TYPE": "",
                "CONTR_ID": "",
                "CONTR_LN_ID": "",
                "CAP_PRD_BEGN_YR_MO": "",
                "CAP_PRD_END_YR_MO": "",
                "REPROC_INDC": "",
                "EMPLOYER_GROUP": "",
                "BENEFIT_PLAN_ID": "",
                "CUST_ID": "",
                "MBR_ID": "",
                "MBR_ID_SUFX": "",
                "COV_SRC_SYS_CD": "",
                "CMS_CONTR": "",
                "Pbp": "",
                "CMS_SEG": "",
                "DIV": "",
                "GET_PANEL": "",
                "PCP_NTWK_ID": "",
                "UPDT_USER_ID": ""

            }
            data[i] = temp;
        }

        for (let i = 0; i < 10; i++) {
            if (forceCaptype[i] === 'PROVIDER') {
                providerRequestBody = {
                    "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                    "FORCE_CAP_TYPE": `${forceCaptype}`,
                    "CONTR_ID": `${contractNumber}`,
                    "CONTR_LN_ID": `${contractLineNumber}`,
                    "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                    "CAP_PRD_END_YR_MO": `${endCapPeriod}`,
                    "REPROC_INDC": `${reprocessIndicator}`,
                    "UPDT_USER_ID": `${userId}`
                }
                data[i] = providerRequestBody;
            } else if (forceCaptype[i] === 'MEMBER') {
                try {
                    var CUST_ID = splitSourceSystemMemberKey(memberId[i])[0];
                    var MBR_ID = splitSourceSystemMemberKey(memberId[i])[1];
                    var MBR_ID_SUFX = splitSourceSystemMemberKey(memberId[i])[2];
                } catch {
                    var CUST_ID = "";
                    var MBR_ID = "";
                    var MBR_ID_SUFX = "";
                }
                if (!CUST_ID) {
                    tempErrorMessages.push('* CUST_ID is mandatory for row ' + (i + 1));
                }

                if (!MBR_ID) {
                    tempErrorMessages.push('* MBR_ID is mandatory for row ' + (i + 1));
                }
                if (!MBR_ID_SUFX) {
                    tempErrorMessages.push('* MBR_ID_SUFX is mandatory for row ' + (i + 1));
                } else if (!/^\d{2}$/.test(MBR_ID_SUFX)) {
                    tempErrorMessages.push('* MBR_ID_SUFX must be 2 numeric characters for row ' + (i + 1));
                }
                if (tempErrorMessages.length === 0) {
                    memberRequestBody = {
                        "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                        "FORCE_CAP_TYPE": `${forceCaptype}`,
                        "CUST_ID": CUST_ID,
                        "MBR_ID": MBR_ID,
                        "MBR_ID_SUFX": MBR_ID_SUFX,
                        "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                        "CAP_PRD_END_YR_MO": `${endCapPeriod}`,
                        "REPROC_INDC": `${reprocessIndicator}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                    data[i] = memberRequestBody;
                }
            } else if (forceCaptype[i] === 'CUSTOMER') {
                customerRequestBody = {
                    "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                    "force_cap_type": `${forceCaptype}`,
                    "EMPLOYER_GROUP": `${employerGroupNumber}`,
                    "BENEFIT_PLAN_ID": `${benefitPlanId}`,
                    "REPROC_INDC": `${reprocessIndicator}`,
                    "UPDT_USER_ID": `${userId}`,
                    "COV_SRC_SYS_CD": `${sourceSystem}`,
                    "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                    "CAP_PRD_END_YR_MO": `${endCapPeriod}`
                }
                data[i] = customerRequestBody;
            } else if (forceCaptype[i] === "BENEFIT FACTOR") {
                benefitRequestBody = {
                    "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                    "FORCE_CAP_TYPE": `${forceCaptype}`,
                    "COV_SRC_SYS_CD": `${sourceSystem}`,
                    "EMPLOYER_GROUP": `${employerGroupNumber}`,
                    "UPDT_USER_ID": `${userId}`,
                    "REPROC_INDC": `${reprocessIndicator}`,
                    "BENEFIT_PLAN_ID": `${benefitPlanId}`,
                    "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                    "CAP_PRD_END_YR_MO": `${endCapPeriod}`
                }
                data[i] = benefitRequestBody;
            } else if (forceCaptype[i] === 'PROV CMS') {
                try {
                    var CMS_CONTR = splitSourceSystemMemberKey(cmsContrPbpSegment[i])[0];
                    var pbp = splitSourceSystemMemberKey(cmsContrPbpSegment[i])[1];
                    var CMS_SEG = splitSourceSystemMemberKey(cmsContrPbpSegment[i])[2];
                } catch {
                    var CMS_CONTR = "";
                    var pbp = "";
                    var CMS_SEG = "";
                }
                
                if (!/^[A-Za-z]\d{4}$/.test(CMS_CONTR)) {
                    tempErrorMessages.push('* CMScontr must be 1 alpha character followed by 4 numeric characters for row ' + (i + 1));
                }

                if (!/^\d{3}$/.test(CMS_SEG)) {
                    tempErrorMessages.push('* Invalid Segment format must be 3 numeric characters for row' + (i + 1));
                }

                if (!/^\d{3}$/.test(pbp)) {
                    tempErrorMessages.push('* Invalid PBP format must be 3 numeric characters for row' + (i + 1));
                }

                // If no errors, assign the values to the request body
                if (tempErrorMessages.length === 0) {
                    cmsRequestBody = {
                        "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                        "FORCE_CAP_TYPE": `${forceCaptype}`,
                        "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                        "CAP_PRD_END_YR_MO": `${endCapPeriod}`,
                        "CONTR_ID": `${contractNumber}`,
                        "CONTR_LN_ID": `${contractLineNumber}`,
                        "REPROC_INDC": `${reprocessIndicator}`,
                        "CMS_CONTR": CMS_CONTR,
                        "Pbp": pbp,
                        "CMS_SEG": CMS_SEG,
                        "UPDT_USER_ID": `${userId}`
                    }
                    data[i] = cmsRequestBody;
                }
            } else if (forceCaptype[i] === "PROV DPN") {
                try {
                var DIV = splitSourceSystemMemberKey(divPanelNetwork[i])[0];
                var GET_PANEL = splitSourceSystemMemberKey(divPanelNetwork[i])[1];
                var PCP_NTWK_ID = splitSourceSystemMemberKey(divPanelNetwork[i])[2];
            } catch {
                var DIV = "";
                var GET_PANEL = "";
                var PCP_NTWK_ID = "";
            }
                if (!/^[a-zA-Z]{3}$/.test(DIV)) {
                console.error('DIV should be only 3 alpha characters');
                tempErrorMessages.push('* DIV should be only 3 alpha characters for row ' + (i + 1));}

                if (!/^\d{3}$/.test(GET_PANEL)) {
                    tempErrorMessages.push('* Panel format must be 3 numeric characters for row ' + (i + 1));
                }

                if (!/^\d{3}$/.test(PCP_NTWK_ID)) {
                    tempErrorMessages.push('* Netwok format must be 7 numeric characters for row' + (i + 1));
                }
                if (tempErrorMessages.length === 0) {
                    dpnRequestBody = {
                        "DATA_SEG_ID": `${getDataSegmentIntFromString(dataSegment)}`,
                        "FORCE_CAP_TYPE": `${forceCaptype}`,
                        "CAP_PRD_BEGN_YR_MO": `${beginCapPeriod}`,
                        "CAP_PRD_END_YR_MO": `${endCapPeriod}`,
                        "CONTR_ID": `${contractNumber}`,
                        "CONTR_LN_ID": `${contractLineNumber}`,
                        "REPROC_INDC": `${reprocessIndicator}`,
                        "DIV": DIV,
                        "GET_PANEL": GET_PANEL,
                        "PCP_NTWK_ID": PCP_NTWK_ID,
                        "UPDT_USER_ID": `${userId}`
                    }
                    data[i] = dpnRequestBody;
                }
            }
            if (selectedDataSegments[i] === 'select') {
                rows.push(i);
            } else {
                if (forceCaptype[i] === 'PROVIDER') {
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].contractNumber = contractNumber[i];
                    data[i].contractLineNumber = contractLineNumber[i];
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                    data[i].reprocessIndicator = reprocessIndicator[i];
                } else if (forceCaptype[i] === 'MEMBER') {
                    let custId = "";
                    let mbrId = "";
                    let mbrIdSufx = "";

                    try {
                    [custId, mbrId, mbrIdSufx] = splitSourceSystemMemberKey(memberId[i]);
                    } catch (error) {
                    console.error("Error splitting source system member key:", error);
                    }
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].custId = custId;
                    data[i].mbrId = mbrId;
                    data[i].mbrIdSufx = mbrIdSufx;
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                    data[i].reprocessIndicator = reprocessIndicator[i];
                } else if (forceCaptype[i] === 'CUSTOMER') {
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].employerGroup = employerGroupNumber[i];
                    data[i].benefitPlanId = benefitPlanId[i];
                    data[i].reprocessIndicator = reprocessIndicator[i];
                    data[i].covSrcSysCd = sourceSystem[i];
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                } else if (forceCaptype[i] === 'BENEFIT FACTOR') {
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].covSrcSysCd = sourceSystem[i];
                    data[i].employerGroup = employerGroupNumber[i];
                    data[i].updtUserId = userId;
                    data[i].reprocessIndicator = reprocessIndicator[i];
                    data[i].benefitPlanId = benefitPlanId[i];
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                } else if (forceCaptype[i] === 'PROV CMS') {
                    let cmsContr = "";
                    let pbp = "";
                    let cmsSeg = "";
                
                    try {
                        [cmsContr, pbp, cmsSeg] = splitSourceSystemMemberKey(cmsContrPbpSegment[i]);
                    } catch (error) {
                        console.error("Error splitting CMS contract PBP segment:", error);
                    }
                
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                    data[i].contractNumber = contractNumber[i];
                    data[i].contractLineNumber = contractLineNumber[i];
                    data[i].reprocessIndicator = reprocessIndicator[i];
                    data[i].cmsContr = cmsContr;
                    data[i].pbp = pbp;
                    data[i].cmsSeg = cmsSeg;
                } else if (forceCaptype[i] === 'PROV DPN') {
                    let div = "";
                    let getPanel = "";
                    let pcpNtwkId = "";
                
                    try {
                        [div, getPanel, pcpNtwkId] = splitSourceSystemMemberKey(divPanelNetwork[i]);
                    } catch (error) {
                        console.error("Error splitting div panel network:", error);
                    }
                
                    data[i].dataSegment = `${selectedDataSegments[i]}`;
                    data[i].forceCaptype = forceCaptype[i];
                    data[i].beginCapPeriod = beginCapPeriod[i];
                    data[i].endCapPeriod = endCapPeriod[i];
                    data[i].contractNumber = contractNumber[i];
                    data[i].contractLineNumber = contractLineNumber[i];
                    data[i].reprocessIndicator = reprocessIndicator[i];
                    data[i].div = div;
                    data[i].getPanel = getPanel;
                    data[i].pcpNtwkId = pcpNtwkId;
                }
            }
        }
        var blankDataSegment = false; // blank data segment

        if (dataSegment === '') {
            tempErrorMessages.push('* DataSegment is required');
            setErrorMessages(tempErrorMessages);
            return;
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].dataSegment === '') {
                blankDataSegment = true;
                tempErrorMessages.push('* Please select a Data Segment for row ' + (i + 1));
            }
            if (data[i].dataSegment) {
                if (data[i].forceCaptype === 'PROVIDER') {
                    if (data[i].contractNumber === '') {
                        tempErrorMessages.push('* Contract ID is required for row ' + (i + 1));
                    }
                    if (data[i].contractLineNumber === '') {
                        tempErrorMessages.push('* Contract Line ID is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                } else if (data[i].forceCaptype === 'MEMBER') {
                    if (data[i].memberId === '') {
                        tempErrorMessages.push('* Member ID is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                } else if (data[i].forceCaptype === 'CUSTOMER') {
                    if (data[i].employerGroup === '') {
                        tempErrorMessages.push('* Employer Group is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].covSrcSysCd === '') {
                        tempErrorMessages.push('* Please enter a Source System for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }

                } else if (data[i].forceCaptype === 'BENEFIT FACTOR') {
                    if (data[i].employerGroup === '') {
                        tempErrorMessages.push('* Employer Group is required for row ' + (i + 1));
                    }
                    if (data[i].benefitPlanId === '') {
                        tempErrorMessages.push('* Benefit Plan ID is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].covSrcSysCd === '') {
                        tempErrorMessages.push('* Source System is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                }
                else if (data[i].forceCaptype === 'PROV CMS') {
                    if (data[i].contractNumber === '') {
                        tempErrorMessages.push('* Contract Number is required for row ' + (i + 1));
                    }
                    if (data[i].contractLineNumber === '') {
                        tempErrorMessages.push('* Contract Line ID is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].cmsContrPbpSegment === '') {
                        tempErrorMessages.push('* Please enter a CMSContr/PBP/Segment for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                }
                else if (data[i].forceCaptype === 'PROV DPN') {
                    if (data[i].contractNumber === '') {
                        tempErrorMessages.push('* Contract Number is required for row ' + (i + 1));
                    }
                    if (data[i].contractLineNumber === '') {
                        tempErrorMessages.push('* Contract Line ID is required for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period Begin is required for row ' + (i + 1));
                    }
                    if (data[i].endCapPeriod === '') {
                        tempErrorMessages.push('* Cap Period End is required for row ' + (i + 1));
                    }
                    if (data[i].reprocessIndicator === '') {
                        tempErrorMessages.push('* Please enter a Reprocess Indicator for row ' + (i + 1));
                    }
                    if (data[i].divPanelNetwork === '') {
                        tempErrorMessages.push('* Please enter a Div/Panel/Network for row ' + (i + 1));
                    }
                    if (data[i].beginCapPeriod >= data[i].endCapPeriod) {
                        tempErrorMessages.push('* The Cap End Period should be greater than Cap Begin Period ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].beginCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid Begin Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                    if (!/^(\d{4})(0[1-9]|1[0-2])$/.test(data[i].endCapPeriod)) {
                        tempErrorMessages.push('* Please enter a valid End Cap Period (YYYYMM) for row ' + (i + 1));
                    }
                }
            }

        }
        if (blankDataSegment) {
            setErrorMessages(tempErrorMessages);
            return;
        }

        if (tempErrorMessages.length === 0) {
            setLoading(true);
            setErrorMessages([]);
            var requestBody = []
            for (let i = 0; i < data.length; i++) {
                if (data[i].dataSegment) {
                    if (data[i].forceCaptype === 'PROVIDER') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "CONTR_ID": data[i].contractNumber,
                            "CONTR_LN_ID": data[i].contractLineNumber,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "UPDT_USER_ID": localStorage.getItem('sub')
                        }
                        requestBody.push(temp);
                    } else if (data[i].forceCaptype === 'MEMBER') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "CUST_ID": data[i].custId,
                            "MBR_ID": data[i].mbrId,
                            "MBR_ID_SUFX": data[i].mbrIdSufx,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "UPDT_USER_ID": localStorage.getItem('sub')
                        }
                        requestBody.push(temp);
                    } else if (data[i].forceCaptype === 'CUSTOMER') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "EMPLOYER_GROUP": data[i].employerGroup,
                            "BENEFIT_PLAN_ID": data[i].benefitPlanId,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "UPDT_USER_ID": localStorage.getItem('sub'),
                            "COV_SRC_SYS_CD": data[i].covSrcSysCd,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod
                        }
                        requestBody.push(temp);
                    } else if (data[i].forceCaptype === 'BENEFIT FACTOR') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "COV_SRC_SYS_CD": data[i].covSrcSysCd,
                            "EMPLOYER_GROUP": data[i].employerGroup,
                            "UPDT_USER_ID": data[i].updtUserId,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "BENEFIT_PLAN_ID": data[i].benefitPlanId,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod
                        }
                        requestBody.push(temp);
                    } else if (data[i].forceCaptype === 'PROV CMS') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod,
                            "CONTR_ID": data[i].contractNumber,
                            "CONTR_LN_ID": data[i].contractLineNumber,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "CMS_CONTR": data[i].cmsContr,
                            "Pbp": data[i].pbp,
                            "CMS_SEG": data[i].cmsSeg,
                            "UPDT_USER_ID": localStorage.getItem('sub')
                        }
                        requestBody.push(temp);
                    } else if (data[i].forceCaptype === 'PROV DPN') {
                        temp = {
                            "DATA_SEG_ID": getDataSegmentIntFromString(data[i].dataSegment),
                            "FORCE_CAP_TYPE": data[i].forceCaptype,
                            "CAP_PRD_BEGN_YR_MO": data[i].beginCapPeriod,
                            "CAP_PRD_END_YR_MO": data[i].endCapPeriod,
                            "CONTR_ID": data[i].contractNumber,
                            "CONTR_LN_ID": data[i].contractLineNumber,
                            "REPROC_INDC": data[i].reprocessIndicator,
                            "DIV": data[i].div,
                            "GET_PANEL": data[i].getPanel,
                            "PCP_NTWK_ID": data[i].pcpNtwkId,
                            "UPDT_USER_ID": localStorage.getItem('sub')
                        }

                        requestBody.push(temp);
                    }
                }
            }
            console.log("requestBody::", requestBody);
            if (requestBody.length > 0) {
                ForceAddApi(requestBody).then(response => {
                    if (response.data.length > 0) {
                        console.log("response::", response.data);
                        const error = response.data;
                        rows.reverse();
                        for (let i = 0; i < error.length; i++) {
                            if (response.data[i].length === 1) {
                                tempErrorMessages.push(['SUCCESS:', response.data[i]].join(' '));
                            } else {
                                for (let j = 0; j < response.data[i].length; j++) {
                                    tempErrorMessages.push(['* ' + response.data[i][j]].join(' '));
                                }
                            }
                        }
                    } else {
                        console.log('error adding records');
                        tempErrorMessages.push('* Error adding records');
                    }
                    setLoading(false);
                    setErrorMessages(tempErrorMessages);
                })
                    .catch(error => {
                        setLoading(false);
                        if (error.response && error.response.data) {
                            setErrorMessages([`* Error: ${error.response.data}`]);
                        } else {
                            setErrorMessages(['* Error adding records']);
                        }
                    });
            } else {
                console.log('Please enter at least one row of data.');
                tempErrorMessages.push('* Please enter at least one row of data.');
                setLoading(false);
                setErrorMessages(tempErrorMessages);

            }
        } else {
            setErrorMessages(tempErrorMessages);
        }
    }

    const resetFields = () => {
        setContractNumber(Array(10).fill(''));
        setContractLineNumber(Array(10).fill(''));
        setemployerGroupNumber(Array(10).fill(''));
        setBenefitPlanId(Array(10).fill(''));
        setMemberId(Array(10).fill(''));
        setReprocessIndicator(Array(10).fill('REPROCESS'));
        setdivPanelNetwork(Array(10).fill(''));
        setcmsContrPbpSegment(Array(10).fill(''));
        setForceCaptype(Array(10).fill('PROVIDER'));
        setBeginCapPeriod(Array(10).fill(''));
        setEndCapPeriod(Array(10).fill(''));
        setSourceSystem(Array(10).fill(''));
        setSelectedDataSegments(Array(10).fill('select'));
        setErrorMessages([]);
        setLoading(false);
    };
    return (
        <div>
            {errorMessages.length > 0 &&
                <Box sx={{ mb: '1rem', width: '100%', textAlign: 'center', color: 'red' }}>
                    {errorMessages.join(', ')}
                </Box>
            }
            {loading && <LinearProgress sx={{ mt: '1rem', width: '100%' }} />}
            <div style={{ height: '65vh', overflow: 'scroll', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ margin: '2px' }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Data Segment</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Force Cap Type</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Contract#</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Contract Line#</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Source System</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Employer Group</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Benefit Plan ID</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Member ID</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>CMSContr/PBP/Segment</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Div/Panel/Network</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Begin Cap Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>End Cap Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Reprocess Indicator(RI)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array(10).fill().map((_, index) => (
                                <TableRow key={'row_' + index}>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Select
                                                size='small'
                                                sx={{ height: '40px', width: '130px', }}
                                                value={selectedDataSegments[index]}
                                                id='data-seg-select'
                                                error={dataSegmentError}
                                                onChange={(event) => setSelectedDataSegments((prev) => {
                                                    const temp = [...prev];
                                                    temp[index] = event.target.value;
                                                    dataSegmentChange(event, index);
                                                    return temp;
                                                })}
                                            >
                                                <MenuItem
                                                    value='select'
                                                >
                                                    ---Select---
                                                </MenuItem>
                                                {dataSegments.map((segment) => (
                                                    <MenuItem
                                                        value={segment}
                                                        key={segment}
                                                        name='data-segment-option'
                                                    >
                                                        {segment}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Box
                                                sx={{ ml: '0.5rem', padding: '2px', height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center' }}
                                            >
                                                <img
                                                    style={{ height: '30px', width: '30px' }}
                                                    src="Binoculars-icon.png"
                                                    title="Filtering Data segment"
                                                    onClick={() => openPopupDataSegment()}
                                                ></img>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Select
                                                size='small'
                                                id='type'
                                                fullWidth
                                                value={forceCaptype[index]}
                                                onChange={(event) => ForceCapTypeChange(event, index)}
                                            >
                                                <MenuItem name='force-cap-type-option' value={"PROVIDER"}>PROVIDER</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"PROV DPN"}>PROV DPN</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"PROV CMS"}>PROV CMS</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"MEMBER"}>MEMBER</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"CUSTOMER"}>CUSTOMER</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"BENEFIT FACTOR"}>BENEFIT FACTOR</MenuItem>
                                            </Select>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="CONTRACT_ID"
                                            size='small'
                                            label="Contract#"
                                            value={contractNumber[index] || ''}
                                            disabled={!isFieldEnabled('contractNumber', index)}
                                            onChange={(event) => contractNumberChange(event, index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField
                                                name="CONTRACT_LN_ID"
                                                size='small'
                                                sx={{ height: '40px', width: '120px', }}
                                                label="Contract Line#"
                                                value={contractLineNumber[index] || ''}
                                                disabled={!isFieldEnabled('contractLineNumber', index)}
                                                onChange={(event) => contractLineNumberChange(event, index)}
                                            />
                                            <Box
                                                sx={{ ml: '0.5rem', padding: '2px', height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center' }}
                                            >
                                                <img
                                                    style={{ height: '30px', width: '30px' }}
                                                    src="Binoculars-icon.png"
                                                    title="Filtering Contract Line#"
                                                    onClick={() => openPopupContract()}
                                                ></img>
                                            </Box>
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Box>
                                            <Select
                                                size='small'
                                                id='type'
                                                fullWidth
                                                disabled={!isFieldEnabled('sourceSystem', index)}
                                                onChange={(event) => sourceSystemChange(event, index)} // pass the index to the event handler
                                                value={sourceSystem[index] || ''}>

                                                <MenuItem name='force-cap-type-option' value={"DEFAULT"}></MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"ACIS"}>ACIS</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"CES"}>CES</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"COSMOS"}>COSMOS</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"ECAP"}>ECAP</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"MAPS"}>MAPS</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"NDB"}>NDB</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"ORACLE IFS"}>ORACLE IFS</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"PRIME"}>PRIME</MenuItem>
                                                <MenuItem name='force-cap-type-option' value={"TOPS"}>TOPS</MenuItem>
                                            </Select>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label="Employer Group"
                                            disabled={!isFieldEnabled('employerGroupNumber', index)}
                                            onChange={(event) => employerGroupNumberChange(event, index)}
                                            value={employerGroupNumber[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            label="Benefit Plan ID"
                                            disabled={!isFieldEnabled('benefitPlanId', index)}
                                            onChange={(event) => benefitPlanIdChange(event, index)}
                                            value={benefitPlanId[index] || ''}

                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label="Cust-Mbr-MbrSufx"
                                            disabled={!isFieldEnabled('memberId', index)}
                                            onChange={(event) => memberIdChange(event, index)}
                                            value={memberId[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label="CMSContr-PBP-Segment"
                                            disabled={!isFieldEnabled('cmsContrPbpSegment', index)}
                                            onChange={(event) => cmsContrPbpSegmentChange(event, index)}
                                            value={cmsContrPbpSegment[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label="Div-Panel-Network"
                                            disabled={!isFieldEnabled('divPanelNetwork', index)}
                                            onChange={(event) => divPanelNetworkChange(event, index)}
                                            value={divPanelNetwork[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            name="CAP_PRD_BEGN_YR_MO"
                                            label="Begin Cap Period"
                                            disabled={!isFieldEnabled('beginCapPeriod', index)}
                                            onChange={(event) => beginCapPeriodChange(event, index)}
                                            value={beginCapPeriod[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="CAP_PRD_END_YR_MO"
                                            size='small'
                                            fullWidth
                                            label="End Cap Period"
                                            disabled={!isFieldEnabled('endCapPeriod', index)}
                                            onChange={(event) => endCapPeriodChange(event, index)}
                                            value={endCapPeriod[index] || ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Select
                                                size='small'
                                                fullWidth
                                                name="REPROC_INDC"
                                                disabled={!isFieldEnabled('reprocessIndicator', index)}
                                                onChange={(event) => reprocessIndicatorChange(event, index)}
                                                value={reprocessIndicator[index] || ''}
                                            >
                                                <MenuItem value={"REPROCESS"}>REPROCESS</MenuItem>
                                                <MenuItem value={"FORCE"}>FORCE</MenuItem>
                                            </Select>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <Box sx={{ mt: '1rem', display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Button variant="contained" onClick={handleSave}>Save</Button>
                <Button variant="contained" color="error" onClick={() => switchTabs(0)}>Cancel</Button>
                <Button variant="contained" color="success" onClick={resetFields}>Reset</Button>
            </Box>
        </div>
    );
};
export default ParametersAdd;
