import {
    Alert,
    Box,
    Button,
    Checkbox,
    LinearProgress,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";


export const ReportingOverridesAddTable = ({
    switchTab,
    inputDataArray,
    showBody,
    inputLoading,
    setInputtedData 
}) => {
    const rowCount = 10;

    const [cycNumberOptions, setCycNumberOptions] = useState([0]);
    const [mdlIdOptions, setMdlIdOptions] = useState([0]);
    const [docIdOptions, setDocIdOptions] = useState([]);
    const [distModeOptions, setDistModeOptions] = useState([]);
    const [provDirDlvrCdOptions, setProvDirDlvrCdOptions] = useState([]);
    const [providerArrangementOptions, setProviderArrangementOptions] = useState([]);

    // selected values (selects)
    const [selectedClears, setSelectedClears] = useState(Array(rowCount).fill(false));
    const [selectedCycNumberOptions, setSelectedCycNumberOptions] = useState(Array(rowCount).fill(0));
    const [selectedMdlIdOptions, setSelectedMdlIdOptions] = useState(Array(rowCount).fill(0));
    const [selectedDocIdOptions, setSelectedDocIdOptions] = useState(Array(rowCount).fill('select'));
    const [selectedDistModeOptions, setSelectedDistModeOptions] = useState(Array(rowCount).fill('select'));
    const [selectedDfltSegAdrInd, setSelectedDfltSegAdrInd] = useState(Array(rowCount).fill(false));
    const [selectedProvDirDlvrCdOptions, setSelectedProvDirDlvrCdOptions] = useState(Array(rowCount).fill('select'));
    const [selectedProviderArrangementOptions, setSelectedProviderArrangementOptions] = useState(Array(rowCount).fill('select'));
    const [selectedActiveInds, setSelectedActiveInds] = useState(Array(rowCount).fill(false));

    // selected values (textfields)
    const [inputtedMpins, setInputtedMpins] = useState(Array(rowCount).fill(''));
    const [inputtedBegins, setInputtedBegins] = useState(Array(rowCount).fill(''));
    const [inputtedEnds, setInputtedEnds] = useState(Array(rowCount).fill(''));

    const [preloadData, setPreloadData] = useState([]);

    const [loading, setLoading] = useState(false);

    const distModes = {
        "B": "B-BOTH",
        "I": "I-INTERNAL",
        "P": "P-PRINT",
        "S": "S-SUPPRESS",
        "V": "V-PORTAL"
    }
    const providerArrangements = {
        "PC": "PRIMARY CARE",
        "HO": "HOSPITAL",
        "SC": "SUBCAP",
        "TP": "THIRD PARTY",
        "IS": "INTERSEGMENT"
    }

    function reset() {
        setSelectedClears(Array(rowCount).fill(false));
        setSelectedCycNumberOptions((Array(rowCount).fill(0)));
        setSelectedMdlIdOptions(Array(rowCount).fill(0));
        setInputtedMpins(Array(rowCount).fill(''));
        setSelectedDocIdOptions(Array(rowCount).fill('select'));
        setInputtedBegins(Array(rowCount).fill(''));
        setInputtedEnds(Array(rowCount).fill(''));
        setSelectedDistModeOptions(Array(rowCount).fill('select'));
        setSelectedDfltSegAdrInd(Array(rowCount).fill(false));
        setSelectedProvDirDlvrCdOptions(Array(rowCount).fill('select'));
        setSelectedProviderArrangementOptions(Array(rowCount).fill('select'));
        setSelectedActiveInds(Array(rowCount).fill(false));
        setCycNumberOptions([0]);
        setMdlIdOptions([0]);
        setDocIdOptions([]);
        setDistModeOptions([]);
        setProvDirDlvrCdOptions([]);
        setProviderArrangementOptions([]);
        setPreloadData([]);
    }

    function addRow() {
        setSelectedClears(prevState => [...prevState, false]);
        setSelectedMdlIdOptions(prevState => [...prevState, 0]);
        setSelectedCycNumberOptions(prevState => [...prevState, 0]);
        setInputtedMpins(prevState => [...prevState, '']);
        setSelectedDocIdOptions(prevState => [...prevState, '']);
        setInputtedBegins(prevState => [...prevState, '']);
        setInputtedEnds(prevState => [...prevState, '']);
        setSelectedDistModeOptions(prevState => [...prevState, '']);
        setSelectedDfltSegAdrInd(prevState => [...prevState, false]);
        setSelectedProvDirDlvrCdOptions(prevState => [...prevState, '']);
        setSelectedProviderArrangementOptions(prevState => [...prevState, '']);
        setSelectedActiveInds(prevState => [...prevState, false]);
    }

    useEffect(() => {
        if (inputDataArray && inputDataArray.length > 0) {
            reset();
            setCycNumberOptions(inputDataArray[0].CYC_NBR);
            setMdlIdOptions(inputDataArray[1].MDL_ID);
            // need to do some parsing because backend can return DOCID-None which we don't want... technically backend should get fixed
            setDocIdOptions(inputDataArray[2].DOC_ID.map(docId => docId.replace('-None', '')));
            setDistModeOptions(inputDataArray[3].DIST_MODE);
            setProvDirDlvrCdOptions(inputDataArray[4].PROV_DIR_DLVR_CD);
            setProviderArrangementOptions(inputDataArray[5].PROVIDER_ARRANGEMENT);
            var temp = inputDataArray[6];
            for (let i = 0; i < temp.length; i++) {
                temp[i] = { ...temp[i], ...inputDataArray[7] };
            }
            if (temp.length > selectedClears.length) {
                for (let i = 0; i < temp.length - selectedClears.length; i++) {
                    addRow();
                }
                setPreloadData(temp);
            } else {
                setPreloadData(temp);
            }
        }
    }, [inputDataArray])

    useEffect(() => {
        setLoading(inputLoading);
    }, [inputLoading])

    useEffect(() => {
        var newSelectedMdlIdOptions = selectedMdlIdOptions;
        var newSelectedCycNumberOptions = selectedCycNumberOptions;
        var newInputtedMpins = inputtedMpins;
        var newSelectedDocIdOptions = selectedDocIdOptions;
        var newInputtedBegins = inputtedBegins;
        var newInputtedEnds = inputtedEnds;
        var newSelectedDistModeOptions = selectedDistModeOptions;
        var newSelectedDfltSegAdrInd = selectedDfltSegAdrInd;
        var newSelectedProvDirDlvrCdOptions = selectedProvDirDlvrCdOptions;
        var newSelectedProviderArrangementOptions = selectedProviderArrangementOptions;
        if (preloadData.length > 0) {
            for (let index = 0; index < preloadData.length; index++) {
                newSelectedMdlIdOptions = [
                    ...newSelectedMdlIdOptions.slice(0, index),
                    preloadData[index].MDL_ID,
                    ...newSelectedMdlIdOptions.slice(index + 1)
                ]
                newSelectedCycNumberOptions = [
                    ...newSelectedCycNumberOptions.slice(0, index),
                    preloadData[index].CYC_NBR,
                    ...newSelectedCycNumberOptions.slice(index + 1)
                ]
                newInputtedMpins = [
                    ...newInputtedMpins.slice(0, index),
                    preloadData[index].PROV_ID,
                    ...newInputtedMpins.slice(index + 1)
                ]
                newSelectedDocIdOptions = [
                    ...newSelectedDocIdOptions.slice(0, index),
                    preloadData[index].DOC_ID + (preloadData[index].DOC_SUB_TYP_CD === 'None' ? '' : '-' + preloadData[index].DOC_SUB_TYP_CD),
                    ...newSelectedDocIdOptions.slice(index + 1)
                ]
                newInputtedBegins = [
                    ...newInputtedBegins.slice(0, index),
                    preloadData[index].BEGN_PROC_PRD,
                    ...newInputtedBegins.slice(index + 1)
                ]
                newInputtedEnds = [
                    ...newInputtedEnds.slice(0, index),
                    preloadData[index].END_PROC_PRD,
                    ...newInputtedEnds.slice(index + 1)
                ]
                newSelectedDistModeOptions = [
                    ...newSelectedDistModeOptions.slice(0, index),
                    preloadData[index].DSTRB_MDE_ID === 'None' ? '' : distModes[preloadData[index].DSTRB_MDE_ID],
                    ...newSelectedDistModeOptions.slice(index + 1)
                ];
                newSelectedProvDirDlvrCdOptions = [
                    ...newSelectedProvDirDlvrCdOptions.slice(0, index),
                    preloadData[index].PROV_DIR_DLVR_CD === 'None' ? 'select' : preloadData[index].PROV_DIR_DLVR_CD,
                    ...newSelectedProvDirDlvrCdOptions.slice(index + 1)
                ];
                newSelectedDfltSegAdrInd = [
                    ...newSelectedDfltSegAdrInd.slice(0, index),
                    preloadData[index].DFLT_SEG_ADR_IND === 'N' ? false : true,
                    ...newSelectedDfltSegAdrInd.slice(index + 1)
                ]
                newSelectedProviderArrangementOptions = [
                    ...newSelectedProviderArrangementOptions.slice(0, index),
                    providerArrangements[preloadData[index].PROV_ARNG_CD.toUpperCase()],
                    ...newSelectedProviderArrangementOptions.slice(index + 1)
                ];
                if (document.getElementById('dfltSegAdrIndCheck_' + index)) {
                    document.getElementById('dfltSegAdrIndCheck_' + index).checked = preloadData[index].DFLT_SEG_ADR_IND === 'N' ? false : true;
                }
            }
            setSelectedMdlIdOptions(newSelectedMdlIdOptions);
            setSelectedCycNumberOptions(newSelectedCycNumberOptions);
            setInputtedMpins(newInputtedMpins);
            setSelectedDocIdOptions(newSelectedDocIdOptions);
            setInputtedBegins(newInputtedBegins);
            setInputtedEnds(newInputtedEnds);
            setSelectedDistModeOptions(newSelectedDistModeOptions);
            setSelectedDfltSegAdrInd(newSelectedDfltSegAdrInd);
            setSelectedProvDirDlvrCdOptions(newSelectedProvDirDlvrCdOptions);
            setSelectedProviderArrangementOptions(newSelectedProviderArrangementOptions);
        }
    }, [preloadData])

    function clearRows() {
        var newInputtedMpins = [];
        var newInputtedBegins = [];
        var newInputtedEnds = [];
        var newSelectedClears = [];
        var newSelectedDfltSegAdrInd = [];
        var newSelectedActiveInds = [];
        for (let i = 0; i < selectedClears.length; i++) {
            newInputtedMpins.push(document.getElementById('mpinInput_' + i).value);
            newInputtedBegins.push(document.getElementById('beginInput_' + i).value);
            newInputtedEnds.push(document.getElementById('endInput_' + i).value);
            newSelectedClears.push(document.getElementById('clearCheck_' + i).checked);
            newSelectedDfltSegAdrInd.push(document.getElementById('dfltSegAdrIndCheck_' + i).checked);
            newSelectedActiveInds.push(document.getElementById('activeIndCheck_' + i).checked);
        }
        var newSelectedMdlIdOptions = selectedMdlIdOptions;
        var newSelectedCycNumberOptions = selectedCycNumberOptions;
        var newSelectedDocIdOptions = selectedDocIdOptions;
        var newSelectedDistModeOptions = selectedDistModeOptions;
        var newSelectedProvDirDlvrCdOptions = selectedProvDirDlvrCdOptions;
        var newSelectedProviderArrangementOptions = selectedProviderArrangementOptions;
        if (newSelectedClears.every(val => val === false)) {
            // all false, display popup
            alert('please select a row');
        } else {
            for (let i = newSelectedClears.length - 1; i >= 0; i--) {
                if (newSelectedClears[i]) {
                    // remove index i from all the selected lists
                    newSelectedClears = [
                        ...newSelectedClears.slice(0, i),
                        ...newSelectedClears.slice(i + 1)
                    ]
                    newSelectedMdlIdOptions = [
                        ...newSelectedMdlIdOptions.slice(0, i),
                        ...newSelectedMdlIdOptions.slice(i + 1)
                    ]
                    newSelectedCycNumberOptions = [
                        ...newSelectedCycNumberOptions.slice(0, i),
                        ...newSelectedCycNumberOptions.slice(i + 1)
                    ]
                    newInputtedMpins = [
                        ...newInputtedMpins.slice(0, i),
                        ...newInputtedMpins.slice(i + 1)
                    ]
                    newSelectedDocIdOptions = [
                        ...newSelectedDocIdOptions.slice(0, i),
                        ...newSelectedDocIdOptions.slice(i + 1)
                    ]
                    newInputtedBegins = [
                        ...newInputtedBegins.slice(0, i),
                        ...newInputtedBegins.slice(i + 1)
                    ]
                    newInputtedEnds = [
                        ...newInputtedEnds.slice(0, i),
                        ...newInputtedEnds.slice(i + 1)
                    ]
                    newSelectedDistModeOptions = [
                        ...newSelectedDistModeOptions.slice(0, i),
                        ...newSelectedDistModeOptions.slice(i + 1)
                    ]
                    newSelectedDfltSegAdrInd = [
                        ...newSelectedDfltSegAdrInd.slice(0, i),
                        ...newSelectedDfltSegAdrInd.slice(i + 1)
                    ]
                    newSelectedProvDirDlvrCdOptions = [
                        ...newSelectedProvDirDlvrCdOptions.slice(0, i),
                        ...newSelectedProvDirDlvrCdOptions.slice(i + 1)
                    ]
                    newSelectedProviderArrangementOptions = [
                        ...newSelectedProviderArrangementOptions.slice(0, i),
                        ...newSelectedProviderArrangementOptions.slice(i + 1)
                    ]
                    newSelectedActiveInds = [
                        ...newSelectedActiveInds.slice(0, i),
                        ...newSelectedActiveInds.slice(i + 1)
                    ]
                }
            }
        }
        // make sure it doesn't go under 10
        if (newSelectedClears.length < 10) {
            const missing = rowCount - newSelectedClears.length;
            newSelectedClears = newSelectedClears.concat(Array(missing).fill(false));
            newSelectedMdlIdOptions = newSelectedMdlIdOptions.concat(Array(missing).fill(0));
            newInputtedMpins = newInputtedMpins.concat(Array(missing).fill(''));
            newSelectedCycNumberOptions = newSelectedCycNumberOptions.concat(Array(missing).fill(0));
            newSelectedDocIdOptions = newSelectedDocIdOptions.concat(Array(missing).fill('select'));
            newInputtedBegins = newInputtedBegins.concat(Array(missing).fill(''));
            newInputtedEnds = newInputtedEnds.concat(Array(missing).fill(''));
            newSelectedDistModeOptions = newSelectedDistModeOptions.concat(Array(missing).fill('select'));
            newSelectedDfltSegAdrInd = newSelectedDfltSegAdrInd.concat(Array(missing).fill(false));
            newSelectedProvDirDlvrCdOptions = newSelectedProvDirDlvrCdOptions.concat(Array(missing).fill('select'));
            newSelectedProviderArrangementOptions = newSelectedProviderArrangementOptions.concat(Array(missing).fill('select'));
            newSelectedActiveInds = newSelectedActiveInds.concat(Array(missing).fill(false));
        }
        for (let i = 0; i < selectedClears.length; i++) {
            document.getElementById('mpinInput_' + i).value = newInputtedMpins[i];
            document.getElementById('beginInput_' + i).value = newInputtedBegins[i];
            document.getElementById('endInput_' + i).value = newInputtedEnds[i];
            document.getElementById('clearCheck_' + i).checked = newSelectedClears[i];
            document.getElementById('dfltSegAdrIndCheck_' + i).checked = newSelectedDfltSegAdrInd[i];
            document.getElementById('activeIndCheck_' + i).checked = newSelectedActiveInds[i];
        }
        // set lists of selected values
        setSelectedClears(newSelectedClears)
        setSelectedMdlIdOptions(newSelectedMdlIdOptions);
        setSelectedCycNumberOptions(newSelectedCycNumberOptions);
        setInputtedMpins(newInputtedMpins);
        setSelectedDocIdOptions(newSelectedDocIdOptions);
        setInputtedBegins(newInputtedBegins);
        setInputtedEnds(newInputtedEnds);
        setSelectedDistModeOptions(newSelectedDistModeOptions);
        setSelectedDfltSegAdrInd(newSelectedDfltSegAdrInd);
        setSelectedProvDirDlvrCdOptions(newSelectedProvDirDlvrCdOptions);
        setSelectedProviderArrangementOptions(newSelectedProviderArrangementOptions);
        setSelectedActiveInds(newSelectedActiveInds);
    }

    // save changes from table into db
    function saveChanges() {
        var temp = [];
        var userId = localStorage.getItem('sub');
        for (let i = 0; i < selectedClears.length; i++) {
            if (selectedDocIdOptions[i] !== 'select') {
                var tempDistModes = Object.keys(distModes).find(key => distModes[key] === selectedDistModeOptions[i]);
                var rowData = {
                    'row_number': i,
                    'MODEL_ID': String(selectedMdlIdOptions[i]),
                    'CYC_NBR': String(selectedCycNumberOptions[i]),
                    'CONTRACT_OWNER_MPIN': document.getElementById('mpinInput_' + i).value,
                    'DOCUMENT_ID': selectedDocIdOptions[i],
                    'BEGN_PROC_PRD': document.getElementById('beginInput_' + i).value,
                    'END_PROC_PRD': document.getElementById('endInput_' + i).value,
                    'DISTRIBUTION_MODE': tempDistModes !== undefined ? tempDistModes : '',
                    'DEFAULT_DATA_SEGMENT_ADDR_INDC': document.getElementById('dfltSegAdrIndCheck_' + i).checked === false ? 'N' : 'Y',
                    'PROVIDER_DIRECT_DELIVERY': selectedProvDirDlvrCdOptions[i] === 'select' ? '' : selectedProvDirDlvrCdOptions[i],
                    'PROVIDER_ARRANGEMENT': selectedProviderArrangementOptions[i],
                    'ACTIVE_INDICATOR': document.getElementById('activeIndCheck_' + i).checked === false ? 'N' : 'Y',
                    'DATA_SEG_ID': '',
                    'USER_ID': userId,
                }
                temp.push(rowData);
            }
        }
        setInputtedData(temp);
    }

    function handleNumberOnly(event, allowFloat) {
        const pattern = /^[0-9\b]+$/; // only allow digits and backspace  
        if (
            !pattern.test(event.key) &&
            event.key !== 'Backspace' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'Tab' &&
            !(event.ctrlKey && event.key === 'v') &&
            !(event.ctrlKey && event.key === 'c') &&
            !(event.metaKey && event.key === 'v') &&
            !(event.metaKey && event.key === 'c')
        ) {
            if (
                allowFloat &&
                event.key !== '.' &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            } else if (
                !allowFloat &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            }
        }
    }

    return (
        <Box>
            <TableContainer sx={{ borderRadius: 1, maxHeight: '60vh' }}>
                <Table>
                    <TableHead style={{ position: 'sticky', top: 0, zIndex: '20' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da', opacity: '1' }}>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>#</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign:'center' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Clear</Typography>
                                <input
                                    type='checkbox'
                                    defaultChecked={false}
                                    style={{ width:'18px', height:'18px', borderRadius:'5' }}
                                    id='clearAllCheck'
                                    onChange={(event) => {
                                        if (document.getElementById('clearCheck_0')) {
                                            for (let i = 0; i < selectedClears.length; i++) {
                                                document.getElementById('clearCheck_' + i).checked = event.target.checked;
                                            }
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Model Id</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Cycle Number</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Contract</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Owner MPIN</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Document Id</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Begin</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Process Period</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>End</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Process Period</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Distribution</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Mode</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Default Data</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Segment</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Address Ind</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Provider</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Direct Delivery</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Provider Arrangement</Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Active Ind</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ zIndex: '19' }}>
                        {loading && (
                            <TableRow>
                                <TableCell colSpan={13} sx={{ padding: 0 }}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {showBody && selectedClears.map((row, index) => (
                            <TableRow key={'row_' + index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell sx={{ textAlign:'center' }}>
                                    <input
                                        type='checkbox'
                                        id={'clearCheck_' + index}
                                        defaultChecked={false}
                                        style={{ width:'18px', height:'18px', borderRadius:'5' }}
                                        onChange={(event) => {
                                            if (!event.target.checked) {
                                                document.getElementById('clearAllCheck').checked = false;
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 65 }}
                                        size='small'
                                        value={selectedMdlIdOptions[index]}
                                        onChange={(event) => setSelectedMdlIdOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {mdlIdOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 60 }}
                                        size='small'
                                        value={selectedCycNumberOptions[index]}
                                        onChange={(event) => setSelectedCycNumberOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {cycNumberOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        style={{ width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={10}
                                        type='text'
                                        defaultValue={inputtedMpins[index]}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                        id={'mpinInput_' + index}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 140 }}
                                        size='small'
                                        value={selectedDocIdOptions[index]}
                                        onChange={(event) => setSelectedDocIdOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {docIdOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        style={{ width: 90, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        type='text'
                                        defaultValue={inputtedBegins[index]}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                        id={'beginInput_' + index}
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        style={{ width: 90, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        type='text'
                                        defaultValue={inputtedBegins[index]}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                        id={'endInput_' + index}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 140 }}
                                        size='small'
                                        value={selectedDistModeOptions[index]}
                                        onChange={(event) => setSelectedDistModeOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {distModeOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell sx={{ textAlign:'center' }}>
                                    <input
                                        type='checkbox'
                                        id={'dfltSegAdrIndCheck_' + index}
                                        defaultChecked={selectedDfltSegAdrInd[index]}
                                        style={{ width:'18px', height:'18px', borderRadius:'5' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 150 }}
                                        size='small'
                                        value={selectedProvDirDlvrCdOptions[index]}
                                        onChange={(event) => setSelectedProvDirDlvrCdOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {provDirDlvrCdOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        sx={{ width: 160 }}
                                        size='small'
                                        value={selectedProviderArrangementOptions[index]}
                                        onChange={(event) => setSelectedProviderArrangementOptions(prevState =>
                                            prevState.map((item, i) => i === index ? event.target.value : item)
                                        )}
                                    >
                                        <MenuItem value='select'></MenuItem>
                                        {providerArrangementOptions.map((option) => (
                                            <MenuItem value={option} key={index + '_' + option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell sx={{ textAlign:'center' }}>
                                    <input
                                        type='checkbox'
                                        id={'activeIndCheck_' + index}
                                        defaultChecked={selectedActiveInds[index]}
                                        style={{ width:'18px', height:'18px', borderRadius:'5' }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {showBody && (
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    <Button
                        variant='contained'
                        sx={{ mr: '1rem' }}
                        onClick={() => addRow()}
                        title="Add a new row"
                    >
                        Add
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ mr: '1rem' }}
                        title="Save changes"
                        onClick={() => saveChanges()}
                    >
                        Save
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ mr: '1rem' }}
                        onClick={() => clearRows()}
                        title="Clear all selected rows"
                    >
                        Clear
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ mr: '1rem' }}
                        title="Return to search"
                        // onClick={() => switchTab(0)}
                    >
                        Cancel
                    </Button>
                </Box>
            )}
        </Box>
    )
}