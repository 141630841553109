import React, { useEffect, useState } from "react";
import { AppBar } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// Admin imports
import { AdminRoles } from "../AdminTab/Roles/Roles";
import { AdminApproval } from "../AdminTab/Approval/Approval";
import { AdminCodes } from "../AdminTab/Codes/AdminCodes";
import { AdminGroupContracts } from "../AdminTab/GroupContracts/GroupContracts";
import { AdminExcludeContracts } from "../AdminTab/ExcludeContracts/ExcludeContracts";
// Maintenance imports
import { MaintenanceAdjustments } from "../MaintenanceTab/Adjustments/Adjustments";
import { MaintenanceParameters } from "../MaintenanceTab/Parameters/Parameters";
import { MaintenanceControl } from "../MaintenanceTab/Control/Control";
import { MaintenanceFactors } from "../MaintenanceTab/Factors/Factors";
// Member imports
import { MemberSearch } from "../MemberTab/MemberSearchTab/MemberSearch";
import { MemberHistoryTab } from "../MemberTab/MemberHistoryTab/MemberHistoryTab";
// Provider imports
import { ProviderTab } from "../ProviderTab/ProviderTab";
// Errors imports
import { Errors } from "../ErrorsTab/Errors";
// Job Process imports
import { JobProcess } from "../JobProcessTab/JobProcess";
// Reports imports
import { ReportsTab } from "../ReportsTab/ReportsTab";
import { RetroPeriodControl } from "../MaintenanceTab/Control/RetroPeriodControl";
import {ReportingOverridesControl} from "../MaintenanceTab/Overrides/ReportingOverridesControl"


export const InnerNav = ({ showTab, setTab }) => {
	return (
		<>
			<AppBar
				sx={{
					top: "7vh",
					backgroundColor: "transparent",
					color: "black",
					paddingLeft: "20px",
					paddingRight: "20px"
				}}
				style={{ marginTop: "8px", marginLeft: "-8px", boxShadow: "none" }}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Grid
						container
						spacing={1}
					>
						{showTab === "Roles" && <AdminRoles />}
						{showTab === "Approval" && <AdminApproval />}
						{showTab === "Codes" && <AdminCodes />}
						{showTab === "Group Contracts" && <AdminGroupContracts />}
						{showTab === "Exclude Contracts" && <AdminExcludeContracts />}
						{showTab === "Cap/Payment Adjustments" && (<MaintenanceAdjustments />)}
						{showTab === "Force Cap Parameters" && <MaintenanceParameters subTabName={showTab}/>}
						{showTab === "Retro Period Control" && <RetroPeriodControl />}
						{showTab === "Reporting Overrides" && <ReportingOverridesControl />}
						{showTab === "Premium Factors" && <MaintenanceFactors />}
						{showTab === "Contract" && <ProviderTab subTabName={showTab}/>}
						{showTab === "Demographics" && <ProviderTab subTabName={showTab} setTab={setTab}/>}
						{showTab === "History" && <ProviderTab subTabName={showTab}/>}
						{showTab === "CRT" && <ProviderTab subTabName={showTab}/>}
						{showTab === "Association" && <ProviderTab subTabName={showTab}/>}
						{showTab === "Member Search" && <MemberSearch />}
						{showTab === "Member History" && <MemberHistoryTab />}
						{showTab === "Member" && <ReportsTab subTabName={showTab} />}
						{showTab === "Benefit" && <ReportsTab subTabName={showTab} />}
						{showTab === "Provider" && <ReportsTab subTabName={showTab} />}
						{showTab === "ECapProcess" && <ReportsTab subTabName={showTab} />}
						{showTab === "Payment" && <ReportsTab subTabName={showTab} />}
						{showTab === "Premium" && <ReportsTab subTabName={showTab} />}
						{showTab === "Errors" && <Errors />}
					</Grid>
				</Box>
			</AppBar>
		</>
	);
};

export default InnerNav;
