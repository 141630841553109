import { useState, useEffect } from "react";

import { ProviderContractMemberRegular } from "./ProviderContractMemberRegular";
import { ProviderContractMemberDetail } from "./ProviderContractMemberDetail";

export const ProviderContractMember = ({
      renderRegular,
      searchLink,
      contractLink,
      lineLink,
      regularLink,
      detailLink,
      inputContractName,
      inputContractNumber,
      inputContractTypeCd,
      inputCalcMethCd,
      inputContractLineId,
      inputContractSublineID,
      selectedDataSegmentString,
      inputContrSrcSysCd,
      inputPublicPri,
      inputMemberSelEffDate,
      inputMemberSelEndDate,
      inputFundArgt,
      inputMsbp
}) => {
      const [contractName, setContractName] = useState();
      const [contractNumber, setContractNumber] = useState();
      const [contractTypeCd, setContractTypeCd] = useState();
      const [calcMethCd, setCalcMethCd] = useState();
      const [contractLineId, setContractLineId] = useState();
      const [contractSublineID, setContractSublineID] = useState();
      const [contrSrcSysCd, setContrSrcSysCd] = useState();
      const [pri, setPri] = useState();
      const [memberSelEffDate, setMemberSelEffDate] = useState();
      const [memberSelEndDate, setMemberSelEndDate] = useState();
      const [fundArgt, setFundArgt] = useState();
      const [msbp, setMsbp] = useState();

      useEffect(() => {
            setContractName(inputContractName);
      }, [inputContractName])
      useEffect(() => {
            setContractNumber(inputContractNumber);
      }, [inputContractNumber])
      useEffect(() => {
            setContractTypeCd(inputContractTypeCd);
      }, [inputContractTypeCd])
      useEffect(() => {
            setCalcMethCd(inputCalcMethCd);
      }, [inputCalcMethCd])
      useEffect(() => {
            setContractLineId(inputContractLineId);
      }, [inputContractLineId])
      useEffect(() => {
            setContractSublineID(inputContractSublineID);
      }, [inputContractSublineID])
      useEffect(() => {
            setContrSrcSysCd(inputContrSrcSysCd);
      }, [inputContrSrcSysCd])
      useEffect(() => {
            setPri(inputPublicPri);
      }, [inputPublicPri])
      useEffect(() => {
            setMemberSelEffDate(inputMemberSelEffDate);
      }, [inputMemberSelEffDate])
      useEffect(() => {
            setMemberSelEndDate(inputMemberSelEndDate);
      }, [inputMemberSelEndDate])
      useEffect(() => {
            setFundArgt(inputFundArgt);
      }, [inputFundArgt])
      useEffect(() => {
            setMsbp(inputMsbp);
      }, [inputMsbp])

      return (
            <div>
                  {renderRegular &&
                        <ProviderContractMemberRegular
                              searchLink={searchLink}
                              contractLink={contractLink}
                              lineLink={lineLink}
                              detailLink={detailLink}
                              inputContractName={contractName}
                              inputContractNumber={contractNumber}
                              inputContractTypeCd={contractTypeCd}
                              inputCalcMethCd={calcMethCd}
                              inputContractLineId={contractLineId}
                              inputContractSublineID={contractSublineID}
                              selectedDataSegmentString={selectedDataSegmentString}
                              inputContrSrcSysCd={contrSrcSysCd}
                              inputPublicPri={pri}
                              inputMsbp={msbp}
                        />
                  }
                  {!renderRegular &&
                        <ProviderContractMemberDetail
                              searchLink={searchLink}
                              regularLink={regularLink}
                              inputContractName={contractName}
                              inputContractNumber={contractNumber}
                              inputContractTypeCd={contractTypeCd}
                              inputCalcMethCd={calcMethCd}
                              inputContractLineId={contractLineId}
                              inputContractSublineID={contractSublineID}
                              selectedDataSegmentString={selectedDataSegmentString}
                              inputContrSrcSysCd={contrSrcSysCd}
                              inputPublicPri={pri}
                              inputMemberSelEffDate={memberSelEffDate}
                              inputMemberSelEndDate={memberSelEndDate}
                              inputFundArgt={fundArgt}
                              inputMsbp={msbp}
                        />
                  }
            </div>
      );
};