import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "@mui/material";
export const PopUpInfo = (props) => {
  const data = props.custInfo;
  const header = props.header;
  const filename = header + "download.csv";
  const timelines = props.timelines;
  const downloadHeader = props.downloadHeader;
  var primarySortedArray = []
    .concat(timelines)
    .sort(
      (a, b) =>
        +(a.mtcn < b.mtcn) ||
        +(a.mtcn === b.mtcn) - 1 ||
        +moment(a.begin).isBefore(b.begin) ||
        +(a.begin === b.begin) - 1
    );
  console.log(primarySortedArray);
  var downloadFile = {
    data: primarySortedArray,
    headers: downloadHeader,
    filename: filename,
  };
  if (!timelines)
    downloadFile = {
      data: [],
      headers: [],
      filename: "nodata.csv",
    };
  return (
    <div>
      <table className='popInfo'>
        <tbody>
          <tr>
            <td>
              <b>
                {" "}
                {header} &emsp; {""}:{" "}
              </b>{" "}
              {""}
              {data.name}
            </td>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              {data.memberId}
            </td>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b> Alt Member ID {""}: </b>
              {""}
              {data.altID}
            </td>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <Button
                variant='contained'
                color='primary'
                style={{ marginLeft: "10px" }}
              >
                {" "}
                <CSVLink
                  style={{ color: "white", textDecoration: "unset" }}
                  {...downloadFile}
                >
                  Download
                </CSVLink>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default PopUpInfo;
