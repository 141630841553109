import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react"
import { retroSearchUpdateApi } from "./retroPeriodControlUpdateApi"
import { LinearProgress } from "@mui/material"
import { getDataSegmentIntFromString } from "../../../Home/dataSegmentApi"


export const RetroPeriodControlUpdate = ({
    rowsToUpdate,
    switchTabs
}) => {
    const [tableData, setTableData] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (rowsToUpdate) {
            setTableData(rowsToUpdate);
            console.log(rowsToUpdate);
        }
    }, [rowsToUpdate])

    function handleNumberOnly(event, allowFloat) {
        const pattern = /^[0-9\b]+$/; // only allow digits and backspace  
        if (
            !pattern.test(event.key) &&
            event.key !== 'Backspace' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'Tab' &&
            !(event.ctrlKey && event.key === 'v') &&
            !(event.ctrlKey && event.key === 'c') &&
            !(event.metaKey && event.key === 'v') &&
            !(event.metaKey && event.key === 'c')
        ) {
            if (
                allowFloat &&
                event.key !== '.' &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            } else if (
                !allowFloat &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            }
        }
    }

    function handleUpdate() {
        setErrorMessages([]);
        var tempErrorMessages = [];
        // types of errors
        var missingBegin = false;
        var missingMax = false;
        var tooShortBegin = false;
        var tooShortEnd = false;
        var invalidBegin = false;
        var invalidEnd = false;
        var endBeforeBegin = false;
        var outOfBoundsMax = false;
        var capAlreadyProcessed = false;
        var futureBegin = false;
        var noChange = false;

        // setup request body
        var data = [];
        for (let i = 0; i < tableData.length; i++) {
            var temp = {
                "DATA_SEG_ID": getDataSegmentIntFromString(tableData[i].DATA_SEG_ID),
                "RETRO_TYPE_CD": tableData[i].RETRO_TYP_CD,
                "BEGIN_EFF": tableData[i].BEGIN_EFF,
                "UPDT_USER_ID": localStorage.getItem('sub')
            }
            let new_begin_eff = document.getElementById('beginDate_' + i).value;
            let new_end_date = document.getElementById('endDate_' + i).value;
            let max_period = document.getElementById('maxPeriod_' + i).value;
            temp['NEW_BEGIN_EFF'] = new_begin_eff;
            temp['NEW_BEGIN_END'] = new_end_date;
            temp['MAX_PERIOD'] = max_period;

            // validate
            if (temp["NEW_BEGIN_EFF"].length == 0) {
                missingBegin = true;
            } else if (temp["NEW_BEGIN_EFF"].length != 6) {
                tooShortBegin = true;
            } else if (parseInt(temp["NEW_BEGIN_EFF"]) < 194000) {
                invalidBegin = true;
            } else if (
                parseInt(temp["NEW_BEGIN_EFF"].slice(4)) > 12 ||
                parseInt(temp["NEW_BEGIN_EFF"].slice(4)) < 1
            ) {
                invalidBegin = true;
            }
            if (temp["NEW_BEGIN_END"].length != 6) {
                tooShortEnd = true;
            } else if (parseInt(temp["NEW_BEGIN_END"]) < 194000) {
                invalidEnd = true;
            } else if (
                parseInt(temp["NEW_BEGIN_END"].slice(4)) > 12 ||
                parseInt(temp["NEW_BEGIN_END"].slice(4)) < 1
            ) {
                invalidEnd = true;
            } else if (parseInt(temp["NEW_BEGIN_END"]) < parseInt(temp["NEW_BEGIN_EFF"])) {
                endBeforeBegin = true;
            }
            if (temp['MAX_PERIOD'] > tableData[i].SYS_DEF) {
                outOfBoundsMax = true;
            }
            data.push(temp);
        }

        if (missingBegin) {
            tempErrorMessages.push('* BeginEffectivePeriod is required.');
        }
        if (missingMax) {
            tempErrorMessages.push('* MaxPeriod is required.');
        }
        if (tooShortBegin) {
            tempErrorMessages.push('* Begin Effective Period should be in the format YYYYMM.');
        }
        if (tooShortEnd) {
            tempErrorMessages.push('* End Effective Period should be in the format YYYYMM');
        }
        if (invalidBegin) {
            tempErrorMessages.push('* Begin Effective Period is not a valid date');
        }
        if (invalidEnd) {
            tempErrorMessages.push('* End Effective Period is not a valid date.');
        }
        if (endBeforeBegin) {
            tempErrorMessages.push('* Begin Effective Period should not be greater than End Effective Period.');
        }
        if (outOfBoundsMax) {
            tempErrorMessages.push('* Max Period cannot be greater than the System Default');
        }

        if (tempErrorMessages.length == 0) {
            setLoading(true);
            retroSearchUpdateApi(data).then(response => {
                console.log(response);
                setLoading(false);
                setErrorMessages(['Updated Successfully']);
            }).catch(error => {
                if (error.response.data) {
                    if (error.response.data.results) {
                        for (let i = 0; i < error.response.data.results.length; i++) {
                            console.log(error.response.data.results[i]);
                            if (error.response.data.results[i].includes("already been processed for this effective period.")) {
                                capAlreadyProcessed = true;
                            }
                            if (error.response.data.results[i].includes("Future beginning effective period information exists.")) {
                                futureBegin = true;
                            }
                            if (error.response.data.results[i].includes("already exists for this Effective period")) {
                                noChange = true;
                            }
                        }
                    }
                }
                if (capAlreadyProcessed) {
                    tempErrorMessages.push('* Cap has already been processed for this effective period.');
                }
                if (futureBegin) {
                    tempErrorMessages.push('* Future beginning effective period information exists.');
                }
                if (noChange) {
                    tempErrorMessages.push('* Retro Period Control already exists for this Effective period.');
                }
                setLoading(false);
                setErrorMessages(tempErrorMessages);
            })
        } else {
            setErrorMessages(tempErrorMessages);
        }
    }

    return (
        <Box sx={{ mt: 1 }}>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
            <Box sx={{ width: '100%', textAlign: 'center', color: 'red' }}>
                {errorMessages.map((error) => (
                    <div>
                        {error}
                    </div>
                ))}
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Data Segment</TableCell>
                        <TableCell>Retro Type</TableCell>
                        <TableCell>Begin Effective Period</TableCell>
                        <TableCell>End Effective Period</TableCell>
                        <TableCell>Max Period</TableCell>
                        <TableCell>System Default</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.DATA_SEG_ID}</TableCell>
                            <TableCell>{row.RETRO_TYP_CD}</TableCell>
                            <TableCell>
                                <input
                                    id={'beginDate_' + index}
                                    type='text'
                                    defaultValue={row.BEGIN_EFF}
                                    style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                    maxLength={6}
                                    onKeyDown={(event) => handleNumberOnly(event, false)}
                                />
                            </TableCell>
                            <TableCell>
                                <input
                                    id={'endDate_' + index}
                                    type='text'
                                    defaultValue={row.BEGIN_END}
                                    style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                    maxLength={6}
                                    onKeyDown={(event) => handleNumberOnly(event, false)}
                                />
                            </TableCell>
                            <TableCell>
                                <input
                                    id={'maxPeriod_' + index}
                                    type='text'
                                    defaultValue={row.MAX_PERIOD}
                                    style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                    maxLength={2}
                                    onKeyDown={(event) => handleNumberOnly(event, false)}
                                />
                            </TableCell>
                            <TableCell>{row.SYS_DEF}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box sx={{ width: '100%' }}>
                <Button
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={() => handleUpdate()}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={() => switchTabs(0)}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    )
}