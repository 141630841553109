import { useEffect, useState } from 'react';

import { ReportsTable1 } from '../ReportsTable1';

function createData(reportID, reportName) {
    return { reportID, reportName};
}

const rows = [
    createData('EC0550PM', 'CAPITATION PAYABLES LIST'),
    createData('EC0560PM', 'CAPITATION PAY-TO ROLL UP LIST'),
    createData('EC0570PM', 'ACCOUNTS PAYABLE INTERFACE ERRORS'),
    createData('EC0580PM', 'PAYABLE / INVOICE COMPARISON REPORT'),
];

const viewButtonV = [true, false, false, true];
const downloadButtonV = [true, true, true, false];

export const ReportsPayment = ({inputDSeg}) => {
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        setDataSegments(inputDSeg);
    }, [inputDSeg]);

    return (
        <ReportsTable1 rows={rows} inputDSeg={dataSegments} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV}/>
    );
};
