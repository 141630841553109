import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import { LinearProgress, Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { InterfaceListApi } from './DailyInterfaceApi';
import { getDailyList, setDailyList } from '../errorPersistence';
import { yyyymmddToDdmmyyyy } from '../../helperFunctions';

export const DailyList = ({
    setRenderChoice,
    inputListData,
    setDetailData
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');
        if (!table) {
            console.error('Table not found');
            return;
        }
        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });
        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');
        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });
        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'DailyList.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        setErrorMessage('');
        var temp = getDailyList();
        if (temp) {
            setTableData(temp);
            setLoadingTable(false);
            setShowTable(true);
        } else {
            InterfaceListApi(inputListData).then(response => {
                if (response.data.length > 0) {
                    setLoadingTable(false);
                    setDailyList(response.data);
                    setTableData(response.data);
                    setShowTable(true);
                    setDetailData('DATA_SEG_ID', inputListData['DATA_SEG_ID']);
                    setDetailData('ERR_SEV_CD', inputListData['ERR_SEV_CD']);
                    setDetailData('JOB_TYP_CD', inputListData['JOB_TYP_CD']);
                    setDetailData('ERR_TYP_CD', inputListData['ERR_TYP_CD']);
                    setDetailData('LNG_DESC', inputListData['LNG_DESC']);
                } else {
                    setLoadingTable(false);
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                setLoadingTable(false);
                setErrorMessage('No data found');
                console.log(error);
            });
        }
    }, []);

    function dailyListClick(errorValue, jobId, time) {
        setDetailData('ERR_DATA_VAL', errorValue);
        setDetailData('JOB_ID', jobId);
        setDetailData('time', time);
        setRenderChoice(2);
    }

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputListData['DATA_SEG_ID'])} | Error Severity: {inputListData['ERR_SEV_CD']} | Error Category: {inputListData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputListData['ERR_TYP_CD']} | Error Message: {inputListData['LNG_DESC']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Daily Interface Search Results</a> {'>'} Error List
            </Typography>
            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Value</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Field</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Job ID</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Count</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Time Stamp</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => dailyListClick(row.ERR_DATA_VAL, row.JOB_ID, row.CREAT_DTTM)}>{row.ERR_DATA_VAL}</a></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_FLD_NM}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.JOB_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CNT}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{yyyymmddToDdmmyyyy(new Date(row.CREAT_DTTM).getFullYear() + '-' + String(new Date(row.CREAT_DTTM).getMonth() + 1).padStart(2, '0') + '-' + String(new Date(row.CREAT_DTTM).getDate()).padStart(2, '0'))}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%', mt: '1rem' }}>
                    <LinearProgress />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: '1rem' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
