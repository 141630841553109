import axios from 'axios';
const REPORTS_GET_CMS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/reports/member/get_cms`;
export const CMSApi = () => {
    return axios.post(
        REPORTS_GET_CMS_ENDPOINT,
        {
            headers:{
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
            },
        }
    )
}