import axios from 'axios';
const CONTRACT_PROVIDERCONTRACTSEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/providerContractSearch`;

export const providerContractSearchApi = (requestBody) => {
    return axios.post(
        CONTRACT_PROVIDERCONTRACTSEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}