import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Switch,
  FormControlLabel,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

const CodesUpdateTable = ({
  showTable,
  tableData,
  loading,
  handleActiveIndicatorChange,
  handleLongDescriptionChange,
  handleShortDescriptionChange,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableData1 = tableData || [];
  console.log(tableData1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!showTable) {
    return null;
  }

  return (
    <Paper sx={{ paddingLeft: "0.1rem", boxShadow: "none", width: "100%" }}>
      <TableContainer
        component={Paper}
        style={{ width: "100%" }}
      >
        <Table
          aria-label='simple table'
          stickyHeader
          style={{ width: "100%" }}
        >
          <TableHead style={{ backgroundColor: "#b0c0da" }}>
            <TableRow style={{ backgroundColor: "#b0c0da" }}>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Code Type
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Code
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Short Description
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Long Description
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Active/Inactive
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
              </TableRow>
            ) : (
              tableData1
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.ECAP_TYP_CD}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.ECAP_CD}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <TextField
                          value={row.SHRT_DESC}
                          onChange={(e) =>
                            handleShortDescriptionChange(e, index)
                          }
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <TextField
                          value={row.LNG_DESC}
                          onChange={(e) =>
                            handleLongDescriptionChange(e, index)
                          }
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.ACTV_IND === "Y"}
                              onChange={(e) =>
                                handleActiveIndicatorChange(e, index)
                              }
                              color='primary'
                            />
                          }
                          label={row.ACTV_IND === "Y" ? "Active" : "Inactive"}
                          labelPlacement='end'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={tableData1.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-selectLabel": {
            paddingTop: "12px",
          },
          ".MuiTablePagination-displayedRows": {
            paddingTop: "13px",
          },
        }}
      />
    </Paper>
  );
};

export default CodesUpdateTable;
