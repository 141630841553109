import { formatDateTime } from "../../utils/formatFunctions";
import { timelineFilter } from "../../utils/filterFunctions";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
export const FactorPopUp = (props) => {
  const columns = [
    {
      Header: "Processed Date",
      accessor: "processed",
      minWidth: 180,
      // Cell: (cell) => formatDateTime(cell.value),
    },
    {
      Header: "MTCN",
      accessor: "mtcn",
      minWidth: 50,
    },
    {
      Header: "Factor Eff Dt",
      accessor: "begin",
      minWidth: 100,
      // Cell: (cell) => formatDate(cell.value),
    },
    {
      Header: "Factor End Dt",
      accessor: "end",
      minWidth: 100,
      // Cell: (cell) => formatDate(cell.value),
    },
    {
      Header: "Gender",
      accessor: "gender",
      minWidth: 100,
    },
    {
      Header: "Relationship Code",
      accessor: "rel",
      minWidth: 125,
    },
    {
      Header: "BirthDate",
      accessor: "birthdate",
      minWidth: 100,
      // Cell: (cell) => formatDate(cell.value),
    },
    {
      Header: "County Code",
      accessor: "county",
      minWidth: 125,
    },
    {
      Header: "County Name",
      accessor: "countyName",
      minWidth: 150,
    },
    {
      Header: "State",
      accessor: "state",
      minWidth: 75,
    },
    {
      Header: "Zip",
      accessor: "zip",
      minWidth: 75,
    },
    {
      Header: "Medicare ID",
      accessor: "medicareId",
      minWidth: 100,
    },
    {
      Header: "Medicaid ID",
      accessor: "medicaidId",
      minWidth: 100,
    },
    {
      Header: "Hospice Indicator",
      accessor: "hospiceIndic",
      minWidth: 180,
    },
  ];
  const data = timelineFilter(props.data, props.start, props.end);
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "90vh", width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{
                    minWidth: column.minWidth,
                    padding: "4px",
                  }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default FactorPopUp;
