import axios from 'axios';
const CONTRACT_MEMBER_CRITERIA_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_member_criteria`;
const CONTRACT_CONTRACT_MEMBER_DETAILS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_member_detail`;

export const providerContractMemberRegularApi = (requestBody) => {
    return axios.post(
        CONTRACT_MEMBER_CRITERIA_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const providerContractMemberDetailApi = (requestBody) => {
    return axios.post(
        CONTRACT_CONTRACT_MEMBER_DETAILS_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}
