import axios from "axios";
const SEARCH_URL = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/search`;
const ADD_OPEN = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/add/open`;
const ADD_DATASEG = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/add/dataseg`;
const ADD_COVSRC = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/add/covsrcsys`;
const UPDATE = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/update`;
const SAVE = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/save`;



export const AdjustmentsSearchApi = (requestBody) => {
	return axios.post(
		SEARCH_URL,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}

export const AdjustmentsAddOpenApi = (requestBody) => {
	return axios.post(
		ADD_OPEN,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}

export const AdjustmentsAddDatasegApi = (requestBody) => {
	return axios.post(
		ADD_DATASEG,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}

export const AdjustmentsAddCovSrcApi = (requestBody) => {
	return axios.post(
		ADD_COVSRC,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}

export const AdjustmentsUpdateApi = (requestBody) => {
	return axios.post(
		UPDATE,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}

export const AdjustmentsSaveApi = (requestBody) => {
	return axios.post(
		SAVE,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
}
