import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { RetroPeriodSearch } from './RetroPeriodSearch';
import RetroPeriodAdd from './Add/RetroPeriodAdd';
import RetroPeriodConfigure from './RetroPeriodConfigure';
import { getDataSegments } from '../../Home/dataSegmentApi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, Breadcrumbs, Link, Box } from "@mui/material";
import { RetroPeriodControlUpdate } from './Update/RetroPeriodControlUpdate';

export const RetroPeriodControl = () => {
    const [selectedUpdateRows, setSelectedUpdateRows] = useState([]);
    const [dataSegments, setDataSegments] = useState([]);
    const [value, setValue] = useState(0);
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        false,
        false,
        true
    ]);

    const sections = [
        "Search",
        "Add",
        "Configure",
        "Update",
    ];

    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTabs = (newValue) => {
        setValue(newValue);
    };
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };

    useEffect(() => {
        if (value === 3) {
            console.log('hey');
            setDisabledTabs([false, false, false, false]);
        } else {
            setDisabledTabs([false, false, false, true]);
        }
    }, [value])

    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return (
                <RetroPeriodSearch
                    setSelectedUpdateRows={setSelectedUpdateRows}
                    switchTabs={switchTabs}
                />
            );
        }
        if (value === 1) {
            return (
                <RetroPeriodAdd
                    inputDataSegments={dataSegments}
                    switchTabs={switchTabs}
                />
            );
        }
        if (value === 2) {
            return (
                <RetroPeriodConfigure
                    inputDataSegments={dataSegments}
                    switchTabs={switchTabs}
                />
            );
        }
        if (value === 3) {
            return (
                <RetroPeriodControlUpdate
                    rowsToUpdate={selectedUpdateRows}
                    switchTabs={switchTabs}
                />
            )
        }
    };

    useEffect(() => {
        setDataSegments(getDataSegments());
    }, []);
    return (
        <Box sx={{ mt: '10px', width: '100%' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link color='inherit' underline="hover" href='/'>
                    Maintenance
                </Link>
                <Typography color="navy">Retro Period Control</Typography>
            </Breadcrumbs>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section, index) => (
                    <Tab
                        key={index}
                        label={section}
                        disabled={disabledTabs[index]}
                    />
                ))}
            </Tabs>
            <MakeTabComponent />
        </Box>
    );
};

