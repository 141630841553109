import axios from 'axios';
const FACTORS_ADD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/premium/add`;

export const FactorsAddApi = (requestBody) => {
    return axios.post(
        FACTORS_ADD_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}