import {
    AppBar,
    Container,
    Toolbar,
    Paper,
    TextField,
    Grid,
    Typography,
    Link,
    Button,
    Box,
    Select,
    MenuItem,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio,
    Stepper,
    Step,
    StepLabel
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';


export const Upload = () => {

    return (
        <Grid container spacing={2} style={{ margin: '15px 0px 0px 0px' }} sx={{ my: 1, maxHeight: '800px', overflowY: 'auto', padding: 2, }}>
            <Grid xs={12} sm={12} md={12} lg={12}>
                <Container component='main' maxWidth='md' sx={{ mb: 4 }}>
                    <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Box sx={{ my: 1 }}>
                            <Grid container spacing={4} item justifyContent="center">
                                <Grid item>
                                    <Typography>Please select a CSV (Comma Separated Values) file</Typography>
                                </Grid>
                            </Grid>
                            <Grid container  spacing={2} justifyContent="center">
                                <Grid item>
                                    <Typography variant='h6'>Select File to Upload </Typography>
                                </Grid>
                                <Grid item alignItems="stretch" style={{ display: "flex" }}>
                                    <Button variant="outlined" component="label" startIcon={<UploadFileIcon />}>Choose File</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} item justifyContent="center">
                                <Grid item >
                                    <Button
                                        type='next'
                                        variant='contained'
                                        sx={{ mt: 3, ml: 1 }}
                                        //fullWidth
                                        size='large'

                                    >
                                        Upload
                                    </Button>
                                    <Button
                                        type='next'
                                        variant='contained'
                                        sx={{ mt: 3, ml: 1 }}
                                        //fullWidth
                                        size='large'
                                        color='error'

                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Container>
            </Grid>
        </Grid>
    );
};
export default Upload;