import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TableHead from '@mui/material/TableHead';
import React, { useState, useEffect } from 'react'; 
import * as Constants from "./MemberInfoConstants";
import { MemberInfoApi } from "./MemberInfoApi";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
  } from "@mui/material";


// Initialize the cache outside of the component  
const apiCache = new Map(); 

  const MemberMemberInfo = ({memberData}) => {

    const [apiData, setApiData] = useState(null); 
    
    const formatDate = (item, date) => {  
      if (item && item.data && item.data[0] && item.data[0][date]) {  
          const dateStr = item.data[0][date];  
          const [year, month, day] = dateStr.split('-');  
          return `${month}/${day}/${year}`;  
      }  
      return '';  
    } 
    function formatCurrency(amount) {  
      if (amount === null || amount === undefined || isNaN(amount) || amount === 0 ) {  
        return "$0.00";  
      }  
      return `$${Number(amount).toFixed(2)}`;  
    } 
  
    const formatDateNew = (dateString) => {  
      if (typeof dateString === 'string' && dateString) {  
        // Split the string into parts  
        const parts = dateString.split('-');  
        // Reorder the parts to MM/DD/YYYY format  
        return `${parts[1]}/${parts[2]}/${parts[0]}`;  
      }  
      return ''; 
    }
      
    

    useEffect(() => {  
      if (memberData) {  
          const { CONTR_LN_ID ,DATA_SEG_ID, ECAP_MBR_KEY } = memberData; 
          
          const cacheKey = `${CONTR_LN_ID}-${DATA_SEG_ID}-${ECAP_MBR_KEY}`; 

            // Check if the data is already in the cache  
      if (apiCache.has(cacheKey)) {  
        // Use the cached data  
        setApiData(apiCache.get(cacheKey));  
      } else { 
          MemberInfoApi(CONTR_LN_ID,DATA_SEG_ID, ECAP_MBR_KEY)  
              .then(response => {  
                console.log("response from member info is",response.data); 
                // Cache the response data  
                apiCache.set(cacheKey, response.data); 
                setApiData(response.data);  
              })  
              .catch(error => {  
                  console.error("Error fetching coverage data:", error);  
              });  
      }  
    }
  }, [memberData]); 

  const headerData = apiData && apiData[0];  
  const member1Data = apiData && apiData[1] && apiData[1][0] && apiData[1][0].member1; 
    return (

        <div>
         <br></br>
         <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX }`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>
            
       <br></br>
       <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                      <TableCell sx={Constants.tableCellStyle}><strong>DOB</strong></TableCell>
                      <TableCell sx={Constants.tableCellStyle}>{formatDateNew(headerData?.data?.[0]?.BTH_DT)}   </TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell sx={Constants.tableCellStyle}><strong>Employer Group#</strong></TableCell>
                      <TableCell sx={Constants.tableCellStyle}>{memberData.CUST_ID}</TableCell>
                    </TableRow>
                 
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
              <TableBody>  
      <TableRow>  
        <TableCell sx={Constants.tableCellStyle}><strong>Benefit Plan ID</strong></TableCell>  
        <TableCell sx={Constants.tableCellStyle}>{}</TableCell>  
      </TableRow>  
      <TableRow>  
        <TableCell sx={Constants.tableCellStyle}><strong>OV</strong></TableCell>  
        <TableCell sx={Constants.tableCellStyle}>  
        {formatCurrency(member1Data ? member1Data.OFC_COPAY_AMT : null)}  
</TableCell>  

      </TableRow>  
      {/* Additional TableRows for other data can be added here */}  
    </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
              <TableBody>  
      <TableRow>  
        <TableCell sx={Constants.tableCellStyle}><strong>HMO ADC</strong></TableCell>  
        <TableCell sx={Constants.tableCellStyle}>{}</TableCell>  
      </TableRow>  
      <TableRow>  
        <TableCell sx={Constants.tableCellStyle}><strong>Obligor ID</strong></TableCell>  
        <TableCell sx={Constants.tableCellStyle}>{member1Data ? member1Data.OBLIG_ID : 'N/A'}</TableCell>  
      </TableRow>  
      {/* Additional TableRows for other data can be added here */}  
    </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
            
            <div style={{marginTop: "2rem"}}>
                <div style={{
                    display : "flex",
                    justifyContent : "flex-end",
                    margin : "2rem 2rem",
                }}>
            
              </div>
            {/* <Paper sx={{ width: '172.3%',  }}> */}
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                            <TableHead>

                                <TableRow>
                                    <Constants.StyledTableCell>RLTN</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Last</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>First</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>DOB</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Sex</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>HIC Number</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>MPIN</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Provider Name</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Start Date</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Term Date</Constants.StyledTableCell>
                                    </TableRow>
                            </TableHead>
                            <Constants.StripedTableBody>
                            {apiData && apiData.map((item, index) => (  
                <TableRow key={index}>  
                    <TableCell>{item.data?.[0]?.MBR_REL_CD}</TableCell>  
                    <TableCell>{item.data?.[0]?.MBR_LST_NM}</TableCell>  
                    <TableCell>{item.data?.[0]?.MBR_FST_NM}</TableCell> 
                    <TableCell>{formatDate(item, "BTH_DT")}</TableCell> 
                    <TableCell>{item.data?.[0]?.GDR_CD}</TableCell>  
                    <TableCell>{item.data?.[0]?.HIC_ID}</TableCell>  
                    <TableCell>{item.data?.[0]?.PCP_PROV_ID}</TableCell>  
                    <TableCell>{item.data?.[0]?.PROV_LST_FST_NM}</TableCell>  
                    <TableCell>{formatDate(item, "MBR_PCP_EFF_DT")}</TableCell>
                    <TableCell>{formatDate(item, "MBR_PCP_END_DT")}</TableCell> 
                </TableRow>  
            ))}  
                            </Constants.StripedTableBody>
                        </Table>
                    </TableContainer>

                {/* </Paper> */}
            </div>
        </div>
    );
};

export default MemberMemberInfo;

