import Button from '@mui/material/Button'
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { AssociationDownloadApi } from "./AssociationDownloadApi";
import { useState } from 'react';

export const AssociationDownload = () => {

    const [capProcess, setCapProcess] = useState(null);

       {/* Cap Process Input */}
       

        function handleDownloadClick(capProcess, Download) {
            var capProcess = '';
        
            if (document.getElementById(`capProcess`)) {
                capProcess = document.getElementById(`capProcess`).value;
            }
         
            var requestBody = {
                "cap_process_month": "202401",
            }
            console.log(requestBody);
          
            if (Download) {
                requestBody['type'] = 'csv';
            }
            AssociationDownloadApi(requestBody).then(response => {
                console.log(response);
                if (response.data.size !== 0) {
                   if (Download) {
                        // Create a link element with the PDF file  
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = `CapProcess.csv`;  
                        link.click();
                    }
                }
            })
        .catch(error => {
            console.log(error);
            
           
        });
    }
    return (
        
        <div style={{ marginTop: "2rem", width: '100%'}}>   
        <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
       <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, mr: 4.9 }}>Cap Process</Typography>
       <Box sx={{ flex: 2, minWidth: 30, maxWidth: 100 }}>
           {/* Reduced maxWidth to 200 */}
           <TextField
               required
               id="taxIDInput"
               value={capProcess}
               fullWidth
               size="small"
           />
       </Box>
   </Box>
   <div style={{ marginTop: "2rem", width: '100%'}}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ textTransform: 'none'}} size="small" variant="contained" onClick={() => handleDownloadClick(capProcess, 'download')}> DOWNLOAD</Button>
        </div>
        </div>
        </div>
    );
};
