import axios from "axios";

const CONTRACT_MPIN_API = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/popup_contr_id`;

export const contractMpinApi = (requestBody) => {
    return axios.post(
        CONTRACT_MPIN_API, 
        requestBody,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    })
}