import axios from 'axios';
const CONTRACT_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/contract_detail`;

export function errorContractDetailApi(requestBody) {
    return axios.post(
        CONTRACT_DETAIL_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        }
    );
};