  import axios from 'axios';  
  const MEMBER_HISTORY_ADJUSTMENTS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/adjustments`;
  
// SearchHandler.js  
export const MemberAdjustmentsApi = (  
  data_segment,
  ecap_contr_ln_key,
  ecap_mbr_key,
  proc_yr_mo,
  

 ) => {
  // Prepare request body  
  const requestBody = {  
    data_segment: data_segment,
    ecap_contr_ln_key: ecap_contr_ln_key,
    ecap_mbr_key:  ecap_mbr_key,
    proc_yr_mo: proc_yr_mo,
    cap_pay_typ_cd: "STD SERV ",
    pay_partn_id: data_segment
  };  
  

  return axios.post(  
    MEMBER_HISTORY_ADJUSTMENTS_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
}; 

