import { useEffect, useState } from "react";
import { Snackbar, Alert, Box, Button, LinearProgress, MenuItem, Select, Typography, Grid, InputLabel } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { green, pink } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StorageIcon from '@mui/icons-material/Storage';
import CardContent from '@mui/material/CardContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from "@mui/icons-material/Cancel";
import Papa from 'papaparse';
import { OverridesUploadApi } from './ReportingOverridesUploadApi';
import { getDataSegmentIntFromString, getDataSegments} from "../../../Home/dataSegmentApi";
import { testPDSeg } from '../../../testPopupDataSegment';


export const ReportingOverridesUpload = ({ switchTab }) => {
    const OVERRIDES_UPLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/upload`;
    const userId = localStorage.getItem("sub");
    const [dataSegment, setDataSegment] = useState("");
    const [dataSegments, setDataSegments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sucessCount, setSucessCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [isDataSegmentSelected, setIsDataSegmentSelected] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [isCsvFile, setIsCsvFile] = useState(false);

    const handleUpload = () => {
        console.log('The user ID is: ' , userId);
        const file = document.getElementById('fileInput').files[0];
        if (!dataSegment) {
            return setIsDataSegmentSelected(true);
        }
        if (!file) {
            return setIsFileSelected(true);
        }
        if (!file.name.includes('.csv')) {
            return setIsCsvFile(true);
        }

        setLoading(true);
        // Parse CSV file, pass the data into the API, send csv with errors to the end user
        Papa.parse(file, {
            header: true,
            delimiter: ",",
            skipEmptyLines: true,
            quoteChar: '"',
            escapeChar: '\\',
            complete: async function (results) {
                console.log("Parsed data: ", results.data);
                const rowsWithErrors = await handleUploadApiCall(results.data);
                if(rowsWithErrors.length > 0){
                    const csvBlob = generateCsvBlob(rowsWithErrors);
                    downloadCsv(csvBlob);
                    // TODO: Send the email to the end user with the error csv file

                }
                setLoading(false);
            },
            error: function (error) {
                console.log("Error parsing CSV file: ", error);
                setLoading(false);
            }
        })   
    }

    const generateCsvBlob = (rowsWithErrors) => {
        const csv = Papa.unparse(rowsWithErrors);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        return blob;
    }

    const downloadCsv = (csvBlob) => {
        const url = window.URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'error_rows.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleUploadApiCall = async (csvData) => {
        const rowWithErrors = [];
        for (let i = 0; i < csvData.length; i++) {
            const rowData = csvData[i];
            try {
                // Trim the white space for the PROV_ARNG_CD
                rowData.PROV_ARNG_CD = rowData.PROV_ARNG_CD.trim();
                // Mapping the DOC_ID to DOCUMENT_ID
                rowData.DATA_SEG_ID_UPLOAD = `${getDataSegmentIntFromString(dataSegment)}`;
                rowData.UPDT_USER_ID = `${userId}`;
                rowData.DOCUMENT_ID = rowData.DOC_ID;
                delete rowData.DOC_ID
                const response = await fetch(OVERRIDES_UPLOAD_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    },
                    body: JSON.stringify([rowData])
                });

                const result = await response.json();
                if (result[0][0].includes('error')) {
                    rowData.ERROR_COMMENTS = result[0][1];
                    rowWithErrors.push(rowData);
                    // If upload fails
                    setFailedCount(prevCount => prevCount + 1);
                } else {
                    rowData.ERROR_COMMENTS = "";
                    // If upload is successful
                    setSucessCount(prevCount => prevCount + 1);
                }
                console.log("Updated Row Data: ", rowData);
            } catch (error) {
                console.log("Error calling");
            }
        }
        return rowWithErrors;
    }

    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegments(dataList);
    }, [])

    const dataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    }

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    return (
        <Box>
            <Grid container spacing={.5} mt={1.5} >
                <Grid sm={4} md={4} pl={2}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ alignContent: 'center', width: 150 }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Data Segment<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                        </Box>
                        <Select
                            labelId="data-seg-select"
                            id="data-seg-select"
                            value={dataSegment}
                            onChange={dataSegmentChange}
                            sx={{ width: 140, height: 40 }}
                        >
                            {dataSegments.map((dataSegment) => (
                                <MenuItem key={dataSegment} value={dataSegment}>{dataSegment}</MenuItem>

                            ))}
                        </Select><div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                alt="data-seg-pop-up-icon"
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <InputLabel
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                color: 'black',
                                flex: 1,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Select File to Upload<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Box><Typography variant='body2'>Please select a CSV (Comma Separated Values) file</Typography></Box>
                        <Box>
                            <input id='fileInput' type="file" accept=".csv" />
                        </Box>
                        <Box sx={{ display: 'flex', mt: 1 }}>
                            <Button
                                variant='contained'
                                startIcon={<CloudUploadIcon />}
                                sx={{ mr: 1 }}
                                onClick={handleUpload}
                                disabled={loading}
                            >
                                Upload
                            </Button>
                            <Button variant="contained" color='error' startIcon={<CancelIcon />} onClick={() => switchTab(0)}>Cancel</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid sm={4} md={4}></Grid>
                <Grid sm={4} md={4} container direction="column" justifyContent="flex-top" alignItems="flex-end">
                    <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
                        <CardMedia sx={{ padding: 2, backgroundColor: '#F5F5F5' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Avatar sx={{ bgcolor: green[500], width: 56, height: 56 }} >
                                    <AssignmentIcon style={{ fontSize: 40 }} />
                                </Avatar>
                                <Box flexGrow={1} mx={2}>
                                    {loading && <LinearProgress color="secondary" />}
                                </Box>
                                <Avatar sx={{ bgcolor: pink[500], width: 56, height: 56 }} >
                                    <StorageIcon style={{ fontSize: 40 }} />
                                </Avatar>
                            </Box>
                        </CardMedia>
                        <CardContent sx={{ padding: 3 }}>
                            <Typography variant="h7" gutterBottom>
                                We are reading your CSV file and inserting into the database. If there is any issue with the data, you will receive an email attach with the CSV file.
                            </Typography>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography sx={{ color: '#10375C', fontWeight: 'bold' }}>Success Count:</Typography>
                                <Typography sx={{ color: 'green', fontWeight: 'bold', ml: 1 }}>{sucessCount}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ color: '#10375C', fontWeight: 'bold' }}>Fail Count:</Typography>
                                <Typography sx={{ color: 'red', fontWeight: 'bold', ml: 1 }}>{failedCount}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Snackbar
                open={isDataSegmentSelected}
                autoHideDuration={5000}
                onClose={() => setIsDataSegmentSelected(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsDataSegmentSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Please Select a Data Segment
                </Alert>
            </Snackbar>

            <Snackbar
                open={isFileSelected}
                autoHideDuration={5000}
                onClose={() => setIsFileSelected(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsFileSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Please select a CSV file to upload with ( , ) comma delimiter format
                </Alert>
            </Snackbar>

            <Snackbar
                open={isCsvFile}
                autoHideDuration={5000}
                onClose={() => setIsCsvFile(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsCsvFile(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Uploaded file should be CSV file.
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default ReportingOverridesUpload;
