import React, { useState, useEffect } from 'react';
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box } from '@mui/material';
import { contractLineApi1, contractLineApi2, contractLineApi3 } from "./contractLineApi";
import { getDataSegmentIntFromString } from '../../../Home/dataSegmentApi';
import { yyyymmddToMmddyyyy, stringDecimals, checkNone } from '../../../helperFunctions';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	TableHead,
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { storeContractContractLineResults, getContractContractLineResults, storeContractContractSubLineResults, getContractContractSubLineResults, getContractContractClmPoolResults, storeContractContractClmPoolResults } from '../providerContractPersistence';

// ProviderContractLine component            
export const ProviderContractLine = ({
	searchLink,
	contractLink,
	rateLink,
	inputContractName,
	inputContractNumber,
	inputContractLineId,
	inputContractTypeCd,
	inputCalcMethCd,
	inputContractLineEffDate,
	inputContractLineEndDate,
	inputPublicPri,
	selectedDataSegmentString,
	inputContrSrcSysCd,
	setContractSublineID,
	setPublicStateCd
}) => {
	// 3 columns of data
	const [columnOneData, setColumnOneData] = useState([]);
	const [columnTwoData, setColumnTwoData] = useState([]);
	const [columnThreeData, setColumnThreeData] = useState([]);
	// subline, clmpool tables
	const [subLineTableData, setSubLineTableData] = useState([]);
	const [clmPoolTableData, setClmPoolTableData] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// from previous queries
	const [contractName, setContractName] = useState();
	const [contractNumber, setContractNumber] = useState();
	const [contractLineId, setContractLineId] = useState();
	const [contractTypeCd, setContractTypeCd] = useState();
	const [calcMethCd, setCalcMethCd] = useState();
	const [contractLineEffDate, setContractLineEffDate] = useState();
	const [contractLineEndDate, setContractLineEndDate] = useState();
	const [pri, setPri] = useState();
	const [contrSrcSysCd, setContrSrcSysCd] = useState();

	useEffect(() => {
		if (inputContractName) {
			setContractName(inputContractName);
		}
	}, [inputContractName]);
	useEffect(() => {
		if (inputContractNumber) {
			setContractNumber(inputContractNumber);
		}
	}, [inputContractNumber]);
	useEffect(() => {
		if (inputContractLineId) {
			setContractLineId(inputContractLineId);
		}
	}, [inputContractLineId]);
	useEffect(() => {
		if (inputContractTypeCd) {
			setContractTypeCd(inputContractTypeCd);
		}
	}, [inputContractTypeCd]);
	useEffect(() => {
		if (inputCalcMethCd) {
			setCalcMethCd(inputCalcMethCd);
		}
	}, [inputCalcMethCd]);
	useEffect(() => {
		setContractLineEffDate(inputContractLineEffDate);
	}, [inputContractLineEffDate]);
	useEffect(() => {
		setContractLineEndDate(inputContractLineEndDate);
	}, [inputContractLineEndDate]);
	useEffect(() => {
		if (inputPublicPri) {
			setPri(inputPublicPri);
		}
	}, [inputPublicPri]);
	useEffect(() => {
		if (inputContrSrcSysCd) {
			setContrSrcSysCd(inputContrSrcSysCd);
		}
	}, [inputContrSrcSysCd]);

	function fillLineDetails(data) {
		var temp = data[0];
		setPublicStateCd(temp.state_code);
		setColumnOneData([
			["Line Eff Date", yyyymmddToMmddyyyy(temp.contr_ln_eff_dt)],
			["Line End Date", yyyymmddToMmddyyyy(temp.contr_ln_end_dt)],
			["Data Segment ID",selectedDataSegmentString],
			["State Code", checkNone(temp.state_code)],
			["Health Plan Code", checkNone(temp.health_plan_code)],
			["Global Cap Indicator", temp.global_cap_indicator],
			["Physician %", stringDecimals(temp.physician_percent, 1)],
			["Hospital %", stringDecimals(temp.hospital_percent, 1)],
			["Mrbrshp Selection Final Day", temp.mrbrshp_selection_final_day],
			["Pay Term Day", temp.pay_term_day],
			["Auto Debit Days#", temp.auto_debit_days]
		]);
		setColumnTwoData([
			["Insurer Fee/Premium Tax Indicator", temp.insurer_fee_premium_tax_indicator],
			["EFT Pymt Sys Ind", temp.eft_pymt_sys_ind],
			["EFT Pymt Eff Date", yyyymmddToMmddyyyy(temp.eft_pymt_eff_date)],
			["Settlement Addr ID", temp.settlement_addr_id],
			["Settlement Addr Type Code", temp.settlement_addr_type_code],
			["Deduct Excptn Clm Ind", temp.deduct_excptn_clm_ind],
			["Proration Type Code", temp.proration_type_code],
			["Part D Buy-Down Exclusion Indicator", temp.part_d_buy_down_exclusion_indicator],
			["Legal Entity Id", temp.legal_entity],
			["Info Date", yyyymmddToMmddyyyy(temp.id_info_date.split('T')[0])]
		]);
		setColumnThreeData([
			["Retro Window Type Code", temp.retro_window_type_code],
			["Retro Window Prem Chg#", temp.retro_window_prem_chg],
			["Retro Window Elig Add#", temp.retro_window_elig_add],
			["Retro Window Elig Term#", temp.retro_window_elig_trm],
			["Retro Window Contract Chg#", temp.retro_window_contract_chg],
			["Pymt Addr Type Code", temp.pymt_addr_type_code],
			["Pymt Addr ID", temp.pymt_addr_id],
			["Adverse Sel Low%", stringDecimals(temp.adverse_sel_low_percent, 1)],
			["Adverse Sel High%", stringDecimals(temp.adverse_sel_high_percent, 1)],
			["Maternity Amount", stringDecimals(temp.maternity_amount, 2)]
		]);
	}

	useEffect(() => {
		var temp = getContractContractLineResults();
		if (temp) {
			fillLineDetails(temp);
		} else if (contractNumber && contractLineId && contractTypeCd && calcMethCd && contractLineEffDate && contractLineEndDate && pri) {
			const requestBody = {
				"CONTR_ID": contractNumber,
				"CONTR_LN_ID": contractLineId,
				"CONTR_TYP_CD": contractTypeCd,
				"CALC_METH_CD": calcMethCd,
				"CONTR_LN_EFF_DT": contractLineEffDate,
				"CONTR_LN_END_DT": contractLineEndDate,
				"PRDCT_RDY_CD": pri
			}
			contractLineApi1(requestBody).then(response => {
				if (response.data.length > 0) {
					fillLineDetails(response.data);
					storeContractContractLineResults(response.data);
				}
			}).catch(error => {
				console.log("There was an error fetching contract line data from api 1 (line)");
			});
		}
	}, [contractNumber, contractLineId, contractTypeCd, calcMethCd, contractLineEffDate, contractLineEndDate, pri]);

	useEffect(() => {
		var temp = getContractContractSubLineResults();
		if (temp) {
			setSubLineTableData(temp);
		} else if (contractNumber && contractLineId && contrSrcSysCd && pri) {
			var requestBody = {
				"CONTR_ID": contractNumber,
				"CONTR_LN_ID": contractLineId,
				"CONTR_SRC_SYS_CD": contrSrcSysCd,
				"PRDCT_RDY_CD": pri
			}
			contractLineApi2(requestBody).then(response => {
				if (response.data.length > 0) {
					var temp = response.data;
					temp.sort(function (first, second) {
						return first.id - second.id;
					});
					setSubLineTableData(temp);
					storeContractContractSubLineResults(temp);
				}
			}).catch(error => {
				console.log("There was an error fetching contract line data from api 2 (subline)");
			});
		}
		temp = getContractContractClmPoolResults();
		if (temp) {
			setClmPoolTableData(temp);
		} else if (contractNumber && contractLineId && contrSrcSysCd && pri) {
			var requestBody = {
				"CONTR_ID": contractNumber,
				"CONTR_LN_ID": contractLineId,
				"CONTR_SRC_SYS_CD": contrSrcSysCd,
				"PRDCT_RDY_CD": pri
			}
			contractLineApi3(requestBody).then(response => {
				if (response.data.length > 0) {
					setClmPoolTableData(response.data);
					storeContractContractClmPoolResults(response.data);
				}
			}).catch(error => {
				console.log("There was an error fetching contract line data from api 3 (clm pool)");
			});
		}
	}, [contractNumber, contractLineId, contrSrcSysCd, pri])

	function sublineClick(sublineID) {
		setContractSublineID(sublineID);
		rateLink();
	}

	// Table cell style            
	const tableCellStyle = {
		fontSize: "0.875rem",
		padding: "1px",
	};

	// Row colors for alternating rows            
	const rowColors = ["#b0c0da", "#ffffff"];

	// ProviderContractLine component JSX            
	return (
		<div>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {inputContractTypeCd} | Calc Method: {calcMethCd}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Contract#: {contractNumber} | Contract Line#: {contractLineId}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => contractLink()}>Contract</a> {">"} Contract Line
			</Typography>
			{columnOneData.length > 0 && columnTwoData.length > 0 && columnThreeData.length > 0 &&
				<Grid container spacing={2} sx={{ marginTop: '.05rem' }}>
					<Grid item xs={4}>
						<Stack spacing={.5}>
							<TableContainer component={Paper}>
								<Table size='small'>
									<TableBody>
										{/* Replace with your actual data */}
										{columnOneData.map(([key, value], index) => (
											<TableRow
												key={key}
												style={{ backgroundColor: rowColors[index % 2] }}
											>
												<TableCell sx={tableCellStyle}>{key}</TableCell>
												<TableCell sx={tableCellStyle}>{value}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={0.5}>
							<TableContainer component={Paper}>
								<Table size='small'>
									<TableBody>
										{/* Replace with your actual data */}
										{columnTwoData.map(([key, value], index) => (
											<TableRow
												key={key}
												style={{ backgroundColor: rowColors[index % 2] }}
											>
												<TableCell sx={tableCellStyle}>{key}</TableCell>
												<TableCell sx={tableCellStyle}>{value}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={0.5}>
							<TableContainer component={Paper}>
								<Table size='small'>
									<TableBody>
										{/* Replace with your actual data */}
										{columnThreeData.map(([key, value], index) => (
											<TableRow
												key={key}
												style={{ backgroundColor: rowColors[index % 2] }}
											>
												<TableCell sx={tableCellStyle}>{key}</TableCell>
												<TableCell sx={tableCellStyle}>{value}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Stack>
					</Grid>
				</Grid>
			}
			{(subLineTableData.length > 0 || clmPoolTableData.length > 0) &&
				<Box sx={{ mt: '1rem', display: 'flex', flexDirection: 'row' }}>
					<TableContainer sx={{ maxHeight: 600, overflowX: 'auto' }}>
						<Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
							<TableHead style={{ backgroundColor: '#b0c0da' }}>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell colSpan={4} style={{ backgroundColor: '#b0c0da', textAlign: 'center', border: '.5px solid white' }}>———— CONTRACT SUBLINE ————</TableCell>
								</TableRow>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>ID</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>Effective Date</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>End Date</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>PRI</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{subLineTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={index}>
											<TableCell style={{ whiteSpace: 'nowrap' }}><a href='#' onClick={() => sublineClick(row.id)}>{row.id}</a></TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.contr_subl_eff_dt)}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.contr_subl_end_dt)}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{row.prdct_rdy_cd}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TableContainer sx={{ ml: '1rem', maxHeight: 600, overflowX: 'auto' }}>
						<Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
							<TableHead style={{ backgroundColor: '#b0c0da' }}>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell colSpan={5} style={{ backgroundColor: '#b0c0da', textAlign: 'center', border: '.5px solid white' }}>———— CLAIM POOL ————</TableCell>
								</TableRow>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>ID</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>Min %</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>Interest Rate</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>Eff Date</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed' }}>End Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{clmPoolTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{row.clm_pool_id}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{row.clm_pool_min_pct}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{row.clm_pool_int_rt}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.clm_pool_eff_dt)}</TableCell>
											<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.clm_pool_end_dt)}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			}
			{(!(columnOneData.length > 0 && columnTwoData.length > 0 && columnThreeData.length > 0) || !(subLineTableData.length > 0 || clmPoolTableData.length > 0)) &&
				<LinearProgress sx={{ mt: 2 }} />
			}
		</div>
	);
};
