import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import Button from '@mui/material/Button';
import { openPrinterFriendly } from "../printerFriendly";
import { testPDSeg } from "../testPopupDataSegment";
import React from "react";
import { useState, useEffect } from 'react';
import { ReportModelIDApi } from "./ReportModelIDApi";
import { ReportCycleRefApi } from "./ReportCycleRefApi";
import { viewDownloadApi } from "./viewDownloadApi";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const HelpButtons = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={{ textTransform: 'none' }}>
                Help
            </Button>
            <Button style={{ textTransform: 'none' }} onClick={() => openPrinterFriendly('reportsMainTable')}>
                Printer Friendly
            </Button>
        </div>
    );
}

const MainTable = ({ rows, inputDSeg, viewButtonV, downloadButtonV }) => {
    const [viewButtonVisibility] = React.useState(viewButtonV);
    const [downloadButtonVisibility] = React.useState(downloadButtonV);
    const [dataSegments, setDataSegments] = useState([]);
    const [segmentsIDDict, setSegmentsIDDict] = useState([]);

    // popup data segment response handler
    const openPopupDataSeg = (rowID) => {
        testPDSeg(rowID);
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                document.getElementById(`${response[0]}DataSegmentSelect`).value = response[1];
                dataSegmentChange(`${response[0]}DataSegmentSelect`, response[1]);
            } catch (error) {
                console.log(error);
                console.log('popup receive message error');
            }
        };
    }

    // update data segments in MainTable's state
    useEffect(() => {
        if (inputDSeg !== undefined) {
            const myDict = inputDSeg.reduce((dict, item) => {
                dict[item.name] = item.id;
                return dict;
            }, {});
            myDict[''] = '';
            setSegmentsIDDict(myDict);
            const temp = inputDSeg.map(item => item.name);
            setDataSegments(['', ...temp]);
        }
    }, [inputDSeg]);

    // creates the select when the data segment information has been populated (allowing a single api call)
    const MakeDataSegmentSelect = ({ row }) => {
        const [dataSegmentSelect, setDataSegmentSelect] = useState(null);
        useEffect(() => {
            if (dataSegments !== undefined) {
                // console.log(dataSegments);
                if (dataSegments.length > 0) {
                    // console.log('updated dataSegments to:', dataSegments);
                    setDataSegmentSelect(makeDataSegmentSelect({ row }));
                }
            }
        }, [row]); // }, [dataSegments]);
        return dataSegmentSelect;
    }
    // makes the JSX for the data segment select element
    const makeDataSegmentSelect = ({ row }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <select id={`${row.reportID}DataSegmentSelect`} onChange={(event) => dataSegmentChange(event.target.id, event.target.value)}>
                    {dataSegments.map((option) => {
                        return <option value={option}>{option}</option>
                    })}
                </select>
                <img style={{ height: '1rem', width: '1rem' }} alt="lookup icon" src="Binoculars-icon.png" onClick={() => openPopupDataSeg(row.reportID)}></img>
            </div>
        )
    }

    /**
     * 
     * When data segment selection is changed, we need to query based on the current row to find options for:
     * - cycle nbr
     * - proc yr mo
     * This function works regardless of whether response is a list
     * @param {String} id 
     * @param {String} value 
     */
    function dataSegmentChange(id, value) {
        var rowID = id.replace('DataSegmentSelect', '');
        var modelIDSelect = document.getElementById(`${rowID}ModelIDSelect`);
        var cycleNbrSelect = document.getElementById(`${rowID}CycleNbrSelect`);
        resetSelect(`${rowID}ModelIDSelect`);
        resetSelect(`${rowID}CycleNbrSelect`);
        if (value !== '') {
            ReportModelIDApi(value).then(response => {
                var modelID = response.data.Model_ID;
                var cycleNbr = response.data.CYC_NBR;
                // fill model id dropdown
                if (Array.isArray(modelID)) {
                    for (let item of modelID) {
                        const optionElement = document.createElement('option');
                        optionElement.value = item;
                        optionElement.text = item;
                        modelIDSelect.appendChild(optionElement);
                    }
                } else {
                    const optionElement = document.createElement('option');
                    optionElement.value = modelID;
                    optionElement.text = modelID;
                    modelIDSelect.appendChild(optionElement);
                }
                // fill cycle nbr dropdown
                if (Array.isArray(cycleNbr)) {
                    for (let item of cycleNbr) {
                        const optionElement = document.createElement('option');
                        optionElement.value = item;
                        optionElement.text = item;
                        cycleNbrSelect.appendChild(optionElement);
                    }
                } else {
                    const optionElement = document.createElement('option');
                    optionElement.value = cycleNbr;
                    optionElement.text = cycleNbr;
                    cycleNbrSelect.appendChild(optionElement);
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * This function basically calls whenever input changes
     * If input is 6 digits, it will make API call and fill in options for a select element
     * 
     * @param {Input event} event 
     * @param {String} rowID 
     */
    function procYrMoChange(rowID) {
        var modelIDOption = document.getElementById(`${rowID}ModelIDSelect`).value;
        var cycleNbrOption = document.getElementById(`${rowID}CycleNbrSelect`).value;
        var inputValue = document.getElementById(`${rowID}ProcYrMo`).value;
        if (inputValue !== null && inputValue.length === 6 && /^\d+$/.test(inputValue) && modelIDOption !== '' && cycleNbrOption !== '') {
            ReportCycleRefApi(modelIDOption, cycleNbrOption, parseInt(inputValue, 10)).then(response => {
                const selectElement = document.getElementById(`${rowID}CycleRef`);
                // Clear existing options  
                selectElement.innerHTML = '';
                resetSelect(`${rowID}CycleRef`);
                // Add new options from list
                if (response.data.CYC_RUN_SEQ_NBR.length > 0) {
                    for (let item of response.data.CYC_RUN_SEQ_NBR) {
                        const optionElement = document.createElement('option');
                        optionElement.value = item;
                        optionElement.text = item;
                        selectElement.appendChild(optionElement);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    // resets a select, adding an option with '' for value and text
    function resetSelect(selectID) {
        const selectElement = document.getElementById(selectID);
        selectElement.innerHTML = '';
        const optionElement = document.createElement('option');
        optionElement.value = '';
        optionElement.text = '';
        selectElement.appendChild(optionElement);
    }

    function handleViewDownloadClick(rowID, viewDownload) {
        var dataSegId = '';
        var procYrMo = '';
        var mdlId = '';
        var cycNbr = '';
        var rptIdCd = '';
        if (document.getElementById(`${rowID}DataSegmentSelect`)) {
            dataSegId = document.getElementById(`${rowID}DataSegmentSelect`).value;
        }
        if (document.getElementById(`${rowID}ProcYrMo`)) {
            procYrMo = document.getElementById(`${rowID}ProcYrMo`).value;
        }
        if (document.getElementById(`${rowID}ModelIDSelect`)) {
            mdlId = document.getElementById(`${rowID}ModelIDSelect`).value;
        }
        if (document.getElementById(`${rowID}CycleNbrSelect`)) {
            cycNbr = document.getElementById(`${rowID}CycleNbrSelect`).value;
        }
        if (document.getElementById(`${rowID}CycleRef`)) {
            rptIdCd = document.getElementById(`${rowID}CycleRef`).value;
        }
        var requestBody = {
            "reportId": rowID,
            "dataSegId": segmentsIDDict[dataSegId],
            "procYrMo": procYrMo,
            "mdlId": segmentsIDDict[dataSegId], // mdlid not used
            "cycNbr": cycNbr.replace(/[a-z]/gi, ''),
            "rptIdCd": rptIdCd.replace(/[a-z]/gi, '')
        }
        console.log(requestBody);
  
        if (viewDownload === 'view') {
            requestBody['type'] = 'pdf';
        } else {
            requestBody['type'] = 'csv';
        }
        viewDownloadApi(requestBody).then(response => {
            console.log(response);
            if (response.data.size !== 0) {
                if (viewDownload === "view") {
                    // Create a blob URL for the PDF file  
                    const blobUrl = URL.createObjectURL(response.data);
                    // Open the PDF file in a new browser tab  
                    const newTab = window.open(blobUrl, 'new', 'width=1000,height=1000,resizable=yes,status=yes');
                    newTab.focus();
                } else if (viewDownload === "download") {
                    // Create a link element with the PDF file  
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = `${rowID}.csv`;  
                    link.click();
                }
            }else {
                alert('The Document is not present to view/download');
            }
        })
            .catch(error => console.error(error));
    }

    return (
        // it does not look right with zoom in/zoom out due to layout of elements. foundations need fixing.
        <div id='reportsMainTable' style={{ overflowY: 'auto' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Report ID</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Report Name</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Data Segment</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Model ID</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Cycle Nbr</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Proc Yr Mo</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Cycle Ref</TableCell>
                            <TableCell style={{ textAlign: 'center', backgroundColor: "#b0c0da" }}>View</TableCell>
                            <TableCell style={{ textAlign: 'center', backgroundColor: "#b0c0da" }}>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.reportID}
                            >
                                <TableCell>{row.reportID}</TableCell>
                                <TableCell>{row.reportName}</TableCell>
                                <TableCell>
                                    <MakeDataSegmentSelect row={row} />
                                </TableCell>
                                <TableCell>
                                    <select
                                        id={`${row.reportID}ModelIDSelect`}
                                        style={{ minWidth: '75%', width: 'fit-content' }}
                                        onChange={() => procYrMoChange(row.reportID)}>
                                    </select>
                                </TableCell>
                                <TableCell>
                                    <select
                                        id={`${row.reportID}CycleNbrSelect`}
                                        style={{ minWidth: '50%', width: 'fit-content' }}
                                        onChange={() => procYrMoChange(row.reportID)}>
                                    </select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={`${row.reportID}ProcYrMo`}
                                        onChange={(event) => procYrMoChange(row.reportID)}
                                        name="myInput"
                                        type="text"
                                        maxLength="6"
                                        style={{ width: "5rem" }}
                                    ></input>
                                </TableCell>
                                <TableCell>
                                    <select id={`${row.reportID}CycleRef`} style={{ minWidth: '50%', width: 'fit-content' }}>
                                    </select>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    {viewButtonVisibility[index] ? (
                                        <Button style={{ textTransform: 'none' }} onClick={() => handleViewDownloadClick(row.reportID, 'view')}>
                                            View
                                        </Button>
                                    ) : null}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    {downloadButtonVisibility[index] ? (
                                        <Button style={{ textTransform: 'none' }} onClick={() => handleViewDownloadClick(row.reportID, 'download')}>
                                            Download
                                        </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

/*
{testDataSegments.map((option) => {
    <option value={option}>{option}</option> 
})}
*/

export const ReportsTable1 = ({ rows, inputDSeg, viewButtonV, downloadButtonV }) => {
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        setDataSegments(inputDSeg);
    }, [inputDSeg]);
    return (
        <div style={{ width: '100%' }}>
            <HelpButtons />
            <MainTable rows={rows} inputDSeg={dataSegments} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV} />
        </div>
    );
};
