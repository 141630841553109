import { Grid, Box, Snackbar, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import { KeyboardArrowRight, KeyboardArrowLeft, FormatListNumberedOutlined } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import React from "react";
import { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ParameterSearchRequest } from "./ParameterSearchRequest";
import { formatYearMonth } from "../../../helperFunctions";
import { getDataSegments, getDataSegmentIntFromString } from "../../../Home/dataSegmentApi";
import { splitSourceSystemMemberKey } from '../../../helperFunctions';
import { Typography } from "@material-ui/core";
import { testPDSeg } from '../../../testPopupDataSegment';
import { testContract } from '../../../PopupContract';


export const ParametersSeachForm = ({ onSearch, onReset, passForceCapType, passDataSegment, passError }) => {
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegments, setDataSegments] = useState(['']);
    const [forceCaptype, setForceCapType] = useState('PROVIDER');
    const [sourceSystem, setSourceSystem] = useState('DEFAULT');
    const [contractNumber, setContractNumber] = useState('');
    const [contractLineNumber, setContractLineNumber] = useState('');
    const [memberId, setMemberId] = useState('');
    const [employerGroupNumber, setEmployerGroupNumber] = useState('');
    const [benefitPlanId, setBenefitPlanId] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDataSegmentSelected, setIsDataSegmentSelected] = useState(false);
    const [isMemberIdEntered, setIsMemberIdEntered] = useState(false);
    const [isSourceSystemSelected, setIsSourceSystemSelected] = useState(false);

    const handleDataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    }
    const handleForceCapTypeChange = (event) => {
        setForceCapType(event.target.value);
    }
    const handleSourceSystemChange = (event) => {
        setSourceSystem(event.target.value);
    }
    const handleContractNumberChange = (event) => {
        setContractNumber(event.target.value.replace(/\D/g, ''));
    }
    const handleContractLineNumberChange = (event) => {
        setContractLineNumber(event.target.value.replace(/\D/g, ''));
    }
    const handleMemberIdChange = (event) => {
        setMemberId(event.target.value);
    }
    const handleEmployerGroupNumberChange = (event) => {
        setEmployerGroupNumber(event.target.value);
    }
    const handleBenefitPlanIdChange = (event) => {
        setBenefitPlanId(event.target.value);
    }
    function startDateChange(date) { setStartDate(date); }
    function endDateChange(date) { setEndDate(date); }

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    function openPopupContract() {
        if (dataSegment) {
            const dataSegmentValue = Number.parseInt(getDataSegmentIntFromString(dataSegment));
            if (dataSegmentValue) {
                testContract(dataSegmentValue); // Pass the selected dataSegment value    
                window.onmessage = (event) => {
                    try {
                        setContractLineNumber(event.data);
                    } catch {
                        console.log('popup receive message error');
                    }
                };
            } else {
                alert('Invalid Data Segment selected. Please select a valid Data Segment.');
            }
        } else {
            alert('Please select a Data Segment before proceeding.');
        }
    }


    const handleSubmit = () => {
         passError(''); 

        var providerRequestBody;
        var memberRequestBody;
        var customerRequestBody;
        var benefitRequestBody;
        // TODO: error handeling
        if (forceCaptype == 'PROVIDER') {
            if (!dataSegment) {
                return setIsDataSegmentSelected(true);
            }
            providerRequestBody = {
                "data_segment": `${getDataSegmentIntFromString(dataSegment)}`,
                "force_cap_type": `${forceCaptype}`,
                "contract_id": `${contractNumber}`,
                "contract_line_id": `${contractLineNumber}`,
                "from_date": `${formatYearMonth(startDate)}`,
                "to_date": `${formatYearMonth(endDate)}`
            }
        } else if (forceCaptype == 'MEMBER') {
            if (!memberId) {
                return setIsMemberIdEntered(true);
            }
            memberRequestBody = {
                "data_segment": `${getDataSegmentIntFromString(dataSegment)}`,
                "force_cap_type": `${forceCaptype}`,
                "customer_id": `${splitSourceSystemMemberKey(memberId)[0]}`,
                "member_id": `${splitSourceSystemMemberKey(memberId)[1]}`,
                "suffix": `${splitSourceSystemMemberKey(memberId)[2]}`,
                "from_date": `${formatYearMonth(startDate)}`,
                "to_date": `${formatYearMonth(endDate)}`
            }
        } else if (forceCaptype == 'CUSTOMER') {
            if (sourceSystem.includes('DEFAULT')) {
                return setIsSourceSystemSelected(true);
            }
            customerRequestBody = {
                "data_segment": `${getDataSegmentIntFromString(dataSegment)}`,
                "force_cap_type": `${forceCaptype}`,
                "employe_group_id": `${employerGroupNumber}`,
                "benefit_plan_id": `${benefitPlanId}`,
                "source_system": `${sourceSystem}`,
                "from_date": `${formatYearMonth(startDate)}`,
                "to_date": `${formatYearMonth(endDate)}`
            }
        } else if (forceCaptype == "BENEFIT FACTOR") {
            if (sourceSystem.includes('DEFAULT')) {
                return setIsSourceSystemSelected(true);
            }
            benefitRequestBody = {
                "data_segment": `${getDataSegmentIntFromString(dataSegment)}`,
                "force_cap_type": `${forceCaptype}`,
                "employe_group_id": `${employerGroupNumber}`,
                "benefit_plan_id": `${benefitPlanId}`,
                "source_system": `${sourceSystem}`,
                "from_date": `${formatYearMonth(startDate)}`,
                "to_date": `${formatYearMonth(endDate)}`
            }

        }

        if (forceCaptype == 'PROVIDER') {
            console.log(providerRequestBody);
            ParameterSearchRequest(providerRequestBody).then
                (response => {
                    if (response.data.length > 0) {
                        onSearch(response.data);
                        passForceCapType(forceCaptype);
                        passDataSegment(dataSegment);
                        passError(''); // Clear error message when data is found  
                    } else {
                        // setErrorMessage('No matching records found');
                        passError('No matching records found');
                    }
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error searching for PROVIDER records');
                    passError(errorMessage);
                });
        } else if (forceCaptype == 'MEMBER') {
            console.log(memberRequestBody);
            ParameterSearchRequest(memberRequestBody).then
                (response => {
                    if (response.data.length > 0) {
                        onSearch(response.data);
                        passForceCapType(forceCaptype);
                        passDataSegment(dataSegment);
                    } else {
                        // setErrorMessage('No matching records found');
                        passError('No matching records found');
                    }
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error searching for MEMBER records');
                });
        } else if (forceCaptype == 'CUSTOMER') {
            console.log(customerRequestBody);
            ParameterSearchRequest(customerRequestBody).then
                (response => {
                    if (response.data.length > 0) {
                        onSearch(response.data);
                        passForceCapType(forceCaptype);
                        passDataSegment(dataSegment);
                    } else {
                        // setErrorMessage('No matching records found');
                        passError('No matching records found');
                    }
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error searching for CUSTOMER records');
                    passError(errorMessage);
                });
        } else if (forceCaptype == "BENEFIT FACTOR") {
            console.log(benefitRequestBody);
            ParameterSearchRequest(benefitRequestBody).then
                (response => {
                    if (response.data.length > 0) {
                        onSearch(response.data);
                        passForceCapType(forceCaptype);
                        passDataSegment(dataSegment);
                    } else {
                        // setErrorMessage('No matching records found');
                        passError('No matching records found');
                    }
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error searching for BENEFIT FACTOR records');
                    passError(errorMessage);
                });
        }
    };

    const handleReset = () => {
        setDataSegment('');
        setContractNumber('');
        setContractLineNumber('');
        setMemberId('');
        setSourceSystem('DEFAULT');
        setEmployerGroupNumber('');
        setBenefitPlanId('');
        setStartDate('');
        setEndDate('');
        onReset(false);
    }

    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegments(dataList);
    }, [])

    return (
        <Grid>
            <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                <Grid xs={4.5}>
                </Grid>
                <Grid xs={7.5}>
                    <Grid container spacing={1} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={2} sm={2} md={2} lg={2}>
                            <Tooltip title="Advanced Filters">
                                <IconButton >
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid xs={2} >
                            <Tooltip title="Settings">
                                <IconButton>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid xs={2} >
                            <Tooltip title="Saved Search">
                                <IconButton>
                                    <BookmarkIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid xs={2} >
                            <Tooltip title="Reset All">
                                <IconButton onClick={handleReset}>
                                    <ReplayIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid xs={2} >
                            <Tooltip title="Minimize Search">
                                <IconButton>
                                    <KeyboardArrowLeft />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                    <InputLabel
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            color: 'black',
                            flex: 1,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        Data Segment<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                </Grid>
                <Grid xs={5}>
                    <Select
                        size='small'
                        id='data-seg-select'
                        style={{ width: '100%' }}
                        onChange={handleDataSegmentChange}
                        value={dataSegment}
                    //error={dataSegmentError}
                    >
                        {dataSegments.map((segment) => (
                            <MenuItem
                                value={segment}
                                key={segment}
                                name='data-segment-option'
                            >
                                {segment}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid sx={2}>
                    <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                        <img
                            style={{ height: '80%', width: '80%' }}
                            src="Binoculars-icon.png"
                            title="Filtering Data segment"
                            onClick={() => openPopupDataSegment()}
                        ></img>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                    <InputLabel
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            color: 'black',
                            flex: 1,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        Force Cap Type<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                </Grid>
                <Grid xs={8}>
                    <Select
                        size='small'
                        id='type'
                        style={{ width: '80%' }}
                        value={forceCaptype}
                        onChange={handleForceCapTypeChange}
                    >
                        <MenuItem name='force-cap-type-option' value={"PROVIDER"}>PROVIDER</MenuItem>
                        <MenuItem name='force-cap-type-option' value={"PROV DPN"}>PROV DPN</MenuItem>
                        <MenuItem name='force-cap-type-option' value={"PROV CMS"}>PROV CMS</MenuItem>
                        <MenuItem name='force-cap-type-option' value={"MEMBER"}>MEMBER</MenuItem>
                        <MenuItem name='force-cap-type-option' value={"CUSTOMER"}>CUSTOMER</MenuItem>
                        <MenuItem name='force-cap-type-option' value={"BENEFIT FACTOR"}>BENEFIT FACTOR</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {forceCaptype == 'PROVIDER' &&
                <Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Contract#
                            </InputLabel>
                        </Grid>
                        <Grid xs={5} >
                            <TextField
                                required
                                id="contract-number"
                                name="contract-number"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleContractNumberChange}
                                value={contractNumber}
                            />
                        </Grid>
                        <Grid xs={2}>
                            <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                                <img
                                    style={{ height: '80%', width: '80%' }}
                                    src="Binoculars-icon.png"
                                    title="Filtering Contract Line#"
                                    onClick={() => openPopupContract()}
                                ></img>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Contract Line#
                            </InputLabel>
                        </Grid>
                        <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                            <TextField
                                required
                                id="contract-line-number"
                                name="contract-line-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleContractLineNumberChange}
                                value={contractLineNumber}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {forceCaptype == 'MEMBER' &&
                <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                    <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                        <InputLabel
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                color: 'black',
                                flex: 1,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Member ID<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                    </Grid>
                    <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                        <TextField
                            required
                            id="member-id"
                            name="member-id"
                            // fullWidth
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            onChange={handleMemberIdChange}
                            value={memberId}
                        />
                    </Grid>
                </Grid>
            }
            {forceCaptype == 'CUSTOMER' &&
                <Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Source System<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                        </Grid>
                        <Grid xs={8}>
                            <Select
                                size='small'
                                id='source-system'
                                style={{ width: '80%' }}
                                value={sourceSystem}
                                onChange={handleSourceSystemChange}
                            >
                                <MenuItem name='force-cap-type-option' value={"DEFAULT"}>--Source System--</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ACIS"}>ACIS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"CES"}>CES</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"COSMOS"}>COSMOS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ECAP"}>ECAP</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"MAPS"}>MAPS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"NDB"}>NDB</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ORACLEIFS"}>ORACLE IFS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"PRIME"}>PRIME</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"TOPS"}>TOPS</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Employer Group #
                            </InputLabel>
                        </Grid>
                        <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                            <TextField
                                required
                                id="employer-group-number"
                                name="employer-group-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleEmployerGroupNumberChange}
                                value={employerGroupNumber}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Benefit Plan ID
                            </InputLabel>
                        </Grid>
                        <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                            <TextField
                                required
                                id="benefit-plan-id"
                                name="benefit-plan-id"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleBenefitPlanIdChange}
                                value={benefitPlanId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {forceCaptype == 'BENEFIT FACTOR' &&
                <Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Source System<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                        </Grid>
                        <Grid xs={8}>
                            <Select
                                size='small'
                                id='source-system'
                                style={{ width: '80%' }}
                                value={sourceSystem}
                                onChange={handleSourceSystemChange}
                            >
                                <MenuItem name='force-cap-type-option' value={"DEFAULT"}></MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ACIS"}>ACIS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"CES"}>CES</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"COSMOS"}>COSMOS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ECAP"}>ECAP</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"MAPS"}>MAPS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"NDB"}>NDB</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"ORACLEIFS"}>ORACLE IFS</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"PRIME"}>PRIME</MenuItem>
                                <MenuItem name='force-cap-type-option' value={"TOPS"}>TOPS</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Employer Group #
                            </InputLabel>
                        </Grid>
                        <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                            <TextField
                                required
                                id="employer-group-number"
                                name="employer-group-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleEmployerGroupNumberChange}
                                value={employerGroupNumber}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Benefit Plan ID
                            </InputLabel>
                        </Grid>
                        <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                            <TextField
                                required
                                id="benefit-plan-id"
                                name="benefit-plan-id"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                onChange={handleBenefitPlanIdChange}
                                value={benefitPlanId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                    <InputLabel
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            color: 'black',
                            flex: 1,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        Start Date
                    </InputLabel>
                </Grid>
                <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id='start-date'
                            sx={{
                                "& .MuiInputBase-input": {
                                    height: '35px',
                                    padding: '4px 8px',
                                },
                                "& .MuiInputBase-root": {
                                    fontSize: '16px',
                                }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false
                                },
                            }}
                            onChange={(date) => startDateChange(date)}
                            format="YYYY MM"
                            openTo="year"
                            views={['year', 'month']}
                            selected={startDate}
                            value={startDate}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                <Grid xs={4} style={{ padding: '8.5px 0px 0px 0px' }}>
                    <InputLabel
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            color: 'black',
                            flex: 1,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        End Date
                    </InputLabel>
                </Grid>
                <Grid xs={8} style={{ padding: '0px 50px 0px 0px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id='end-date'
                            sx={{
                                "& .MuiInputBase-input": {
                                    height: '35px',
                                    padding: '4px 8px',
                                },
                                "& .MuiInputBase-root": {
                                    fontSize: '16px',
                                }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false
                                },
                            }}
                            onChange={(date) => endDateChange(date)}
                            format="YYYY MM"
                            openTo="year"
                            views={['year', 'month']}
                            selected={endDate}
                            value={endDate}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container spacing={.10} style={{ margin: '25px 0px 0px 0px' }}>
                <Grid xs={5}>
                    <Button
                        variant="contained"
                        id='search-button'
                        size="medium"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >SEARCH</Button>
                </Grid>
                <Grid xs={7}>
                </Grid>
            </Grid>

            <Snackbar
                open={isDataSegmentSelected}
                autoHideDuration={5000}
                onClose={() => setIsDataSegmentSelected(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsDataSegmentSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Please Select a Data Segment
                </Alert>
            </Snackbar>

            <Snackbar
                open={isMemberIdEntered}
                autoHideDuration={5000}
                onClose={() => setIsMemberIdEntered(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsMemberIdEntered(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Please Enter a Member ID
                </Alert>
            </Snackbar>

            <Snackbar
                open={isSourceSystemSelected}
                autoHideDuration={5000}
                onClose={() => setIsSourceSystemSelected(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setIsSourceSystemSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    Please Select a valid Source System
                </Alert>
            </Snackbar>
        </Grid>
    );
};
export default ParametersSeachForm;