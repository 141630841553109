import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import TablePagination from '@mui/material/TablePagination';
import { formatDollars } from '../../../helperFunctions';
import Button from '@mui/material/Button'


function Row(props) {
	const { group, handleTabChange, onToggleExpand } = props;
	const [open, setOpen] = React.useState(false);
	const [currentTab, setCurrentTab] = useState('tab1');



	// Check if group.members is defined and has at least one element  
	if (!group.members || group.members.length === 0) {
		return null;  // or some default JSX if you prefer  
	}

	console.log("groups after sorted are", group);



	const row = { ...group.members[0] };

	row.CAP_PRD_YR_MO = "";

	console.log("row value for parent is when selected", row);


	console.log("row value when child is selected is", row);

	const sumProperties = ['CURRENT_MEMBER_COUNT', 'RETRO_MEMBER_COUNT', 'TOTAL_MEMBER_COUNT', 'CURRENT_STANDARD_SERVICE_CAP', 'RETRO_STANDARD_SERVICE_CAP', 'ADJCAP', 'TOTAL_CAP'];



	const handleExpandClick = () => {
		const newOpenState = !open;
		setOpen(newOpenState);
		if (onToggleExpand) {
			onToggleExpand(group.groupName, newOpenState); // Pass the group name and new open state  
		}
	};

	// // Function to calculate the sum of a property for all members  
	// function sumByProperty(arr, propName) {  
	//     return arr.reduce((total, obj) => {  
	//         const value = parseFloat(obj[propName]);  
	//         return total + (isNaN(value) ? 0 : value);  
	//     }, 0);  
	// }  



	const handleSuffixClick = (memberData) => (event) => {
		event.preventDefault();
		const providerDetailTabIndex = 1;

		// Summable properties that need to be aggregated  
		const sumProperties = ['CURRENT_MEMBER_COUNT', 'RETRO_MEMBER_COUNT', 'TOTAL_MEMBER_COUNT', 'CURRENT_STANDARD_SERVICE_CAP', 'RETRO_STANDARD_SERVICE_CAP', 'ADJCAP', 'TOTAL_CAP'];

		// Function to calculate the sum of a property for all members  
		function sumByProperty(arr, propName) {
			return arr.reduce((total, obj) => {
				const value = parseFloat(obj[propName]);
				return total + (isNaN(value) ? 0 : value);
			}, 0);
		}

		// Create an object with the sums of all properties  
		const sumOfProperties = sumProperties.reduce((acc, propName) => {
			acc[propName] = sumByProperty(group.members, propName);
			console.log("ARRAY TO DEBUG ADJ CAP IS", acc);
			return acc;
		}, {});

		// Create a new object that combines the sumOfProperties with the other properties of memberData  
		const combinedData = {
			...memberData, // Take all properties from the original memberData  
			...sumOfProperties, // Override with summed properties  
			CAP_PRD_YR_MO: "" // Set CAP_PRD_YR_MO to an empty string  
		};

		console.log("member data being routed to other components", combinedData);
		handleTabChange(combinedData, providerDetailTabIndex);
	};

	const handleChildSuffixClick = (memberData) => (event) => {
		event.preventDefault();
		const providerDetailTabIndex = 1;
		handleTabChange(memberData, providerDetailTabIndex);
	};



	const columnWidths = {
		showHistory: '7.7%',
		suffix: '5.4%',
		memberName: '15.7%',
		processMonth: '10.3%',
		capPeriod: '7.8%',
		contractOwnerMPIN: '15.3%',
		contract: '6.52%',
		contractLine: '10.6%',
		argt: '5.2%',
		capContractType: '11.5%',
		totalCap: '9.2%',
		// Add more widths for other columns if needed...  
	};

	function sumByProperty(arr, propName) {

		if (!Array.isArray(arr)) {
			console.error('sumByProperty: The first argument is not an array', arr);
			return 0; // Return 0 or some other default value  
		}

		return arr.reduce((total, obj) => {
			const value = parseFloat(obj[propName]);
			return total + (isNaN(value) ? 0 : value);
		}, 0);
	}



	// This example assumes that each JSON object is a separate row and does not have a history array.  
	return (
		<React.Fragment>
			{/** first row (suffix row) */}
			<TableRow id="member-table-main-row">
				<TableCell name='show-history-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} >
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={handleExpandClick}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell name='process-month-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{row.PAY_TO_PROV_ID}</TableCell>
				<TableCell name='suffix-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
					<TabContext value={currentTab}>
						<Link
							href="#"
							onClick={handleSuffixClick(row)}

						>
							{row.CONTR_ID}
						</Link>
					</TabContext>
				</TableCell>
				<TableCell name='process-month-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='cap-period-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{row.CONTR_LN_ID}</TableCell>
				<TableCell name='mpin-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{row.PROC_YR_MO}</TableCell>
				<TableCell name='contract-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='contract-line-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='argt-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{row.PROV_ARNG_CD}</TableCell>
				<TableCell name='cap-contract-type-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{row.CONTR_TYP_CD}</TableCell>
				<TableCell name='cap-contract-type-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{parseFloat(sumByProperty(group.members, 'CURRENT_MEMBER_COUNT'))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{parseFloat(sumByProperty(group.members, 'RETRO_MEMBER_COUNT'))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{parseFloat(sumByProperty(group.members, 'TOTAL_MEMBER_COUNT'))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(parseFloat(sumByProperty(group.members, 'CURRENT_STANDARD_SERVICE_CAP')))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(parseFloat(sumByProperty(group.members, 'RETRO_STANDARD_SERVICE_CAP')))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(parseFloat(sumByProperty(group.members, 'ADJCAP')))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(parseFloat(sumByProperty(group.members, 'TOTAL_CAP')))}</TableCell>
			</TableRow>
			{open && (
				<>
					{group.members && group.members.length > 0 ? (
						group.members.map((member, index) => (
							<TableRow key={index}>
								<TableCell name='show-history-column' style={{ textAlign: 'left', whiteSpace: 'nowrap', height: '2rem', }}></TableCell>
								<TableCell name='suffix-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}></TableCell>
								<TableCell name='member-name-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}><Link href="#" onClick={handleChildSuffixClick(member)}>
									{member.CONTR_ID}
								</Link></TableCell>
								<TableCell name='process-month-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.PAY_TO_PROV_ID}</TableCell>
								<TableCell name='cap-period-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{member.CONTR_LN_ID}
								</TableCell>
								<TableCell name='mpin-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.PROC_YR_MO}</TableCell>
								<TableCell name='contract-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.ASSOC_PROV_ID}</TableCell>
								<TableCell name='contract-line-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.CAP_PRD_YR_MO}</TableCell>
								<TableCell name='argt-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.PROV_ARNG_CD}</TableCell>
								<TableCell name='cap-contract-type-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{member.CONTR_TYP_CD}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{member.CURRENT_MEMBER_COUNT}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{member.RETRO_MEMBER_COUNT}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{member.TOTAL_MEMBER_COUNT}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{formatDollars((member.CURRENT_STANDARD_SERVICE_CAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{formatDollars((member.RETRO_STANDARD_SERVICE_CAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{formatDollars((member.ADJCAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
									{formatDollars((member.TOTAL_CAP))}</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan='100%'>No additional members</TableCell>
						</TableRow>
					)}
				</>
			)
			}
		</React.Fragment>
	);
}

export function HistorySearchTable({ searchResults, handleTabChange }) {


	const StripedTableBody = styled(TableBody)(({ theme }) => ({
		'& tr:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		'& tr:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	}));

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const tableRows = searchResults || [];

	// Function to process the data and return the nested JSON structure  
	const processData = (tableRows) => {
		const groupedData = tableRows.reduce((acc, item) => {
			const group = item.PROC_YR_MO;
			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(item);
			return acc;
		}, {});
		// Check if groupedData is an object and is not undefined  
		const sortedGroups = (typeof groupedData === 'object' && groupedData !== null) ? Object.keys(groupedData).sort((a, b) => b.localeCompare(a)) : [];
		console.log("grouped data for history search", sortedGroups);

		// Additional check to ensure sortedGroups is an array before mapping  
		return Array.isArray(sortedGroups) ? sortedGroups.map((group) => ({
			groupName: group,
			members: groupedData[group],
		})) : [];


	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

		const handleDownload = () => {  
			// Define the headers based on the table columns you want to include  
			const headers = [  
				'Payment MPIN', 'Contract#', 'Contract Owner MPIN', 'Contract Line#',  
				'Process Month', 'Assoc Group', 'Cap Period',  
				'Arrangement Code', 'Current Member Count', 
				'Retro Member Count', 'Total Member Count', 'Current Standard Service Cap',  
				'Retro Standard Service Cap', 'Adjustment Cap', 'Total Cap'
			];  
		  
			// Convert rowData to CSV format  
			const csvContent = [  
				headers.join(','), // header row first  
				...tableRows.map(row => [  
					row.PAY_TO_PROV_ID, row.CONTR_ID, row.OWNR_PROV_ID, row.CONTR_LN_ID,
					row.PROC_YR_MO, row.ASSOC_PROV_ID, row.CAP_PRD_YR_MO,  
					row.PROV_ARNG_CD, row.CURRENT_MEMBER_COUNT,  
					row.RETRO_MEMBER_COUNT, row.TOTAL_MEMBER_COUNT, row.CURRENT_STANDARD_SERVICE_CAP,  
					row.RETRO_STANDARD_SERVICE_CAP,row.ADJCAP, row.TOTAL_CAP  
				].join(',')) // map each row of data into a CSV string  
			].join('\n');  
		  
			// Create a Blob from the CSV Content  
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });  
		  
			// Create a link and set the URL using createObjectURL  
			const url = URL.createObjectURL(blob);  
			const link = document.createElement('a');  
			link.href = url;  
			link.download = 'History_Download.csv'; // Name the file here  
		  
			// Append to the document  
			document.body.appendChild(link);  
		  
			// Trigger 'click' to download  
			link.click();  
		  
			// Clean up and remove the link  
			document.body.removeChild(link);  
			URL.revokeObjectURL(url);  
		};  
		


	return (

		<Paper sx={{ width: '78%', overflow: 'hidden' }}>


			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>
				<Button
					onClick={handleDownload}
					id='download-button'
				>
					Download
				</Button>
			</div>

			{/* <div style={{ overflow: 'auto', maxHeight: '100vh' }}>   */}
			<TableContainer component={Paper} style={{ height: '70vh', width: '100%', overflow: 'auto' }}>
				<Table id="member-table" aria-label="collapsible table" stickyHeader>
					<TableHead id="member-table-header" style={{ backgroundColor: '#b0c0da', lineHeight: '0.4' }} >
						<TableRow id="member-table-row" style={{ backgroundColor: '#b0c0da', lineHeight: '1.0' }}>
							{/* <TableCell style={{ backgroundColor: '#b0c0da' }} /> */}
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>ShowAll</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Payment MPIN</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract#</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Contract Owner<br /> MPIN</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract Line#</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Process Month</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Assoc Group</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Cap Period</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Argt</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Cap Contract <br /> Type</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Current<br /> Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Retro <br />Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Total <br /> Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>
								Current Standard<br />Service Cap
							</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>
								Retro Standard<br />Service Cap
							</TableCell>


							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Adj Cap</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Total Cap</TableCell>
						</TableRow>
					</TableHead>
					<StripedTableBody>
						{/* {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={tableRows.CAP_PRD_YR_MO} row={row} handleTabChange={handleTabChange}  />
          ))} */}
						{processData(tableRows).map((group, index) => (
							<Row key={index} group={group} handleTabChange={handleTabChange} />
						))}

					</StripedTableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={processData(tableRows).length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</Paper>

	);
}
