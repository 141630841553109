import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItem, ListItemText } from "@mui/material";

export default function SelectionMenu({
  text,
  setTab,
  setIsDrawerOpen,
  isDrawerOpen,
  sectionSubsectionDictionary,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (
      event.currentTarget.children[0].textContent === "Errors" ||
      event.currentTarget.children[0].textContent === "Job Process"
    ) {
      setTab(event.currentTarget.children[0].textContent);
      setIsDrawerOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (event) => {
    console.log(event.target.innerText);
    setTab(event.target.innerText);
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <ListItem
        button
        id='basic-button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
      >
        <ListItemText
          onClick={handleClick}
          primary={text}
          sx={{ color: "white" }}
        />
      </ListItem>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            marginLeft: "17px",
            paddingBottom: "15px",
            backgroundColor: "#002153",
            color: "white",
          },
        }}
      >
        {sectionSubsectionDictionary[text] &&
          sectionSubsectionDictionary[text].map((item, index) => (
            <MenuItem
              key={index}
              onClick={(e) => {
                handleSubMenuClick(e);
              }}
            >
              {item}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
