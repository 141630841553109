import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { Typography } from "@mui/material";
import * as Constants from "./TransactionConstants";
import { TransactionHistoryApi } from './TransactionHistoryApi';
import React, { useState, useEffect } from 'react';

// Initialize the cache outside of the component  
const apiCache = new Map();

const MemberTransactionHistory = ({ memberData }) => {
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');


      const getValueOrDefault = (value) => {  
        // Check for both null and undefined explicitly  
        if (value === null || value === undefined || value=== 'None') {  
          return '';  
        }  
        return value;  
      }; 

        useEffect(() => {
            if (memberData) {
                const { CUST_ID,MBR_ID,MBR_ID_SUFX,ECAP_MBR_KEY,DATA_SEG_ID} = memberData;
                const cacheKey = `${CUST_ID}-${MBR_ID}-${MBR_ID_SUFX}-${ECAP_MBR_KEY}-${DATA_SEG_ID}`;
     
                // Check if the data is already in the cache  
                if (apiCache.has(cacheKey)) {
                    // Use the cached data  
                    setTransactionHistory(apiCache.get(cacheKey));
                } else {
                    TransactionHistoryApi(CUST_ID,MBR_ID,MBR_ID_SUFX,ECAP_MBR_KEY,DATA_SEG_ID)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data);
                        if(response.data.length > 0) {
                              var recievedData = response.data;
                              recievedData.sort((a, b) => {
                                    const dateA = new Date(a.UPDT_DTTM);
                                    const dateB = new Date(b.UPDT_DTTM);
                                    if (dateA.getTime() === dateB.getTime()) {
                                      const beginDateA = new Date(a.TRANS_EFF_DT);
                                      const beginDateB = new Date(b.TRANS_EFF_DT);
                                      return beginDateB.getTime() - beginDateA.getTime();
                                    }
                                    return dateB.getTime() - dateA.getTime();
                              });
                              setTransactionHistory(recievedData);
                              }
                         else {
                            setErrorMessage('No Record Found');
                        }
                    })
                        .catch(error => {
                            console.error('There was an error fetching the provider history:', error);
                        });
                }
            }
        }, [memberData]);

    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>


            <div style={{ marginTop: "2rem", paddingLeft: "1px" }}>

                {/* <Paper sx={{ width: '142.2%' }}> */}
                <TableContainer sx={{ maxHeight: 580, }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead>

                            <TableRow>
                                <Constants.StyledTableCell>Trans</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Info Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Begin Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>End Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Term Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Elig Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Employer Group#</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Benefit Plan ID</Constants.StyledTableCell>
                                <Constants.StyledTableCell>MPIN</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <Constants.StripedTableBody>
                            {
                                transactionHistory.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.TRANS_CD}</TableCell>
                                        <TableCell>{item.UPDT_DTTM}</TableCell>
                                        <TableCell>{item.TRANS_EFF_DT}</TableCell>
                                        <TableCell>{item.TRANS_END_DT}</TableCell>
                                        <TableCell>{item.TERM_DATE}</TableCell>
                                        <TableCell>{getValueOrDefault(item.MBR_ELIG_EFF_DT)}</TableCell>
                                        <TableCell>{item.Employer_Group_Number}</TableCell>
                                        <TableCell>{item.CAP_BEN_PLN_ID}</TableCell>
                                        <TableCell>{item.PCP_PROV_ID}</TableCell>
                                    </TableRow>
                                ))}
                        </Constants.StripedTableBody>
                    </Table>
                </TableContainer>

                {/* </Paper> */}
            </div>
            {errorMessage && (<div style={{width:'100%', textAlign:'center', color:'red'}}>{errorMessage}</div>)}
        </div>

    );
};

export default MemberTransactionHistory;
