import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { AssociationSearchApi } from "./AssociationSearchApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import AssociationSearchTable from './AssociationSearchTable';


export const AssociationSearch = ({ contractLink }) => {
    const [contractNumber, setContractNumber] = React.useState('');
    const [AssociateContract, setAssociateContract] = React.useState('');
    const [ContractLine, setContractLine] = React.useState('');
    const [AssociateContractLine, setAssociateContractLine] = React.useState('');
    const [MPIN, setMPIN] = React.useState('');
    const [AssociateMPIN, setAssociateMPIN] = React.useState('');
    const [CapProcessMonth, setCapProcessMonth] = React.useState('');
    const [showSearch, setShowSearch] = React.useState(true);

    const handleContractNumberChange = (event) => {
        setContractNumber(event.target.value.replace(/\D/g, ''));
    };

    const handleAssociateContractChange = (event) => {
        setAssociateContract(event.target.value.replace(/\D/g, ''));
    };

    const handleContractLineChange = (event) => {
        setContractLine(event.target.value.replace(/\D/g, ''));
    };

    const handleAssociateContractLineChange = (event) => {
        setAssociateContractLine(event.target.value.replace(/\D/g, ''));
    };

    const handleMPINChange = (event) => {
        setMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleAssociateMPINChange = (event) => {
        setAssociateMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleCapProcessMonthChange = (event) => {
        setCapProcessMonth(event.target.checked.replace(/\D/g, ''));
    };

    const [showTable, setShowTable] = React.useState(false); // State to control table visibility
    const [tableData, setTableData] = useState();

    // error messages when none of the fields are filled in 

    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = () => {
        if (contractNumber === "" &&
            AssociateContract=== "",
            ContractLine === "" &&
            AssociateContractLine === ""
        ) {
            setErrorMessage("Fill in at least one set of the following fields: Contract ID and Contract Line ID --- OR --- Associate Contract ID and Associate Contract Line ID");
            setShowTable(false);
        } else {
            setErrorMessage('');
            const requestBody = {
                "contract": `${contractNumber}`,
                "associate_contract": `${AssociateContract}`,
                "contract_line": `${ContractLine}`,
                "associate_contract_line": `${AssociateContractLine}`,
                "mpin": `${MPIN}`,
                "associate_mpin": `${AssociateMPIN}`,
                "cap_process_month": `${CapProcessMonth}`
            }
            AssociationSearchApi(requestBody).then
                (response => {
                    console.log(response);
                    if(response.data.length > 0) {
                        setTableData(response.data);
                    } else {
                        setErrorMessage('No Record Found');
                    }
                    
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('No Record Found') 
                });
        }
    };

    useEffect(() => {
        if (tableData !== undefined) {
            // eslint-disable-next-line
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);

    const handleReset = () => {
        // Clear all search fields  
        setContractNumber('');
        setAssociateContract('');
        setContractLine('');
        setAssociateContractLine('');
        setMPIN('');
        setAssociateMPIN('');
        setCapProcessMonth('');

        // Hide the table if it's currently being shown  
        setShowTable(false);
    };


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    return (
        <div style={{ width: 'calc(98.5vw)', height: '100%', display: 'flex', flexDirection: 'row', overflowX:'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '315px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract#</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="contractNumberInput"
                                value={contractNumber}
                                onChange={handleContractNumberChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}> Contract Line</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="ContractLineInput"
                                value={ContractLine}
                                onChange={handleContractLineChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1 }}>Associate Contract</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="AssociateContractInput"
                                value={AssociateContract}
                                onChange={handleAssociateContractChange }
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>
                        <Box>
                        Associate Contract
                            </Box>
                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                Line
                            </Box>
                        </Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="AssociateContractLineInput"
                                value={AssociateContractLine}
                                onChange={handleAssociateContractLineChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>MPIN</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="MPINInput"
                                value={MPIN}
                                onChange={handleMPINChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Associate MPIN</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="AssociateMPINInput"
                                value={AssociateMPIN}
                                onChange={handleAssociateMPINChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Cap Process Month</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="CapProcessMonthInput"
                                value={CapProcessMonth}
                                onChange={handleCapProcessMonthChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Button
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }

{!errorMessage &&(<div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '3.5rem' }}>
                <AssociationSearchTable showTable={showTable} inputTableData={tableData} />
            </div>)}
            {errorMessage && (<div style={{width:'100%', textAlign:'center', color:'red'}}>{errorMessage}</div>)}
        </div>
    );
};
