import axios from "axios";
const ASSOCIATION_DOWNLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/association/download`;

export const AssociationDownloadApi = (requestBody) => {
    return axios.post(
      ASSOCIATION_DOWNLOAD_ENDPOINT, 
        requestBody,
    {
        headers: {  
            'Cache-Control': 'no-cache',  
            'Pragma': 'no-cache',  
          },
          validateStatus: function (status) {
            return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
          },
    })
}

