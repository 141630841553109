import axios from 'axios';
const CONTRACT_RATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate`;
const CONTRACT_RATE_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate_detail`;
const CONTRACT_RATE_SCHDEULE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate_schedule`;

export const providerContractRateRegularApi = (requestBody) => {
    return axios.post(
        CONTRACT_RATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const providerContractRateDetailApi = (requestBody) => {
    return axios.post(
        CONTRACT_RATE_DETAIL_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const providerContractRateScheduleApi = (requestBody) => {
    return axios.post(
        CONTRACT_RATE_SCHDEULE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}