import { createSlice } from "@reduxjs/toolkit";

const rolesMapSlice = createSlice({
  name: "roles_temp",
  initialState: {},
  reducers: {
    updateCurrentRoleMap: (state, action) => {
      const map = action.payload;
      return map;
    },
  },
});

export const { updateCurrentRoleMap } = rolesMapSlice.actions;
export default rolesMapSlice;
