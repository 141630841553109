import axios from 'axios';
const MEMBER_HISTORY_TOTALCAP_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/total-cap`;

// SearchHandler.js
export const MemberTotalCapApi = (
    data_segment,
    ecap_mbr_key,
    ecap_contr_ln_key,
    proc_yr_mo,
    pay_partn_id


) => {
  // Prepare request body
  const requestBody = {
    data_segment: data_segment,
    ecap_mbr_key: ecap_mbr_key ,
    ecap_contr_ln_key: ecap_contr_ln_key ,
    proc_yr_mo: proc_yr_mo,
    pay_partn_id: pay_partn_id
  };


  return axios.post(
    MEMBER_HISTORY_TOTALCAP_ENDPOINT,
    requestBody,
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
};
