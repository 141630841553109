import { useEffect, useState } from "react";

import { ReportsMember } from "../ReportsTab/Member/Member";
import { ReportsBenefit } from "../ReportsTab/Benefit/Benefit";
import { ReportsProvider } from "../ReportsTab/Provider/Provider";
import { ReportsECapProcess } from "../ReportsTab/ECapProcess/ECapProcess";
import { ReportsPayment } from "../ReportsTab/Payment/Payment";
import { ReportsPremium } from "../ReportsTab/Premium/Premium";
import { getDataSegments } from "../Home/dataSegmentApi";


export const ReportsTab = ({subTabName}) => {
    const [dataSegment, setDataSegment] = useState();
    const [CMSID, setCMSID] = useState();
    useEffect(() => {
        setDataSegment(getDataSegments().map(item => ({ id: item.DATA_SEG_ID, name: item.NAME })));
    }, []);
    return (
        <div style={{ width:'100%', height:'calc(91vh)', overflowY:'scroll' }}>
            {subTabName === "Member" && <ReportsMember inputDSeg={dataSegment}/> }
            {subTabName === "Benefit" && <ReportsBenefit inputDSeg={dataSegment}/> }
            {subTabName === "Provider" && <ReportsProvider inputDSeg={dataSegment}/> }
            {subTabName === "ECapProcess" && <ReportsECapProcess inputDSeg={dataSegment}/> }
            {subTabName === "Payment" && <ReportsPayment inputDSeg={dataSegment}/> }
            {subTabName === "Premium" && <ReportsPremium /> }
        </div>
    )
}