import axios from "axios";
const DAILYINTERFACE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface`;
const DAILYINTERFACE_LIST_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface_list`;
const DAILYINTERFACE_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface_detail`;

export const InterfaceApi = (requestBody) => {
    return axios.post(
        DAILYINTERFACE_ENDPOINT, 
        requestBody,
    {
        headers: {  
            'Cache-Control': 'no-cache',  
            'Pragma': 'no-cache',  
          },
          validateStatus: function (status) {
            return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
          },
    })
}

export const InterfaceListApi = (requestBody) => {
  return axios.post(
      DAILYINTERFACE_LIST_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}

export const InterfaceDetailApi = (requestBody) => {
  return axios.post(
      DAILYINTERFACE_DETAIL_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}



