import axios from "axios";

const OVERRIDES_UPLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/upload`;

export const OverridesUploadApi = (requestBody) => {
    return axios.post(
        OVERRIDES_UPLOAD_ENDPOINT, 
        requestBody,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    })
}