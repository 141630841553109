import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { set } from "react-hook-form";

const GroupContractsTable = ({
  showTable,
  inputTableData,
  tableLoading,
  modelOptions,
  cycOptions,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState([]);
  const permissions = useSelector((store) => store.user.permissions);
  useEffect(() => {
    if (inputTableData) {
      const updatedTableData = inputTableData.map((row) => ({
        ...row,
        moveToModelName: "",
        moveToModelCycle: "",
      }));
      setTableData(updatedTableData);
    }
  }, [inputTableData]);
  const handleModelChange = (event, index) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].moveToModelName = event.target.value;
    setTableData(updatedTableData);
  };

  const handleCycChange = (event, index) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].moveToModelCycle = event.target.value;
    setTableData(updatedTableData);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [checkedRows, setCheckedRows] = useState([]);
  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, row]);
    } else {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    }
  };
  const updatePermission =
    permissions.admin_group.update === "Y" ? true : false;
  if (!showTable) {
    return null;
  }
  const move = () => {
    console.log(checkedRows);
    checkedRows.forEach((row) => {
      const moved = false;
      if (
        row.moveToModelName !== "" &&
        row.moveToModelCycle !== "" &&
        row.moveToModelName != row.MDL_NM &&
        row.moveToModelCycle != row.CYC_NBR
      ) {
        console.log(row);
        moved = true;
      }
      if (!moved) {
        alert("Please select a different model and cycle to move the contract");
      }
    });
  };

  return (
    <>
      <Paper
        sx={{
          paddingLeft: "0.2rem",
          boxShadow: "none",
          width: "100%",
          paddingTop: "1.5rem",
          overflow: "hidden",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ width: "100%" }}
          sx={{ maxHeight: "70vh" }}
        >
          <Table
            aria-label='simple table'
            stickyHeader
            style={{ width: "100%" }}
          >
            <TableHead style={{ backgroundColor: "#b0c0da" }}>
              <TableRow style={{ backgroundColor: "#b0c0da" }}>
                <TableCell
                  colSpan={5}
                  style={{
                    backgroundColor: "#b0c0da",
                    border: ".5px solid white",
                  }}
                ></TableCell>
                <TableCell
                  colSpan={2}
                  style={{
                    backgroundColor: "#b0c0da",
                    textAlign: "center",
                    border: ".5px solid white",
                  }}
                >
                  —— MOVE FROM ——
                </TableCell>
                <TableCell
                  colSpan={5}
                  style={{
                    backgroundColor: "#b0c0da",
                    textAlign: "center",
                    border: ".5px solid white",
                  }}
                >
                  —— MOVE TO ——
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: "#b0c0da" }}>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Select
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Contract
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Contract Line
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  MPIN
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Model
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Cycle
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Model
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Cycle
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                </TableRow>
              ) : (
                tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isChecked = checkedRows.includes(row);
                    return (
                      <TableRow key={row.CONTR_ID}>
                        <TableCell style={{ textAlign: "center" }}>
                          <input
                            type='checkbox'
                            checked={isChecked}
                            onChange={(event) =>
                              handleCheckboxChange(event, row)
                            }
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CONTR_ID}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CONTR_LN}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.PROC_IND === "Y" ? "	Processed" : "Inprogress"}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.PROV_ID}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.MDL_NM}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CYC_NBR}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Select
                            value={row.moveToModelName}
                            onChange={(event) =>
                              handleModelChange(event, index)
                            }
                            size='small'
                            fullWidth
                          >
                            {modelOptions.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Select
                            value={row.moveToModelCycle}
                            onChange={(event) => handleCycChange(event, index)}
                            size='small'
                            fullWidth
                          >
                            {cycOptions.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={tableData ? tableData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel": {
              paddingTop: "12px",
            },
            ".MuiTablePagination-displayedRows": {
              paddingTop: "13px",
            },
          }}
        />
      </Paper>
      <br/>
      <br/>
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          size='medium'
          variant='contained'
          disabled={checkedRows.length === 0 || !updatePermission}
          onClick={move}
        >
          Move
        </Button>
      </div>
    </>
  );
};

export default GroupContractsTable;
