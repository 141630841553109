import axios from 'axios';  
const MEMBER_HISTORY_TRANSCATION_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/transaction-history`;
   
export const TransactionHistoryApi = (  
    custID, 
    mbrID, 
    suffix,  
    ecapMbrKey,  
    mbrPartID,  
) => {  
  // Prepare request body  
  const requestBody = {  
    custID: custID,   
    mbrID: mbrID,  
    suffix: suffix,  
    ecapMbrKey: ecapMbrKey, 
    mbrPartID: mbrPartID
    
  };  
  

  return axios.post(  
    MEMBER_HISTORY_TRANSCATION_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
      },  
    }  
  ); 
};  

