import axios from "axios";
const VIEW_MEMBER_URL = `${process.env.REACT_APP_EAST_URL}/view/member/`;
const MEMBER_DETAILS_URL = `${process.env.REACT_APP_EAST_URL}/view/member-details/`;
const EAST_DB = `${process.env.REACT_APP_EAST_DB}`;
const DATA_SEG_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;
export const viewMembersAxiosClient = axios.create({
  baseURL: VIEW_MEMBER_URL,
});
export const memberDetailsAxiosClient = axios.create({
  baseURL: MEMBER_DETAILS_URL,
});
export const dataSegAxiosClient = axios.create({
  baseURL: DATA_SEG_ENDPOINT,
});
export var viewMembersJsonSchema = {
  sourceSystem: "",
  segmentName: "",
  sourceSystemMemberId: "",
  idType: "",
  idNumber: "",
  lastName: "",
  firstName: "",
  currentDatabase: EAST_DB,
};
export var memberDetailsJsonSchema = {
  sourceSystem: "",
  segmentName: "",
  sourceSystemMemberId: "",
  alternateId: "",
  lastName: "",
  firstName: "",
  currentDatabase: EAST_DB,
};
