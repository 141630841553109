import Table from '@mui/material/Table';
import { Typography } from "@mui/material";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as Constants from "./ProviderConstants";
import { ProvdierHistoryApi } from './ProvdierHistoryApi';
import React, { useState, useEffect } from 'react';

// Initialize the cache outside of the component  
const apiCache = new Map();

const MemberProviderHistory = ({ memberData }) => {

    const [providerData, setProviderData] = useState([]);


    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        let newDate = `${month}/${day}/${year}`;
        return newDate;
    };

    const getValueOrDefault = (value) => {  
        // Check for both null and undefined explicitly  
        if (value === null || value === undefined || value=== 'None') {  
          return '';  
        }  
        return value;  
      };  

    useEffect(() => {
        if (memberData) {
            const { DATA_SEG_ID, ECAP_MBR_KEY } = memberData;
            const cacheKey = `${DATA_SEG_ID}-${ECAP_MBR_KEY}`;

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data  
                setProviderData(apiCache.get(cacheKey));
            } else {
                ProvdierHistoryApi(DATA_SEG_ID, ECAP_MBR_KEY)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data); 
                        setProviderData(response.data);
                    })
                    .catch(error => {
                        console.error("Error fetching provider data:", error);
                    });
            }
        }
        }, [memberData]);



    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>
            <div style={{ marginTop: "2rem" }}>

                {/* <Paper sx={{ width: '129.3%' }}> */}
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead>

                            <TableRow>
                                <Constants.StyledTableCell>Effective Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>End Effective Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Info Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>MPIN</Constants.StyledTableCell>
                                <Constants.StyledTableCell style={{ width: '15%' }}>Provider Name</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Tax ID</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Mkt Type</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Mkt Number</Constants.StyledTableCell>
                                <Constants.StyledTableCell>IPA</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Provider End Date</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <Constants.StripedTableBody>
                            {providerData.map((item, index) => (
                                <TableRow key={index}>

                                    <TableCell>{formatDate(item.MBR_PCP_EFF_DT)}</TableCell>
                                    <TableCell>{formatDate(item.MBR_PCP_END_DT)}</TableCell>
                                    <TableCell>{formatDate(item.UPDT_DTTM.split(' ')[0])}</TableCell>
                                    <TableCell>{item.PCP_PROV_ID}</TableCell>
                                    <TableCell>{`${item.LST_NM} ${item.FST_NM}`}</TableCell>
                                    <TableCell>{item.PCP_TAX_ID_NBR}</TableCell>
                                    <TableCell>{getValueOrDefault(item.PCP_MKT_TYP_CD)}</TableCell>
                                    <TableCell>{item.PCP_MKT_NBR}</TableCell>
                                    <TableCell>{item.PCP_IPA_NBR}</TableCell>
                                    <TableCell>{formatDate(item.CANC_DT)}</TableCell>

                                </TableRow>
                            ))}
                        </Constants.StripedTableBody>
                    </Table>
                </TableContainer>

                {/* </Paper> */}
            </div>
        </div>
    );
};

export default MemberProviderHistory;
