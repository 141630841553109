import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const DemographicsSearchTable = ({
    showTable,
    inputTableData,
    switchTabs,
    setTab,
    setProvId,
    setProvTaxIdNbr,
    setProvTaxIdTypCd,
    seteffectivedate,
    setcorpmpin,
    setenddate,
    setlastname
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            console.log(inputTableData)
        }
        setPage(0);
    }, [inputTableData]);

    const switchToContractTax = (TaxID) => {
        sessionStorage.setItem('demographicsCorpMPIN', null);
        sessionStorage.setItem('demographicsTaxID', JSON.stringify(TaxID));
        setTab("Contract");
    };
    const switchToContractMPIN = (CorpMPIN) => {
        sessionStorage.setItem('demographicsTaxID', null);
        sessionStorage.setItem('demographicsCorpMPIN', JSON.stringify(CorpMPIN));
        setTab("Contract");
    };

    const getValueOrDefault = (value) => {
        // Check for both null and undefined explicitly  
        if (value === null || value === undefined || value === 'None') {
            return '';
        }
        return value;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (!showTable) {
        return null;
    }

    function providerIdClick(id, nbr, cd, ed, cm, end, ln) {
        setProvId(id);
        setProvTaxIdNbr(nbr);
        setProvTaxIdTypCd(cd);
        seteffectivedate(ed);
        setcorpmpin(cm);
        setenddate(end);
        setlastname(ln)
        switchTabs(1);
    }

    return (
        <Paper sx={{ paddingLeft: '0.2rem', boxShadow: 'none', width: '100%' }}>
            <TableContainer component={Paper} style={{ width: '100%', height: '70vh' }}>
                <Table aria-label="simple table" stickyHeader style={{ width: '100%' }}>
                    <TableHead style={{ backgroundColor: '#b0c0da' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Source System ID</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Provider ID</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Corp MPIN#</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Provider Name / Last Name</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>First Name</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Tax ID#</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Tax ID Type</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Provider PCP Ind</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Effective Date</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>End Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow key={row.CONTR_SRC_SYS_CD}>
                                        <TableCell style={{ textAlign: 'left' }}>{row.CONTR_SRC_SYS_CD}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}><a href='#' onClick={() => providerIdClick(row.PROV_ID, row.PROV_TAX_ID_NBR, row.PROV_TAX_ID_TYP_CD, row.PROV_EFF_DT, row.CORP_MPIN, row.PROV_END_DT, row.LST_NM)}>{row.PROV_ID}</a></TableCell>
                                        <TableCell style={{ textAlign: 'left' }}><a href='#' onClick={() => switchToContractMPIN(row.CORP_MPIN)}>{row.CORP_MPIN}</a></TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.LST_NM}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{(row.FST_NM === 'None') ? '' : row.FST_NM}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}><a href='#' onClick={() => switchToContractTax(row.PROV_TAX_ID_NBR)}>{row.PROV_TAX_ID_NBR}</a></TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{(row.PROV_TAX_ID_TYP_CD === 'TIN') ? 'TAX IDENTIFICATION NUMBER' : row.PROV_TAX_ID_TYP_CD}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{getValueOrDefault(row.PCP_TYP_CD)}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.PROV_EFF_DT}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.PROV_END_DT}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                    },
                }}
            />

        </Paper>
    );
};

export default DemographicsSearchTable;
