import React from "react";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Home/Footer";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path='/'
          element={<Home />}
        />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
