import axios from "axios";

const DATA_SEG_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;
export const dataSegAxiosClient = axios.create({
  baseURL: DATA_SEG_ENDPOINT,
});
const ADMIN_PROC_MDL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search/proc_mdl`;
export const adminProcMdlAxiosClient = axios.create({
  baseURL: ADMIN_PROC_MDL_ENDPOINT,
});
const ADMIN_CYC_NBR_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search/mdl_cyc`;
export const adminMdlCycAxiosClient = axios.create({
  baseURL: ADMIN_CYC_NBR_ENDPOINT,
});
const ADMIN_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search`;
export const adminGroupSearchAxiosClient = axios.create({
  baseURL: ADMIN_SEARCH_ENDPOINT,
});
const ADMIN_EXCLUDE_CHECK = `${process.env.REACT_APP_BACKEND_URL}/admin/exclude_contracts/check`;
export const adminExcludeCheckAxiosClient = axios.create({
  baseURL: ADMIN_EXCLUDE_CHECK,
});
const ADMIN_EXCLUDE = `${process.env.REACT_APP_BACKEND_URL}/admin/exclude_contracts/exclude`;
export const adminExcludeAxiosClient = axios.create({
  baseURL: ADMIN_EXCLUDE,
});
