import { combineReducers } from "redux";
import permissionsSlice from "./redux/slices/permissionSlice";
import userInfoSlice from "./redux/slices/userInfoSlice";
import roleSlice from "./redux/slices/roleSlice";
const userReducer = combineReducers({
  permissions: permissionsSlice.reducer,
  user: userInfoSlice.reducer,
  roles: roleSlice.reducer,
});
export default userReducer;
