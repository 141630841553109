import axios from "axios";
const ADMIN_CODES_GET_SEARCH = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/getsearch`;
const ADMIN_CODES_SEARCH = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/search`;
const ADMIN_CODES_ADD = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/add`;
const ADMIN_CHECK_UPDATE = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/upload`;

export const adminCodesGetSearchAxiosClient = axios.create({
  baseURL: ADMIN_CODES_GET_SEARCH,
});
export const adminCodesSearchAxiosClient = axios.create({
  baseURL: ADMIN_CODES_SEARCH,
});
export const adminCodesAddAxiosClient = axios.create({
  baseURL: ADMIN_CODES_ADD,
});
export const admincheckupdateAxiosClient = axios.create({
  baseURL: ADMIN_CHECK_UPDATE,
});
