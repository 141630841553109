import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    IconButton,
    Checkbox,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
    Typography
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { getDataSegments, getDataSegmentIntFromString } from "../../../Home/dataSegmentApi"
import { ParameterUpdateRequest } from '../../Parameters/Search/ParameterSearchRequest';


const ReportingOverridesSearchTable = ({ showTable, inputTableData, onShowUpdateTable, onCancel }) => {
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegments, setDataSegments] = useState(['']);
    const [forceCaptype, setForceCapType] = useState('PROVIDER');
    const [sourceSystem, setSourceSystem] = useState('DEFAULT');
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]); // Store selected row IDs
    const [editFormData, setEditFormData] = useState([]); // Store form data for editing
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar for warning message
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showButtons, setShowButtons] = useState(true);
    const [showUpdateTable, setShowUpdateTable] = useState(false);
    const [showTableState, setShowTableState] = useState(true);
    const [isLoading, setIsLoading] = useState(false); // Add a loading state  

    console.log("table data inside searchtable", inputTableData);

    console.log("show table value", showTable);


    // Instead of conditionally calling useEffect based on inputTableData,  
    // always call useEffect and place the conditional logic inside of it.  

    useEffect(() => {
        // The useEffect hook should always be called, but the logic inside can be conditional.  
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            setShowButtons(true); // Show the buttons when new data is loaded    
        }
        setPage(0);
    }, [inputTableData]);


    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegments(dataList);
    }, [])

    const handleDataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    }

    const handleForceCapTypeChange = (event) => {
        setForceCapType(event.target.value);
    }

    const handleSourceSystemChange = (event) => {
        setSourceSystem(event.target.value);
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle individual checkbox selection  
    // Handle individual checkbox selection  
    const handleCheckboxChange = (contractOwner) => {
        setSelectedRows((prevSelectedRows) => {
            const selectedIndex = prevSelectedRows.indexOf(contractOwner);
            let newSelectedRows = [];

            if (selectedIndex === -1) {
                newSelectedRows = [...prevSelectedRows, contractOwner];
            } else {
                newSelectedRows = prevSelectedRows.filter((owner) => owner !== contractOwner);
            }

            return newSelectedRows;
        });
    };






    // Handle Select All  
    // Handle Select All  
    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allRowIds = tableData.map((row) => row.uniqueId); // Use the same identifier as in handleCheckboxChange  
            setSelectedRows(allRowIds);
            setSelectAllChecked(true); // You should also set the selectAllChecked state to true here  
        } else {
            setSelectedRows([]);
            setSelectAllChecked(false); // And to false here  
        }
    };







    const handleUpdateClick = () => {
        if (selectedRows.length > 0) {
            console.log("row selected in search to update is", selectedRows);
            onShowUpdateTable(selectedRows); // Pass the selected rows to the parent component  
        } else {
            alert("Please select at least one row to update.");
        }
    };

    function formatDateTime(dateTimeString) {
        if (dateTimeString && dateTimeString !== "None") {
            // Extract the date and time up to the microsecond part  
            const formattedDateTime = dateTimeString.substring(0, 23);
            return formattedDateTime;
        }
        return " "; // Return a single space if dateTimeString is "None" or null  
    }





    // Handle Delete Click
    const handleDownloadClick = () => {
        // Define the headers based on the table columns you want to include  
        const headers = [
            'Model Id', 'Cycle Number', 'Contract Owner', 'Document Id',
            'Begin Process Period', 'End Process End', 'Distribution Mode',
            'Default Data Segment Address Ind', 'Provider Direct Delivery',
            'Provider Arrangement', 'Active Ind', 'Last User Id', 'Last Update Date-Time'
        ];

        // Convert tableData to CSV format  
        const csvContent = [
            headers.join(','), // header row first  
            ...tableData.map(row => [
                row.MODEL_ID, row.CYC_NBR, row.CONTRACT_OWNER, row.DOC_ID,
                row.BEGIN, row.END, row.DISTRIBUTION_MODE, row.DEFAULT_DATA_SEG_ADDRESS,
                row.PROVIDER_DIRECT_DELIVERY, row.PROVIDER_ARG, row.ACTIVE_IND,
                row.LAST_USER, row.UPDATE_DATE_TIME
            ].join(',')) // map each row of data into a CSV string  
        ].join('\n');

        // Create a Blob from the CSV Content  
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link and set the URL using createObjectURL  
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ReportingOverride_Download.csv'; // Name the file here  

        // Append to the document  
        document.body.appendChild(link);

        // Trigger 'click' to download  
        link.click();

        // Clean up and remove the link  
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };




    // Handle Dialog Save
    const handleDialogSave = () => {
        const updatedData = tableData.map((row) =>
            editFormData.find((editRow) => editRow.FRC_CAP_ID === row.FRC_CAP_ID) || row
        );
        setTableData(updatedData);
        setDialogOpen(false);
        // Clear the checkbox selection after save
        setSelectedRows([]);
        setSelectAllChecked(false);

        console.log(updatedData)

        for (let i = 0; i < updatedData.length; i++) {
            const object = updatedData[i];

            const providerUpdateRequestBody = {
                "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                "CONTR_ID": `${object.CONTRACT_ID}`,
                "CONTR_LN_ID": `${object.CONTRACT_LN_ID}`,
                "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                "REPROC_CD": `${object.REPO_CD}`
            }

            console.log(providerUpdateRequestBody)

            ParameterUpdateRequest(providerUpdateRequestBody).then(response => {
                if (response.status === 200) {
                    console.log('Update successfully', response.data)
                }
            }
            );
        }

    };

    // Handle Cancel Click (Close and reset form)
    const handleCancelClick = () => {
        setDialogOpen(false);
        // Reset the form data
        setEditFormData([]);
    };



    // Handle Snackbar Close
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    if (!showTable) {
        return null;
    }




    const handleClearTable = () => {
        setTableData([]); // Clear table data  
        setSelectedRows([]); // Clear selected rows  
        setSelectAllChecked(false); // Uncheck the 'Select All' checkbox  
        setEditFormData([]); // Reset edit form data  
        setDialogOpen(false); // Close any open dialog  
        setShowButtons(false); // Hide the buttons  
        setErrorMessage(''); // Clear any error messages  
        setShowUpdateTable(false); // Hide the update table  
        // Inform the parent component to hide the entire table  

        if (typeof onCancel === 'function') {
            onCancel();
        }
    };
    console.log("table data to filter is ", tableData);

    return (
        <div>
            {/* Table for displaying data */}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 640 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Update</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Model Id</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Cycle Number</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Contract Owner</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Document Id</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Begin Process Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>End Process End</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Distribution Mode</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Default Data Segment Address Ind</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Provider Direct Delivery</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Provider Arrangement</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Active Inde</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Last User Id</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Last Update Date-Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectedRows.includes(row.uniqueId)}
                                                    onChange={() => handleCheckboxChange(row.uniqueId)}
                                                />
                                            </TableCell>


                                            <TableCell>{row.MODEL_ID === "None" || row.MODEL_ID === null ? " " : row.MODEL_ID}</TableCell>
                                            <TableCell>{row.CYC_NBR === "None" || row.CYC_NBR === null ? " " : row.CYC_NBR}</TableCell>
                                            <TableCell>{row.CONTRACT_OWNER === "None" || row.CONTRACT_OWNER === null ? " " : row.CONTRACT_OWNER}</TableCell>
                                            <TableCell>{row.DOC_ID === "None" || row.DOC_ID === null ? " " : row.DOC_ID}</TableCell>
                                            <TableCell>{row.BEGIN === "None" || row.BEGIN === null ? " " : row.BEGIN}</TableCell>
                                            <TableCell>{row.END === "None" || row.END === null ? " " : row.END}</TableCell>
                                            <TableCell>{row.DISTRIBUTION_MODE === "None" || row.DISTRIBUTION_MODE === null ? " " : row.DISTRIBUTION_MODE}</TableCell>
                                            <TableCell>{row.DEFAULT_DATA_SEG_ADDRESS === "None" || row.DEFAULT_DATA_SEG_ADDRESS === null ? " " : row.DEFAULT_DATA_SEG_ADDRESS}</TableCell>
                                            <TableCell>{row.PROVIDER_DIRECT_DELIVERY === "None" || row.PROVIDER_DIRECT_DELIVERY === null ? " " : row.PROVIDER_DIRECT_DELIVERY}</TableCell>
                                            <TableCell>{row.PROVIDER_ARG === "None" || row.PROVIDER_ARG === null ? " " : row.PROVIDER_ARG}</TableCell>
                                            <TableCell>{row.ACTIVE_IND === "None" || row.ACTIVE_IND === null ? " " : row.ACTIVE_IND}</TableCell>
                                            <TableCell>{row.LAST_USER === "None" || row.LAST_USER === null ? " " : row.LAST_USER}</TableCell>
                                            <TableCell>
                                                {formatDateTime(row.UPDATE_DATE_TIME)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                            <TableRow>

                                <TableCell>
                                    <Box display="flex" alignItems="center" flexWrap="nowrap">
                                        <Checkbox
                                            indeterminate={selectedRows.length > 0 && selectedRows.length < tableData.length}
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        />
                                        <Typography variant="body2" noWrap>
                                            Select All
                                        </Typography>
                                    </Box>
                                </TableCell>

                                <TableCell colSpan={15}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                        <Button variant="contained" size='medium' onClick={() => handleUpdateClick(selectedRows)}>Update</Button>
                                        <Button variant="contained" size='medium' style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClearTable} >Cancel</Button>
                                        <Button variant="contained" size='medium' color="success" onClick={handleDownloadClick}>Download</Button>

                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sx={{
                        '.MuiTablePagination-selectLabel': {
                            paddingTop: '12px',
                        },
                        '.MuiTablePagination-displayedRows': {
                            paddingTop: '13px',
                        },
                    }}
                />
            </Paper>
            {/* Dialog for editing */}

            {/* Snackbar for warnings */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" variant="filled" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};
export default ReportingOverridesSearchTable;