import {
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Collapse,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";

import {
  viewMembersAxiosClient,
  viewMembersJsonSchema,
} from "./MemberSearchConstant";
import { useState, useEffect } from "react";
import { dataSegAxiosClient } from "./MemberSearchConstant";
import { MemberTable } from "./Member/MemberTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    width: "100%",
    height: "30%",
    maxHeight: "10%",
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",

  height: "50px",
  width: "100px",
  border: "none",
  fontWeight: "bold",
  color: "black",
  boxShadow: "none",
  sx: {
    width: "50%", // Adjust the width value as needed
  },
}));
export const MemberSearch = () => {
  const classes = useStyles();
  const [segmentMapping, setSegmentMapping] = useState([""]);
  useEffect(() => {
    console.log(segmentMapping.length);
    if (segmentMapping.length === 1) {
      dataSegAxiosClient.get("").then((response) => {
        console.log(response.data);
        setSegmentMapping(response.data);
      });
    }
  }, [segmentMapping]);
  const [sourceSystem, setSourceSystem] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [idType, setIdType] = useState("");
  const [isInputGiven, setIsInputGiven] = useState(false);
  const [sourceSystemMemberId, setSourceSystemMemberId] = useState("");
  const [alternateId, setAlternateId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tableGridSize, setTableGridSize] = useState(9);
  const handleCollapseEnd = () => {
    if (tableGridSize === 9) setTableGridSize(11);
  };
  const handleCollapseStart = () => {
    if (tableGridSize === 11) setTableGridSize(9);
  };
  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [downloadFile, setDownloadFile] = useState({
    data: [],
    headers: [],
    filename: "nodata.csv",
  });
  const columns = [
    {
      key: "sourceSystemMemberId",
      label: "Source System Member Key",
    },
    {
      key: "memberId",
      label: "Member ID",
    },
    {
      key: "memberIdSuffix",
      label: "Suffix",
    },
    {
      key: "lastName",
      label: "Last Name",
    },
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "alternateId",
      label: "Alt ID	",
    },
    {
      key: "medicareId",
      label: "Medicare ID",
    },
    {
      key: "medicaidId",
      label: "Medicaid ID",
    },
  ];
  const handleSearch = () => {
    let newError = "";
    if (!sourceSystem) {
      newError = "Please select Source System";
    } else if (!segmentName) {
      newError = "Please select Data Segment";
    } else if (
      !(sourceSystemMemberId || alternateId || lastName || firstName)
    ) {
      newError = "Please provide any one of the inputs";
    } else if (sourceSystem && segmentName && idType && !alternateId) {
      newError = "Please provide the ID number";
    } else if (sourceSystem && segmentName && alternateId && !idType) {
      newError = "Please select the ID Type";
    } else if (sourceSystem && segmentName && firstName && !lastName) {
      newError = "Last Name is required";
    } else if (
      sourceSystem &&
      segmentName &&
      sourceSystemMemberId &&
      alternateId
    ) {
      newError =
        "Either select source system member key or alternative id, click clear to clear input.";
    } else if (
      sourceSystem &&
      segmentName &&
      sourceSystemMemberId &&
      firstName &&
      lastName
    ) {
      newError =
        "Either select source system member key or first name and last name , click clear to clear input.";
    }
    setError(newError);
    if (newError === "") {
      setLoading(true);
      setResults([]);
      setShowTable(true);
      setError("");
      const requestBody = { ...viewMembersJsonSchema };
      requestBody.sourceSystemMemberId = sourceSystemMemberId;
      requestBody.firstName = firstName;
      requestBody.lastName = lastName;
      requestBody.sourceSystem = sourceSystem;
      requestBody.idType = idType;
      requestBody.idNumber = alternateId;
      requestBody.segmentName = segmentName;
      console.log(requestBody);
      viewMembersAxiosClient
        .post("", requestBody)
        .then((response) => {
          console.log(response.data);
          if (
            response.status === 200 &&
            (response.data[0].message === "Member Not Found! Try again" ||
              response.data[0].message ===
                "Timed Out to fetch data provide more information to search")
          ) {
            setError(response.data[0].message);
          } else {
            console.log(response.data);
            setResults(response.data);
            setDownloadFile({
              data: response.data,
              headers: columns,
              filename: "search_results.csv",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            setError("Error 500: Internal Server Error");
          } else {
            setError("Error: " + error.message);
          }
        });
    }
  };
  const handleSourceSystemIdChange = (event) => {
    setSourceSystemMemberId(event.target.value);
    setIsInputGiven(true);
  };
  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    setIsInputGiven(true);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
    setIsInputGiven(true);
  };
  const handleAlternaIdChange = (event) => {
    setAlternateId(event.target.value);
    setIsInputGiven(true);
  };
  const handleSourceSystemChange = (event) => {
    setSourceSystem(event.target.value);
    setIsInputGiven(true);
  };
  const handleIdTypeChange = (event) => {
    setIdType(event.target.value);
    setIsInputGiven(true);
  };
  const handleSegmentNameChange = (event) => {
    setSegmentName(event.target.value);
    setIsInputGiven(true);
  };
  const resetPage = () => {
    setSourceSystem("");
    setSegmentName("");
    setIdType("");
    setSourceSystemMemberId("");
    setAlternateId("");
    setFirstName("");
    setLastName("");
    setError("");
    setResults([]);
    setShowTable(false);
    setIsInputGiven(false);
    setLoading(false);
  };
  return (
    <Grid
      container
      spacing={-1}
      sx={{ paddingTop: "4vh" }}
    >
      <Grid
        item
        xs={isCollapsed ? 0 : 3}
        sx={{
          transition: "width 300ms",
        }}
      >
        <Collapse
          orientation='horizontal'
          in={!isCollapsed}
          onExited={handleCollapseEnd}
          onEnter={handleCollapseStart}
        >
          <Stack spacing={0.7}>
            <Grid
              container
              spacing={-10}
              alignItems='center'
            >
              {" "}
              <Grid xs={8}></Grid>
              <Grid xs={4}>
                <Stack
                  direction='row'
                  spacing={-0.5}
                >
                  <Tooltip title='Advanced Filters'>
                    <IconButton>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Save Search'>
                    <IconButton>
                      <BookmarkIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Download Search'>
                    <IconButton>
                      <CSVLink
                        style={{ color: "grey", textDecoration: "unset" }}
                        {...downloadFile}
                      >
                        <DownloadIcon />
                      </CSVLink>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Reset All'>
                    <IconButton onClick={resetPage}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Collapse'>
                    <IconButton>
                      <IconButton onClick={handleCollapseClick}>
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={-11}
              alignItems='center'
              sx={{ height: "6vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
              >
                <Item sx={{ width: "100%" }}>
                  Source System
                  <span style={{ color: "red" }}>*</span>
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Select
                  value={sourceSystem}
                  onChange={handleSourceSystemChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.input}
                  sx={{
                    width: "100%",
                  }}
                  size='small'
                >
                  <MenuItem
                    value=''
                    sx={{
                      fontSize: "0.8rem",
                    }}
                  ></MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "0.8rem",
                    }}
                    value={"CES"}
                  >
                    CES
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "0.8rem",
                    }}
                    value={"COSMOS"}
                  >
                    COSMOS
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "0.8rem",
                    }}
                    value={"GPS"}
                  >
                    GPS
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "0.8rem",
                    }}
                    value={"CSP"}
                  >
                    CSP
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={-10}
              alignItems='center'
              sx={{ height: "5vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
              >
                <Item sx={{ width: "100%" }}>
                  Data Segment<span style={{ color: "red" }}>*</span>
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Select
                  value={segmentName}
                  onChange={handleSegmentNameChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.input}
                  size='small'
                >
                  <MenuItem value=''>
                    <em></em>
                  </MenuItem>
                  {segmentMapping.map((segment) => (
                    <MenuItem
                      value={segment.NAME + " (" + segment.DATA_SEG_ID + ")"}
                      key={segment.DATA_SEG_ID}
                    >
                      {segment.NAME} ({segment.DATA_SEG_ID})
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={-10}
              alignItems='center'
              sx={{ height: "5vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
              >
                <Item sx={{ width: "100%" }}>Source System Member Key</Item>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  value={sourceSystemMemberId}
                  onChange={handleSourceSystemIdChange}
                  id='name'
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  fullWidth
                  size='small'
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={-10}
              alignItems='center'
              sx={{ height: "5vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
                sx={{ marginTop: "7px" }}
              >
                <FormControl sx={{ width: "80%" }}>
                  <InputLabel id='id-type-label'>
                    <Typography
                      variant='h5'
                      fontWeight='bold'
                      display='block'
                      textAlign='center'
                      sx={{ fontSize: "0.9rem" }}
                    >
                      Other ID
                    </Typography>
                  </InputLabel>
                  <Select
                    labelId='id-type-label'
                    value={idType}
                    onChange={handleIdTypeChange}
                    label='Other ID type'
                    className={classes.input}
                    fullWidth
                    size='small'
                  >
                    <MenuItem value=''>
                      <em></em>
                    </MenuItem>
                    <MenuItem value={"Alt ID"}>
                      <Typography
                        variant='h5'
                        fontWeight='bold'
                        display='block'
                        align='left'
                        sx={{ fontSize: "0.9rem" }}
                      >
                        Alt ID
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Member ID"}>
                      <Typography
                        variant='h5'
                        fontWeight='bold'
                        display='block'
                        align='left'
                        sx={{ fontSize: "0.9rem" }}
                      >
                        Member ID
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Medicare ID"}>
                      <Typography
                        variant='h5'
                        fontWeight='bold'
                        display='block'
                        align='left'
                        sx={{ fontSize: "0.9rem" }}
                      >
                        Medicare ID
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"Medicaid ID"}>
                      <Typography
                        variant='h5'
                        fontWeight='bold'
                        display='block'
                        align='left'
                        sx={{ fontSize: "0.9rem" }}
                      >
                        Medicaid ID
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  value={alternateId}
                  onChange={handleAlternaIdChange}
                  id='name'
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  fullWidth
                  size='small'
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={-10}
              alignItems='center'
              sx={{ height: "5vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
              >
                <Item sx={{ width: "100%" }}>First Name</Item>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  value={firstName}
                  onChange={handleFirstName}
                  id='name'
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  sx={{ width: "100%" }}
                  fullWidth
                  size='small'
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={-10}
              alignItems='center'
              sx={{ height: "5vh", width: "19vw" }}
            >
              <Grid
                item
                xs={6}
              >
                <Item sx={{ width: "100%" }}>Last Name</Item>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  onChange={handleLastName}
                  value={lastName}
                  id='name'
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  fullWidth
                  size='small'
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Typography
              variant='error'
              fontWeight='bold'
              display='block'
              align='left'
              sx={{ fontSize: "1rem", color: "red" }}
            >
              {error}
            </Typography>

            {/* Add more form inputs here */}

            <Grid
              container
              spacing={0.5}
            >
              <Grid
                item
                xs={4}
                alignItems='center'
              >
                <Button
                  variant='contained'
                  endIcon={<SendIcon />}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Grid>
              <Grid
                item
                xs={4}
                alignItems='center'
              ></Grid>
              <Grid
                item
                xs={4}
                alignItems='center'
              ></Grid>
            </Grid>
          </Stack>
        </Collapse>
      </Grid>
      {isCollapsed ? (
        <Grid
          item
          xs='auto'
          alignItems='center'
          sx={{ marginLeft: !isCollapsed ? "-5.2vw" : 0 }}
        >
          <Tooltip title='Collapse'>
            <IconButton>
              <IconButton onClick={handleCollapseClick}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={tableGridSize}
      >
        <MemberTable
          rows={results}
          loading={loading}
          showTable={showTable}
        />
      </Grid>
    </Grid>
  );
};
