import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

export const data = [
    {
      MBR_REL_CD: "EE",
      MBR_LST_NM: "TRAN",
      MBR_FST_NM: "QUANG",
      BTH_DT: "1944-01-01",
      GDR_CD: "MALE",
      HIC_ID: "9DW6KR0QE49",
      PCP_PROV_ID: "3399725",
      PROV_LST_NM: "PHAN",
      PROV_FST_NM: "DUC",
      MBR_PCP_EFF_DT: "2021-01-01",
      MBR_PCP_END_DT: "2021-01-01"
    },

];
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#b0c0da",
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

export const StripedTableBody = styled(TableBody)(({ theme }) => ({
  '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  },
  '& tr:hover': {
      backgroundColor: theme.palette.action.hover,
  },
}));

export const memberData = {
    "Data Segment": "UHUHSCS",
    name: "FIELDING, GERRYLEEN ",
    "Member id": " DC99DCDSNPF-00122031457-00",
    Arrangment: "PC",
    "Cap Contract Type": "PCA",
  };

export const cl2 = {
    "DOB": "14-05-1953",
    "Employer Group#": "DC99DCDSNPF",
  };
  export const cl1 = {
    "Benefit Plan ID": "",
    "OV": "	$0.00",
  };
  export const cl3 = {
    "HMO ADC": "",
    "Obligor ID": "01",
  };
 export const tableCellStyle = {
        fontSize: "0.875rem", // Adjust the font size as needed
        padding: "10px 10px", // Adjust the padding as needed
    };
