import { useEffect, useState } from "react"
import { AgingNormal } from "./AgingNormal";
import { AgingDetail } from "./AgingDetail";
import { AgingContractDetail } from "./AgingContractDetail";
import { clearAgingDetail } from "../errorPersistence";

export const ErrorsAging = () => {
    const [renderChoice, setRenderChoice] = useState(0);
    const [detailData, setDetailData] = useState({});
    const [contractDetailData, setContractDetailData] = useState({});

    function adjustDetailData(key, value) {
        var temp = detailData;
        temp[key] = value;
        setDetailData(temp);
    }

    function adjustContractDetailData(key, value) {
        var temp = contractDetailData;
        temp[key] = value;
        setContractDetailData(temp);
    }

    if (renderChoice === 0) {
        clearAgingDetail();
        return <AgingNormal
            setRenderChoice={setRenderChoice}
            adjustDetailData={adjustDetailData}
        />
    } else if (renderChoice === 1) {
        return <AgingDetail
            setRenderChoice={setRenderChoice}
            inputDetailData={detailData}
            adjustContractDetailData={adjustContractDetailData}
        />
    } else if (renderChoice == 2) {
        return <AgingContractDetail
            setRenderChoice={setRenderChoice}
            inputContractDetailData={contractDetailData}
        />
    }
}