import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { AgingDetailApi } from './AgingApi';
import { getAgingDetail, setAgingDetail } from '../errorPersistence';

export const AgingDetail = ({
    setRenderChoice,
    inputDetailData,
    adjustContractDetailData
}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingTable, setLoadingTable] = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');
        if (!table) {
            console.error('Table not found');
            return;
        }
        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });
        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');
        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });
        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'AgingDetail.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        console.log(inputDetailData);
        var temp = getAgingDetail();
        if (temp) {
            setTableData(temp);
            setLoadingTable(false);
            setShowTable(true);
        } else {
            if (inputDetailData['over6']) {
                setLoadingTable(false);
                setShowTable(false);
                setErrorMessage('No data found');
            } else {
                AgingDetailApi(inputDetailData).then(response => {
                    setLoadingTable(false);
                    if (response.data.length > 0) {
                        setAgingDetail(response.data);
                        setShowTable(true);
                        setTableData(response.data);
                        adjustContractDetailData('DATA_SEG_ID', inputDetailData['DATA_SEG_ID']);
                        adjustContractDetailData('JOB_TYP_CD', inputDetailData['JOB_TYP_CD']);
                        adjustContractDetailData('Process_month', inputDetailData['Process_month']);
                        adjustContractDetailData('End_Month', inputDetailData['End_Month']);
                        adjustContractDetailData('PROV_ID', inputDetailData['PROV_ID']);
                        adjustContractDetailData('POL_ID', inputDetailData['POL_ID']);
                        adjustContractDetailData('CUST_ID', inputDetailData['CUST_ID']);
                        adjustContractDetailData('MBR_ID', inputDetailData['MBR_ID']);
                        adjustContractDetailData('MBR_ID_SUFX', inputDetailData['MBR_ID_SUFX']);
                        adjustContractDetailData('ERR_TYP_CD', inputDetailData['ERR_TYP_CD']);
                        adjustContractDetailData('ERR_SEV_CD', inputDetailData['ERR_SEV_CD']);
                        adjustContractDetailData('LNG_DESC', inputDetailData['LNG_DESC']);
                        adjustContractDetailData('CNT', inputDetailData['CNT']);
                        adjustContractDetailData('PRD_AGE_CD', inputDetailData['PRD_AGE_CD']);
                    } else {
                        setErrorMessage('No data found');
                    }
                }).catch(error => {
                    console.log(error);
                    setLoadingTable(false);
                    setErrorMessage('No data found');
                });
            }
        }
    }, []);

    function agingDetailClick(PROV_ID, id, lineId) {
        adjustContractDetailData('PROV_ID', PROV_ID);
        adjustContractDetailData('CONTR_ID', PROV_ID);
        adjustContractDetailData('CONTR_LN_ID', PROV_ID);
        setRenderChoice(2);
    }

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputDetailData['DATA_SEG_ID'])} | Error Severity: {inputDetailData['ERR_SEV_CD']} | Error Category: {inputDetailData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputDetailData['ERR_TYP_CD']} | Error Message: {inputDetailData['LNG_DESC']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Current Count: {inputDetailData['CNT']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Aging Search Results</a> {'>'} Aging Detail
            </Typography>
            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Source System</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Field</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Process Month</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Cap Period</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Member ID</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract MPIN</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Employer Group#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Time Stamp</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow key={row.ERR_TYP_CD}>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_FLD_NM}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.PROC_YR_MO}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CAP_PRD_YR_MO}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.MBR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => agingDetailClick(row.PROV_ID, row.CONTR_ID, row.CONTR_LN_ID)}>{row.PROV_ID}</a></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CONTR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CONTR_LN_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.POL_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CREAT_DTTM}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.COMMT}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ mt: '2rem' }} />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
