import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import {
    AppBar,
    Container,
    Toolbar,
    Paper,
    TextField,
    Grid,
    Typography,
    Link,
    Button,
    Box,
    Select,
    MenuItem,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio,
    Stepper,
    Step,
    StepLabel
} from '@mui/material';


export const ProviderForm = () => {

    return (
        <Box>
            <Typography variant='h6' gutterBottom>Provider</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Data Segment</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Provider Arrangement</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Cap Process Month'
                        fullWidth
                        variant='standard'
                    // {...register('capProcessMonth')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Cap Period'
                        fullWidth
                        variant='standard'
                    // {...register('capPeriod')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Amount'
                        fullWidth
                        variant='standard'
                    // {...register('amount')}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="outlined-multiline-static"
                        autoComplete="off"
                        required
                        label="Description"
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Coverage Source System</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Employer/Div-Group</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>HMO ADC</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Product Code</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Product Class ID</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Financial Market Segment Code</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Funding Arrangement</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Plan State of Issue</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Provider Network</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>UHUHSMR</MenuItem>
                        <MenuItem value='America'>UHUHSM3</MenuItem>
                        <MenuItem value='Nigeria'>UHUHSM2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Provider Contract Number'
                        fullWidth
                        variant='standard'
                    // {...register('capProcessMonth')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Provider Contract Line Number'
                        fullWidth
                        variant='standard'
                    // {...register('capPeriod')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Contract Owner MPIN'
                        fullWidth
                        variant='standard'
                    // {...register('capProcessMonth')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Assoc Group'
                        fullWidth
                        variant='standard'
                    // {...register('capPeriod')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Specialty Code</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Adjustment Category Code</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="off"
                        required
                        label='Adjustment Code'
                        fullWidth
                        variant='standard'
                    // {...register('capPeriod')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ textAlign: 'left' }}>Extension</FormLabel>
                    <Select
                        required
                        label='Country'
                        fullWidth
                        variant='standard'
                    // {...register('country')}
                    >
                        <MenuItem value='USA'>HOSPITAL</MenuItem>
                        <MenuItem value='America'>INTERSEGMENT</MenuItem>
                        <MenuItem value='Nigeria'>PRIMARY CARE</MenuItem>
                        <MenuItem value='America'>SUBCAP</MenuItem>
                        <MenuItem value='Nigeria'>THIRD PARTY</MenuItem>
                    </Select>
                </Grid>
                <Grid container spacing={1} item justifyContent="flex-end">
                    <Grid item >
                        <Button
                            type='next'
                            variant='contained'
                            sx={{ mt: 3, ml: 1 }}
                            //fullWidth
                            size='large'
                            color='error'

                        >
                            Cancel
                        </Button>
                        <Button
                            type='next'
                            variant='contained'
                            sx={{ mt: 3, ml: 1 }}
                            //fullWidth
                            size='large'
                            color='warning'

                        >
                            Clear
                        </Button>
                        <Button
                            type='next'
                            variant='contained'
                            sx={{ mt: 3, ml: 1 }}
                            //fullWidth
                            size='large'

                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
export default ProviderForm;