import axios from 'axios';
const RETRO_SEARCH_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/retro/search_update`;

export const retroSearchUpdateApi = (requestBody) => {
    return axios.post(
        RETRO_SEARCH_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}
