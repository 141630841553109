import axios from "axios";
const REPORTS_VIEW_DOWNLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/reports/view_download`;
export const viewDownloadApi = (requestBody) => {
    return axios.post(
        REPORTS_VIEW_DOWNLOAD_ENDPOINT, 
        requestBody,
    {
        responseType: 'blob',
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    })
}