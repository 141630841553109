import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { ProviderContractAdjustment } from "./ContractAdjustment/ProviderContractAdjustment";
import { ProviderContractSearch } from "./ContractSearch/ProviderContractSearch";
import { ProviderContractRate } from "./ContractRate/ProviderContractRate";
import { ProviderContractContract } from "./ContractContract/ProviderContractContract";
import { ProviderContractMember } from "./ContractMember/ProviderContractMember";
import { useEffect } from "react";
import { getDataSegmentsDict } from "../../Home/dataSegmentApi";
import { contractClear, contractLineClear, searchClear } from "./providerContractPersistence";
import { providerContractMsbpApi } from "./generalProviderContractTabApi";

// subtabs within provider contract  
const sections = [
    "Search",
    "Contract",
    "Rate",
    "Adjustments",
    "Member Selection Criteria",
];

export const ProviderContractTab = () => {
    const [value, setValue] = useState(0);
    const [renderContractRegular, setRenderContractRegular] = useState(true); // regular, not regular
    const [renderedRate, setRenderedRate] = useState(0); // 0, 1, 2
    const [renderRegularMemberSelectionCriteria, setRenderRegularMemberSelectionCriteria] = useState(true); // regular, not regular
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        true,
        true,
        true,
        true
    ]);
    // data segment shared variables
    const [selectedDataSegment, setSelectedDataSegment] = useState();
    const [selectedDataSegmentString, setSelectedDataSegmentString] = useState();
    // shared variables
    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractEffDate, setContractEffDate] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [contractLineEffDate, setContractLineEffDate] = useState();
    const [contractLineEndDate, setContractLineEndDate] = useState();
    const [rateEffDate, setRateEffDate] = useState();
    const [scheduleId, setScheduleId] = useState();
    const [scheduleName, setScheduleName] = useState();
    const [scheduleEffDate, setScheduleEffDate] = useState();
    const [publicPri, setPublicPri] = useState();
    const [schedulePri, setSchedulePri] = useState();
    const [memberSelEffDate, setMemberSelEffDate] = useState();
    const [memberSelEndDate, setMemberSelEndDate] = useState();
    const [fundArgt, setFundArgt] = useState();
    const [stateCd, setStateCd] = useState();
    const [msbp, setMsbp] = useState();

    // only adjust component if value changes  
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // premade functions to change tabs
    function providerContractSearchLink() {
        searchClear();
        setStateCd();
        setRenderContractRegular(true);
        setDisabledTabs([false, true, true, true, true]);
        setValue(0);
    }
    function providerContractRegularLink() {
        contractClear();
        setStateCd();
        setRenderContractRegular(true);
        setDisabledTabs([false, false, true, true, true]);
        setValue(1);
    }
    function providerContractLineLink() {
        contractLineClear();
        setStateCd();
        setRenderContractRegular(false);
        setDisabledTabs([false, false, true, true, true]);
        setValue(1);
    }
    function providerContractLineLinkValues(id, name, calc, lineEff, lineEnd) {
        setContractLineId(id);
        setContractTypeCd(name);
        setCalcMethCd(calc);
        setContractLineEffDate(lineEff);
        setContractLineEndDate(lineEnd);
        setRenderContractRegular(false);
        setDisabledTabs([false, false, true, true, true]);
        setValue(1);
    }
    function providerContractRateRegularLink() {
        setRenderedRate(0);
        setDisabledTabs([false, false, false, false, false]);
        setValue(2);
    }
    function providerContractRateDetailLink() {
        setRenderedRate(1);
        setDisabledTabs([false, false, false, false, false]);
        setValue(2);
    }
    function providerContractRateScheduleLink() {
        setRenderedRate(2);
        setDisabledTabs([false, false, false, false, false]);
        setValue(2);
    }
    function memberSelectionCriteriaNormal() {
        setRenderRegularMemberSelectionCriteria(true);
        setValue(4);
    }
    function memberSelectionCriteriaDetail(eff, end, fundArgt) {
        setMemberSelEffDate(eff);
        setMemberSelEndDate(end);
        setFundArgt(fundArgt);
        setRenderRegularMemberSelectionCriteria(false);
        setValue(4);
    }
    // set tab selection value (0, 1, 2, 3, 4) from tab component
    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                providerContractSearchLink();
                break;
            case 1:
                providerContractRegularLink();
                break;
            case 2:
                providerContractRateRegularLink();
                break;
            case 3:
                setValue(3);
                break;
            case 4:
                memberSelectionCriteriaNormal();
                break;
            default:
                providerContractSearchLink();
                break;
        }
    };
    useEffect(() => {
        if (selectedDataSegment) {
            setSelectedDataSegmentString(getDataSegmentsDict()[selectedDataSegment]);
        }
    }, [selectedDataSegment]);
    useEffect(() => {
        if (selectedDataSegment && stateCd && contractLineEffDate) {
            var requestBody = {
                "DATA_SEG_ID": selectedDataSegment,
                "PREM_FCT_TYP_CD": "PREMTAX_" + stateCd,
                "PROC_YR_MO": contractLineEffDate
            }
            providerContractMsbpApi(requestBody).then(response => {
                setMsbp(response.data);
            }).catch(error => {
                console.log('error calling endpoint for nbsp');
            });
        }
    }, [selectedDataSegment, stateCd, contractLineEffDate]);
    // actual components to show depending on tab value  
    const makeTabComponent = (value) => {
        if (value === 0 && providerContractRegularLink && setContractName && setContractEffDate && setContractNumber && setPublicPri) {
            return <ProviderContractSearch
                contractLink={providerContractRegularLink}
                setPublicContractName={setContractName}
                setPublicContractEffDate={setContractEffDate}
                setPublicContractNumber={setContractNumber}
                setPublicPri={setPublicPri}
                setContrSrcSysCd={setContrSrcSysCd}
            />;
        }
        if (value === 1) {
            return <ProviderContractContract
                // links
                renderRegular={renderContractRegular}
                searchLink={providerContractSearchLink}
                contractLink={providerContractRegularLink}
                lineLink={providerContractLineLinkValues}
                rateLink={providerContractRateRegularLink}
                // input variables
                inputContractName={contractName}
                inputContractNumber={contractNumber}
                inputContractLineId={contractLineId}
                inputContractEffDate={contractEffDate}
                inputContractTypeCd={contractTypeCd}
                inputCalcMethCd={calcMethCd}
                inputPublicPri={publicPri}
                inputPublicContractLineEffDate={contractLineEffDate}
                inputPublicContractLineEndDate={contractLineEndDate}
                selectedDataSegmentString={selectedDataSegmentString}
                inputContrSrcSysCd={contrSrcSysCd}
                // input functions
                setPublicSelectedDataSegment={setSelectedDataSegment}
                setContractSublineID={setContractSublineID}
                setPublicStateCd={setStateCd}
            />;
        }
        if (value === 2) {
            return <ProviderContractRate
                // links
                renderRegular={renderedRate}
                searchLink={providerContractSearchLink}
                contractLink={providerContractRegularLink}
                lineLink={providerContractLineLink}
                regularLink={providerContractRateRegularLink}
                detailLink={providerContractRateDetailLink}
                scheduleLink={providerContractRateScheduleLink}
                // input variables
                inputContractName={contractName}
                inputContractNumber={contractNumber}
                inputContractTypeCd={contractTypeCd}
                inputCalcMethCd={calcMethCd}
                inputContractLineId={contractLineId}
                inputContractSublineID={contractSublineID}
                selectedDataSegmentString={selectedDataSegmentString}
                inputContrSrcSysCd={contrSrcSysCd}
                inputPublicPri={publicPri}
                inputRateEffDate={rateEffDate}
                inputScheduleId={scheduleId}
                inputScheduleName={scheduleName}
                inputScheduleEffDate={scheduleEffDate}
                inputSchedulePri={schedulePri}
                inputMsbp={msbp}
                // input functions
                setPublicRateEffDate={setRateEffDate}
                setPublicScheduleId={setScheduleId}
                setPublicScheduleName={setScheduleName}
                setPublicScheduleEffDate={setScheduleEffDate}
                setPublicSchedulePri={setSchedulePri}
            />;
        }
        if (value === 3) {
            return <ProviderContractAdjustment
                searchLink={providerContractSearchLink}
                contractLink={providerContractRegularLink}
                lineLink={providerContractLineLink}
                // input variables
                inputContractName={contractName}
                inputContractNumber={contractNumber}
                inputContractTypeCd={contractTypeCd}
                inputCalcMethCd={calcMethCd}
                inputContractLineId={contractLineId}
                inputContractSublineID={contractSublineID}
                selectedDataSegmentString={selectedDataSegmentString}
                inputContrSrcSysCd={contrSrcSysCd}
                inputPublicPri={publicPri}
                inputMsbp={msbp}
            />;
        }
        if (value === 4) {
            return <ProviderContractMember
                renderRegular={renderRegularMemberSelectionCriteria}
                searchLink={providerContractSearchLink}
                contractLink={providerContractRegularLink}
                lineLink={providerContractLineLink}
                regularLink={memberSelectionCriteriaNormal}
                detailLink={memberSelectionCriteriaDetail}
                // input variables
                inputContractName={contractName}
                inputContractNumber={contractNumber}
                inputContractTypeCd={contractTypeCd}
                inputCalcMethCd={calcMethCd}
                inputContractLineId={contractLineId}
                inputContractSublineID={contractSublineID}
                selectedDataSegmentString={selectedDataSegmentString}
                inputContrSrcSysCd={contrSrcSysCd}
                inputPublicPri={publicPri}
                inputMemberSelEffDate={memberSelEffDate}
                inputMemberSelEndDate={memberSelEndDate}
                inputFundArgt={fundArgt}
                inputMsbp={msbp}
            />;
        }
    };
    return (
        <div style={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section, index) => (
                    <Tab
                        key={index}
                        label={section}
                        disabled={disabledTabs[index]}
                        sx={{
                            color: value === index ? "#002153" : "#000000",
                            "&.Mui-selected": {
                                color: "#1876d2",
                            },
                        }}
                    />
                ))}
            </Tabs>
            <MakeTabComponent />
        </div>
    );
};
