import axios from 'axios';
const CONTRACT_CONTRACT_REQULAR_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_regular`;
export const contractNormalApi = (requestBody) => {
    return axios.post(
        CONTRACT_CONTRACT_REQULAR_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
};
