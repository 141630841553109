import { useEffect, useState } from "react"
import { ProviderContractRateRegular } from "./ProviderRateRegular"
import { ProviderContractRateDetail } from "./ProviderRateDetail"
import { ProviderContractRateSchedule } from "./ProviderRateSchedule"


export const ProviderContractRate = ({
    renderRegular,
    searchLink,
    contractLink,
    lineLink,
    regularLink,
    detailLink,
    scheduleLink,
    inputContractName,
    inputContractNumber,
    inputContractTypeCd,
    inputCalcMethCd,
    inputContractLineId,
    inputContractSublineID,
    selectedDataSegmentString,
    inputContrSrcSysCd,
    inputPublicPri,
    inputRateEffDate,
    inputScheduleId,
    inputScheduleName,
    inputScheduleEffDate,
    inputSchedulePri,
    inputMsbp,
    setPublicRateEffDate,
    setPublicScheduleId,
    setPublicScheduleName,
    setPublicScheduleEffDate,
    setPublicSchedulePri
}) => {
    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [pri, setPri] = useState();
    const [rateEffDate, setRateEffDate] = useState();
    const [scheduleId, setScheduleId] = useState();
    const [scheduleName, setScheduleName] = useState();
    const [scheduleEffDate, setScheduleEffDate] = useState();
    const [schedulePri, setSchedulePri] = useState();
    const [msbp, setMsbp] = useState();

    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName])
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber])
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd])
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId])
    useEffect(() => {
        setContractSublineID(inputContractSublineID);
    }, [inputContractSublineID])
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd])
    useEffect(() => {
        setPri(inputPublicPri);
    }, [inputPublicPri])
    useEffect(() => {
        setRateEffDate(inputRateEffDate);
    }, [inputRateEffDate])
    useEffect(() => {
        setScheduleId(inputScheduleId);
    }, [inputScheduleId])
    useEffect(() => {
        setScheduleName(inputScheduleName);
    }, [inputScheduleName])
    useEffect(() => {
        setScheduleEffDate(inputScheduleEffDate);
    }, [inputScheduleEffDate])
    useEffect(() => {
        setSchedulePri(inputSchedulePri);
    }, [inputSchedulePri])
    useEffect(() => {
        setMsbp(inputMsbp);
    }, [inputMsbp])
    
    return (
        <div>
            {renderRegular === 0 &&
                <ProviderContractRateRegular
                    searchLink={searchLink}
                    contractLink={contractLink}
                    lineLink={lineLink}
                    detailLink={detailLink}
                    scheduleLink={scheduleLink}
                    inputContractName={contractName}
                    inputContractNumber={contractNumber}
                    inputContractTypeCd={contractTypeCd}
                    inputCalcMethCd={calcMethCd}
                    inputContractLineId={contractLineId}
                    inputContractSublineID={contractSublineID}
                    selectedDataSegmentString={selectedDataSegmentString}
                    inputContrSrcSysCd={contrSrcSysCd}
                    inputPublicPri={pri}
                    setPublicRateEffDate={setPublicRateEffDate}
                    setPublicScheduleId={setPublicScheduleId}
                    setPublicScheduleName={setPublicScheduleName}
                    setPublicScheduleEffDate={setPublicScheduleEffDate}
                    setPublicSchedulePri={setPublicSchedulePri}
                />
            }
            {renderRegular === 1 &&
                <ProviderContractRateDetail
                    searchLink={searchLink}
                    contractLink={contractLink}
                    lineLink={lineLink}
                    regularLink={regularLink}
                    inputContractName={contractName}
                    inputContractNumber={contractNumber}
                    inputContractTypeCd={contractTypeCd}
                    inputCalcMethCd={calcMethCd}
                    inputContractLineId={contractLineId}
                    inputContractSublineID={contractSublineID}
                    selectedDataSegmentString={selectedDataSegmentString}
                    inputContrSrcSysCd={contrSrcSysCd}
                    inputPublicPri={pri}
                    inputRateEffDate={rateEffDate}
                    inputMsbp={msbp}
                />
            }
            {renderRegular === 2 &&
                <ProviderContractRateSchedule
                    searchLink={searchLink}
                    contractLink={contractLink}
                    lineLink={lineLink}
                    regularLink={regularLink}
                    inputContractName={contractName}
                    inputContractNumber={contractNumber}
                    inputContractTypeCd={contractTypeCd}
                    inputCalcMethCd={calcMethCd}
                    inputContractLineId={contractLineId}
                    inputContractSublineID={contractSublineID}
                    selectedDataSegmentString={selectedDataSegmentString}
                    inputScheduleId={scheduleId}
                    inputScheduleName={scheduleName}
                    inputScheduleEffDate={scheduleEffDate}
                    inputContrSrcSysCd={contrSrcSysCd}
                    inputSchedulePri={schedulePri}
                    inputMsbp={msbp}
                />
            }
        </div>
    )
}