import axios from 'axios';
const CYCLE_REF_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/cycle_reference`;

export function errorCycleRefApi(requestBody) {
    return axios.post(
        CYCLE_REF_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        }
    );
};