import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography, LinearProgress, Box } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { InterfaceDetailApi } from './DailyInterfaceApi';

export const DailyDetail = ({
    setRenderChoice,
    inputDetailData
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');
        if (!table) {
            console.error('Table not found');
            return;
        }
        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });
        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');
        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });
        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'DailyDetail.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        setErrorMessage('');
        InterfaceDetailApi(inputDetailData).then(response => {
            setLoadingTable(false);
            if (response.data.length > 0) {
                setTableData(response.data);
                setShowTable(true);
            } else {
                setShowTable(false);
                setErrorMessage('No data found');
            }
        }).catch(error => {
            setLoadingTable(false);
            setErrorMessage('No data found');
            console.log(error);
        });
    }, [])

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputDetailData['DATA_SEG_ID'])} | Error Severity: {inputDetailData['ERR_SEV_CD']} | Error Category: {inputDetailData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputDetailData['ERR_TYP_CD']} | Error Message: {inputDetailData['LNG_DESC']} | Error Value: {inputDetailData['ERR_DATA_VAL']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Daily Interface Search Results</a> {'>'} <a href='#' onClick={() => setRenderChoice(1)}>Error List</a> {'>'} Provider Error Detail
            </Typography>
            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Source System</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract MPIN</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Comments</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow key={row.ERR_TYP_CD}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROV_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CONTR_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CONTR_LN_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.COMMT}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%', mt: '1rem' }}>
                    <LinearProgress />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: '1rem' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
