import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
import { formatDate } from "../utils/formatFunctions";
export const PcpHist = (props) => {
  const data = props.pcpHist;
  const columns = [
    {
      Header: "Begin",
      accessor: "begin",
      format: (cell) => formatDate(cell),
      minWidth: 125,
    },
    {
      Header: "End",
      accessor: "end",
      format: (cell) => formatDate(cell),
      minWidth: 125,
    },
    {
      Header: "MPIN",
      accessor: "mpin",
      minWidth: 125,
    },
    {
      Header: "Name",
      accessor: "name",
      minWidth: 125,
    },
    {
      Header: "Address",
      accessor: "address",
      minWidth: 125,
    },
    {
      Header: "Tax ID",
      accessor: "taxID",
      minWidth: 125,
    },
    {
      Header: "IPA",
      accessor: "ipa",
      minWidth: 125,
    },
    {
      Header: "Market",
      accessor: "market",
      minWidth: 75,
    },
    {
      Header: "Mkt Type",
      accessor: "mktType",
      minWidth: 100,
    },
    {
      Header: "PCP DIV",
      accessor: "pcpDiv",
      minWidth: 100,
    },
    {
      Header: "PCP Pannel",
      accessor: "pcpPannel",
      minWidth: 125,
    },
    {
      Header: "PCP Network",
      accessor: "pcpNetwork",
      minWidth: 125,
    },
    {
      Header: "SRC SYS PROV ID",
      accessor: "srcsycprovID",
      minWidth: 125,
    },
  ];
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "40vh" }}>
      <Table>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{ padding: "4px" }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
