import axios from 'axios';
const DATASEGEMT_ID_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;

export function storeDataSegments() {
    const response = axios.get(
        DATASEGEMT_ID_ENDPOINT,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        }
    ).then(response => {
        sessionStorage.setItem('dataSegments', JSON.stringify(response.data));
    }).catch(error => {
        console.log('Error fetching data segments');
    });
};

export function getDataSegments() {
    const dataSegments = JSON.parse(sessionStorage.getItem('dataSegments'));
    if (dataSegments == null || dataSegments == undefined) {
        return [];
    }
    return dataSegments;
}

export function getDataSegmentsDict() {
    return getDataSegments().reduce((acc, item) => {
        acc[item.DATA_SEG_ID] = item.NAME;
        return acc;
    }, {});
}

export function getDataSegmentStringFromInt(input) {
    return getDataSegmentsDict[input];
}

export function getDataSegmentIntFromString(input) {
    for (const [key, val] of Object.entries(getDataSegmentsDict())) {
        if (val === input) {  
            return key; // Return the key if value matches  
        }  
    }  
    return null; // Return null if value not found  
}
