
import React from "react";
import { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
    MenuItem,
    Select
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FactorsUpdateApi } from "./FactorsUpdateApi";


const MainTable = ({ rows , onCancel,dataSegment}) => {

    const factoryNameCodeMap = {
        "MSP": "MSP",
        "PLAN ADJ": "PLAN ADJ",
        "PREM ADJ": "PREM ADJ"
    };

    const adjIndicator = {
        "Y": "Y",
        "N": "N"
    };

    const [page, setPage] = useState(0);
    const [editFormData, setEditFormData] = useState([]); // Store form data for editing
    const [dialogOpen, setDialogOpen] = useState(false);
    const [factorname, setfactorname] = useState('');
    const [factorCategory, setfactorCategory] = useState('MSP'); // Set 'MSP' as default value  
    const [contractAdjIndicator, setcontractAdjIndicator] = useState('');
    const [calculationId, setCalculationId] = useState("0");
    const [calcOrder, setCalcOrder] = useState("0");
    const [dataSegments, setDataSegments] = useState([]);
    const [segmentsIDDict, setSegmentsIDDict] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');  
    const [snackbarOpen, setSnackbarOpen] = useState(false); 
    const [validationMessage, setValidationMessage] = useState(''); 
    const [previousData, setPreviousData] = useState(null); // State to hold previous data for comparison  
    const [updateMessage, setUpdateMessage] = useState('');
     // Add a state to control the visibility of the success message  
     const [showSuccessMessage, setShowSuccessMessage] = useState(false);  
     const [editableRows, setEditableRows] = useState(rows.map(row => ({ ...row })));  


    const FactoryNameoptions = ["", ...Object.keys(factoryNameCodeMap).filter(key => key !== "ALL")];

    const IndicatorOptions = ["", ...Object.keys(adjIndicator).filter(key => key !== "ALL")];



    const rowsRender = Array.isArray(rows) ? rows : [];

    const tableRows = Array.isArray(rowsRender) ? rowsRender : [];
    console.log('MainTable rows:', tableRows);

    console.log('FactorsUpdateTable rowData:', rows);
  
    const handlefactornameChange = (event) => {
        setfactorname(event.target.value);
    };

    const handlefactorCategoryChange = (event) => {
        setfactorCategory(event.target.value);
    };

    const handlecontractAdjChange = (event) => {
        setcontractAdjIndicator(event.target.value);
    };

    useEffect(() => {  
        // Set the initial previous data when the component is first rendered or when rows change  
        setPreviousData(rows);  
    }, [rows]); 

    // Handle Edit Click (opens dialog)
    const handleUpdateClick = () => {
        setSuccessMessage('');  
    setShowSuccessMessage(false);  
        if (tableRows.length === 0) {

            
            //    setSnackbarMessage('Please check at least one row.');
            //    setSnackbarOpen(true);
            return;
        }

        const selectedRow = tableRows[0];

         // Check if the selected row's active status is 'inactive'  
         if (selectedRow.ACTIVE.toLowerCase() === "inactive") {  
            // Set validation message and do not proceed with the update  
            setValidationMessage('Records cannot be updated when the status is inactive.');  
            setSuccessMessage('');  
            return; // Exit the function to prevent API call  
        }  

         // Validation for 'Contract Prem Adj Ind' when 'Factor Category' is 'MSP'  
    if (factorCategory === 'MSP' && contractAdjIndicator !== 'N') {  
        setValidationMessage('Contract Prem Adj Ind must be N for Factor Category other than PLAN ADJ and PREM ADJ.');  
        return; // Exit the function to prevent API call  
    }

    //  validation for 'calculationId' and 'calcOrder' when 'Factor Category' is 'MSP'  
    if (factorCategory === 'MSP' && (calculationId !== "0" || calcOrder !== "0")) {  
        setValidationMessage('Calculation ID and Calc Order must be 0 for Factor Category other than PLAN ADJ and PREM ADJ.');  
        return; // Exit the function to prevent API call  
    }  

    setValidationMessage('');  
        


        const requestBody = {  
            "DATA_SEG_ID": dataSegment, // Assuming this is the correct mapping  
            "PREM_FCT_TYP_CD": selectedRow.FACTOR_NAME, // Assuming this is the correct mapping  
            "BEGN_PAY_PROC_YR_M": parseInt(selectedRow.BEGIN, 10),  
            "END_PAY_PROC_YR_MO": parseInt(selectedRow.END, 10),  
            "PREM_FCT": parseFloat(selectedRow.FACTOR),  
            "PREM_FCT_CATGY_CD": factorCategory, // Assuming this is the correct mapping  
            "CALC_ID": calculationId, // You need to provide the correct value here  
            "CALC_ORDR": calcOrder, // You need to provide the correct value here  
            "CONTR_PREM_ADJ_IND": contractAdjIndicator,  
            "ACTV_IND": selectedRow.ACTIVE === "active" ? "Y" : "N",  
            "CREAT_DTTM": selectedRow.CREATE.split(' ')[0],  
            "USER": localStorage.getItem('sub') // You need to provide the correct value here or get it from user input/session  
        }; 

        console.log("request body is", requestBody);


        FactorsUpdateApi(requestBody).then(response => {
            if (response.status === 200) {  

                console.log("status was 200", response.status);
                // Display success message  
                setSuccessMessage(' Record updated successfully');  

                setShowSuccessMessage(true); 

                console.log ("message being displayed is ", successMessage);
             //   setSnackbarOpen(true);  

         
                
            } else {  
                setSuccessMessage('');  
            setShowSuccessMessage(false);  
            }  
        }).catch(error => {  
            // Handle the error case  

            setSuccessMessage('');  
        setShowSuccessMessage(false);  
        });  

       

      
    };

    const handleCancelClick = () => {  
        if (typeof onCancel === 'function') {  
            onCancel();  
        }  
    }; 
    
    const handleResetClick = () => {  
        if (typeof onCancel === 'function') {  
            onCancel();  
        }  
    }; 

    const handleCellChange = (event, rowId, fieldName) => {  
        const newValue = event.target.value;  
        setEditableRows(prevEditableRows => prevEditableRows.map(row => {  
            if (row.FRC_CAP_ID === rowId) {  
                return { ...row, [fieldName]: newValue };  
            }  
            return row;  
        }));  
    };  




   


    return (
        // it does not look right with zoom in/zoom out due to layout of elements. foundations need fixing.
        <div id='reportsMainTable' style={{ overflowY: 'auto' }}>

{validationMessage && (  
            <Box sx={{  
                backgroundColor: 'pink',  
                padding: 2,  
                display: 'flex',  
                justifyContent: 'center',  
                alignItems: 'center',  
                width: '100%',  
                color: 'red',  
                marginBottom: '10px'  
            }}>  
                <Typography variant="body2">{validationMessage}</Typography>  
            </Box>  
        )} 

    {showSuccessMessage && (  
    <Box sx={{  
        backgroundColor: 'lightgreen',  
        padding: 2,  
        position: 'absolute', 
        top: '5%',  
        left: '50%', 
        transform: 'translate(35%, 0%)', 
        display: 'flex',  
        justifyContent: 'center', 
        alignItems: 'center', 
        zIndex: 1000,  
        minWidth: '300px', 
        maxWidth: '80%', 
    }}>  
        <Typography variant="body2">{successMessage}</Typography>  
    </Box>  
)}  


            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440, width: 1380 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                    Data Segment</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Factor Name</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Begin Payment
                                    Process Period</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>End Payment
                                    Process Period</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Factor</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Factor Category</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Calculation Id</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Calc Order</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Contract Prem
                                    Adj Ind</TableCell>

                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {editableRows
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.FRC_CAP_ID}>
                                            <TableCell>{row.DATA_SEGMENT === "None" || row.DATA_SEGMENT === null ? " " : row.DATA_SEGMENT}</TableCell>

                                            <TableCell>{row.FACTOR_NAME === "None" || row.FACTOR_NAME === null ? " " : row.FACTOR_NAME}</TableCell>
                                            <TableCell>{row.BEGIN === "None" || row.BEGIN === null ? " " : row.BEGIN}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={row.END === "None" || row.END === null ? "" : row.END}
                                                    // If you want to handle changes to the TextField, you can add an onChange event handler  
                                                    onChange={(event) => handleCellChange(event, row.FRC_CAP_ID, 'END')} 
                                                // Add any other props you may need, such as fullWidth, disabled, etc.  
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={row.FACTOR === "None" || row.FACTOR === null ? "" : row.FACTOR}
                                                    // If you want to handle changes to the TextField, you can add an onChange event handler  
                                                    onChange={(event) => handleCellChange(event, row.FRC_CAP_ID, 'FACTOR')} 
                                                // Add any other props you may need, such as fullWidth, disabled, etc.  
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <Select
                                                    id='error-Category'
                                                    required
                                                    size="small"
                                                    fullWidth
                                                    value={factorCategory}
                                                    onChange={(event) => handlefactorCategoryChange(event)}


                                                >
                                                    {FactoryNameoptions.map((option) => (
                                                        <MenuItem name='error-option' value={option} key={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={calculationId} // Bind the TextField value to the state  
                                                    onChange={(event) => {  
                                                        const newValue = event.target.value;  
                                                        // Allow only numeric values and limit to 3 digits  
                                                        if (/^\d{0,3}$/.test(newValue)) {  
                                                            setCalculationId(newValue);  
                                                        }  
                                                    }}  
                                                    inputProps={{  
                                                        maxLength: 3 // Limit input length to 3 characters  
                                                    }}  
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={calcOrder} // Bind the TextField value to the state  
                                                    onChange={(event) => {  
                                                        const newValue = event.target.value;  
                                                        // Allow only numeric values and limit to 2 digits  
                                                        if (/^\d{0,2}$/.test(newValue)) {  
                                                            setCalcOrder(newValue);  
                                                        }  
                                                    }}  
                                                    inputProps={{  
                                                        maxLength: 2 // Limit input length to 2 characters  
                                                    }} 
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    id='error-Category'
                                                    required
                                                    size="small"
                                                    fullWidth
                                                    value={contractAdjIndicator}
                                                    onChange={(event) => handlecontractAdjChange(event)}


                                                >
                                                    {IndicatorOptions.map((option) => (
                                                        <MenuItem name='error-option' value={option} key={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        <TableBody>
                            <TableRow>

                                <TableCell colSpan={15}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                        <Button variant="contained" size='medium'   onClick={() => handleUpdateClick()}>Update</Button>
                                        <Button variant="contained" size='medium' color="success" onClick={() => handleResetClick()}>Reset</Button>
                                        <Button variant="contained" size='medium'  style={{ backgroundColor: 'red', color: 'white' }}  onClick={() => handleCancelClick()}>Cancel</Button>
                                        {/* <Button variant="contained" size='large' color="warning" onClick={handleClearTable} >Cancel</Button>
                                        <Button variant="contained" size='large' color="error" onClick={handleDeleteClick}>Download</Button> */}

                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
               
            </Paper>
        </div>
    );
}

export const FactorsUpdateTable = ({ rowData, onCancel,dataSegmentId }) => {

    console.log("selected row coming from factor search is", rowData);
    return (
        <div style={{ width: '100%' }}>

            <MainTable rows={rowData} onCancel={onCancel} dataSegment={dataSegmentId}/>
        </div>
    );
};
