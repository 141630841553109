// make sure we aren't returning unintended values
function verify(data) {
    if (data === undefined || data === null || data === 'null') {
        return undefined
    } else {
        return data
    }
}

/**
 * Store and get functions 
 */
// search filters
export function storeContractSearchFilters(data) {
    sessionStorage.setItem('providerContractSearchFilters', JSON.stringify(data));
}

export function getContractSearchFilters() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractSearchFilters')));
}

export function clearContractSearchFilters() {
    sessionStorage.removeItem('providerContractSearchFilters');
}

// search results
export function storeContractSearchResults(data) {
    sessionStorage.setItem('providerContractSearchResults', JSON.stringify(data));
}

export function getContractSearchResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractSearchResults')));
}

export function clearContractSearchResults() {
    sessionStorage.removeItem('providerContractSearchResults');
}

// contract normal
export function storeContractContractResults(data) {
    sessionStorage.setItem('providerContractContractNormal', JSON.stringify(data));
}

export function getContractContractResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractContractNormal')));
}

export function clearContractContractResults() {
    sessionStorage.removeItem('providerContractContractNormal');
}

// contract line
export function storeContractContractLineResults(data) {
    sessionStorage.setItem('providerContractContractLine', JSON.stringify(data));
}

export function getContractContractLineResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractContractLine')));
}

export function clearContractContractLineResults() {
    sessionStorage.removeItem('providerContractContractLine');
}

// contract subline
export function storeContractContractSubLineResults(data) {
    sessionStorage.setItem('providerContractContractSubLine', JSON.stringify(data));
}

export function getContractContractSubLineResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractContractSubLine')));
}

export function clearContractContractSubLineResults() {
    sessionStorage.removeItem('providerContractContractSubLine');
}

// contract clmpool
export function storeContractContractClmPoolResults(data) {
    sessionStorage.setItem('providerContractContractClmPool', JSON.stringify(data));
}

export function getContractContractClmPoolResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractContractClmPool')));
}

export function clearContractContractClmPoolResults() {
    sessionStorage.removeItem('providerContractContractClmPool');
}

// rate normal
export function storeContractRateNormalResults(data) {
    sessionStorage.setItem('providerContractRateNormal', JSON.stringify(data));
}

export function getContractRateNormalResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractRateNormal')));
}

export function clearContractRateNormalResults() {
    sessionStorage.removeItem('providerContractRateNormal');
}

// adjustments
export function storeContractAdjustmentResults(data) {
    sessionStorage.setItem('providerContractAdjustment', JSON.stringify(data));
}

export function getContractAdjustmentResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractAdjustment')));
}

export function clearContractAdjustmentResults() {
    sessionStorage.removeItem('providerContractAdjustment');
}

// member
export function storeContractMemberResults(data) {
    sessionStorage.setItem('providerContractMember', JSON.stringify(data));
}

export function getContractMemberResults() {
    return verify(JSON.parse(sessionStorage.getItem('providerContractMember')));
}

export function clearContractMemberResults() {
    sessionStorage.removeItem('providerContractMember');
}

/**
 * Clear functions for mutiple clears at once
 */
// clear all
export function providerContractClearAll() {
    clearContractSearchFilters();
    clearContractSearchResults();
    searchClear();
}

// go to search
export function searchClear() {
    clearContractContractResults();
    contractClear();
}

// go to contract normal
export function contractClear() {
    clearContractContractLineResults();
    clearContractContractSubLineResults();
    clearContractContractClmPoolResults();
    contractLineClear();
}

// go to contract line
export function contractLineClear() {
    clearContractRateNormalResults();
    clearContractAdjustmentResults();
    clearContractMemberResults();
}