import React, { Component, Fragment, useMemo, useState } from "react";

import { formatDate, formatDateTime } from "../../utils/formatFunctions";
import CustInfo from "../CustInfo";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Grid,
  Button,
} from "@mui/material";

function ErrorTable({ columns, data }) {
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "90vh" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{
                    minWidth: column.minWidth,
                    padding: "4px",
                  }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                  {column.comp ? column.comp({ cell: { row } }) : ""}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export const ErrorPopUp = (props) => {
  const compareData = props.data;
  const transData = props.transData;
  const custInfo = props.memberDetailResult.customerInformation;
  const errorHistory = props.memberDetailResult.errorHistory;
  const [recordKey, setrecordKey] = useState("");
  const [fieldName, setfieldName] = useState("");
  const [dataValue, setdataValue] = useState("");
  const [comment, setcomment] = useState("");
  const [data, setdata] = useState("");
  const [loaded, setloaded] = useState(true);
  const setValues = (e) => {
    console.log(e);
    setrecordKey(e.errorRecordKey);
    setfieldName(e.errorFieldName);
    setdataValue(e.errorDataValue);
    setcomment(e.errorComment);
    setdata(e.errorData);
  };

  console.log(errorHistory);
  const columns = [
    {
      Header: "Logged on",
      accessor: "created",
      format: (cell) => formatDateTime(cell),
      minWidth: 180,
    },
    {
      Header: "Error ID",
      accessor: "errorId",
      minWidth: 100,
    },
    {
      Header: "Category",
      accessor: "shortDescription",
      minWidth: 100,
    },
    {
      Header: "T / B",
      accessor: "techBusinessIndicator",
      minWidth: 50,
    },
    {
      Header: "Type",
      accessor: "errorType",
      minWidth: 50,
    },
    {
      Header: "Severity",
      accessor: "errorSeverity",
      minWidth: 75,
    },
    {
      Header: "Discription",
      accessor: "longDescription",
    },
    {
      Header: "Customer ID",
      accessor: "customerId",
    },
    {
      Header: "Policy",
      accessor: "policyId",
    },
    {
      Header: "Details",
      accessor: "name",
      comp: ({ cell }) => (
        <Button
          onClick={(e) => {
            console.log(cell);
            setValues(cell.row);
            setloaded(false);
          }}
          target='_blank'
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <h4>Current Information</h4>
      <CustInfo custInfo={custInfo} />
      <h4>Member Error Information</h4>

      <ErrorTable
        columns={columns}
        data={errorHistory}
      />
      <table>
        <tbody>
          <tr>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>Record Key :</b> {recordKey}
            </td>
          </tr>
          <tr>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>Field Name :</b> {fieldName}
            </td>
          </tr>
          <tr>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>Data Value:</b> {dataValue}
            </td>
          </tr>
          <tr>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>Comment :</b>
              {loaded ? (
                <></>
              ) : (
                <div
                  style={{
                    width: "30vw",
                    height: "30vh",
                    display: "inline-flex",
                  }}
                >
                  {comment}
                </div>
              )}
            </td>
            <td>
              <b>&emsp; &ensp;</b> {""}
              <b>&emsp; &ensp;</b> {""}
              <b>Data :</b>{" "}
              {loaded ? (
                <></>
              ) : (
                <div
                  style={{
                    width: "30vw",
                    height: "30vh",
                    display: "inline-flex",
                  }}
                >
                  {data}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan='2'>
              <b>
                <b>&emsp; &ensp;</b> {""}
                <b>&emsp; &ensp;</b> {""}
                {errorHistory.length} Errors found for this member, please
                select one
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
