import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

const CodesSearchTable = ({ showTable, tableData, loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableData1 = tableData || [];
  console.log(tableData1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!showTable) {
    return null;
  }

  return (
    <Paper
      sx={{
        paddingLeft: "0.1rem",
        boxShadow: "none",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <TableContainer
        component={Paper}
        style={{ width: "100%" }}
        sx={{ maxHeight: "75vh" }}
      >
        <Table
          aria-label='simple table'
          stickyHeader
          style={{ width: "100%" }}
        >
          <TableHead style={{ backgroundColor: "#b0c0da" }}>
            <TableRow style={{ backgroundColor: "#b0c0da" }}>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Code Type
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Code
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Short Description
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Long Description
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Active/Inactive
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Last Updated By
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
              >
                Last Updated Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                  />
                </TableCell>
              </TableRow>
            ) : (
              tableData1
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.ECAP_TYP_CD}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.ECAP_CD}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.SHRT_DESC}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.LNG_DESC}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.ACTV_IND}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.UPDT_USER_ID}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.UPDT_DTTM}
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={tableData1.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-selectLabel": {
            paddingTop: "12px",
          },
          ".MuiTablePagination-displayedRows": {
            paddingTop: "13px",
          },
        }}
      />
    </Paper>
  );
};

export default CodesSearchTable;
