import { Box, Button, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { getDataSegmentIntFromString } from "../../../Home/dataSegmentApi";
import { FactorsAddApi } from "./AddApi";
import { testPDSeg } from '../../../testPopupDataSegment';


export const FactorsAdd = ({
    inputDataSegments,
    switchTab
}) => {
    const [errorMessages, setErrorMessages] = useState([]);
    const factorNames = {
        'SYSTEM ADJ PREMIUM TAX': 'ADJPREMTAX',
        'ESRD RA FACTOR': 'ESRD RA FACTOR',
        'HOSPICE RA FACTOR': 'HOSPICE RA FACTOR',
        'PREMIUM TAX': 'PREM TAX',
        'SEQUESTRATION': 'SEQ FEE',
        'USER FEE': 'USER FEE',
        'ESRD MSP POST-GRAFT FCT': 'WAESRDPFCT',
        'ESRD MSP TRANSPLNT FCT': 'WAESRDTFCT',
        'AGED DISABLED MSP FCT': 'WAFCT'
    }
    const factorCategories = [
        'MSP',
        'PLAN ADJ',
        'PREM ADJ'
    ]
    const adjInd = [
        'Y',
        'N'
    ]
    const [selectedDataSegments, setSelectedDataSegments] = useState(Array(10).fill('select'));
    const [selectedFactorName, setSelectedFactorName] = useState(Array(10).fill('select'));
    const [selectedFactorCategory, setSelectedFactorCategory] = useState(Array(10).fill('select'));
    const [selectedAdjInd, setSelectedAdjInd] = useState(Array(10).fill('select'));

    function openPopupDataSegment(index) {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setSelectedDataSegments([
                    ...selectedDataSegments.slice(0, index),
                    response[1],
                    ...selectedDataSegments.slice(index + 1)
                ]);
            } catch {
                console.log('popup receive message error');
            }
        };
    };

    const handleClear = () => {
        setErrorMessages(['']);
        setSelectedDataSegments(Array(10).fill('select'));
        setSelectedFactorName(Array(10).fill('select'));
        setSelectedFactorCategory(Array(10).fill('select'));
        setSelectedAdjInd(Array(10).fill('select'));
        for (let i = 0; i < 10; i++) {
            var temp = document.getElementById(i + '_begin');
            temp.value = '';
            temp = document.getElementById(i + '_end');
            temp.value = '';
            temp = document.getElementById(i + '_factor');
            temp.value = '';
            temp = document.getElementById(i + '_calculation');
            temp.value = '';
            temp = document.getElementById(i + '_calc');
            temp.value = '';
        }
    }

    const handleSave = () => {
        var tempErrorMessages = [];
        setErrorMessages([]);
        var data = Array(10);
        var rows = [];
        for (let i = 0; i < 10; i++) {
            var temp = {
                "dataSegment": "",
                "factorName": "",
                "begin": "",
                "end": "",
                "factor": "",
                "factorCategory": "",
                "calculation": "",
                "calc": "",
                "adjInd": ""
            }
            data[i] = temp;
        }
        for (let i = 0; i < 10; i++) {
            data[i]["dataSegment"] = selectedDataSegments[i];
            data[i]["factorName"] = selectedFactorName[i];
            data[i]["begin"] = document.getElementById(i + "_begin").value;
            data[i]["end"] = document.getElementById(i + "_end").value;
            data[i]["factor"] = document.getElementById(i + "_factor").value;
            data[i]["factorCategory"] = selectedFactorCategory[i];
            data[i]["calculation"] = document.getElementById(i + "_calculation").value;
            data[i]["calc"] = document.getElementById(i + "_calc").value;
            data[i]["adjInd"] = selectedAdjInd[i];
        }

        // when no data is entered and clicked add button
        var hasNoData = true; // no data
        var blankDataSegment = false; // blank data segment
        var blankFactorName = false; // blank factor name
        var blankBeginPeriod = false; // blank begin period
        var invalidBeginPeriod = false; // begin period not a number
        var invalidBeginPeriod2 = false; // begin period not a date (month wrong)
        var blankEndPeriod = false; // blank end period
        var invalidEndPeriod = false; // end period not a number
        var invalidEndPeriod2 = false; // end period not a date (month wrong)
        var invalidEndPeriod3 = false; // end period < begin period
        var blankFactor = false; // factor is blank
        var invalidFactor = false; // factor is not numeric
        var invalidFactor2 = false; // factor is not in right format 99.999999
        var invalidFactor3 = false; // hospice factor between 0 and 1
        var blankCategory = false; // blank category
        var blankCalculation = false; // blank calculation
        var invalidCalculation = false; // calculation should be int
        var blankCalc = false; // blank calc
        var invalidCalc = false; // calc should be int
        var invalidCalculationCalc = false; // calculation and calc should be empty or 0 with PLAN/PREM
        var blankAdjInd = false; // blank adj ind
        var adjIndNError = false; // adj ind must be N for MSP category
        for (let i = 9; i >= 0; i--) {
            if (!(
                data[i]["dataSegment"] === 'select' &&
                data[i]["factorName"] === 'select' &&
                /^\s*$/.test(data[i]["begin"]) &&
                /^\s*$/.test(data[i]["end"]) &&
                /^\s*$/.test(data[i]["factor"]) &&
                data[i]["factorCategory"] === 'select' &&
                /^\s*$/.test(data[i]["calculation"]) &&
                /^\s*$/.test(data[i]["calc"]) &&
                data[i]["adjInd"] === 'select'
            )) {
                hasNoData = false;
                var hasIssues = false;
                if (data[i]["dataSegment"] === 'select') {
                    hasIssues = true;
                    blankDataSegment = true;
                }
                if (data[i]["factorName"] === 'select') {
                    hasIssues = true;
                    blankFactorName = true;
                }
                if (/^\s*$/.test(data[i]["begin"])) {
                    hasIssues = true;
                    blankBeginPeriod = true;
                } else if (
                    data[i]["begin"].length !== 6 ||
                    /^[1-9]\d*$/.test(data[i]["begin"]) == false
                ) {
                    hasIssues = true;
                    invalidBeginPeriod = true;
                } else if (!(
                    parseInt(data[i]["begin"].slice(4)) >= 1 &&
                    parseInt(data[i]["begin"].slice(4)) <= 12
                )) {
                    hasIssues = true;
                    invalidBeginPeriod2 = true;
                }
                if (/^\s*$/.test(data[i]["end"])) {
                    hasIssues = true;
                    blankEndPeriod = true;
                } else if (
                    data[i]["end"].length !== 6 ||
                    /^[1-9]\d*$/.test(data[i]["end"]) == false
                ) {
                    hasIssues = true;
                    invalidEndPeriod = true;
                } else if (!(
                    parseInt(data[i]["end"].slice(4)) >= 1 &&
                    parseInt(data[i]["end"].slice(4)) <= 12
                )) {
                    hasIssues = true;
                    invalidEndPeriod2 = true;
                }
                if (
                    !invalidBeginPeriod &&
                    !invalidBeginPeriod2 &&
                    ((!invalidEndPeriod &&
                        !invalidEndPeriod2 &&
                        parseInt(data[i]["end"]) < parseInt(data[i]["begin"])) ||
                        (invalidEndPeriod || invalidEndPeriod2))
                ) {
                    hasIssues = true;
                    invalidEndPeriod3 = true;
                }
                if (
                    /^[1-9]\d*$/.test(data[i]["factor"]) == false &&
                    /^-?\d*(\.\d+)?$/.test(data[i]["factor"]) == false
                ) {
                    hasIssues = true;
                    invalidFactor = true;
                } else if (
                    /^\d{1,2}(\.\d{1,6})?$/.test(data[i]["factor"]) == false
                ) {
                    hasIssues = true;
                    invalidFactor2 = true;
                } else if (
                    parseFloat(data[i]["factor"]) < 0 ||
                    parseFloat(data[i]["factor"]) > 1 &&
                    data[i]["factorName"] === "HOSPICE RA FACTOR"
                ) {
                    hasIssues = true;
                    invalidFactor3 = true;
                }
                if (data[i]["factorCategory"] === 'select') {
                    hasIssues = true;
                    blankCategory = true;
                } else if (data[i]["factorCategory"] === 'MSP') {
                    if (data[i]["adjInd"] !== "N") {
                        hasIssues = true;
                        adjIndNError = true;
                    }
                    if (
                        !(/^\d+$/.test(data[i]["calculation"])) &&
                        data[i]["calculation"].trim() !== ''
                    ) {
                        hasIssues = true;
                        invalidCalculation = true;
                    } else if (
                        data[i]["calculation"].trim() !== "0" &&
                        data[i]["calculation"].trim() !== ""
                    ) {
                        hasIssues = true;
                        invalidCalculationCalc = true;
                    }
                    if (
                        !(/^\d+$/.test(data[i]["calc"])) &&
                        data[i]["calc"].trim() !== ''
                    ) {
                        hasIssues = true;
                        invalidCalc = true;
                    } else if (
                        data[i]["calc"].trim() !== "0" &&
                        data[i]["calc"].trim() !== ""
                    ) {
                        hasIssues = true;
                        invalidCalculationCalc = true;
                    }
                } else {
                    if (/^\s*$/.test(data[i]["calculation"])) {
                        hasIssues = true;
                        blankCalculation = true;
                    }
                    if (/^\s*$/.test(data[i]["calc"])) {
                        hasIssues = true;
                        blankCalc = true;
                    }
                    if (data[i]["adjInd"] === 'select') {
                        hasIssues = true;
                        blankAdjInd = true;
                    }
                }
                if (/^\s*$/.test(data[i]["factor"])) {
                    hasIssues = true;
                    blankFactor = true;
                }
                if (hasIssues) {
                    data = data.slice(0, i).concat(data.slice(i + 1, data.length));
                } else {
                    rows.push(i + 1);
                }
            } else {
                data = data.slice(0, i).concat(data.slice(i + 1, data.length));
            }
        }
        if (blankDataSegment) {
            tempErrorMessages.push('* Please select a Data Segment.');
        }
        if (hasNoData) {
            tempErrorMessages.push('* Please enter at least one row of data.');
        }
        if (blankFactorName) {
            tempErrorMessages.push('* Factor Name should not be blank.');
        }
        if (blankBeginPeriod) {
            tempErrorMessages.push('* Begin Effective Period cannot be blank.');
        } else if (invalidBeginPeriod) {
            tempErrorMessages.push('* Begin Effective Period should be in the format YYYYMM');
        } else if (invalidBeginPeriod2) {
            tempErrorMessages.push('* Begin Effective Period is not a valid date');
        }
        if (blankEndPeriod) {
            tempErrorMessages.push('* End Effective Period cannot not be blank.');
        } else if (invalidEndPeriod) {
            tempErrorMessages.push('* End Effective Period should be in the format YYYYMM');
        } else if (invalidEndPeriod2) {
            tempErrorMessages.push('* End Effective Period is not a valid date');
        }
        if (invalidEndPeriod3) {
            tempErrorMessages.push('* Begin Effective Period should not be greater than End Effective Period.');
        }
        if (blankCategory) {
            tempErrorMessages.push('* Factor Category is mandatory.');
        } else {
            if (blankCalculation) {
                tempErrorMessages.push('* PLAN ADJ/PREM ADJ related fields are mandatory. Please enter the Calculation ID.')
            } else if (invalidCalculation) {
                tempErrorMessages.push('* Calculation ID should be numeric.');
            }
            if (blankCalc) {
                tempErrorMessages.push('* PLAN ADJ/PREM ADJ related fields are mandatory. Please enter the Calc Order.')
            } else if (invalidCalc) {
                tempErrorMessages.push('* Calc Order should be numeric.');
            }
            if (blankAdjInd) {
                tempErrorMessages.push('* PLAN ADJ/PREM ADJ related fields are mandatory. Please enter the Contract Prem Adj Ind.');
            }
            if (invalidCalculationCalc) {
                tempErrorMessages.push('* Calculation ID and Calc Order must be 0 for Factor Category other than PLAN ADJ and PREM ADJ.');
            }
        }
        if (adjIndNError) {
            tempErrorMessages.push('* Contract Prem Adj must be N for Factor Category other than PLAN ADJ and PREM ADJ');
        }
        if (blankFactor) {
            tempErrorMessages.push('* Factor is mandatory.');
        } else if (invalidFactor) {
            tempErrorMessages.push('* Factor Should be numeric.');
        } else if (invalidFactor2) {
            tempErrorMessages.push('* Enter valid Factor in the format 99.999999.');
        } else if (invalidFactor3) {
            tempErrorMessages.push('* Please enter a Hospice Rescale Factor > 0 and < 1.');
        }
        if (tempErrorMessages.length === 0) {
            var requestBody = []
            for (let i = 0; i < data.length; i++) {
                var temp = {
                    "DATA_SEG_ID": getDataSegmentIntFromString(data[i]["dataSegment"]) === null ? '' : getDataSegmentIntFromString(data[i]["dataSegment"]),
                    "PREM_FCT_TYP_CD": factorNames[data[i]["factorName"]] === undefined ? '' : factorNames[data[i]["factorName"]],
                    "BEGN_PAY_PROC_YR_M": data[i]["begin"] === undefined ? '' : data[i]["begin"],
                    "END_PAY_PROC_YR_MO": data[i]["end"] === undefined ? '' : data[i]["end"],
                    "PREM_FCT": data[i]["factor"] === undefined ? '' : data[i]["factor"],
                    "PREM_FCT_CATGY_CD": data[i]["factorCategory"] === undefined ? '' : data[i]["factorCategory"],
                    "CALC_ID": data[i]["calculation"] === undefined ? '' : data[i]["calculation"],
                    "CALC_ORDR": data[i]["calc"] === undefined ? '' : data[i]["calc"],
                    "CONTR_PREM_ADJ_IND": data[i]["adjInd"] === undefined ? '' : data[i]["adjInd"],
                    "USER": localStorage.getItem("sub") === null ? 'testtest' : localStorage.getItem("sub")
                }
                requestBody.push(temp);
            }
            FactorsAddApi(requestBody).then(response => {
                // response successful
                setErrorMessages(['* Saved Successfully']);
            }).catch(error => {
                // either failed or has duplicate (400 response from backend)
                if ("results" in error.response.data) {
                    rows.reverse();
                    for (let i = 0; i < error.response.data["results"].length; i++) {
                        var responseType = error.response.data["results"][i].split(' ').slice(-1);
                        if (responseType == 'record') {
                            tempErrorMessages.push('* Row ' + rows[i] + ' - Duplicate Record already exists');
                        } else if (responseType == 'overlap') {
                            tempErrorMessages.push('* Row ' + rows[i] + ' - Begin and End Payment Process Period overlap.');
                        }
                    }
                    setErrorMessages(tempErrorMessages);
                }
            })
        } else {
            setErrorMessages(tempErrorMessages);
        }
    }

    function handleNumberOnly(event, allowFloat) {
        const pattern = /^[0-9\b]+$/; // only allow digits and backspace  
        if (
            !pattern.test(event.key) &&
            event.key !== 'Backspace' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight'
        ) {
            if (allowFloat && event.key !== '.') {
                event.preventDefault();
            } else if (!allowFloat) {
                event.preventDefault();
            }
        }
    }

    return (
        <Box sx={{ width: '98vw', overflowX: "scroll", overflowY:'none' }}>
            {errorMessages.length > 0 && errorMessages.map((temp, index) => (
                <Box key={'errorMessage_' + index} sx={{ width: '100%', textAlign: 'center', color: 'red' }}>
                    {temp}
                </Box>
            ))}
            <Box sx={{ mt:'1rem', maxHeight: '60vh', overflowY:'scroll' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Data Segment</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Factor Name</TableCell>
                            <TableCell>
                                <Box sx={{ whiteSpace: 'nowrap' }}>Begin Payment</Box>
                                <Box sx={{ whiteSpace: 'nowrap' }}>Process Period</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ whiteSpace: 'nowrap' }}>End Payment</Box>
                                <Box sx={{ whiteSpace: 'nowrap' }}>Process Period</Box>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Factor</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Factor Category</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Calculation Id</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Calc Order</TableCell>
                            <TableCell>
                                <Box sx={{ whiteSpace: 'nowrap' }}>Contract Prem</Box>
                                <Box sx={{ whiteSpace: 'nowrap' }}>Adj Ind</Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array(10).fill().map((_, index) => (
                            <TableRow key={'row_' + index}>
                                <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Select
                                        value={selectedDataSegments[index]}
                                        sx={{ width: 150 }}
                                        size='small'
                                        onChange={(event) => setSelectedDataSegments([
                                            ...selectedDataSegments.slice(0, index),
                                            event.target.value,
                                            ...selectedDataSegments.slice(index + 1)
                                        ])}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                            ---Select---
                                        </MenuItem>
                                        {inputDataSegments.map((row) => (
                                            <MenuItem key={index + '_' + row.DATA_SEG_ID} value={row.NAME}>{row.NAME}</MenuItem>
                                        ))}
                                    </Select>
                                    <Box
                                        sx={{ ml: '0.5rem', padding: '5px', height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center' }}
                                        onClick={() => openPopupDataSegment(index)}
                                    >
                                        <img
                                            style={{ height: '30px', width: '30px' }}
                                            src="Binoculars-icon.png"
                                            title="Filtering Data segment"
                                        // onClick={() => openPopupDataSegment()}
                                        ></img>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={selectedFactorName[index]}
                                        sx={{ width: 260 }}
                                        size='small'
                                        onChange={(event) => setSelectedFactorName([
                                            ...selectedFactorName.slice(0, index),
                                            event.target.value,
                                            ...selectedFactorName.slice(index + 1)
                                        ])}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                        </MenuItem>
                                        {Object.keys(factorNames).map(name => (
                                            <MenuItem
                                                key={index + '_' + name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_begin'}
                                        key={index + '_begin'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_end'}
                                        key={index + '_end'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_factor'}
                                        key={index + '_factor'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={9}
                                        onKeyDown={(event) => handleNumberOnly(event, true)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={selectedFactorCategory[index]}
                                        sx={{ width: 130 }}
                                        size='small'
                                        onChange={(event) => setSelectedFactorCategory([
                                            ...selectedFactorCategory.slice(0, index),
                                            event.target.value,
                                            ...selectedFactorCategory.slice(index + 1)
                                        ])}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                        </MenuItem>
                                        {factorCategories.map(category => (
                                            <MenuItem
                                                key={index + '_' + category}
                                                value={category}
                                            >
                                                {category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_calculation'}
                                        key={index + '_calculation'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={3}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_calc'}
                                        key={index + '_calc'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={2}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={selectedAdjInd[index]}
                                        sx={{ width: 60 }}
                                        size='small'
                                        onChange={(event) => setSelectedAdjInd([
                                            ...selectedAdjInd.slice(0, index),
                                            event.target.value,
                                            ...selectedAdjInd.slice(index + 1)
                                        ])}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                        </MenuItem>
                                        {adjInd.map(ind => (
                                            <MenuItem
                                                key={index + '_' + ind}
                                                value={ind}
                                            >
                                                {ind}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ mt:'1rem', display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Button variant="contained" onClick={handleSave}>Save</Button>
                <Button variant="contained" onClick={handleClear}>Clear</Button>
                <Button variant="contained" onClick={() => switchTab(0)}>Cancel</Button>
            </Box>
        </Box>
    )
}
export default FactorsAdd;
