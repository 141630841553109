import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Box, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { providerContractRateScheduleApi } from './providerRateApi';
import { stringDecimals, formatDollars } from '../../../helperFunctions';

export const ProviderContractRateSchedule = ({
    searchLink,
    contractLink,
    lineLink,
    regularLink,
    inputContractName,
    inputContractNumber,
    inputContractTypeCd,
    inputCalcMethCd,
    inputContractLineId,
    inputContractSublineID,
    selectedDataSegmentString,
    inputScheduleId,
    inputScheduleName,
    inputScheduleEffDate,
    inputContrSrcSysCd,
    inputSchedulePri,
    inputMsbp
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);

    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [scheduleId, setScheduleId] = useState();
    const [scheduleName, setScheduleName] = useState();
    const [scheduleEffDate, setScheduleEffDate] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [schedulePri, setSchedulePri] = useState();
    const [msbp, setMsbp] = useState();

    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName])
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber])
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd])
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId])
    useEffect(() => {
        setContractSublineID(inputContractSublineID);
    }, [inputContractSublineID])
    useEffect(() => {
        setScheduleId(inputScheduleId);
    }, [inputScheduleId])
    useEffect(() => {
        setScheduleName(inputScheduleName);
    }, [inputScheduleName])
    useEffect(() => {
        setScheduleEffDate(inputScheduleEffDate);
    }, [inputScheduleEffDate])
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd])
    useEffect(() => {
        setSchedulePri(inputSchedulePri);
    }, [inputSchedulePri])
    useEffect(() => {
        setMsbp(inputMsbp);
    }, [inputMsbp])

    useEffect(() => {
        if (scheduleId) {
            var requestBody = {
                "SCHED_ID": scheduleId,
                "CONTR_SRC_SYS_CD": contrSrcSysCd,
                "SCHED_EFF_DT": scheduleEffDate,
                "PRDCT_RDY_CD": schedulePri
            }
            providerContractRateScheduleApi(requestBody).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    setTableData(response.data);
                } else {
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                console.log('Error with providerContractRateScheduleApi', error);
            });
        }
    }, [scheduleId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function getAge(str) {
        if (str) {
            var temp = Number(str);
            if (temp == 0) {
                str = "0";
            } else if(temp < 12) {
                str = parseInt(temp / 100 + temp % 100 / 100);
            } else {
                str = parseInt(temp / 100) + "";
            }
        }
        return str
    }

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {contractTypeCd} | Calc Method: {calcMethCd} | MSBP Ind: {msbp}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {contractNumber} | Contract Line#: {contractLineId} | Contract Subline ID: {contractSublineID} | Schedule ID: {scheduleId} | Schedule Name: {scheduleName}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => contractLink()}>Contract</a> {">"} <a href='#' onClick={() => lineLink()}>Contract Line</a> {">"} <a href='#' onClick={() => regularLink()}>Rate</a> {">"} Schedule Detail
            </Typography>
            {!loadingTable && !errorMessage &&
                <Paper sx={{ mt: 2 }}> {/* Added space on top of the table */}
                    <TableContainer sx={{ maxHeight: 800 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Gender</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Age</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Gender Factor Type</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Gender Factor</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Copay Amount</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Copay Factor Type</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Copay Factor</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Org Code</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Business Segment Code</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Prod Ready Ind</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow key={'rateSchedule_' + index}>
                                                <TableCell style={{ textAlign: 'left' }}>{row.GDR_CD}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{getAge(row.AGE_FROM_NBR)} - {getAge(row.AGE_TO_NBR)}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{row.GDR_FCT_TYP_CD}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{stringDecimals(row.GDR_FCT_AMT, 4)}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{formatDollars(row.COPAY_AMT)}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{row.COPAY_FCT_TYP_CD}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{stringDecimals(row.COPAY_FCT_AMT, 2)}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{row.CONTR_ORG_CD}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{row.BUS_SEG_CD}</TableCell>
                                                <TableCell style={{ textAlign: 'left' }}>{row.PRDCT_RDY_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length} // Replace with the actual count of rows  
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {loadingTable &&
                <LinearProgress sx={{ mt: 2 }} />
            }
            {errorMessage &&
                <Box sx={{ width: '100%', textAlign: 'center', color: 'red', mt: 2 }}>
                    {errorMessage}
                </Box>
            }
        </div>
    );
};  
