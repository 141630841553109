import { createSlice } from "@reduxjs/toolkit";

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    admin: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    admin_approval: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    admin_codes: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    admin_exclude: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    admin_group: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    admin_roles: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    errors: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    job_scheduling: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_benefit: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_cappayments: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_forcecap: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_premium: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_reportingoverrides: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    maintenance_retro: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    member: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    member_history: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    member_search: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider_association: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider_contract: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider_crt: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider_demographics: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    provider_history: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_benefit: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_ecapprocess: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_member: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_payment: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_premium: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
    reports_provider: {
      add: "N",
      delete: "N",
      update: "N",
      view: "N",
    },
  },
  reducers: {
    updatePermission: (state, action) => {
      const { permission, field, value } = action.payload;
      state[permission][field] = value;
    },
  },
});

export const { updatePermission } = permissionsSlice.actions;
export default permissionsSlice;
