import moment from "moment";

export const formatDate = (dateTime) => {
  // const format = "DD-MMM-YYYY";
  // const date = new Date(dateTime);
  // return moment(date).format(format);
  var temp = dateTime.split("-");
  var months = {};
  months["01"] = "Jan";
  months["02"] = "Feb";
  months["03"] = "Mar";
  months["04"] = "Apr";
  months["05"] = "May";
  months["06"] = "Jun";
  months["07"] = "Jul";
  months["08"] = "Aug";
  months["09"] = "Sep";
  months["10"] = "Oct";
  months["11"] = "Nov";
  months["12"] = "Dec";
  var year = temp[0];
  var month = months[temp[1]];
  var day = temp[2];
  var date = day + "-" + month + "-" + year;
  return date;
};
export const giveDate = (dateTime) => {
  var temp = dateTime.split("T")[0].split("-");
  var months = {};
  var temp = dateTime.split("-");
  var months = {};
  months["01"] = "Jan";
  months["02"] = "Feb";
  months["03"] = "Mar";
  months["04"] = "Apr";
  months["05"] = "May";
  months["06"] = "Jun";
  months["07"] = "Jul";
  months["08"] = "Aug";
  months["09"] = "Sep";
  months["10"] = "Oct";
  months["11"] = "Nov";
  months["12"] = "Dec";
  var year = temp[0];
  var month = months[temp[1]];
  var day = temp[2].split("T")[0];
  var date = day + "-" + month + "-" + year;
  return date;
};
export const formatDateTime = (dateTime) => {
  const format = "DD-MMM-YYYY HH:mm:ss.SSS";
  const date = new Date(dateTime);
  return moment(date).format(format);
};
export const formatDateTimeDate = (dateTime) => {
  const format = "DD-MMM-YYYY HH:mm:ss.SSS";
  const date = new Date(dateTime);

  return moment(date).format(format);
};
export const formatCase = (name, value) => {
  if (
    name === "sourceSystemMemberId" ||
    name === "lastName" ||
    name === "firstName"
  ) {
    return value.toUpperCase().trim();
  } else {
    return value.trim();
  }
};

export const formatDates = (dateTime) => {
  const format = "YYYY-MM-DD";
  const date = new Date(dateTime);
  return moment(date).format(format);
  // return date
};

export const formatEncrytpedDates = (dateTime) => {
  const format = "YYYY-DD-MM";
  const date = new Date(dateTime);
  return moment(date).format(format);
  // return date
};

export const formatProcessed = (dateTime) => {
  const format = "DD-MMM-YYYY";
  const date = new Date(dateTime);
  console.log(dateTime);
  return moment(date).format(format);
};
