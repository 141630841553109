import axios from "axios";
const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/approval/search`;
const MEMBER_ROLES_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/approval/update`;
export const adminApprovalAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_URL,
});

export const adminApprovalUpdateAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_UPDATE_URL,
});
