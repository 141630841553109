
import React from "react";
import { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
    MenuItem,
    Select
} from '@mui/material';

import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material'; 

import { ReportOverrideUpdateApi } from "./ReportOverrideUpdateApi";
import { useSelector } from 'react-redux';  




const MainTable = ({ rows, onCancel ,dataSegment}) => {

    const factoryNameCodeMap = {
        "B-BOTH": "B-BOTH",
        "I-INTERNAL": "I-INTERNAL",
        "P-PRINT": "P-PRINT",
        "S-SUPPRESS": "S-SUPPRESS",
        "V-PORTAL": "V-PORTAL"
    };

    const adjIndicator = {
        "PRIMARY CARE": "PC",
        "THIRD PARTY": "TP"
    };

    const provDirectDelivery = {
        "RPT TO": "RPT TO",
        "CONTR OWNR": "CONTR OWNR",
        "NO PROVDIR": "NO PROVDIR"
    };


    const [factorname, setfactorname] = useState('');
    const [factorCategory, setfactorCategory] = useState('MSP'); // Set 'MSP' as default value  
    const [contractAdjIndicator, setcontractAdjIndicator] = useState('');
    let [endProcess, setEndProcess] = useState("0");
    const [successMessage, setSuccessMessage] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [previousData, setPreviousData] = useState(null); // State to hold previous data for comparison  
    // Add a state to control the visibility of the success message  
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [checkedStates, setCheckedStates] = useState({});
    const [dropdownSelections, setDropdownSelections] = useState({});
    const [editableRows, setEditableRows] = useState(rows.map(row => ({ ...row })));
    const [isLoading, setIsLoading] = useState(false);  
    







    const FactoryNameoptions = ["", ...Object.keys(factoryNameCodeMap).filter(key => key !== "ALL")];

    const IndicatorOptions = ["", ...Object.keys(adjIndicator).filter(key => key !== "ALL")];

    const providerDirect = ["", ...Object.keys(provDirectDelivery).filter(key => key !== "ALL")];




    const rowsRender = Array.isArray(rows) ? rows : [];

    const tableRows = Array.isArray(rowsRender) ? rowsRender : [];

    const handlefactornameChange = (event) => {
        setfactorname(event.target.value);
    };

    const handlefactorCategoryChange = (event) => {
        setfactorCategory(event.target.value);
    };

    const handlecontractAdjChange = (event) => {
        setcontractAdjIndicator(event.target.value);
    };



    useEffect(() => {
        // Set the initial previous data when the component is first rendered or when rows change  
        setPreviousData(rows);
    }, [rows]);

    useEffect(() => {
        const initialCheckedStates = {};
        rows.forEach((row) => {
            initialCheckedStates[row.CONTRACT_OWNER] = {
                activeInd: false,
                defaultDataSegmentAddressInd: false,
            };
        });
        setCheckedStates(initialCheckedStates);
    }, [rows]);

    useEffect(() => {  
        const initialDropdownSelections = {}; 
        const initialCheckedStates = {};  
        console.log("ALL ROWS ARE ", rows);
        rows.forEach(row => { 
            let initialDistributionMode = '';  
            switch (row.DISTRIBUTION_MODE) {  
                case 'V':  
                    initialDistributionMode = 'V-PORTAL';  
                    break;  
                case 'S':  
                    initialDistributionMode = 'S-SUPPRESS';  
                    break;  
                case 'P':  
                    initialDistributionMode = 'P-PRINT';  
                    break;  
                case 'I':  
                    initialDistributionMode = 'I-INTERNAL';  
                    break;  
                case 'B':  
                    initialDistributionMode = 'B-BOTH';  
                    break;  
                default:  
                    initialDistributionMode = ''; // Default or no matching code  
                    break;  
            }  
      
            let initialContractPrem = '';  
            switch (row.PROVIDER_DIRECT_DELIVERY) {  
                case 'RPT TO':  
                    initialContractPrem = 'RPT TO';  
                    break;  
                case 'CONTR OWNR':  
                    initialContractPrem = 'CONTR OWNR';  
                    break;  
                case 'NO PROVDIR':  
                    initialContractPrem = 'NO PROVDIR';  
                    break;  
                default:  
                    initialContractPrem = ''; // Default or no matching code  
                    break;  
            } 
            

            let initialProviderArrangement = '';    
            switch (row.PROVIDER_ARG) {    
                case 'PRIMARY CARE':    
                    initialProviderArrangement = 'PRIMARY CARE';    
                    break;    
                case 'THIRD PARTY':    
                    initialProviderArrangement = 'THIRD PARTY';    
                    break;  
                case 'PC':    
                    initialProviderArrangement = 'PRIMARY CARE';    
                    break; 
                case 'TP':    
                    initialProviderArrangement = 'THIRD PARTY';    
                    break;   
                default:    
                    initialProviderArrangement = ''; // Default or no matching code    
                    break;    
            } 
          
      
            initialDropdownSelections[row.CONTRACT_OWNER ] = {  
                distributionMode: initialDistributionMode,  
                contractPrem: initialContractPrem,  
                providerArrangement: initialProviderArrangement,
            }; 
        });  
        setDropdownSelections(initialDropdownSelections);  
    }, [rows]);  

    useEffect(() => {  
        const initialCheckedStates = {};  
        
        rows.forEach((row) => {  
            initialCheckedStates[row.CONTRACT_OWNER] = {  
                activeInd: row.ACTIVE_IND === 'Y', // Make sure the value is exactly 'Y'  
                defaultDataSegmentAddressInd: row.DEFAULT_DATA_SEG_ADDRESS === 'Y',
            };  
        });  
        setCheckedStates(initialCheckedStates); 
    }, [rows]);  





    const handleCheckboxChange = (modelId, checkboxName) => {
        setCheckedStates((prevCheckedStates) => {
            const updatedCheckedStates = {
                ...prevCheckedStates,
                [modelId]: {
                    ...prevCheckedStates[modelId],
                    [checkboxName]: !prevCheckedStates[modelId][checkboxName],
                },
            };
            console.log(updatedCheckedStates); // Check the updated state  
            return updatedCheckedStates;
        });
    };

    const handleDropdownChange = (contractOwner, dropdownType, selectedValue) => {  
        setDropdownSelections(prevDropdownSelections => ({  
            ...prevDropdownSelections,  
            [contractOwner]: {  
                ...prevDropdownSelections[contractOwner],  
                [dropdownType]: selectedValue  
            }  
        }));  
    };   

    useEffect(() => {  
        console.log('dropdownSelections state:', dropdownSelections);  
    }, [dropdownSelections]);  



    // Handle Edit Click (opens dialog)
    const handleUpdateClick = () => {
        setIsLoading(true);
        setSuccessMessage('');
        setShowSuccessMessage(false);
        if (tableRows.length === 0) {


            //    setSnackbarMessage('Please check at least one row.');
            //    setSnackbarOpen(true);
            return;
        }



        // Assuming you have multiple selectedRows and you want to create an array of request bodies  
        const requestBody = editableRows.map((selectedRow) => {

            console.log("selected row is", selectedRow);
            const distributionMode = (dropdownSelections[selectedRow.CONTRACT_OWNER]?.distributionMode || '').split('-')[0];
            const dataSegmentIndicator = checkedStates[selectedRow.CONTRACT_OWNER]?.activeInd ? 'Y' : 'N';
            const providerArrangment = adjIndicator[dropdownSelections[selectedRow.CONTRACT_OWNER]?.providerArrangement] || dropdownSelections[selectedRow.CONTRACT_OWNER]?.providerArrangement || '';  
            const contractPrem = dropdownSelections[selectedRow.CONTRACT_OWNER]?.contractPrem || '';
            const activeIndicator = checkedStates[selectedRow.CONTRACT_OWNER]?.defaultDataSegmentAddressInd ? 'Y' : 'N';

            return {
                "DATA_SEG_ID": dataSegment,
                "MDL_ID": selectedRow.MODEL_ID,
                "DOCUMENT_ID": selectedRow.DOC_ID,
                "CYC_NBR": selectedRow.CYC_NBR,
                "CONTRACT_OWNER": selectedRow.CONTRACT_OWNER,
                "BEGN_PROC_PRD": selectedRow.BEGIN,
                "END_PROC_PRD": selectedRow.END,
                "DISTRIBUTION_MODE": distributionMode,
                "DEFAULT_DATA_SEGMENT_ADDR_INDC": dataSegmentIndicator,
                "PROVIDER_ARRANGEMENT": providerArrangment,
                "PROVIDER_DIRECT_DELIVERY": contractPrem,
                "UPDT_USER_ID":localStorage.getItem('sub'),
                "ACTV_INDC": activeIndicator
            };
        });

        console.log(requestBody); // This will log an array of objects  

        // Now you can send this array of request bodies to your API  


        console.log("request body is", requestBody);


        ReportOverrideUpdateApi(requestBody).then(response => {
            if (response.status === 200 && Array.isArray(response.data) && response.data.length === 0) {
                console.log("response message is", response.data);
                console.log("status was 200", response.status);
                // Display success message    
                setSuccessMessage('Saved Successfully');
                setShowSuccessMessage(true);
                console.log("message being displayed is ", successMessage);
                // setSnackbarOpen(true); // Uncomment this if you want to show a snackbar/notification  
            } else {
                // Handle the case where response.data contains error messages  
                console.log("response message is", response.data);

                // Check if response.data is an array and has at least one sub-array with error messages  
                if (Array.isArray(response.data) && response.data.length > 0 && Array.isArray(response.data[0])) {
                    // Join all the error messages into one string  
                    const errorMessages = response.data.map(errorGroup => errorGroup.join(' ')).join(' ');
                    setSuccessMessage(errorMessages);
                } else {
                    // Fallback error message if response.data is not in the expected format  
                    setSuccessMessage("An error occurred");
                }

                setShowSuccessMessage(true);
            }
        }).catch(error => {
            // Handle the error case  
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "An error occurred while processing the request.";
            setSuccessMessage(errorMessage);
            setShowSuccessMessage(true);
        }).finally(() => {  
            setIsLoading(false); // Stop loading regardless of outcome  
        });  



    };


    const handleCancelClick = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleResetClick = () => {
        resetStates();

    };

    const handleCellChange = (event, contractOwner, fieldName) => {
        const newValue = event.target.value;
        setEditableRows(prevEditableRows => prevEditableRows.map(row => {
            if (row.CONTRACT_OWNER === contractOwner) {
                return { ...row, [fieldName]: newValue };
            }
            return row;
        }));
    };




    const resetStates = () => {
        // Reset checked states    
        const resetCheckedStates = { ...checkedStates };
        Object.keys(resetCheckedStates).forEach((modelId) => {
            resetCheckedStates[modelId] = {
                activeInd: false,
                defaultDataSegmentAddressInd: false,
            };
        });
        setCheckedStates(resetCheckedStates);

        // Reset dropdown selections    
        const resetDropdownSelections = { ...dropdownSelections };
        Object.keys(resetDropdownSelections).forEach((modelId) => {
            resetDropdownSelections[modelId] = {
                distributionMode: '', // Reset to default value for Distribution Mode    
                contractPrem: '', // Reset to default value for Contract Prem Provider Direct Delivery    
                providerArrangement: '', // Reset to default value for Provider Arrangement Direct Delivery    
            };
        });
        setDropdownSelections(resetDropdownSelections);
    };



    return (
        // it does not look right with zoom in/zoom out due to layout of elements. foundations need fixing.
        <div id='reportsMainTable'>

            {validationMessage && (
                <Box sx={{
                    backgroundColor: 'pink',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    color: 'red',
                    marginBottom: '10px'
                }}>
                    <Typography variant="body2">{validationMessage}</Typography>
                </Box>
            )}

            {showSuccessMessage && (
                <Box sx={{
                    backgroundColor: 'lightgreen',
                    padding: 2,
                    position: 'absolute',
                    top: '5%',
                    left: '50%', // Position the left edge of the box in the center of the screen  
                    transform: 'translate(-50%, 0%)', // Shift the box to the left by half of its width  
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 500,
                    minWidth: '300px',
                    maxWidth: '45%',
                }}>
                    <Typography variant="body2">{successMessage}</Typography>
                </Box>
            )}

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading && <LinearProgress />}
                <TableContainer sx={{ maxHeight: '70vh', maxWidth: '100%', overflow: 'auto' }}>  
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>

                                    Model Id</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Cycle Number</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Contract
                                    Owner MPIN</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Document Id</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Begin
                                    Process Period</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>End
                                    Process Period</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Distribution
                                    Mode</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Default Data
                                    Segment
                                    Address Ind</TableCell>
                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Contract Prem
                                    Provider
                                    Direct Delivery</TableCell>

                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Provider Arrangement
                                    Direct Delivery</TableCell>

                                <TableCell style={{ backgroundColor: "#b0c0da" }}>Active Ind</TableCell>

                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {tableRows
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.CONTRACT_OWNER}>
                                            <TableCell>{row.MODEL_ID === "None" || row.MODEL_ID === null ? " " : row.MODEL_ID}</TableCell>

                                            <TableCell>{row.CYC_NBR === "None" || row.CYC_NBR === null ? " " : row.CYC_NBR}</TableCell>
                                            <TableCell>{row.CONTRACT_OWNER === "None" || row.CONTRACT_OWNER === null ? " " : row.CONTRACT_OWNER}</TableCell>
                                            <TableCell>
                                                {row.DOC_ID === "None" || row.DOC_ID === null ? "" : row.DOC_ID}

                                            </TableCell>

                                            <TableCell>
                                                {row.BEGIN === "None" || row.BEGIN === null ? "" : row.BEGIN}

                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={
                                                        (editableRows.find(editableRow => editableRow.CONTRACT_OWNER === row.CONTRACT_OWNER) || {}).END || ''
                                                    }
                                                    onChange={(event) => handleCellChange(event, row.CONTRACT_OWNER, 'END')}
                                                // Add any other props you may need, such as fullWidth, disabled, etc.  
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <Select
                                                    id="distributionMode-select"
                                                    required
                                                    size="small"
                                                    fullWidth
                                                    value={dropdownSelections[row.CONTRACT_OWNER]?.distributionMode || ''}
                                                    onChange={(event) => handleDropdownChange(row.CONTRACT_OWNER, 'distributionMode', event.target.value)}
                                                >
                                                    {FactoryNameoptions.map((option) => (
                                                        <MenuItem value={option} key={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={checkedStates[row.CONTRACT_OWNER]?.activeInd || false}
                                                    onChange={() => handleCheckboxChange(row.CONTRACT_OWNER, 'activeInd')}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    id="providerDirectDelivery-select"
                                                    required
                                                    size="small"
                                                    fullWidth
                                                    value={dropdownSelections[row.CONTRACT_OWNER]?.contractPrem || ''}  
                                                    onChange={(event) => handleDropdownChange(row.CONTRACT_OWNER, 'contractPrem', event.target.value)}  
                                                >
                                                    {providerDirect.map((option) => (
                                                        <MenuItem value={option} key={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>

                                            <TableCell>
                                                <Select
                                                    id='error-Category'
                                                    required
                                                    size="small"
                                                    fullWidth
                                                    value={dropdownSelections[row.CONTRACT_OWNER]?.providerArrangement || ''}  
                                                    onChange={(event) => handleDropdownChange(row.CONTRACT_OWNER, 'providerArrangement', event.target.value)}  
                                                >  
                                                   {IndicatorOptions.map((option) => (  
            <MenuItem name='providerArrangement-option' value={option} key={option}>  
                {option}  
            </MenuItem>  
        ))} 
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={checkedStates[row.CONTRACT_OWNER]?.defaultDataSegmentAddressInd || false}
                                                    onChange={() => handleCheckboxChange(row.CONTRACT_OWNER, 'defaultDataSegmentAddressInd')}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                            <TableRow>

                                <TableCell colSpan={15}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                        <Button variant="contained" size='medium'  onClick={() => handleUpdateClick()}>Save</Button>
                                        <Button variant="contained" size='medium' color="success" onClick={() => handleResetClick()}>Reset</Button>
                                        <Button variant="contained" size='medium' style={{ backgroundColor: 'red', color: 'white' }}  onClick={() => handleCancelClick()}>Cancel</Button>


                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    );
}

export const ReportingOverridesUpdateTable = ({ rowData, onCancel ,dataSegmentId}) => {

    console.log("selected row coming from report override search is", rowData);
    return (
        <div style={{ width: '100%' }}>

            <MainTable rows={rowData} onCancel={onCancel} dataSegment={dataSegmentId}/>
        </div>
    );
};