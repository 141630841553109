export const EligDownloadHeaders = [
  {
    label: "Source System Member Key",
    key: "sourceSystemMemberId",
  },
  {
    label: "Alt ID",
    key: "altId",
  },
  {
    label: "Member Last Name",
    key: "last",
  },
  {
    label: "Member First Name",
    key: "first",
  },
  {
    label: "Member Middle Initial",
    key: "middle",
  },
  {
    label: "Processed Date",
    key: "prdate",
  },
  {
    label: "MTCN",
    key: "mtcn",
  },
  {
    label: "Coverage Indicator",
    key: "covInd",
  },
  {
    label: "Elig Eff Dt",
    key: "begin",
  },
  {
    label: "Elig End Dt",
    key: "end",
  },
  {
    label: "Coverage Source System",
    key: "covSrc",
  },
  {
    label: "Policy",
    key: "policy",
  },
  {
    label: " Policy Name",
    key: "policyName",
  },
  {
    label: "Plan Variation",
    key: "planVar",
  },
  {
    label: "Report Code",
    key: "rptCd",
  },
  {
    label: "Office Visit Copay",
    key: "ovc",
  },
  {
    label: "ER Copay",
    key: "erc",
  },
  {
    label: "State of Issue",
    key: "stateOfIssue",
  },
  {
    label: "CMS Contract ID",
    key: "cmsContractID",
  },
  {
    label: "CMS PBP",
    key: "cmsPbp",
  },
  {
    label: "CMS Segment",
    key: "cmsSegment",
  },
  {
    label: "Member/Employer Premium Amount",
    key: "premAmnt",
  },
];
export const FactDownloadHeaders = [
  {
    label: "Source System Member Key",
    key: "sourceSystemMemberId",
  },
  {
    label: "Alt ID",
    key: "altId",
  },
  {
    label: "Member Last Name",
    key: "last",
  },
  {
    label: "Member First Name",
    key: "first",
  },
  {
    label: "Member Middle Initial",
    key: "middle",
  },
  {
    label: "Processed Date",
    key: "prdate",
  },
  {
    label: "MTCN",
    key: "mtcn",
  },
  {
    label: "Factor Eff Dt",
    key: "begin",
  },
  {
    label: "Factor End Dt",
    key: "end",
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Relationship Code",
    key: "rel",
  },
  {
    label: "Birthdate",
    key: "birthdate",
  },
  {
    label: "County Code",
    key: "county",
  },
  {
    label: "County Name",
    key: "countyName",
  },
  {
    label: "State",
    key: "state",
  },
  {
    label: "Zip",
    key: "zip",
  },
  {
    label: "Medicare ID",
    key: "medicareId",
  },
  {
    label: "Hospice Indicator",
    key: "hospiceIndic",
  },
];
export const PCPDownloadHeaders = [
  {
    label: "Source System Member Key",
    key: "sourceSystemMemberId",
  },
  {
    label: "Alt ID",
    key: "altId",
  },
  {
    label: "Member Last Name",
    key: "last",
  },
  {
    label: "Member First Name",
    key: "first",
  },
  {
    label: "Member Middle Initial",
    key: "middle",
  },
  {
    label: "Processed Date",
    key: "prdate",
  },
  {
    label: "MTCN",
    key: "mtcn",
  },
  {
    label: "PCP Eff Dt",
    key: "begin",
  },
  {
    label: "PCP End Dt",
    key: "end",
  },
  {
    label: "PCP TIN",
    key: "pcpTin",
  },
  {
    label: "PCP MPIN",
    key: "pcpMpin",
  },
  {
    label: "PCP Address Sequence/ID",
    key: "address",
  },
  {
    label: "PCP Name",
    key: "name",
  },
  {
    label: "PCP State",
    key: "pcpState",
  },
  {
    label: "PCP County",
    key: "pcpCountry",
  },
  {
    label: "PCP Zip Code",
    key: "pcpZip",
  },
  {
    label: "Market Type",
    key: "mktType",
  },
  {
    label: "Market Number",
    key: "market",
  },
  {
    label: "IPA Number ",
    key: "ipa",
  },
  {
    label: "PCP Div ",
    key: "pcpDiv",
  },
  {
    label: "PCP Panel",
    key: "pcpPanel",
  },
  {
    label: "PCP Network",
    key: "pcpNetwork",
  },
  {
    label: "Source System Provider ID",
    key: "srcsycprovId",
  },
];
export const compareDownloadHeader = [
  {
    label: "MTCN",
    key: "mtcn",
  },
  {
    label: "Data Segment",
    key: "dataSeg",
  },
  {
    label: "Source System Member Key",
    key: "sourceSystemMemberId",
  },
  {
    label: "Alt ID",
    key: "altId",
  },
  {
    label: "Medicaid ID",
    key: "medicaidId",
  },

  {
    label: "Member Last Name",
    key: "last",
  },
  {
    label: "Member First Name",
    key: "first",
  },
  {
    label: "Member Middle Initial",
    key: "middle",
  },
  {
    label: "Begin",
    key: "begin",
  },
  {
    label: "End",
    key: "end",
  },
  {
    label: "Cov",
    key: "cov",
  },
  {
    label: "PCP MPIN",
    key: "mpin",
  },
  {
    label: "Address",
    key: "address",
  },
  {
    label: "Tax Id",
    key: "taxId",
  },
  {
    label: "IPA",
    key: "ipa",
  },
  {
    label: "Market",
    key: "market",
  },
  {
    label: "Market Type",
    key: "marketType",
  },
  {
    label: "PCP State/ Zip",
    key: "pcpStateZip",
  },
  {
    label: "PCP County",
    key: "pcpCounty",
  },
  {
    label: "Member State/ Zip",
    key: "memberStateZip",
  },
  {
    label: "Cov System",
    key: "covSystem",
  },
  {
    label: "Policy",
    key: "policy",
  },
  {
    label: "PV / RC",
    key: "pvrc",
  },
  {
    label: "Copays (Off/Er)",
    key: "copays",
  },
  {
    label: "State of Issue",
    key: "stateOfIssue",
  },
  {
    label: "PCP DIV",
    key: "pcpDiv",
  },
  {
    label: "PCP PANEL",
    key: "pcpPanel",
  },
  {
    label: "PCP Network",
    key: "pcpNetwork",
  },
  {
    label: "SRC SYS PROV ID",
    key: "srcsycprovId",
  },
  {
    label: "Medicare ID",
    key: "medicareId",
  },

  {
    label: "CMS Contract ID",
    key: "cmsContractId",
  },
  {
    label: "CMS PBP",
    key: "pbp",
  },
  {
    label: "Cms Segment ID ",
    key: "cmsSegment",
  },
  {
    label: "Mbr/Emp Prem Amnt",
    key: "mbrEmpPremAmt",
  },
];
