  import axios from 'axios';  
  const MEMBER_HISTORY_MEMBERINFO_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/member-info`;
// SearchHandler.js  
export const MemberInfoApi = (  
  member_partition_id,
  data_segment,  
  ecap_mbr_key, 
  
) => {  
  // Prepare request body  
  const requestBody = {  
    member_partition_id: "17",
    data_segment: data_segment,   
    ecap_mbr_key: ecap_mbr_key,      
  };  
  

  return axios.post(  
    MEMBER_HISTORY_MEMBERINFO_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
      },  
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
}; 