import React from 'react';
import { ProviderContractRegular } from "./ProviderContractRegular"
import { ProviderContractLine } from "./ProviderContractLine"
import { useEffect, useState } from "react";

export const ProviderContractContract = ({
    // links
    renderRegular,
    searchLink,
    contractLink,
    lineLink,
    rateLink,
    // input variables
    inputContractName,
    inputContractNumber,
    inputContractLineId,
    inputContractEffDate,
    inputContractTypeCd,
    inputCalcMethCd,
    inputPublicPri,
    inputPublicContractLineEffDate,
    inputPublicContractLineEndDate,
    selectedDataSegmentString,
    inputContrSrcSysCd,
    // input functions
    setPublicSelectedDataSegment,
    setContractSublineID,
    setPublicStateCd
}) => {
    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractEffDate, setContractEffDate] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [pri, setPri] = useState();
    const [contractLineEffDate, setContractLineEffDate] = useState();
    const [contractLineEndDate, setContractLineEndDate] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName]);
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber]);
    useEffect(() => {
        setContractEffDate(inputContractEffDate);
    }, [inputContractEffDate])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId]);
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd]);
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd]);
    useEffect(() => {
        setPri(inputPublicPri);
    }, [inputPublicPri]);
    useEffect(() => {
        setContractLineEffDate(inputPublicContractLineEffDate);
    }, [inputPublicContractLineEffDate]);
    useEffect(() => {
        setContractLineEndDate(inputPublicContractLineEndDate);
    }, [inputPublicContractLineEndDate]);
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd]);

    return (
        <div>
            {/* {renderRegular ? <div>regular</div> : <div>line</div> } */}
            {renderRegular ?
                <ProviderContractRegular
                    searchLink={searchLink}
                    lineLink={lineLink}
                    inputContractName={contractName}
                    inputContractNumber={contractNumber}
                    inputContractEffDate={contractEffDate}
                    inputPublicPri={pri}
                    selectedDataSegmentString={selectedDataSegmentString}
                    setPublicSelectedDataSegment={setPublicSelectedDataSegment}
                />
                :
                <ProviderContractLine
                    searchLink={searchLink}
                    contractLink={contractLink}
                    rateLink={rateLink}
                    inputContractName={contractName}
                    inputContractNumber={contractNumber}
                    inputContractLineId={contractLineId}
                    inputContractTypeCd={contractTypeCd}
                    inputCalcMethCd={calcMethCd}
                    inputContractLineEffDate={contractLineEffDate}
                    inputContractLineEndDate={contractLineEndDate}
                    inputPublicPri={pri}
                    selectedDataSegmentString={selectedDataSegmentString}
                    inputContrSrcSysCd={contrSrcSysCd}
                    setContractSublineID={setContractSublineID}
                    setPublicStateCd={setPublicStateCd}
                />}
        </div>
    )
}