import { useEffect, useState } from 'react';

import { ReportsTable1 } from '../ReportsTable1';

function createData(reportID, reportName) {
    return { reportID, reportName};
}

const rows = [
    createData('EC0230PR', 'CAPITATION EMPLOYER GROUP COUNTS'),
    createData('EC0240PR', 'CAPITATION PROVIDER COUNTS'),
    createData('EC0300PR', 'STANDARD SERVICES VARIANCE ANALYSIS - CURRENT'),
    createData('EC0310PR', 'STANDARD SERVICES VARIANCE ANALYSIS - CURRENT'),
    createData('EC0320PR', 'STANDARD SERVICES VARIANCE ANALYSIS - RETRO'),
    createData('EC0330PR', 'SUPPLEMENTAL BENEFIT VARIANCE ANALYSIS - CURRENT'),
    createData('EC0340PR', 'SUPPLEMENTAL BENEFIT VARIANCE ANALYSIS - RETRO'),
    createData('EC0350PR', 'SYSTEM ADJUSTMENT VARIANCE ANALYSIS - CURRENT'),
    createData('EC0360PR', 'SYSTEM ADJUSTMENT VARIANCE ANALYSIS - CURRENT'),
    createData('EC0370PR', 'SYSTEM ADJUSTMENT VARIANCE - RETRO'),
];

const viewButtonV = [false, false, true, false, false, false, false, true, false, false];
const downloadButtonV = [true, true, false, true, true, true, true, false, true, true];

export const ReportsProvider = ({inputDSeg}) => {
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        setDataSegments(inputDSeg);
    }, [inputDSeg]);

    return (
        <ReportsTable1 rows={rows} inputDSeg={dataSegments} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV}/>
    );
};