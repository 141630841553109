import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Search } from "./Search";

export const SearchControl = ({
    handleInvoiceClick,
    inputDataSegments
}) => {
    const [dataSegments, setDataSegments] = useState([]);

    useEffect(() => {
        setDataSegments(inputDataSegments);
    }, [inputDataSegments]);

    return (
        <Box>
            <Search
                inputDataSegments={dataSegments}
                handleInvoiceClick={handleInvoiceClick}
            />
        </Box>
    );
};