import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import HistoryAdjustment from "./HistoryAdjustment/HistoryAdjustment";
import HistorySearch from "./HistorySearch/HistorySearch";
import { HistoryProviderDetail } from "./HistoryProviderDetail/HistoryProviderDetail";
import HistoryTotalCap from "./HistoryTotalCap/HistoryTotalCap";
import { useEffect } from "react";
//import { DataSegmentApi } from '../MemberTab/MemberHistoryTab/MemberHistorySearch/DataSegmentApi'
import { DataSegmentApi } from '../../MemberTab/MemberHistoryTab/MemberHistorySearch/DataSegmentApi'

// subtabs within provider contract
const sections = [
    "Search",
    "Provider Detail ",
    "Total Cap",
    "Adjustment",
];

export const ProviderHistoryTab = () => {
    const [value, setValue] = useState(0);
    const [dataSegmentInfo, setDataSegmentInfo] = useState('');
    const [contractNameInfo, setContractNameInfo] = useState([]);
    const [showMemberSearchTable, setShowMemberSearchTable] = useState(false);
    const [selectedMemberData, setSelectedMemberData] = useState(null); // State to hold member data
    //   const [selectedRequestData, setSelectedRequestData] = useState(null);
    const [dataSegments, setDataSegments] = useState([]);
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        true,
        true,
        true,
        true
    ]);
    // set tab selection value (0, 1, 2, 3, 4)
    const [dataSeg, setDataSeg] = useState();
    const [contractInfo, setContractInfo] = useState();
    const [dataSegmentGlobal, setDataSegmentGlobal] = useState(null); 

    useEffect(() => {
        // Fetch the data when the component mounts  
        DataSegmentApi()
            .then(response => {
                // Set the fetched data to the dataSegments state  
                setDataSegments(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data segments:', error);
            });
    }, []);

    const handleTabChange = (memberData, newDataSegmentInfo, newContractNameInfo) => {
        console.log("data for data segment info is", newDataSegmentInfo);
        const providerDetailTabIndex = 1; // Set this to the index of the ProviderDetail tab  
        const updatedDisabledTabs = disabledTabs.map(() => false);
        setValue(providerDetailTabIndex); // Switch to the ProviderDetail tab  
        setDisabledTabs(updatedDisabledTabs);
        setShowMemberSearchTable(false);
        setSelectedMemberData(memberData);
        setDataSegmentInfo(newDataSegmentInfo);
        setContractNameInfo(newContractNameInfo);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTabs = (newValue, newDataSegmentInfo = '', newContractNameInfo = '') => {
        setValue(newValue); // Update the tab index  
        setDataSegmentInfo(newDataSegmentInfo); // Update dataSegmentInfo if provided  
        setContractNameInfo(newContractNameInfo); // Update contractNameInfo if provided  
    };
    // only adjust component if value changes

    useEffect(() => {
        console.log(dataSeg);
    }, [dataSeg])

    // Render the current tab content based on the value  
    const renderTabContent = (value) => {
        switch (value) {
            case 0:
                return (
                    <HistorySearch
                        handleTabChange={handleTabChange}
                        switchTabs={switchTabs}
                        dataSegmentInfo={dataSegmentInfo}
                        inputDataSegments={dataSegments}
                        setDataSegmentGlobal={setDataSegmentGlobal}
                    />);
            case 1:
                return (
                    <HistoryProviderDetail
                        memberData={selectedMemberData} 
                        switchTabs={switchTabs}
                        setDataSeg={setDataSeg}
                        setContractInfo={setContractInfo}
                        dataSegmentGlobal={dataSegmentGlobal}
                    />);
            case 2:
                return (
                    <HistoryTotalCap
                        handleTabChange={handleTabChange}
                        memberData={selectedMemberData}
                        switchTabs={switchTabs}
                        dataSegmentInfo={dataSegmentInfo}
                        contractNameInfo={contractNameInfo}
                        inputDataSeg={dataSeg}
                        inputContractInfo={contractInfo}
                        dataSegmentGlobal={dataSegmentGlobal}
                    />);
            case 3:
                return (
                    <HistoryAdjustment
                        memberData={selectedMemberData}
                        switchTabs={switchTabs}
                        dataSegmentInfo={dataSegmentInfo}
                        contractNameInfo={contractNameInfo}
                        inputDataSeg={dataSeg}
                        inputContractInfo={contractInfo}
                        dataSegmentGlobal={dataSegmentGlobal}
                    />);
            default:
                return null;
        }
    };
    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section, index) => (
                    <Tab
                        key={section}
                        label={section}
                        disabled={disabledTabs[index]}
                    />
                ))}
            </Tabs>
            {renderTabContent(value)}
        </div>
    );
};
