import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { blue } from "@mui/material/colors";
import { FormControl, useFormControlContext } from "@mui/base/FormControl";
import { Input, inputClasses } from "@mui/base/Input";
import { Menu as MenuIcon } from "@mui/icons-material";
import SelectionMenu from "./SelectionMenu";
import { styled } from "@mui/system";
import clsx from "clsx";

import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  List,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

const drawerWidth = 125;


export const Navbar = ({ setShowForm, setShowInnerNav, setTab }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const permissions = useSelector((store) => store.user.permissions);
  const [subValue, setSubValue] = useState('');
  const [allowedSection, setAllowedSection] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toLocaleString());
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleSubValueChange = (newValue) => {
    setSubValue(newValue);
};
  const makeSection = () => {
    const tempSection = [];
    if (permissions.admin.view === "Y") tempSection.push("Admin");
    if (permissions.maintenance.view === "Y") tempSection.push("Maintenance");
    if (permissions.member.view === "Y") tempSection.push("Member");
    if (permissions.provider.view === "Y") tempSection.push("Provider");
    if (permissions.reports.view === "Y") tempSection.push("Reports");
    if (permissions.errors.view === "Y") tempSection.push("Errors");
    if (permissions.job_scheduling.view === "Y")
      tempSection.push("Job Process");
    return tempSection;
  };
  const makeSubSection = () => {
    const tempSubSection = {
      Admin: [],
      Maintenance: [],
      Member: [],
      Provider: [],
      Errors: [],
      JobProcess: [],
      Reports: [],
    };
    if (permissions.admin_approval.view === "Y")
      tempSubSection.Admin.push("Approval");
    if (permissions.admin_codes.view === "Y")
      tempSubSection.Admin.push("Codes");
    if (permissions.admin_group.view === "Y")
      tempSubSection.Admin.push("Group Contracts");
    if (permissions.admin_exclude.view === "Y")
      tempSubSection.Admin.push("Exclude Contracts");
    if (permissions.admin_roles.view === "Y")
      tempSubSection.Admin.push("Roles");
    if (permissions.maintenance_cappayments.view === "Y")
      tempSubSection.Maintenance.push("Cap/Payment Adjustments");
    if (permissions.maintenance_forcecap.view === "Y")
      tempSubSection.Maintenance.push("Force Cap Parameters");
    if (permissions.maintenance_retro.view === "Y")
      tempSubSection.Maintenance.push("Retro Period Control");
    if (permissions.maintenance_reportingoverrides.view === "Y")
      tempSubSection.Maintenance.push("Reporting Overrides");
    if (permissions.maintenance_premium.view === "Y")
      tempSubSection.Maintenance.push("Premium Factors");
    if (permissions.member_search.view === "Y")
      tempSubSection.Member.push("Member Search");
    if (permissions.member_history.view === "Y")
      tempSubSection.Member.push("Member History");
    if (permissions.provider_contract.view === "Y")
      tempSubSection.Provider.push("Contract");
    if (permissions.provider_demographics.view === "Y")
      tempSubSection.Provider.push("Demographics");
    if (permissions.provider_history.view === "Y")
      tempSubSection.Provider.push("History");
    if (permissions.provider_crt.view === "Y")
      tempSubSection.Provider.push("CRT");
    if (permissions.provider_association.view === "Y")
      tempSubSection.Provider.push("Association");
    if (permissions.errors.view === "Y") tempSubSection.Errors.push("Errors");
    if (permissions.job_scheduling.view === "Y")
      tempSubSection.JobProcess.push("Job Scheduling");
    if (permissions.reports_member.view === "Y")
      tempSubSection.Reports.push("Member");
    if (permissions.reports_benefit.view === "Y")
      tempSubSection.Reports.push("Benefit");
    if (permissions.reports_provider.view === "Y")
      tempSubSection.Reports.push("Provider");
    if (permissions.reports_ecapprocess.view === "Y")
      tempSubSection.Reports.push("ECapProcess");
    if (permissions.reports_payment.view === "Y")
      tempSubSection.Reports.push("Payment");
    if (permissions.reports_premium.view === "Y")
      tempSubSection.Reports.push("Premium");
    return tempSubSection;
  };
  const section = makeSection();
  const subSection = makeSubSection();
  useEffect(() => {
    const timer = setInterval(() => {
        setCurrentDateTime(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }) + ' EST');
    }, 1000);

    return () => {
        clearInterval(timer);
    };
}, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ backgroundColor: "#002153", zIndex: 1400 }}
      >
        <Toolbar style={{ height: "7vh" }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            noWrap
            component='div'
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <a href='/'>
              <img
                className='logo'
                src='https://vbrs-portal-test.optum.com/static/media/logo.6e1173bef58f1d8a7367.png'
                width={160}
                height={40}
                alt='logo with product name borader'
              />
            </a>
            <Box sx={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
            <Typography variant='h4' sx={{fontSize: '3rem', marginRight: '5px' }}>|</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant='h5' sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>ECap</Typography>
            <Typography variant='caption'>Enterprise Capitation System</Typography>
            </Box>
            </Box>
            </Box>
          </Typography>
          <Typography
    variant="h6"
    sx={{ flexGrow: 1, textAlign: 'right' }}
>
    Welcome, {localStorage.getItem("sub")}
    <Typography variant="body1">
        {currentDateTime}
    </Typography>
</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='temporary'
        anchor='left'
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#002153",
            marginTop: "6px",
          },
        }}
      >
        <Toolbar />
        <List>
          {section.map((text, index) => (
            <SelectionMenu
              key={text}
              text={text}
              setTab={setTab}
              setIsDrawerOpen={setIsDrawerOpen}
              IsDrawerOpen={isDrawerOpen}
              sectionSubsectionDictionary={subSection}
            />
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

// Additional components (BasicFormControl, StyledInput, Label, HelperText) omitted for brevity

export function BasicFormControl() {
  return (
    <FormControl
      defaultValue=''
      required
    >
      <Label>Name</Label>
      <StyledInput placeholder='Write your name here' />
      <HelperText />
    </FormControl>
  );
}

const StyledInput = styled(Input)(
  ({ theme }) => `

  .${inputClasses.input} {
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }
  }
`
);

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? "invalid" : "")}>
      {children}
      {required ? " *" : ""}
    </p>
  );
})`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
