import { useState, useEffect } from 'react';
import { Grid, Typography, Breadcrumbs, Link } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getDataSegments } from '../../Home/dataSegmentApi';
import { ReportingOverridesAdd } from './Add/ReportingOverridesAdd';
import { ReportingOverridesSearch } from './Search/ReportingOverridesSearch';
import { ReportingOverridesUpload} from './Upload/ReportingOverridesUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const sections = [
    "Search",
    "Add",
    "Upload",
];

export const ReportingOverridesControl = () => {
    const [dataSegments, setDataSegments] = useState([]);

    useEffect(() => {
        setDataSegments(getDataSegments());
    }, [])

    const [value, setValue] = useState(0);
    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTab = (newValue) => {
        setValue(newValue);
    };
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return <ReportingOverridesSearch inputDataSegments={dataSegments} switchTab={switchTab} />;
        }
        if (value === 1) {
            return (
                <ReportingOverridesAdd
                    inputDataSegments={dataSegments}
                    switchTab={switchTab}
                />
            );
        }
        if (value === 2) {
            return <ReportingOverridesUpload 
            switchTab={switchTab}/>
        }
    };

    return (
        <Grid container spacing={1} style={{ margin: '10px 0px' }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link color='inherit' underline="hover" href='/'>
                    Maintenance
                </Link>
                <Typography color="navy">Reporting Overrides</Typography>
            </Breadcrumbs>
            <Grid sx={12} lg={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
                >
                    {sections.map((section) => (
                        <Tab label={section} />
                    ))}
                </Tabs>
                <MakeTabComponent />
            </Grid>
        </Grid>
    );
};






