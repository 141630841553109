

import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import { ProviderDetailApi } from './ProviderDetailApi';
import { useLocation } from 'react-router-dom';
import { formatDollars } from '../../../helperFunctions';


// A cache object outside of your component to store API responses  
const apiCache = new Map();

export const HistoryProviderDetail = ({
	memberData,
	switchTabs,
	setDataSeg,
	setContractInfo,
	dataSegmentGlobal
}) => {

	const [dataSegmentInfo, setDataSegmentInfo] = useState([]);
	const [contractNameInfo, setContractNameInfo] = useState([]);




	const rowColors = ["#b0c0da", "#ffffff"];

	const [cl1, setCl1] = useState({
		"Legal Entity": "",
		"Cap Product Category": "",
		"Regulatory Segment": "",
		"Major Shared Arrangement": "",
		"Payment MPIN": "",
		"Process Month": "",
		"Cap Period": "",
		"Current Member Count": "",
		"Retro Member Count": "",
		"Total Member Count": "",
	});
	const [cl2, setCl2] = useState({
		"CMS MMR Premium Amount": "",
		"Part D Buy-Down": "",
		"User Fee": "",
		"Sequestration": "",
		"Premium Tax": "",
		"Premium Adjustment 1": "",
		"Premium Adjustment 2": "",
		"Premium Adjustment 3": "",
		"Gross Cap": "",
	});
	const [cl3, setCl3] = useState({
		"Current Standard Service Cap": "",
		"Retro Standard Service Cap": "",
		"Adjustment Cap": "",
	});

	const formatMemberDetailResponse = (value) => {
		if (value === null || value === 'None' || (typeof value === 'string' && value.trim() === '') || typeof value === 'undefined') {
			return '$0.00';
		} else {
			// Assuming value is a number or a string that can be converted to a number  
			// Format the number as a currency string with 2 decimal places  
			const numberValue = parseFloat(value);
			return `$${numberValue.toFixed(2)}`;
		}
	};



	const formatDecimals = (amountString) => {
		// Attempt to convert the string to a floating-point number  
		const amount = parseFloat(amountString);

		// Check if the conversion was successful and the result is a finite number  
		if (!isNaN(amount) && isFinite(amount)) {
			// Format to 4 decimal places and return the resulting string  
			return `$${amount.toFixed(2)}`;
		}

		// If conversion failed or resulted in Infinity, handle as needed  
		// For example, return '0.0000' or an empty string or any other default value  
		return '$0.00';
	};






	const formatWithCommas = (value) => {
		const num = Number(value);
		if (!isNaN(num) && isFinite(num)) {
			return num.toLocaleString();
		}

		return value;
	};


	useEffect(() => {
		// Define the parameters for the API call

		if (!memberData) {
			return; // Do nothing if memberData is not available  
		}
		const PAY_PARTN_ID = dataSegmentGlobal;
		const DATA_SEG_ID = dataSegmentGlobal;
		const { CAP_PRD_YR_MO, PROC_YR_MO, CONTR_ID } = memberData;

		console.log("one", CAP_PRD_YR_MO);
		console.log("two", PROC_YR_MO);
		console.log("three", CONTR_ID);


		//  const cacheKey = `${memberData.DATA_SEG_ID}-${memberData.ECAP_MBR_KEY}-${memberData.ECAP_CONTR_LN_KEY}-${memberData.PROC_YR_MO}-${memberData.CAP_PRD_YR_MO}`;  
		const cacheKey = `${memberData.CAP_PRD_YR_MO}-${memberData.PROC_YR_MO}-${memberData.ECAP_CONTR_LN_KEY}-${memberData.CONTR_ID}-${memberData.CAP_PRD_YR_MO}-${memberData.DATA_SEG_ID}-${memberData.PAY_PARTN_ID}`;

		var requestBody = {}
		requestBody['CAP_PRD_YR_MO'] = CAP_PRD_YR_MO;
		requestBody['PROC_YR_MO'] = PROC_YR_MO;
		requestBody['CONTR_ID'] = CONTR_ID;
		requestBody['PAY_PARTN_ID'] = PAY_PARTN_ID;
		requestBody['DATA_SEG_ID'] = PAY_PARTN_ID;
		if (apiCache.has(cacheKey)) {
			const cachedData = apiCache.get(cacheKey);
			setCl1(cachedData.cl1);
			setCl2(cachedData.cl2);
			setCl3(cachedData.cl3);
			setDataSegmentInfo(cachedData.dataSegmentInfo);
			setDataSeg(cachedData.dataSegmentInfo);
			setContractNameInfo(cachedData.contractNameInfo);
			setContractInfo(cachedData.contractNameInfo);
		} else {
			// Call your API with the parameters from the location state
			ProviderDetailApi(requestBody)
				.then(response => {
					// Assuming there's only one item in the response array and it's the one you want
					const data = response.data;
					console.log("response from provider detail", data);

					// Now you need to decide how to split this data into cl1, cl2, and cl3
					// For example, you could do something like this:

					const cl1Data = {
						"Legal Entity": data.LEGAL_ENTITY,
						"Cap Product Category": data.CAP_PRODUCT_CATEGORY,
						"Regulatory Segment": data.REGULATORY_SEGMENT,
						"Major Shared Arrangement": data.MAJOR_SHARED_ARRANGEMENT,
						"Payment MPIN": memberData.PAY_TO_PROV_ID,
						"Process Month": memberData.PROC_YR_MO,
						"Cap Period": memberData.CAP_PRD_YR_MO,
						"Current Member Count": formatWithCommas(memberData.CURRENT_MEMBER_COUNT),
						"Retro Member Count": memberData.RETRO_MEMBER_COUNT,
						"Total Member Count": formatWithCommas(memberData.TOTAL_MEMBER_COUNT)
						// ... other fields for cl1
					};

					const cl2Data = {
						"CMS MMR Premium Amount": formatDecimals(data.CMSMMR_PREMIUM_AMOUNT),
						"Part D Buy-Down": formatDecimals(data.PART_D_BUY_DOWN),
						"User Fee": formatDecimals(data.USER_FEE),
						"Sequestration": formatDecimals(data.SEQUESTRATION),
						"Premium Tax": formatDecimals(data.PREMIUM_TAX),
						"Premium Adjustment 1": formatDecimals(data.PREMIUM_ADJUSTMENT_1),
						"Premium Adjustment 2": formatDecimals(data.PREMIUM_ADJUSTMENT_2),
						"Premium Adjustment 3": formatDecimals(data.PREMIUM_ADJUSTMENT_3),
						"Gross Cap": formatDecimals(data.GROSS_CAP),
						// ... other fields for cl2
					};

					const currentStandardServiceCap = data.CURRENT_STANDARD_SERVICE_CAP
						? (data.CURRENT_STANDARD_SERVICE_CAP)
						: (memberData.CURRENT_STANDARD_SERVICE_CAP);

					const currentRetroStandardServiceCap = data.RETRO_STANDARD_SERVICE_CAP
						? (data.RETRO_STANDARD_SERVICE_CAP)
						: (memberData.RETRO_STANDARD_SERVICE_CAP);

					console.log("ADJUSTMENT CAP FROM MEMBER IS", memberData.ADJUSTMENT_CAP);

					const currentAdjustmentCap = data.ADJUSTMENT_CAP
						? (data.ADJUSTMENT_CAP)
						: (memberData.ADJCAP);

					const cl3Data = {
						"Current Standard Service Cap": formatDollars(currentStandardServiceCap),
						"Retro Standard Service Cap": formatDollars(currentRetroStandardServiceCap),
						"Adjustment Cap": formatDollars(currentAdjustmentCap),
					};

					const newDataSegmentInfo = [
						data.LEGAL_ENTITY,
						data.CAP_PRODUCT_CATEGORY,
						data.MAJOR_SHARED_ARRANGEMENT,
						data.REGULATORY_SEGMENT
					].filter(Boolean).join(' - ');

					const newContractNameInfo = data.CONTRACT_NAME;

					// Now set the dataSegmentInfo state with the new string  
					setDataSegmentInfo(newDataSegmentInfo);
					setContractNameInfo(data.CONTRACT_NAME);
					//   switchTabs(2, newDataSegmentInfo,newContractNameInfo);


					// Update the cache with the new data  
					apiCache.set(cacheKey, { dataSegmentInfo: newDataSegmentInfo, contractNameInfo: newContractNameInfo, cl1: cl1Data, cl2: cl2Data, cl3: cl3Data });
					// Update the state with the new data  
					setCl1(cl1Data);
					setCl2(cl2Data);
					setCl3(cl3Data);
					setDataSegmentInfo(newDataSegmentInfo);
					setDataSeg(newDataSegmentInfo);
					setContractNameInfo(newContractNameInfo);
					setContractInfo(newContractNameInfo);
				})
				.catch(error => {
					console.error('There was an error fetching the member details:', error);
				});
		}
	}, [memberData]); // Dependency array is empty, so this effect runs only once on component mount




	const tableCellStyle = {
		fontSize: "0.875rem", // Adjust the font size as needed
		padding: "6px 10px", // Adjust the padding as needed
	};

	function adjustmentLink() {
		switchTabs(3);
	}

	return (
		<>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Contract#: {memberData.CONTR_ID} | Contract Line#: {memberData.CONTR_LN_ID} Data Segment: {dataSegmentInfo}  | Arrangement: {memberData.PROV_ARNG_CD}|  Cap Contract Type: {memberData.CONTR_TYP_CD}|  Total Cap: <a href='#' onClick={() => switchTabs(2, dataSegmentInfo, contractNameInfo)} style={{ cursor: 'pointer' }}>{`${formatDollars(parseFloat(memberData.TOTAL_CAP))}`}</a>
				<br />
				Contact Name: {contractNameInfo}
			</Typography>
			<Grid
				container
				spacing={16}
			>
				<Grid
					item
					xs={4}
				>
					<Stack spacing={0.5}>
						<TableContainer component={Paper}>
							<Table size='small'>
								<TableBody>
									{Object.entries(cl1).map(([key, value], index) => (
										<TableRow
											key={key}
											style={{ backgroundColor: rowColors[index % 2] }}
										>
											<TableCell sx={tableCellStyle}>{key}</TableCell>
											<TableCell name={key} sx={tableCellStyle}>{value}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>
				</Grid>
				<Grid
					item
					xs={4}
				>
					<Stack spacing={0.5}>
						<TableContainer component={Paper}>
							<Table size='small'>
								<TableBody>
									{Object.entries(cl2).map(([key, value], index) => (
										<TableRow
											key={key}
											style={{ backgroundColor: rowColors[index % 2] }}
										>
											<TableCell sx={tableCellStyle}>{key}</TableCell>
											<TableCell name={key} sx={tableCellStyle}>{value}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>
				</Grid>
				<Grid
					item
					xs={4}
				>
					<Stack spacing={0.5}>
						<TableContainer component={Paper}>
							<Table size='small'>
								<TableBody>
									{Object.entries(cl3).slice(0, -1).map(([key, value], index) => (
										<TableRow
											key={key}
											style={{ backgroundColor: rowColors[index % 2] }}
										>
											<TableCell sx={tableCellStyle}>{key}</TableCell>
											<TableCell name={key} sx={tableCellStyle}>{value}</TableCell>
										</TableRow>
									))}
									<TableRow
										style={{ backgroundColor: rowColors[0] }}>
										<TableCell sx={tableCellStyle}>Adjustment Cap</TableCell>
										<TableCell sx={tableCellStyle}><a href='#' onClick={() => adjustmentLink()}>{cl3['Adjustment Cap']}</a></TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};
