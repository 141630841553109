import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import Login from "./components/Login";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";

//ReactDOM.render(
// <React.StrictMode>
//  <App />
//  </React.StrictMode>,
//  document.getElementById("root")
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const Index = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };
  return (
    <div>
      {isLoggedIn ? (
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      ) : (
        <React.StrictMode>
          <Provider store={store}>
            <Login onLoginSuccess={handleLoginSuccess} />
          </Provider>
        </React.StrictMode>
      )}
    </div>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));
