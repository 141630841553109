import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Button
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { parseISO, format } from 'date-fns';  


const RetroPeriodSearchTable = ({
    inputTableData,
    setSelectedUpdateRows,
    switchTabs
}) => {
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]); // Store selected row IDs
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => {
        // The useEffect hook should always be called, but the logic inside can be conditional.  
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            setSelectedRows(Array(inputTableData.length).fill(false));
        }
        setPage(0);
    }, [inputTableData]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle checkbox selection
    const handleCheckboxChange = (event, index) => {
        setSelectedRows(prevState => [
            ...prevState.slice(0, index),
            event.target.checked,
            ...prevState.slice(index + 1)
        ]);
    };

    useEffect(() => {
        setSelectAllChecked(selectedRows.every(row => row === true));
    }, [selectedRows])

    // Handle Edit Click (opens dialog)
    const handleUpdateClick = () => {
        const selectedRowData = tableData.filter((row, index) => selectedRows[index]);
        setSelectedUpdateRows(selectedRowData);
        switchTabs(3);
    };

    // Handle Select All
    const handleSelectAllChange = (event) => {
        setSelectAllChecked(event.target.checked);
        var temp = Array(tableData.length).fill(event.target.checked);
        setSelectedRows(temp);
    };

    return (
        <div>
            {/* Table for displaying data */}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '60vh', width: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Update</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Retro Type</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Begin Effective Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>End Effective Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Max Period</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>System Default</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Time Stamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Checkbox
                                                    sx={{ textAlign: 'center' }}
                                                    checked={selectedRows[index]}
                                                    onChange={(event) => handleCheckboxChange(event, index)}
                                                />
                                            </TableCell>
                                            <TableCell>{row.RETRO_TYP_CD === "None" || row.RETRO_TYP_CD === null ? " " : row.RETRO_TYP_CD}</TableCell>
                                            <TableCell>{row.BEGIN_EFF === "None" || row.BEGIN_EFF === null ? " " : row.BEGIN_EFF}</TableCell>
                                            <TableCell>{row.BEGIN_END === "None" || row.BEGIN_END === null ? " " : row.BEGIN_END}</TableCell>
                                            <TableCell>{row.MAX_PERIOD === "None" || row.MAX_PERIOD === null ? " " : row.MAX_PERIOD}</TableCell>
                                            <TableCell>{row.SYS_DEF === "None" || row.SYS_DEF === null ? " " : row.SYS_DEF}</TableCell>
                                            <TableCell> {row.TIME === "None" || row.TIME === null   ? " "   : format(parseISO(row.TIME), 'dd-MMM-yyyy HH:mm:ss.SSS')} </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Checkbox
                                        checked={selectAllChecked}
                                        onChange={handleSelectAllChange}
                                    />
                                     Select All
                                </TableCell>
                                <TableCell colSpan={15}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                        <Button
                                            variant="contained"
                                            size='large'
                                            color="success"
                                            onClick={() => handleUpdateClick()}
                                            disabled={selectedRows.every(row => row === false)}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sx={{
                        '.MuiTablePagination-selectLabel': {
                            paddingTop: '12px',
                        },
                        '.MuiTablePagination-displayedRows': {
                            paddingTop: '13px',
                        },
                    }}
                />
            </Paper>
        </div>
    );
};
export default RetroPeriodSearchTable;