import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
  name: "roles",
  initialState: [],
  reducers: {
    addRole: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { addRole } = roleSlice.actions;
export default roleSlice;
