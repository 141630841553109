import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';


const CRTSearchTable = ({ showTable, inputTableData }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
        }
        setPage(0);
    }, [inputTableData]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'Membersearch_table.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    if (!showTable) {
        return null;
    }

    return (
        <Grid>
            <Grid container justifyContent="flex-end">
                <Button
                    size="medium"
                    variant="text"
                    onClick={handleDownload}
                    id='download-button'>Download</Button>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>CRT#</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Payment Contract</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Payment MPIN</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Relation Code</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Owner Contract</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Owner MPIN</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Name</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>MPIN</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Master Ind</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Opt Out Ind</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.contract}>
                                            <TableCell>{row.crt === "None" || row.crt === null ? " " : row.crt}</TableCell>
                                            <TableCell>{row.payment_contract === "None" || row.payment_contract === null ? " " : row.payment_contract}</TableCell>
                                            <TableCell>{row.payment_mpin === "None" || row.payment_mpin === null ? " " : row.payment_mpin}</TableCell>
                                            <TableCell>{row.relation_code === "None" || row.relation_code === null ? " " : row.relation_code}</TableCell>
                                            <TableCell>{row.owner_contract === "None" || row.owner_contract === null ? " " : row.owner_contract}</TableCell>
                                            <TableCell>{row.owner_mpin === "None" || row.owner_mpin === null ? " " : row.owner_mpin}</TableCell>
                                            <TableCell>{row.contract === "None" || row.contract === null ? " " : row.contract}</TableCell>
                                            <TableCell>{row.contract_name === "None" || row.contract_name === null ? " " : row.contract_name}</TableCell>
                                            <TableCell>{row.mpin === "None" || row.mpin === null ? " " : row.mpin}</TableCell>
                                            <TableCell>{row.master_ind === "None" || row.master_ind === null ? " " : row.master_ind}</TableCell>
                                            <TableCell>{row.opt_out_ind === "None" || row.opt_out_ind === null ? " " : row.opt_out_ind}</TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sx={{
                        '.MuiTablePagination-selectLabel': {
                            paddingTop: '12px',
                        },
                        '.MuiTablePagination-displayedRows': {
                            paddingTop: '13px',
                        },
                    }}
                />
            </Paper>
        </Grid>
    );
};

export default CRTSearchTable;