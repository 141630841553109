import { Box, Typography, LinearProgress } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { errorContractDetailApi } from '../errorContractDetailApi';
import { useEffect, useState } from 'react';
import { stringDecimals } from '../../helperFunctions';

export const MonthlyDetail = ({
    setRenderChoice,
    inputDetailData
}) => {
    const [data, setData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        var requestBody = {
            'CONTR_ID': inputDetailData['CONTR_ID'] ? inputDetailData['CONTR_ID'] : '4144',
            'CONTR_LN_ID': inputDetailData['CONTR_LN_ID'] ? inputDetailData['CONTR_LN_ID'] : '1',
            'PRDCT_RDY_CD': ''
        }
        errorContractDetailApi(requestBody).then(response => {
            setErrorMessage('');
            setLoadingTable(false);
            if (response.data.length > 0) {
                setData(response.data);
                setShowTable(true);
            } else {
                setErrorMessage('No data found');
            }
        }).catch(error => {
            console.log('error with errors monthly contract detail', error);
            setLoadingTable(false);
            setErrorMessage('No data found');
        })
    }, [])

    // Row colors for alternating rows            
    const rowColors = ["#b0c0da", "#ffffff"];

    return (
        <Box>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputDetailData['DATA_SEG_ID'])} | Error Severity: {inputDetailData['ERR_SEV_CD']} | Error Category: {inputDetailData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputDetailData['ERR_TYP_CD']} | Error Message: {inputDetailData['LNG_DESC']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Value: {inputDetailData['ERR_DATA_VAL']} | Corporate MPIN: {inputDetailData['CORP_MPIN']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Monthly Processed Search Results</a> {'>'} <a href='#' onClick={() => setRenderChoice(1)}>Error Group by Value</a> {'>'} <a href='#' onClick={() => setRenderChoice(2)}>Error Detail List</a> {'>'} Contract Detail
            </Typography>
            <Box sx={{ maxHeight: '70vh', overflowY: 'scroll', mt:'1rem' }}>
                {showTable &&
                    data.map((row, index) => {
                        return (
                            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', width: '100%', mb: '2rem'}}>
                                <Box sx={{ flex: 1, mr: '15%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Process month</Box>
                                        <Box sx={{ flex: 1 }}>{inputDetailData['PROC_YR_MO']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Cap period</Box>
                                        <Box sx={{ flex: 1 }}>{inputDetailData['CAP_PRD_YR_MO']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Contract#</Box>
                                        <Box sx={{ flex: 1 }}>{inputDetailData['CONTR_ID']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Contract Line #</Box>
                                        <Box sx={{ flex: 1 }}>{inputDetailData['CONTR_LN_ID']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Arrangement</Box>
                                        <Box sx={{ flex: 1 }}>{row['CONTR_ARNG_CD']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Effective Date</Box>
                                        <Box sx={{ flex: 1 }}>{row['CONTR_LN_EFF_DT']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>End Date</Box>
                                        <Box sx={{ flex: 1 }}>{row['CONTR_LN_END_DT']}</Box>
                                    </Box>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Calculation Method Code</Box>
                                        <Box sx={{ flex: 1 }}>{row['CALC_METH_CD']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Proration Type Code</Box>
                                        <Box sx={{ flex: 1 }}>{row['PRORT_TYP_CD']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Retro Window Type Code</Box>
                                        <Box sx={{ flex: 1 }}>{row['RETRO_WNDW_TYP_CD']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Physician Percent</Box>
                                        <Box sx={{ flex: 1 }}>{stringDecimals(row['PHYSN_PCT'], 1)}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Hospital Percent</Box>
                                        <Box sx={{ flex: 1 }}>{stringDecimals(row['HOSP_PCT'], 1)}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Rollup Id</Box>
                                        <Box sx={{ flex: 1 }}>{row['RLP_ID']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[1], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Legacy Prov Id</Box>
                                        <Box sx={{ flex: 1 }}>{row['LGCY_PROV_NBR']}</Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor:rowColors[0], paddingLeft:'0.5rem' }}>
                                        <Box sx={{ flex: 1 }}>Legacy Vendor Id</Box>
                                        <Box sx={{ flex: 1 }}>{row['LGCY_VEND_NBR']}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
            {loadingTable &&
                <Box sx={{ width: '100%', mt: '1rem' }}>
                    <LinearProgress />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: '1rem' }}>
                    {errorMessage}
                </div>
            )}
        </Box>
    );
};
