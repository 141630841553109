import { createSlice } from "@reduxjs/toolkit";
const userInputSlice = createSlice({
  name: "userinput",
  initialState: {},
  reducers: {
    pushInputs: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    clearInputs: (state) => {
      state = {};
    },
  },
});

export const { pushInputs, clearInputs } = userInputSlice.actions;

export default userInputSlice;
