import axios from 'axios';
const OVERRIDE_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search`;
const DOCUMENT_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search/document`;
const OVERRIDE_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/update`;
const OVERRIDE_UPDATE_SAVE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search/update_save`;

export const ReportOverrideApi = (requestBody) => {
    return axios.post(
        OVERRIDE_SEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const ReportOverrideDocumentApi = (requestBody) => {
    return axios.post(
        DOCUMENT_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}


export const ReportOverrideUpdateApi = (requestBody) => {
    return axios.post(
        OVERRIDE_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const ReportOverrideUpdateSaveApi = (requestBody) => {
    return axios.post(
        OVERRIDE_UPDATE_SAVE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}