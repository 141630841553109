import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button'


function Row(props) {
	const { group, handleTabChange } = props;
	const [open, setOpen] = React.useState(false);
	const [currentTab, setCurrentTab] = useState('tab1');

	// Check if group.members is defined and has at least one element  
	if (!group.members || group.members.length === 0) {
		return null;  // or some default JSX if you prefer  
	}

	const row = group.members[0];

	const handleSuffixClick = (memberData) => (event) => {
		event.preventDefault();
		handleTabChange(memberData);
	};


	const columnWidths = {
		showHistory: '7.7%',
		suffix: '5.4%',
		memberName: '15.7%',
		processMonth: '10.3%',
		capPeriod: '7.8%',
		contractOwnerMPIN: '15.3%',
		contract: '6.52%',
		contractLine: '10.6%',
		argt: '5.2%',
		capContractType: '11.5%',
		totalCap: '9.2%',
		// Add more widths for other columns if needed...  
	};

	const totalAmt1 = group.members.reduce((acc, sumRow) => {  
		return acc + parseFloat(sumRow.AMT1);  
	  }, 0); 
	
	// This example assumes that each JSON object is a separate row and does not have a history array.  
	return (
		<React.Fragment>
			{/** first row (suffix row) */}
			<TableRow id="member-table-main-row">
				<TableCell name='show-history-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }} >
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => {
							setOpen(!open);
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell name='suffix-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
					<TabContext value={currentTab}>
						<Link
							href="#"
							onClick={handleSuffixClick(row)}
						>
							{row.MBR_ID_SUFX}
						</Link>
					</TabContext>
				</TableCell>
				<TableCell name='member-name-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{`${row.LST_NM} ${row.FST_NM}`}</TableCell>
				<TableCell name='process-month-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.PROC_YR_MO}</TableCell>
				<TableCell name='cap-period-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{}</TableCell>
				<TableCell name='mpin-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.OWNR_PROV_ID}</TableCell>
				<TableCell name='contract-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.CONTR_ID}</TableCell>
				<TableCell name='contract-line-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.CONTR_LN_ID}</TableCell>
				<TableCell name='argt-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.PROV_ARNG_CD}</TableCell>
				<TableCell name='cap-contract-type-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{row.CONTR_TYP_CD}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap',paddingRight:'2rem' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
			        ${(parseFloat(totalAmt1.toFixed(2) ))}</TableCell>
			        </TableRow>
			{open && (
				<>
					{group.members && group.members.length > 0 ? (
						group.members.map((member, index) => (
							<TableRow key={index}>
								<TableCell name='show-history-column' style={{ textAlign: 'center', whiteSpace: 'nowrap', height: '2rem', }}></TableCell>
								<TableCell name='suffix-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}></TableCell>
								<TableCell name='member-name-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}></TableCell>
								<TableCell name='process-month-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.PROC_YR_MO}</TableCell>
								<TableCell name='cap-period-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
									<Link href="#" onClick={handleSuffixClick(member)}>
										{member.CAP_PRD_YR_MO}
									</Link>
								</TableCell>
								<TableCell name='mpin-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.OWNR_PROV_ID}</TableCell>
								<TableCell name='contract-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.CONTR_ID}</TableCell>
								<TableCell name='contract-line-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.CONTR_LN_ID}</TableCell>
								<TableCell name='argt-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.PROV_ARNG_CD}</TableCell>
								<TableCell name='cap-contract-type-column' style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{member.CONTR_TYP_CD}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap', paddingRight:'2rem'}}>
									{(!isNaN(parseFloat(member.AMT1)))
										? `$${(parseFloat(member.AMT1)).toFixed(2)}`
										: 'Invalid data'}
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan='100%'>No additional members</TableCell>
						</TableRow>
					)}
				</>
			)
			}
		</React.Fragment>
	);
}

export function MemberSearchTable({ searchResults, handleTabChange }) {

	const StripedTableBody = styled(TableBody)(({ theme }) => ({
		'& tr:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		'& tr:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	}));

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const tableRows = searchResults || [];

	// Function to process the data and return the nested JSON structure  
	const processData = (tableRows) => {
		const groupedData = tableRows.reduce((acc, item) => {
			const group = item.PROC_YR_MO;
			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(item);
			return acc;
		}, {});

		const sortedGroups = Object.keys(groupedData).sort((a, b) => b.localeCompare(a)); // Sort the groups

		return sortedGroups.map((group) => ({
			groupName: group,
			members: groupedData[group],
		}));
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleDownload = () => {

		const table = document.querySelector('.MuiTable-root');

		if (!table) {
			console.error('Table not found');
			return;
		}

		const headers = [];
		table.querySelectorAll('th').forEach(header => {
			headers.push(header.innerText);
		});

		const data = [headers];
		const tableBodyRows = table.querySelectorAll('tbody tr');

		tableBodyRows.forEach(row => {
			const rowData = [];
			row.querySelectorAll('td').forEach(cell => {
				rowData.push(cell.innerText);
			});
			data.push(rowData);
		});

		const csvData = data.map(row => row.join(',')).join('\n');
		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		const url = URL.createObjectURL(blob);
		link.href = url;
		link.download = 'Membersearch_table.csv';

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	return (

		<Paper sx={{ width: '100%', overflow: 'hidden' }}>


			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					onClick={handleDownload}
					id='download-button'
				>
					Download
				</Button>
			</div>

			{/* <div style={{ overflow: 'auto', maxHeight: '100vh' }}>   */}
			<TableContainer component={Paper} style={{ height: '70vh', width: '100%', overflow: 'auto' }}>
				<Table id="member-table" aria-label="collapsible table" stickyHeader>
					<TableHead id="member-table-header" style={{ backgroundColor: '#b0c0da' }} >
						<TableRow id="member-table-row" style={{ backgroundColor: '#b0c0da' }}>
							{/* <TableCell style={{ backgroundColor: '#b0c0da' }} /> */}
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Show History</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Suffix</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Member Name</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Process Month</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Cap Period</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract Owner MPIN</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract Line</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Argt</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Cap Contract Type</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Total Cap</TableCell>
						</TableRow>
					</TableHead>
					<StripedTableBody>
						{/* {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={tableRows.CAP_PRD_YR_MO} row={row} handleTabChange={handleTabChange}  />
          ))} */}
						{processData(tableRows).map((group, index) => (
							<Row key={index} group={group} handleTabChange={handleTabChange} />
						))}

					</StripedTableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={processData(tableRows).length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</Paper>

	);
}
