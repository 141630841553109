import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import DemographicsSearchTable from './DemographicsSearchTable';
import { useState, useEffect } from 'react';
import { MenuItem, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { DemographicsSearchApi } from './DemographicsSearchApi';



export const DemographicsSearch = ({ switchTabs, setTab, setProvId, setProvTaxIdNbr, setProvTaxIdTypCd, seteffectivedate, setcorpmpin, setenddate, setlastname }) => {
    const [ProviderName, setProviderName] = React.useState('');
    const [ProviderIDMPIN, setProviderIDMPIN] = React.useState('');
    const [TaxID, setTaxID] = React.useState('');
    const [FirstName, setFirstName] = React.useState('');
    const [CorpMPIN, setCorpMPIN] = React.useState('');
    const [TaxIDType, setTaxIDType] = React.useState('TIN');
    const [showSearch, setShowSearch] = React.useState(true);

    const handleProviderNameChange = (event) => {
        setProviderName(event.target.value);
    };

    const handleProviderIDMPINChange = (event) => {
        setProviderIDMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleTaxIDChange = (event) => {
        setTaxID(event.target.value.replace(/\D/g, ''));
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleCorpMPINChange = (event) => {
        setCorpMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleTaxIDTypeChange = (event) => {
        setTaxIDType(event.target.value);
    };


    const [showTable, setShowTable] = React.useState(false); // State to control table visibility
    const [tableData, setTableData] = useState();

    // error messages when none of the fields are filled in 

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.clear();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    function storeAll() {
        // console.log('storing into session storage');
        sessionStorage.setItem('demographicsprovidername', ProviderName);
        sessionStorage.setItem('demographicsprovideridmpin', ProviderIDMPIN);
        sessionStorage.setItem('demographicstaxid', TaxID);
        sessionStorage.setItem('demographicsfirstname', FirstName);
        sessionStorage.setItem('demographicscorpmpin', CorpMPIN);
        sessionStorage.setItem('demographicstaxidtype', TaxIDType);
        sessionStorage.setItem('demographicsShowTable', showTable);
        sessionStorage.setItem('demographicsTableData', JSON.stringify(tableData));
        // clear after 5 min
        setTimeout(function () {
            sessionStorage.clear();
        }, 300000);
    }

    // clear session storage because sessionStorage.clear() is iffy sometimes
    function clearSessionStorage() {
        console.log('clearing session storage');
        sessionStorage.setItem('demographicsprovidername', null);
        sessionStorage.setItem('demographicsprovideridmpin', null);
        sessionStorage.setItem('demographicstaxid', null);
        sessionStorage.setItem('demographicsfirstname', null);
        sessionStorage.setItem('demographicscorpmpin', null);
        sessionStorage.setItem('demographicstaxidtype', null);
        sessionStorage.setItem('demographicsShowTable', null);
        sessionStorage.setItem('demographicsTableData', null);
    }

    // grab from sessionstorage
    useEffect(() => {
        // console.log('grabbing from session storage');

        if (sessionStorage.getItem('demographicsprovidername') && sessionStorage.getItem('demographicsprovidername') !== 'null') {
            setProviderName(sessionStorage.getItem('demographicsprovidername'));
        }
        if (sessionStorage.getItem('demographicsprovideridmpin') && sessionStorage.getItem('demographicsprovideridmpin') !== 'null') {
            setProviderIDMPIN(sessionStorage.getItem('demographicsprovideridmpin'));
        }
        if (sessionStorage.getItem('demographicstaxid') && sessionStorage.getItem('demographicstaxid') !== 'null') {
            setTaxID(sessionStorage.getItem('demographicstaxid'));
        }
        if (sessionStorage.getItem('demographicsfirstname') && sessionStorage.getItem('demographicsfirstname') !== 'null') {
            setTaxID(sessionStorage.getItem('demographicsfirstname'));
        }
        if (sessionStorage.getItem('demographicscorpmpin') && sessionStorage.getItem('demographicscorpmpin') !== 'null') {
            setCorpMPIN(sessionStorage.getItem('demographicscorpmpin'));
        }
        if (sessionStorage.getItem('demographicstaxidtype') && sessionStorage.getItem('demographicstaxidtype') !== 'null' && sessionStorage.getItem('demographicstaxidtype') !== 'TIN') {
            setTaxIDType(sessionStorage.getItem('demographicstaxidtype'));
        }
        if (sessionStorage.getItem('demographicsShowTable') && sessionStorage.getItem('demographicsShowTable') !== 'null') {
            setShowTable(sessionStorage.getItem('demographicsShowTable'));
        }
        if (sessionStorage.getItem('demographicsTableData') && sessionStorage.getItem('demographicsTableData') !== 'null') {
            setTableData(JSON.parse(sessionStorage.getItem('demographicsTableData')));
        }
    }, [])

    const handleSubmit = () => {
        if (ProviderName === "" &&
            ProviderIDMPIN === "" &&
            TaxID === ""
        ) {
            setErrorMessage("Please enter Last Name OR Provider ID OR Tax ID. ");
            setShowTable(false);
        } else {
            setErrorMessage('');
            const requestBody = {
                "last_name": `${ProviderName}`,
                "PROV_ID": `${ProviderIDMPIN}`,
                "PROV_TAX_ID_NBR": `${TaxID}`,
                "CORP_MPIN": `${CorpMPIN}`,
                "prov_tax_id_typ_cd": `${TaxIDType}`,
                "first_name": `${FirstName}`

            }
            DemographicsSearchApi(requestBody).then
                (response => {
                    console.log("Response Body :", response);
                    console.log("Request Body :", requestBody);
                    if (response.data.length > 0) {
                        clearSessionStorage();
                        setTableData(response.data);
                    } else {
                        setErrorMessage('No Record Found');
                    }
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('No Record Found')
                });
        }
    };

    useEffect(() => {
        if (tableData !== undefined) {
            storeAll();
            // eslint-disable-next-line
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);

    const handleReset = () => {
        // Clear all search fields  
        clearSessionStorage();
        setProviderName('');
        setProviderIDMPIN('');
        setTaxID('');
        setFirstName('');
        setCorpMPIN('');
        //setTaxIDType('TIN');

        // Hide the table if it's currently being shown  
        setShowTable(false);
    };


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    return (
        <div style={{ width: 'calc(98.5vw)', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '315px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}> <Box>
                            Provider Name/
                        </Box>
                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                Last Name
                            </Box></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="ProviderNameInput"
                                value={ProviderName}
                                onChange={handleProviderNameChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}> Provider ID(MPIN)</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="ProviderIDMPINInput"
                                value={ProviderIDMPIN}
                                onChange={handleProviderIDMPINChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1 }}>Tax ID</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="TaxIDInput"
                                value={TaxID}
                                onChange={handleTaxIDChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>First Name</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="FirstNameInput"
                                value={FirstName}
                                onChange={handleFirstNameChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Corp MPIN</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="CorpMPINInput"
                                value={CorpMPIN}
                                onChange={handleCorpMPINChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Tax ID Type</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <Select
                                value={TaxIDType}
                                onChange={handleTaxIDTypeChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                    width: "100%",
                                }}
                                size='small'>
                                <MenuItem
                                    sx={{
                                        fontSize: "0.8rem",
                                    }}
                                    value={"TIN"}>
                                    TAX IDENTIFICATION NUMBER
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        fontSize: "0.8rem",
                                    }}
                                    value={"SSN"}>
                                    SOCIAL SECURITY NUMBER
                                </MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Button
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {!errorMessage && (<div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '3.5rem' }}>
                <DemographicsSearchTable
                    showTable={showTable}
                    inputTableData={tableData}
                    switchTabs={switchTabs}
                    setTab={setTab}
                    setProvId={setProvId}
                    setProvTaxIdNbr={setProvTaxIdNbr}
                    setProvTaxIdTypCd={setProvTaxIdTypCd}
                    seteffectivedate={seteffectivedate}
                    setcorpmpin={setcorpmpin}
                    setenddate={setenddate}
                    setlastname={setlastname}
                />
            </div>)}
            {errorMessage && (<div style={{ width: '100%', textAlign: 'center', color: 'red' }}>{errorMessage}</div>)}
        </div>
    );
};

export default DemographicsSearch;
