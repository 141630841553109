import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { providerContractMemberDetailApi } from './providerContractMemberApi';
import { yyyymmddToMmddyyyy } from '../../../helperFunctions';

export const ProviderContractMemberDetail = ({
    searchLink,
    regularLink,
    inputContractName,
    inputContractNumber,
    inputContractTypeCd,
    inputCalcMethCd,
    inputContractLineId,
    inputContractSublineID,
    selectedDataSegmentString,
    inputContrSrcSysCd,
    inputPublicPri,
    inputMemberSelEffDate,
    inputMemberSelEndDate,
    inputFundArgt,
    inputMsbp
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(99);
    const [marketData, setMarketData] = useState([]);
    const [stateCountyData, setStateCountyData] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [employerGrpData, setEmployerGrpData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [fundArgtData, setFundArgtData] = useState([]);
    const [beneData, setBeneData] = useState([]);
    const [mbrSrcData, setMbrSrcData] = useState([]);
    const [cmsData, setCmsData] = useState([]);
    const [divPanelData, setDivPanelData] = useState([]);

    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [pri, setPri] = useState();
    const [memberSelEffDate, setMemberSelEffDate] = useState();
    const [memberSelEndDate, setMemberSelEndDate] = useState();
    const [fundArgt, setFundArgt] = useState();
    const [msbp, setMsbp] = useState();

    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName])
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber])
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd])
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId])
    useEffect(() => {
        setContractSublineID(inputContractSublineID);
    }, [inputContractSublineID])
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd])
    useEffect(() => {
        setPri(inputPublicPri);
    }, [inputPublicPri])
    useEffect(() => {
        setMemberSelEffDate(inputMemberSelEffDate);
    }, [inputMemberSelEffDate])
    useEffect(() => {
        setMemberSelEndDate(inputMemberSelEndDate);
    }, [inputMemberSelEndDate])
    useEffect(() => {
        setFundArgt(inputFundArgt);
    }, [inputFundArgt])
    useEffect(() => {
        setMsbp(inputMsbp);
    }, [inputMsbp])

    useEffect(() => {
        if (contrSrcSysCd && contractNumber && contractLineId && contractSublineID && pri && memberSelEffDate && memberSelEndDate) {
            const requestBody = {
                "CONTR_SRC_SYS_CD": contrSrcSysCd,
                "CONTR_ID": contractNumber,
                "CONTR_LN_ID": contractLineId,
                "CONTR_SUBL_ID": contractSublineID,
                "PRDCT_RDY_CD": pri,
                "MBR_SEL_EFF_DT": memberSelEffDate,
                "MBR_SEL_END_DT": memberSelEndDate
            }
            providerContractMemberDetailApi(requestBody).then(response => {
                try {
                    setMarketData(response.data['market_ipa'] === undefined ? [] : response.data['market_ipa']);
                    setStateCountyData(response.data['state_county'] === undefined ? [] : response.data['state_county']);
                    setProviderData(response.data['provider'] === undefined ? [] : response.data['provider']);
                    setEmployerGrpData(response.data['employer_grp'] === undefined ? [] : response.data['employer_grp']);
                    setProductData(response.data['product'] === undefined ? [] : response.data['product']);
                    setFundArgtData(fundArgt === 'None' ? [] : [fundArgt]);
                    setBeneData(response.data['bene_rider'] === undefined ? [] : response.data['bene_rider']);
                    setMbrSrcData(response.data['mbr_src'] === undefined ? [] : response.data['mbr_src']);
                    setCmsData(response.data['cms'] === undefined ? [] : response.data['cms']);
                    setDivPanelData(response.data['div_panel_network'] === undefined ? [] : response.data['div_panel_network'])
                } catch (error) {
                    console.log('provider contract member details did not return valid response', error);
                }
            }).catch(error => {
                console.log('Error with providerContractMemberDetailApi', error);
            });
        }
    }, [contrSrcSysCd, contractNumber, contractLineId, contractSublineID, pri, memberSelEffDate, memberSelEndDate]);

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {contractTypeCd} | Calc Method: {calcMethCd} | MSBP Ind: {msbp}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {contractNumber} | Member Selection Effective Date: {yyyymmddToMmddyyyy(memberSelEffDate)}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => regularLink()}>Member Selection Criteria</a> {">"} Selection Criteria Detail
            </Typography>
            {(marketData.length > 0 || stateCountyData.length > 0 || providerData.length > 0 || employerGrpData.length > 0 || productData.length > 0 || fundArgtData.length > 0 || beneData.length > 0 || mbrSrcData.length > 0 || cmsData.length > 0 || divPanelData.length > 0) ?
                <div style={{ height:'70vh', overflow: 'scroll', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={6} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>———  M A R K E T   I P A  ———</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Mkt Type</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Mkt Type I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Mkt#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Mkt# I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>IPA</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>IPA I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {marketData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'marketData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MKT_TYP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MRK_TYP_INXCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MKT_NBR}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MBR_NBR_INXCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.IPA_NBR}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.IPA_NBR_INXCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={8} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>———— S T A T E    C O U N T Y ————</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Geo Type</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>State</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>State I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>County</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>County I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Zip</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Zip I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Zip Ext</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stateCountyData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'stateCountyData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.GEO_TYP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ST_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ST_INCL_XCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CNTY_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CNTY_INCL_XCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ZIP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ZIP_INCL_XCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ZIP_EXT_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={8} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>PROVIDER</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Tin</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Tin I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Tin Type</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Seq #</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Seq # I/E</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>ID</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Eff Date</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {providerData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'providerData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROV_TAX_ID_NBR}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.TAX_INXCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROV_TAX_ID_TYP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROV_ADR_SEQ_NBR}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ADR_INXCLD_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROV_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.MBR_SEL_EFF_DT)}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={4} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>EMPLOYER GRP</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Cov Sys Code</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Code</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Eff Date</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {employerGrpData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'employerGrpData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.COV_SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ER_GRP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.MBR_SEL_EFF_DT)}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={5} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>CMS CONTRACT</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contr ID</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>PBP</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Segment</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Eff Date</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cmsData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'cmsData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CMS_CONTR_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PLN_BEN_PCK_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PBP_SEG_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MBR_SEL_EFF_DT}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={5} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>DIV/PANEL/NETWORK</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Div</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Panel</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Network</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Eff Date</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {divPanelData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'divPanelData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.DIV_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PNL_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.NTWK_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MBR_SEL_EFF_DT}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={2} style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>PRODUCT</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Code</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'employerGrpData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PRDCT_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={1} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'center' }}>FUND ARGT</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        {/* No header for this table */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fundArgtData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'fundArgtData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ width: '3000px', margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={2} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'center' }}>BENE RIDER</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Code</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {beneData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'beneData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.BEN_RIDE_TYP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ width: '3000px', margin: '10px' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>  {/* Added size="small" */}
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell colSpan={2} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'center' }}>MBR SRC</TableCell>
                                    </TableRow>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Code</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>I/E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mbrSrcData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={'mbrSrcData_' + index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.MBR_SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.INCL_XCLD_CD}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                :
                <LinearProgress sx={{ mt: '10px' }} />}
        </div>
    );
};
