import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MemberDetailsApi } from "./MemberDetailsApi";
import { useLocation } from "react-router-dom";

// A cache object outside of your component to store API responses
const apiCache = new Map();

export const MemberDetails = ({ memberData, switchTabs }) => {
  const rowColors = ["#b0c0da", "#ffffff"];

  const [cl1, setCl1] = useState({
    "Member #": "",
    "Member Suffix": "",
    "Medicare ID": "",
    Name: "",
    Age: "",
    Gender: "",
    "Cap Period": "",
    "Process Month": "",
    "CMS Contract ID": "",
    PBP: "",
    "CMS Geo Code": "",
    "Health Status Indicator": "",
    "RA Factor Score": "",
    "Geo Code": "",
    "Employer Group #": "",
    "Benefit Plan ID": "",
    "Elig. Start Date": "",
    "Elig. End Date": "",
    "Funding Arrangement": "",
    "County Name": "",
    "County Code": "",
  });
  const [cl2, setCl2] = useState({
    "Legal Entity": "",
    "Cap Product Category": "",
    "Regulatory Segment": "",
    "Major Shared Arrangement": "",
    MPIN: "",
    "Provider Name": "",
    "Begin Effective Date": "",
    "End Effective Date": "",
    "Payment MPIN": "",
    "Assoc Group": "",
  });
  const [cl3, setCl3] = useState({
    "CMS MMR Premium Amount": "",
    "Part D Buy-Down": "",
    "User Fee": "",
    Sequestration: "",
    "Premium Tax": "",
    "Premium Adjustment 1": "",
    "Premium Adjustment 2": "",
    "Premium Adjustment 3": "",
    "Gross Cap": "",
    "Per Capita Code": "",
    "Fixed Adj Type Code": "",
    "Reimbursement Type Code": "",
    "POP/Base Rate": "",
    "Age/Sex Factor": "",
    "Benefit Factor": "",
    "Co-Pay Factor": "",
    "Standard Service Cap": "",
    "Adjustment Cap": "",
    "Total Cap": "",
  });

  const formatDate = (dateStr) => {  
    if (dateStr === "") {  
      return ""; // Return empty string if dateStr is empty  
    }  
    
    const [year, month, day] = dateStr.split("-").map(Number); // Convert to numbers  
    if (isNaN(year) || isNaN(month) || isNaN(day)) {  
      return ""; // Return empty string if any part of the date is NaN  
    }  
    
    let newDate = `${month}/${day}/${year}`;  
    return newDate;  
  };  
  

  const formatMemberDetailResponse = (value) => {
    if (value === null || value === "None" || value.trim() === "") {
      return "$0.00";
    } else {
      // Assuming value is a number or a string that can be converted to a number
      // Format the number as a currency string with 2 decimal places

      const numberValue = parseFloat(value);
      return `$${numberValue.toFixed(2)}`;
    }
  };

  const getValueOrDefault = (value) => {
    // Check for both null and undefined explicitly
    if (value === null || value === undefined || value === "None") {
      return "";
    }
    return value;
  };

  function getAge(number) {  
    // Convert the number to a string  
    const ageMonth = number.toString();  
    const age = ageMonth.slice(0, 2);  
    return age;  
  } 

  const getFixedAdjTypeCode = (code) => {
    // Check if code is strictly null or strictly undefined
    return code === null || code === undefined || code === "None" ? 0 : code;
  };

  const formatDecimals = (amountString) => {
    // Attempt to convert the string to a floating-point number
    const amount = parseFloat(amountString);

    // Check if the conversion was successful and the result is a finite number
    if (!isNaN(amount) && isFinite(amount)) {
      // Format to 4 decimal places and return the resulting string
      return amount.toFixed(4);
    }

    // If conversion failed or resulted in Infinity, handle as needed
    // For example, return '0.0000' or an empty string or any other default value
    return "0.0000";
  };

  function goSomewhereElse() {
    console.log("go somewhere else");
    switchTabs(4);
  }

  function goSomewhereElse1() {
    console.log("go somewhere else");
    switchTabs(2);
  }

  useEffect(() => {
    // Define the parameters for the API call

    if (!memberData) {
      return; // Do nothing if memberData is not available
    }
    // Now you can use the destructured variables from location.state here
    const {
      DATA_SEG_ID,
      ECAP_MBR_KEY,
      ECAP_CONTR_LN_KEY,
      PROC_YR_MO,
      CAP_PRD_YR_MO,
      MBR_ALT_ID,
    } = memberData;

    const cacheKey = `${memberData.DATA_SEG_ID}-${memberData.ECAP_MBR_KEY}-${memberData.ECAP_CONTR_LN_KEY}-${memberData.PROC_YR_MO}-${memberData.CAP_PRD_YR_MO}`;

    if (apiCache.has(cacheKey)) {
      const cachedData = apiCache.get(cacheKey);
      setCl1(cachedData.cl1);
      setCl2(cachedData.cl2);
      setCl3(cachedData.cl3);
    } else {
      // Call your API with the parameters from the location state
      MemberDetailsApi(
        DATA_SEG_ID,
        ECAP_MBR_KEY,
        ECAP_CONTR_LN_KEY,
        PROC_YR_MO,
        CAP_PRD_YR_MO
      )
        .then((response) => {
          // Assuming there's only one item in the response array and it's the one you want
          const data = response.data[0];

          // Now you need to decide how to split this data into cl1, cl2, and cl3
          // For example, you could do something like this:

          const cl1Data = {
            "Member #": (
              <a
                href='#'
                onClick={() => goSomewhereElse()}
              >
                {MBR_ALT_ID}
              </a>
            ),
            "Member Suffix": data.MBR_ID_SUFX === "0" ? "00" : data.MBR_ID_SUFX,
            "Medicare ID": data.HIC_ID,
            Name: `${data.LST_NM}, ${data.FST_NM}`,
            Age: getAge(data.AGE),
            Gender: data.GDR_CD,
            "Cap Period": data.CAP_PRD_YR_MO,
            "Process Month": data.PROC_YR_MO,
            "CMS Contract ID": data.CMS_CONTR_ID,
            PBP: data.PLN_BEN_PCK_ID,
            "CMS Geo Code": data.ST_CNTY_CD,
            "Health Status Indicator": data.HEALTH_STATUS_INDICATOR,
            "RA Factor Score": data.RISK_ADJ_SCOR_FCT,
            "Geo Code": data.ST_CD,
            "Employer Group #": data.POL_ID,
            "Benefit Plan ID": data.CAP_BEN_PLN_ID,
            "Elig. Start Date": formatDate(data.MBR_ELIG_EFF_DT),
            "Elig. End Date": formatDate(data.MBR_ELIG_END_DT),
            "Funding Arrangement": data.FUND_ARNG_CD,
            "County Name": data.COUNTY_NM,
            "County Code": data.COUNTY_CD,
            // ... other fields for cl1
          };

          const cl2Data = {
            "Legal Entity": getValueOrDefault(data.LGL_ENTY_ID),
            "Cap Product Category": data.ECAP,
            "Regulatory Segment": data.ECAP_RGLTRY_SEG_CD,
            "Major Shared Arrangement": data.ECAP_M_SHR_ARNG_CD,
            MPIN: data.CORP_MPIN,
            "Provider Name": `${data.PROV_FST_NM}, ${data.PROV_LST_NM}`,
            "Begin Effective Date": formatDate(data.MBR_PCP_EFF_DT),
            "End Effective Date": formatDate(data.MBR_PCP_END_DT),
            "Payment MPIN": data.PAY_TO_PROV_ID,
            "Assoc Group": data.ASSOC_MPIN,
            // ... other fields for cl2
          };

          const cl3Data = {
            "CMS MMR Premium Amount": `$${data.CMS_MMR_PREM_AMT}`,
            "Part D Buy-Down": formatMemberDetailResponse(data.PARTDBUYDOWN),
            "User Fee": formatMemberDetailResponse(data.USER_FEE),
            Sequestration: formatMemberDetailResponse(data.SEQUESTRATION),
            "Premium Tax": formatMemberDetailResponse(data.PREMIUM_TAX),
            "Premium Adjustment 1": formatMemberDetailResponse(data.PREM_ADJ_1),
            "Premium Adjustment 2": formatMemberDetailResponse(data.PREM_ADJ_2),
            "Premium Adjustment 3": formatMemberDetailResponse(data.PREM_ADJ_3),
            "Gross Cap": formatMemberDetailResponse(data.NET_PREM_AMT),
            "Per Capita Code": data.PER_CPTA_CD,
            "Fixed Adj Type Code": getFixedAdjTypeCode(data.FIX_ADJ_TYP_CD),
            "Reimbursement Type Code": data.REIM_TYP_CD,
            "POP/Base Rate": `${data.BAS_PCT}%`,
            "Age/Sex Factor": formatDecimals(data.GDR_FCT_AMT),
            "Benefit Factor": formatDecimals(data.BEN_FCT),
            "Co-Pay Factor": formatDecimals(data.COPAY_FCT_AMT),
            "Standard Service Cap": formatMemberDetailResponse(data.SRVCCAP),
            "Adjustment Cap": formatMemberDetailResponse(data.ADJCAP),
            "Total Cap": <a href='#' onClick={() => goSomewhereElse1()}>{formatMemberDetailResponse(data.TOTAL_CAP)}</a>,
          };

          // Update the cache with the new data
          apiCache.set(cacheKey, { cl1: cl1Data, cl2: cl2Data, cl3: cl3Data });
          // Update the state with the new data
          setCl1(cl1Data);
          setCl2(cl2Data);
          setCl3(cl3Data);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the member details:",
            error
          );
        });
    }
  }, [memberData]); // Dependency array is empty, so this effect runs only once on component mount

  const tableCellStyle = {
    fontSize: "0.875rem", // Adjust the font size as needed
    padding: "6px 10px", // Adjust the padding as needed
  };

  return (
    <>
      <Typography sx={{ fontSize: "0.875rem" }}>
        Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
        Member ID:
        {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableBody>
                  {Object.entries(cl1).map(([key, value], index) => (
                    <TableRow
                      key={key}
                      style={{ backgroundColor: rowColors[index % 2] }}
                    >
                      <TableCell sx={tableCellStyle}>{key}</TableCell>
                      <TableCell
                        name={key}
                        sx={tableCellStyle}
                      >
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableBody>
                  {Object.entries(cl2).map(([key, value], index) => (
                    <TableRow
                      key={key}
                      style={{ backgroundColor: rowColors[index % 2] }}
                    >
                      <TableCell sx={tableCellStyle}>{key}</TableCell>
                      <TableCell
                        name={key}
                        sx={tableCellStyle}
                      >
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack spacing={0.5}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableBody>
                  {Object.entries(cl3).map(([key, value], index) => (
                    <TableRow
                      key={key}
                      style={{ backgroundColor: rowColors[index % 2] }}
                    >
                      <TableCell sx={tableCellStyle}>{key}</TableCell>
                      <TableCell
                        name={key}
                        sx={tableCellStyle}
                      >
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
