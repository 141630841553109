import axios from "axios";
const PREMIUM_VIEW_DOWNLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/reports/premium/view_download`;

export const premiumViewDownloadApi = (requestBody) => {
    return axios.post(
        PREMIUM_VIEW_DOWNLOAD_ENDPOINT, 
        requestBody,
    {
        responseType: 'blob',
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    })
}