import axios from 'axios';
const DATA_SEGMENT_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;

export const DataSegmentApi = () => {
    return axios.get(
      DATA_SEGMENT_ENDPOINT,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      }
    );
  };



