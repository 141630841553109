import { useEffect, useState } from 'react';

import { ReportsTable1 } from '../ReportsTable1';

function createData(reportID, reportName) {
    return { reportID, reportName};
}

const rows = [
    createData('EC0500EC', 'FORCED CAPITATION / REPROCESS - PROVIDER GROUPS'),
    createData('EC0510EC', 'FORCED CAPITATION / REPROCESS - CUSTOMER GROUPS / BENEFIT PLANS'),
    createData('EC0520EC', 'FORCED CAPITATION / REPROCESS - MEMBERS'),
    createData('EC0530EC', 'FORCED CAPITATION / REPROCESS SUMMARY'),
];

const viewButtonV = [false, false, false, true];
const downloadButtonV = [true, true, true, false];

export const ReportsECapProcess = ({inputDSeg}) => {
    // get and update data segment options
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        setDataSegments(inputDSeg);
    }, [inputDSeg]);

    return (
        <ReportsTable1 rows={rows} inputDSeg={dataSegments} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV}/>
    );
};
