import axios from 'axios';  
const PROVIDER_HISTORY_PROVIDERDETAILS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/history/provider_detail`;
  
// SearchHandler.js  
 
  export const ProviderDetailApi = (requestBody) => {

    console.log("request body is",requestBody);

  return axios.post(
    PROVIDER_HISTORY_PROVIDERDETAILS_ENDPOINT,
    requestBody,

		{
       
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
			},
     
			validateStatus: function (status) {
				return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx      
			},
		}
	)
		.then(response => {
			// console.log(response); // This will print the entire response object    
			// console.log(response.status); // This will print the status code    
			// console.log(response.data); // This will print the response data    

			// Return the response so it can be used in handleButtonClick  
			return response;
		})
		.catch(error => {
			console.error(error);
		});
};

