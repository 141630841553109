import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';


export const jsonInfo = [
    {
        OWNR_PROV_ID: "8454213",
        CONTR_ID: "4377",
        CONTR_LN_ID: "1",
        ASSOC_CONTR_ID: "0",
        PROC_YR_MO: "202307",
        CAP_PRD_YR_MO: "202303",
        STD_SRVC_ADD_AMT: "$0.00",
        STD_SRVC_TERM_AMT: "$0.00",
        CALCULATED_FIELD_1: "$0.00",
        CALCULATED_FIELD_2: "$0.00",
        TOTAL_CAP:"$0",
        TOTAL_ADJ_AMT: "$213.78",
    },

];

export const footerData = {
    OWNR_PROV_ID: "",
    CONTR_ID: "",
    CONTR_LN_ID: "",
    ASSOC_CONTR_ID: "",
    PROC_YR_MO: "",
    CAP_PRD_YR_MO: "Total",
    STD_SRVC_ADD_AMT: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.STD_SRVC_ADD_AMT.substring(1)), 0)).toFixed(2),
    STD_SRVC_TERM_AMT: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.STD_SRVC_TERM_AMT.substring(1)), 0)).toFixed(2),
    CALCULATED_FIELD_1: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.CALCULATED_FIELD_1.substring(1)), 0)).toFixed(2),
    CALCULATED_FIELD_2: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.CALCULATED_FIELD_2.substring(1)), 0)).toFixed(2),
    TOTAL_CAP: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.TOTAL_CAP.substring(1)), 0)).toFixed(2),
    TOTAL_ADJ_AMT: `$` + parseFloat(jsonInfo.reduce((r, d) => r + parseFloat(d.TOTAL_ADJ_AMT.substring(1)), 0)).toFixed(2),

};


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b0c0da",
        //paddingLeft: "2px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StripedTableBody = styled(TableBody)(({ theme }) => ({
    '& tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '& tr:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));
