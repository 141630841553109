import counterSlice from "./slices/counterSlice";
import userInputSlice from "./slices/userInputSlice";
import { combineReducers } from "redux";

const memberSearchTabReducer = combineReducers({
  counter: counterSlice.reducer,
  userInput: userInputSlice.reducer,
});

export default memberSearchTabReducer;
