  import axios from 'axios';  
  const PROVIDER_HISTORY_ADJUSTMENTS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/history/adjustments`;
  
// SearchHandler.js  
export const HistorydjustmentsApi = (  
  PROC_YR_MO,
  CONTR_ID,
  CONTR_LN_ID,
  PAY_PARTN_ID,
  CONTR_PARTN_ID,
  ASSOC_PROV_ID

 ) => {
  // Prepare request body  
  const requestBody = {  
    PROC_YR_MO: PROC_YR_MO,
    CONTR_ID: CONTR_ID,
    CONTR_LN_ID:  CONTR_LN_ID,
    PAY_PARTN_ID: PAY_PARTN_ID,
    CONTR_PARTN_ID: CONTR_PARTN_ID,
    ASSOC_PROV_ID: ASSOC_PROV_ID
  };  
  

  return axios.post(  
    PROVIDER_HISTORY_ADJUSTMENTS_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
}; 

