import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Grid,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { formatDate, formatDateTime } from "../../utils/formatFunctions";
import CustInfo from "../CustInfo";
import { CSVLink } from "react-csv";
import { compareDownloadHeader } from "./downloadHeaders";
import { compareTimelineMerger } from "../../utils/filterFunctions";
import moment from "moment";

const CompareTable = forwardRef(({ columns, data }, ref) => {
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "40vh" }}>
      <Table ref={ref}>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{
                    minWidth: column.minWidth,
                    padding: "4px",
                  }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
export const ComparePopUp = (props) => {
  const columns = [
    {
      Header: "Begin",
      accessor: "begin",
      format: (cell) => formatDate(cell),
      minWidth: 100,
    },
    {
      Header: "End",
      accessor: "end",
      format: (cell) => formatDate(cell),
      minWidth: 100,
    },
    {
      Header: "Cov",
      accessor: "cov",
      minWidth: 50,
    },
    {
      Header: "PCP MPIN",
      accessor: "pcpMpin",
      minWidth: 100,
    },
    {
      Header: "Address",
      accessor: "address",
      minWidth: 75,
    },
    {
      Header: "Tax Id",
      accessor: "taxId",
      minWidth: 75,
    },
    {
      Header: "IPA",
      accessor: "ipa",
      minWidth: 100,
    },
    {
      Header: "Market",
      accessor: "market",
      minWidth: 50,
    },
    {
      Header: "Market Type",
      accessor: "mktType",
      minWidth: 100,
    },
    {
      Header: "PCP State/ Zip",
      accessor: "pcpStateZip",
      minWidth: 125,
    },
    {
      Header: "PCP County",
      accessor: "pcpCountry",
      minWidth: 100,
    },
    {
      Header: "Member State/ Zip",
      accessor: "memberStateZip",
      minWidth: 150,
    },
    {
      Header: "Cov System",
      accessor: "covSystem",
      minWidth: 125,
    },
    {
      Header: "Policy",
      accessor: "policy",
      minWidth: 150,
    },
    {
      Header: "PV / RC",
      accessor: "pvrc",
      minWidth: 75,
    },
    {
      Header: "Copays (Off/Er)",
      accessor: "copays",
      minWidth: 125,
    },
    {
      Header: "State of Issue",
      accessor: "stateOfIssue",
      minWidth: 125,
    },
    {
      Header: "PCP DIV",
      accessor: "pcpDiv",
      minWidth: 100,
    },
    {
      Header: "PCP PANEL",
      accessor: "pcpPanel",
      minWidth: 100,
    },
    {
      Header: "PCP Network",
      accessor: "pcpNetwork",
      minWidth: 100,
    },
    {
      Header: "SRC SYS PROV ID",
      accessor: "srcsycprovId",
      minWidth: 150,
    },
    {
      Header: "Medicare ID",
      accessor: "medicareId",
      minWidth: 100,
    },
    //   {
    //     Header: "Medicaid ID",
    //     accessor: "medicaidId",
    //   },
    {
      Header: "CMS Contract ID",
      accessor: "cmsContractId",
      minWidth: 125,
    },
    {
      Header: "PBP",
      accessor: "pbp",
      minWidth: 100,
    },
    {
      Header: "Mbr/Emp Prem Amnt",
      accessor: "mbrEmpPremAmt",
      minWidth: 150,
    },
  ];
  const compareData = props.data;
  for (var i = 0; i < compareData.length; i++) {
    if (compareData[i].pvrc === " / ") {
      compareData[i].pvrc = "";
    }
    if (compareData[i].pbp == 0) {
      compareData[i].pbp = "";
    }
  }
  const transData = props.transData;
  const custInfo = props.memberDetailResult.customerInformation;
  const segementName = props.segmentName;
  const mergedTimelines = compareTimelineMerger(
    segementName,
    compareData,
    custInfo
  );
  const firstMtcn = transData[0]["mtcn"];
  const firstTransTime = formatDateTime(transData[0]["processed"]);
  const firstTransCount = transData[0]["transactionCount"];
  const [mtcnFromListOne, setmtcnFromListOne] = useState(firstMtcn.toString());
  const [transTimeOne, settransTimeOne] = useState(firstTransTime);
  const [transCountOne, settransCountOne] = useState(firstTransCount);
  const [mtcnFromListTwo, setmtcnFromListTwo] = useState(firstMtcn.toString());
  const [transTimeTwo, settransTimeTwo] = useState(firstTransTime);
  const [transCountTwo, settransCountTwo] = useState(firstTransCount);
  const [loading, setLoading] = useState(true);
  const mtcnArray = [];
  for (var itr = 0; itr < transData.length; itr++) {
    mtcnArray.push(transData[itr]["mtcn"]);
  }
  const getOptions = (timelines) => {
    const allValidMtcn = [];
    for (let i = 0; i < timelines.length; i++) {
      const mtcn = timelines[i].mtcn;
      allValidMtcn.push(mtcn);
    }
    return allValidMtcn.map((mtcn) => {
      return <option value={mtcn}>{mtcn}</option>;
    });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "mtcnOne") {
      var proc;
      var count;
      for (let i = 0; i < transData.length; i++) {
        const mtcn = transData[i].mtcn;
        if (value === mtcn.toString()) {
          proc = formatDateTime(transData[i].processed);
          count = transData[i].transactionCount;
        }
      }
      setmtcnFromListOne(value);
      settransCountOne(count);
      settransTimeOne(proc);
    } else if (id === "mtcnTwo") {
      var proc2;
      var count2;
      for (let i = 0; i < transData.length; i++) {
        const mtcn = transData[i].mtcn;
        if (value === mtcn.toString()) {
          proc2 = formatDateTime(transData[i].processed);
          count2 = transData[i].transactionCount;
        }
      }
      setmtcnFromListTwo(value);
      settransCountTwo(count2);
      settransTimeTwo(proc2);
    }
  };
  const filterTimeline = (timelines, mtcn) => {
    // console.log(timelines);
    var resultTimelines = [];
    for (let i = 0; i < timelines.length; i++) {
      if (mtcn === timelines[i].mtcn.toString())
        resultTimelines.push(timelines[i]);
    }
    const sortedtimelines = []
      .concat(resultTimelines)
      .sort((a, b) => (moment(a.begin).isBefore(b.begin) ? 1 : -1));
    resultTimelines = sortedtimelines;
    return resultTimelines;
  };
  const getCombinedFile = (filteredTimelines1, filteredTimelines2) => {
    const resultArray = [];
    for (let i = 0; i < filteredTimelines1.length; i++) {
      resultArray.push(filteredTimelines1[i]);
    }

    for (let i = 0; i < filteredTimelines2.length; i++) {
      resultArray.push(filteredTimelines2[i]);
    }
    return resultArray;
  };
  // console.log(mergedTimelines);
  var downloadFile1 = {
    data: filterTimeline(mergedTimelines, mtcnFromListOne),
    headers: compareDownloadHeader,
    filename: "Compare_download_" + mtcnFromListOne + ".csv",
  };
  var downloadFile2 = {
    data: filterTimeline(mergedTimelines, mtcnFromListTwo),
    headers: compareDownloadHeader,
    filename: "Compare_download_" + mtcnFromListTwo + ".csv",
  };
  var combinedDownloadFile = {
    data: getCombinedFile(
      filterTimeline(mergedTimelines, mtcnFromListOne),
      filterTimeline(mergedTimelines, mtcnFromListTwo)
    ),
    headers: compareDownloadHeader,
    filename:
      "Compare_Combined_download_" +
      mtcnFromListOne +
      "_" +
      mtcnFromListTwo +
      ".csv",
  };
  // below piece of code is written on css level grabbing each component on css level and adding css class
  const table1Ref = useRef(null);
  const table2Ref = useRef(null);
  const clearRows = (rows) => {
    for (let i = 1; i < rows.length; i++) {
      rows[i].style.backgroundColor = "";
      const cells = rows[i].cells;
      for (let j = 0; j < cells.length; j++) {
        cells[j].style.backgroundColor = "";
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      const table1 = table1Ref.current;
      const table2 = table2Ref.current;

      const timelines1 = filterTimeline(mergedTimelines, mtcnFromListOne);
      const timelines2 = filterTimeline(mergedTimelines, mtcnFromListTwo);

      const table1Length = timelines1.length;
      const table2Length = timelines2.length;

      clearRows(table1.querySelectorAll("tr"));
      clearRows(table2.querySelectorAll("tr"));

      let table1Index = 1;
      let table2Index = 1;
      console.log(table1, table2);
      console.log(table1.rows[1], table2.rows[1]);
      while (table1Index <= table1Length && table2Index <= table2Length) {
        const table1Begin = table1.rows[table1Index].cells[0].innerText;
        const table2Begin = table2.rows[table2Index].cells[0].innerText;
        console.log(table1, table2);
        if (table1Begin === table2Begin) {
          const table1Cells = table1.rows[table1Index].cells;
          const table2Cells = table2.rows[table2Index].cells;

          for (let i = 0; i < table1Cells.length; i++) {
            if (table1Cells[i].innerText !== table2Cells[i].innerText) {
              table1Cells[i].style.backgroundColor = "yellow";
              table2Cells[i].style.backgroundColor = "yellow";
            }
          }

          table1Index += 1;
          table2Index += 1;
        } else if (moment(table1Begin).isAfter(table2Begin)) {
          table1.rows[table1Index].style.backgroundColor = "cyan";
          table1Index += 1;
        } else {
          table2.rows[table2Index].style.backgroundColor = "cyan";
          table2Index += 1;
        }
      }

      while (table1Index <= table1Length) {
        table1.rows[table1Index].style.backgroundColor = "cyan";
        table1Index += 1;
      }

      while (table2Index <= table2Length) {
        table2.rows[table2Index].style.backgroundColor = "cyan";
        table2Index += 1;
      }
    }
  }, [mtcnFromListOne, mtcnFromListTwo]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <h4>Current Information</h4>
          <CustInfo custInfo={custInfo} />
          <table>
            <tbody>
              <tr>
                <td>
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>MTCN: </b>
                  <select
                    id='mtcnOne'
                    name='mtcnsList'
                    value={mtcnFromListOne}
                    onChange={(e) => handleChange(e)}
                  >
                    {getOptions(transData)}
                  </select>
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>Transaction date: </b>
                  {transTimeOne}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>Transaction Count: </b>
                  {transCountOne}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <Button
                    variant='contained'
                    color='primary'
                  >
                    {" "}
                    <CSVLink
                      style={{ color: "white", textDecoration: "unset" }}
                      {...downloadFile1}
                    >
                      Download
                    </CSVLink>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          <CompareTable
            ref={table1Ref}
            columns={columns}
            data={filterTimeline(compareData, mtcnFromListOne)}
          />
          <table>
            <tbody>
              <tr>
                <td>
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>MTCN: </b>
                  <select
                    id='mtcnTwo'
                    name='mtcnsList'
                    value={mtcnFromListTwo}
                    onChange={(e) => handleChange(e)}
                  >
                    {getOptions(transData)}
                  </select>
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>Transaction date: </b>
                  {transTimeTwo}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>Transaction Count: </b>
                  {transCountTwo}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <Button
                    variant='contained'
                    color='primary'
                  >
                    {" "}
                    <CSVLink
                      style={{ color: "white", textDecoration: "unset" }}
                      {...downloadFile2}
                    >
                      Download
                    </CSVLink>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          <CompareTable
            ref={table2Ref}
            columns={columns}
            data={filterTimeline(compareData, mtcnFromListTwo)}
          />
          <table>
            <tbody>
              <tr>
                <td>
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <b>&emsp; &ensp;</b> {""}
                  <Button
                    variant='contained'
                    color='primary'
                  >
                    {" "}
                    <CSVLink
                      style={{ color: "white", textDecoration: "unset" }}
                      {...combinedDownloadFile}
                    >
                      Download Both in separate File
                    </CSVLink>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
export default ComparePopUp;
