// make sure we aren't returning unintended values
function verify(data) {
    if (data === undefined || data === null || data === 'null') {
        return undefined
    } else {
        return data
    }
}

// data for monthly processed
export function setMonthlyProcessed(data) {
    sessionStorage.setItem('errorsMonthlyProcessed', JSON.stringify(data));
}

export function getMonthlyProcessed() {
    return verify(JSON.parse(sessionStorage.getItem('errorsMonthlyProcessed')));
}

export function clearMonthlyProcessed() {
    sessionStorage.setItem('errorsMonthlyProcessed', null);
}

// data for monthly processed > error group by value
export function setMonthlyGroup(data) {
    sessionStorage.setItem('errorsMonthlyGroup', JSON.stringify(data));
}

export function getMonthlyGroup() {
    return verify(JSON.parse(sessionStorage.getItem('errorsMonthlyGroup')));
}

export function clearMonthlyGroup() {
    sessionStorage.setItem('errorsMonthlyGroup', null);
}

// data for monthly processed > error group by value > error detail list
export function setMonthlyList(data) {
    sessionStorage.setItem('errorsMonthlyList', JSON.stringify(data));
}

export function getMonthlyList() {
    return verify(JSON.parse(sessionStorage.getItem('errorsMonthlyList')));
}

export function clearMonthlyList() {
    sessionStorage.setItem('errorsMonthlyList', null);
}

// data for aging
export function setAging(data) {
    sessionStorage.setItem('errorsAging', JSON.stringify(data));
}

export function getAging() {
    return verify(JSON.parse(sessionStorage.getItem('errorsAging')));
}

export function clearAging() {
    sessionStorage.setItem('errorsAging', null);
}

// data for aging > aging detail
export function setAgingDetail(data) {
    sessionStorage.setItem('errorsAgingDetail', JSON.stringify(data));
}

export function getAgingDetail() {
    return verify(JSON.parse(sessionStorage.getItem('errorsAgingDetail')));
}

export function clearAgingDetail() {
    sessionStorage.setItem('errorsAgingDetail', null);
}

// data for daily interface
export function setDaily(data) {
    sessionStorage.setItem('errorsDaily', JSON.stringify(data));
}

export function getDaily() {
    return verify(JSON.parse(sessionStorage.getItem('errorsDaily')));
}

export function clearDaily() {
    sessionStorage.setItem('errorsDaily', null);
}

// data for daily interface > error list
export function setDailyList(data) {
    sessionStorage.setItem('errorsDailyList', JSON.stringify(data));
}

export function getDailyList() {
    return verify(JSON.parse(sessionStorage.getItem('errorsDailyList')));
}

export function clearDailyList() {
    sessionStorage.setItem('errorsDailyList', null);
}

// data for daily interface > error list > provider error detail
export function setDailyDetail(data) {
    sessionStorage.setItem('errorsDailyDetail', JSON.stringify(data));
}

export function getDailyDetail() {
    return verify(JSON.parse(sessionStorage.getItem('errorsDailyDetail')));
}

export function clearDailyDetail() {
    sessionStorage.setItem('errorsDailyDetail', null);
}

// general clearing functions
export function clearAfterErrorsMonthlyProcessed() {
    clearMonthlyGroup();
    clearMonthlyList();
}

export function clearAfterErrorsDaily() {
    clearDailyList();
    clearDailyDetail();
}

export function clearAllErrorsMonthly() {
    clearAfterErrorsMonthlyProcessed();
    clearMonthlyProcessed();
}

export function clearAllErrorsAging() {
    clearAgingDetail();
    clearAging();
}

export function clearAllErrorsDaily() {
    clearAfterErrorsDaily();
    clearDaily();
}

export function clearAllErrors() {
    clearAllErrorsMonthly();
    clearAllErrorsAging();
    clearAllErrorsDaily();
}