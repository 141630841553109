import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const AssociationSearchTable = ({ showTable, inputTableData, switchTabs }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);

      useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            console.log(inputTableData)
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (!showTable) {
        return null;
    }

    return (
        <Paper sx={{ paddingLeft: '0.2rem',boxShadow: 'none',width:'100%'  }}>
            <TableContainer component={Paper} style={{width:'100%'}}>
                <Table aria-label="simple table" stickyHeader style={{width:'100%'}}>
                    <TableHead style={{ backgroundColor: '#b0c0da' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                            <TableCell style={{ backgroundColor: '#b0c0da',textAlign: 'center', }}>Provider Argt</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Contract#</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Contract Line#</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>MPIN</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Assoc Contract</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Assoc Contract Line</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', }}>Assoc MPIN</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow key={row.CONTR_SRC_SYS_CD}>
                                        <TableCell style={{ textAlign: 'center' }}>{row.contract}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.contract_line}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.mpin}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.associate_contract}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.associate_contract_line }</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.associate_mpin}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.cap_process_month}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                    },
                }}
            />

        </Paper>
    );
};

export default AssociationSearchTable;  


