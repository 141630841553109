import axios from "axios";
const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/search`;
const MEMBER_ROLES_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/update`;
export const memberRolesAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_URL,
});
export var memberRolesJsonSchema = {
  role: [],
};
export const memberRolesUpdateAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_UPDATE_URL,
});
