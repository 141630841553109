import axios from "axios";

const PARAMETERS_UPLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/force_cap/upload`;

export const parametersUploadApi = (requestBody) => {
    return axios.post(
        PARAMETERS_UPLOAD_ENDPOINT, 
        requestBody,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    })
}