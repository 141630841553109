import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import {
    AppBar,
    Container,
    Toolbar,
    Paper,
    TextField,
    Grid,
    Typography,
    Link,
    Button,
    Box,
    Select,
    MenuItem,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio,
    Stepper,
    Step,
    StepLabel
} from '@mui/material';
import { Margin } from '@mui/icons-material';
import ProviderForm from './ProviderForm';
import MemberForm from './MemberForm';

export const ProviderMemberOptions = () => {

    const [selectedProviderMemberOption, setSelectedProviderMemberOption] = React.useState('');

    const handleCheckboxChange = (event) => {
        setSelectedProviderMemberOption(event.target.checked ? event.target.value : '');
    }


    return (

        <Box>
            <Box sx={{ my: 1 }}>
                <Typography component="legend">Provider/Member</Typography>
                <Grid container spacing={1} ml={.5}>
                    <FormGroup>
                        <Box display="flex" flexDirection="row">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedProviderMemberOption === 'provider'}
                                        onChange={handleCheckboxChange}
                                        value="provider"
                                    />
                                }
                                label="Provider"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedProviderMemberOption === 'member'}
                                        onChange={handleCheckboxChange}
                                        value="member"
                                    />
                                }
                                label="Member"
                            />
                        </Box>
                    </FormGroup>
                </Grid>
            </Box>
            {selectedProviderMemberOption === 'provider' && (
                <ProviderForm />
            )}

            {selectedProviderMemberOption === 'member' && (
                <MemberForm />
            )}
        </Box>
    );
};
export default ProviderMemberOptions;