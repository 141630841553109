import {
  formatDate,
  formatDateTime,
  giveDate,
  formatProcessed,
} from "./formatFunctions";
import moment from "moment";
export const timelineFilter = (timelines, startDate, endDate) => {
  const filteredTimelines = [];
  for (var i = 0; i < timelines.length; i++) {
    const timeline = timelines[i];
    const timelineProcessedDate = new Date(timeline.processed).getTime();
    if (
      timelineProcessedDate <= endDate &&
      timelineProcessedDate >= startDate
    ) {
      filteredTimelines.push(timeline);
    }
  }
  var sortedTimelines = []
    .concat(filteredTimelines)
    .sort(
      (a, b) =>
        +(a.mtcn < b.mtcn) ||
        +(a.mtcn === b.mtcn) - 1 ||
        +moment(a.begin).isBefore(b.begin) ||
        +(a.begin === b.begin) - 1
    );
  return sortedTimelines;
};
export const eligTimelineMerger = (
  eligArray,
  intMbrArray,
  custInfo,
  transMtcn
) => {
  const cmsContractIdMap = new Map();
  const mtcnProcessedMap = new Map();
  const name = custInfo.name;
  const sourceSystemMemberId = custInfo.memberId;
  const altId = custInfo.altID;
  const [first, middle, last] = name.split(" ");
  for (var itr = 0; itr < eligArray.length; itr++) {
    cmsContractIdMap.set(eligArray[itr].cmsContractID, eligArray[itr].pbp);
  }
  for (var itr = 0; itr < transMtcn.length; itr++) {
    mtcnProcessedMap.set(transMtcn[itr].mtcn, transMtcn[itr].processed);
  }
  const source = [...eligArray].shift().source;
  const resultArray = [];
  for (var itr = 0; itr < intMbrArray.length; itr++) {
    var timeline = intMbrArray[itr];
    var mtcn = timeline.mtcn;
    var processed = formatDateTime(mtcnProcessedMap.get(timeline.mtcn));
    var prdate = formatProcessed(processed);
    var covInd = timeline.cov;
    var begin = formatDate(timeline.begin);
    var end = formatDate(timeline.end);
    var covSrc = timeline.covSystem;
    var [policy, policyName] = timeline.policy.split(":");
    var [planVar, rptCd] = timeline.pvrc.split("/");
    var [ovc, erc] = timeline.copays.split("/");
    var stateOfIssue = timeline.stateOfIssue;
    var cmsContractID = timeline.cmsContractId;
    var cmsPbp = cmsContractIdMap.get(cmsContractID);
    if (cmsPbp == 0) cmsPbp = "";
    var premAmnt = timeline.mbrEmpPremAmt;
    const resultTimeLine = {
      sourceSystemMemberId: sourceSystemMemberId,
      altId: altId,
      last: last,
      first: first,
      middle: middle,
      processed: processed,
      prdate: prdate,
      mtcn: mtcn,
      covInd: covInd,
      begin: begin,
      end: end,
      covSrc: covSrc,
      policy: policy,
      policyName: policyName,
      planVar: planVar,
      rptCd: rptCd,
      ovc: ovc,
      erc: erc,
      stateOfIssue: stateOfIssue,
      cmsContractID: cmsContractID,
      cmsPbp: cmsPbp,
      cmsSegment: "",
      premAmnt: premAmnt,
      source: source,
    };
    resultArray.push(resultTimeLine);
  }
  // console.log(resultArray);
  return resultArray;
};
export const factTimelineMerger = (intMbrArray, custInfo, transMtcn) => {
  const name = custInfo.name;
  const sourceSystemMemberId = custInfo.memberId;
  const altId = custInfo.altID;
  const mtcnProcessedMap = new Map();
  const [first, middle, last] = name.split(" ");
  const resultArray = [];
  for (var itr = 0; itr < transMtcn.length; itr++) {
    mtcnProcessedMap.set(transMtcn[itr].mtcn, transMtcn[itr].processed);
  }
  for (var itr = 0; itr < intMbrArray.length; itr++) {
    var timeline = intMbrArray[itr];
    var mtcn = timeline.mtcn;
    var processed = formatDateTime(mtcnProcessedMap.get(timeline.mtcn));
    var prdate = formatProcessed(processed);
    var begin = formatDate(timeline.begin);
    var end = formatDate(timeline.end);
    var gender = timeline.gender;
    var rel = timeline.relationshipCd;
    var birthdate = formatDate(timeline.birthDate);
    var county = timeline.memberCountyCd;
    var countyName = timeline.memberCountyNm;
    var [state, zip] = timeline.memberStateZip.split("/");
    var medicareId = timeline.medicareId;
    var hospiceIndic = timeline.hospiceInd;
    const resultTimeLine = {
      sourceSystemMemberId: sourceSystemMemberId,
      altId: altId,
      last: last,
      first: first,
      middle: middle,
      processed: processed,
      prdate: prdate,
      mtcn: mtcn,
      begin: begin,
      end: end,
      gender: gender,
      rel: rel,
      birthdate: birthdate,
      county: county,
      countyName: countyName,
      state: state,
      zip: zip,
      medicareId: medicareId,
      hospiceIndic: hospiceIndic,
    };
    resultArray.push(resultTimeLine);
  }
  // console.log(resultArray);
  return resultArray;
};
export const pcpTimelineMerger = (
  timelineArray,
  custInfo,
  pcpArray,
  transMtcn
) => {
  const resultArray = [];
  const name = custInfo.name;
  const sourceSystemMemberId = custInfo.memberId;
  const altId = custInfo.altID;
  const [first, middle, last] = name.split(" ");
  const pcpNameMap = new Map();
  const mtcnProcessedMap = new Map();
  for (var itr = 0; itr < pcpArray.length; itr++) {
    pcpNameMap.set(pcpArray[itr].mpin, pcpArray[itr].name);
  }
  for (var itr = 0; itr < transMtcn.length; itr++) {
    mtcnProcessedMap.set(transMtcn[itr].mtcn, transMtcn[itr].processed);
  }
  for (var itr = 0; itr < timelineArray.length; itr++) {
    const timeline = timelineArray[itr];
    var mtcn = timeline.mtcn;
    var processed = formatDateTime(
      formatDateTime(mtcnProcessedMap.get(timeline.mtcn))
    );
    var prdate = formatProcessed(processed);
    var begin = formatDate(timeline.begin);
    var cov = timeline.cov;
    var end = formatDate(timeline.end);
    var mpin = timeline.pcpMpin;
    var address = timeline.address;
    var [pcpState, pcpZip] = timeline.pcpStateZip.split("/");
    var pcpCounty = timeline.pcpCountry;
    var marketType = timeline.mktType;
    var marketNumber = timeline.market;
    var ipa = timeline.ipa;
    var pcpDiv = timeline.pcpDiv;
    var pcpPanel = timeline.pcpPanel;
    var pcpStateZip = timeline.pcpStateZip;
    var pcpNetwork = timeline.pcpNetwork;
    var srcsycprovId = timeline.srcsycprovId;
    var pcpTin = "";
    if (timeline.taxIdType === "TIN") pcpTin = timeline.taxId;
    var pcpName = "";
    if (pcpNameMap.has(mpin)) pcpName = pcpNameMap.get(mpin);
    const resultTimeLine = {
      sourceSystemMemberId: sourceSystemMemberId,
      altId: altId,
      last: last,
      first: first,
      middle: middle,
      processed: processed,
      prdate: prdate,
      mtcn: mtcn,
      begin: begin,
      end: end,
      pcpTin: pcpTin,
      pcpMpin: mpin,
      address: address,
      name: pcpName,
      pcpState: pcpState,
      pcpCountry: pcpCounty,
      pcpZip: pcpZip,
      mktType: marketType,
      market: marketNumber,
      ipa: ipa,
      pcpDiv: pcpDiv,
      pcpPanel: pcpPanel,
      pcpNetwork: pcpNetwork,
      srcsycprovId: srcsycprovId,
      pcpStateZip: pcpStateZip,
      cov: cov,
    };
    resultArray.push(resultTimeLine);
  }
  return resultArray;
};
export const compareTimelineMerger = (segmentName, intMbrArray, custInfo) => {
  const resultArray = [];
  const name = custInfo.name;
  const sourceSystemMemberId = custInfo.memberId;
  const altId = custInfo.altID;
  const [first, middle, last] = name.split(" ");
  const medicaidId = custInfo.medicaidId;
  const medicareId = custInfo.medicareId;
  const dataSeg = segmentName;
  for (var itr = 0; itr < intMbrArray.length; itr++) {
    const timeline = intMbrArray[itr];
    var begin = formatDate(timeline.begin);
    var cov = timeline.cov;
    var mtcn = timeline.mtcn;
    var processed = formatDateTime(timeline.processed);
    var prdate = giveDate(processed);
    var end = formatDate(timeline.end);
    var mpin = timeline.pcpMpin;
    var address = timeline.address;
    var pcpStateZip = timeline.pcpStateZip;
    var pcpCounty = timeline.pcpCountry;
    var marketType = timeline.mktType;
    var market = timeline.market;
    var ipa = timeline.ipa;
    var pcpPanel = timeline.pcpPanel;
    var pcpNetwork = timeline.pcpNetwork;
    var srcsycprovId = timeline.srcsycprovId;
    var taxId = timeline.taxId;
    var memberStateZip = timeline.memberStateZip;
    var covSystem = timeline.covSystem;
    var policy = timeline.policy;
    var pvrc = timeline.pvrc;
    var copays = timeline.copays;
    var stateOfIssue = timeline.stateOfIssue;
    var pcpDiv = timeline.pcpDiv;
    var cmsContractId = timeline.cmsContractId;
    var pbp = timeline.pbp;
    var cmsSegment = "";
    var mbrEmpPremAmt = timeline.mbrEmpPremAmt;
    const resultTimeLine = {
      processed: processed,
      prdate: prdate,
      mtcn: mtcn,
      dataSeg: dataSeg,
      sourceSystemMemberId: sourceSystemMemberId,
      altId: altId,
      medicaidId: medicaidId,
      last: last,
      first: first,
      middle: middle,
      begin: begin,
      end: end,
      cov: cov,
      mpin: mpin,
      address: address,
      taxId: taxId,
      ipa: ipa,
      market: market,
      marketType: marketType,
      pcpStateZip: pcpStateZip,
      pcpCounty: pcpCounty,
      memberStateZip: memberStateZip,
      covSystem: covSystem,
      policy: policy,
      pvrc: pvrc,
      copays: copays,
      stateOfIssue: stateOfIssue,
      pcpDiv: pcpDiv,
      pcpPanel: pcpPanel,
      pcpNetwork: pcpNetwork,
      srcsycprovId: srcsycprovId,
      medicareId: medicareId,
      cmsContractId: cmsContractId,
      pbp: pbp,
      cmsSegment: cmsSegment,
      mbrEmpPremAmt: mbrEmpPremAmt,
    };
    resultArray.push(resultTimeLine);
  }
  return resultArray;
};
