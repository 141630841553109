import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Typography, Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';
import { providerContractAdjustmentApi } from './providerContractAdjustmentApi';
import { getContractAdjustmentResults, storeContractAdjustmentResults } from '../providerContractPersistence';
import { yyyymmddToMmddyyyy } from '../../../helperFunctions';

export const ProviderContractAdjustment = ({
	searchLink,
	contractLink,
	lineLink,
	inputContractName,
	inputContractNumber,
	inputContractTypeCd,
	inputCalcMethCd,
	inputContractLineId,
	inputContractSublineID,
	selectedDataSegmentString,
	inputContrSrcSysCd,
	inputPublicPri,
	inputMsbp
}) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tableData, setTableData] = useState([]);

	const [contractName, setContractName] = useState();
	const [contractNumber, setContractNumber] = useState();
	const [contractTypeCd, setContractTypeCd] = useState();
	const [calcMethCd, setCalcMethCd] = useState();
	const [contractLineId, setContractLineId] = useState();
	const [contractSublineID, setContractSublineID] = useState();
	const [contrSrcSysCd, setContrSrcSysCd] = useState();
	const [pri, setPri] = useState();
	const [msbp, setMsbp] = useState();

	const [loadingTable, setLoadingTable] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		setContractName(inputContractName);
	}, [inputContractName])
	useEffect(() => {
		setContractNumber(inputContractNumber);
	}, [inputContractNumber])
	useEffect(() => {
		setContractTypeCd(inputContractTypeCd);
	}, [inputContractTypeCd])
	useEffect(() => {
		setCalcMethCd(inputCalcMethCd);
	}, [inputCalcMethCd])
	useEffect(() => {
		setContractLineId(inputContractLineId);
	}, [inputContractLineId])
	useEffect(() => {
		setContractSublineID(inputContractSublineID);
	}, [inputContractSublineID])
	useEffect(() => {
		setContrSrcSysCd(inputContrSrcSysCd);
	}, [inputContrSrcSysCd])
	useEffect(() => {
		setPri(inputPublicPri);
	}, [inputPublicPri])
	useEffect(() => {
		setMsbp(inputMsbp);
	}, [inputMsbp])

	useEffect(() => {
		var temp = getContractAdjustmentResults();
		if (temp) {
			setTableData(temp);
			if (temp.length == 0) {
				setErrorMessage('No data found');
			}
		} else if (contrSrcSysCd && contractNumber && contractLineId && contractSublineID && pri) {
			setLoadingTable(true);
			const requestBody = {
				"CONTR_SRC_SYS_CD": contrSrcSysCd,
				"CONTR_ID": contractNumber,
				"CONTR_LN_ID": contractLineId,
				"CONTR_SUBL_ID": contractSublineID,
				"PRDCT_RDY_CD": pri
			}
			providerContractAdjustmentApi(requestBody).then(response => {
				setLoadingTable(false);
				if (response.data.length > 0) {
					var temp = response.data;
					temp.sort(function (first, second) {
						return Number(first.adj_eff_date.split('-').join('')) - Number(second.adj_eff_date.split('-').join(''));
					});
					setTableData(temp);
					storeContractAdjustmentResults(temp);
				} else {
					storeContractAdjustmentResults([]);
					setErrorMessage('No records found');
				}
			}).catch(error => {
				console.log('Error with providerContractAdjustmentApi', error);
			});
		}
	}, [contrSrcSysCd, contractNumber, contractLineId, contractSublineID, pri]);
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<div>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {contractTypeCd} | Calc Method: {calcMethCd} | MSBP Ind: {msbp}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Contract#: {contractNumber} | Contract Line#: {contractLineId} | Contract Subline ID: {contractSublineID}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => contractLink()}>Contract</a> {">"} <a href='#' onClick={() => lineLink()}>Contract Line</a> {">"} Adjustments
			</Typography>
			{!loadingTable && !errorMessage && tableData.length > 0 &&
				<Paper sx={{ mt: 2 }}> {/* Added space on top of the table */}
					<TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
							<TableHead style={{ backgroundColor: '#b0c0da' }}>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Code</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Cat Code</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Type Code</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Eff Date</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj End Date</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Prod Ready Indic</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Calc Method</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Adj Amount Rate</TableCell>
									<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Disbursement/ Accrual</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<TableRow key={'contractAdjustment_' + index}>
												<TableCell style={{ textAlign: 'left' }}>{row.adj_code}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.adj_cat_code}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.adj_type_code}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.adj_eff_date)}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.adj_end_date)}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.pri}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.adj_calc_method}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.adj_amount_rate}</TableCell>
												<TableCell style={{ textAlign: 'left' }}>{row.disbursement_accrual}</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={tableData.length} // Replace with the actual count of rows    
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							'.MuiTablePagination-selectLabel': {
								paddingTop: '12px',
							},
							'.MuiTablePagination-displayedRows': {
								paddingTop: '13px',
							},
						}}
					/>
				</Paper>
			}
			{loadingTable &&
				<LinearProgress sx={{ mt: 2 }} />
			}
			{errorMessage &&
				<Box sx={{ width: '100%', textAlign: 'center', color: 'red', mt: 2 }}>
					{errorMessage}
				</Box>
			}
		</div>
	);
};  
