import axios from 'axios';
const SYS_DEFAULT_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/retro/sys_default`;
const RETRO_ADD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/retro/add`;

export const retroAddSysDefaultApi = (requestBody) => {
    return axios.post(
        SYS_DEFAULT_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}

export const retroAddApi = (requestBody) => {
    return axios.post(
        RETRO_ADD_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}