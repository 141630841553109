import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
const DATASEGMENT_POPUP_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg_popup`;


var results = [];
var selectID = '';



var noResults = false;

const handleClick = (choice) => {
    window.opener.postMessage(choice, window.location.origin);
    window.close();
};

const dataSegmentMapping = {
    "PHSCACO": 1,
    "PHSORCO": 2,
    "PHSWACO": 3,
    "PHSTXCO": 4,
    "PHSCOCO": 5,
    "PHSNVCO": 6,
    "UHUHSM3": 7,
    "UHUHSM2": 8,
    "MAOCICO": 9,
    "MAMDICO": 10,
    "UHUHSCO": 12,
    "UHUHSMR": 13,
    "UHNHPCO": 14,
    "UHMHPMR": 15,
    "UHPCPMR": 16,
    "UHUHSCS": 17
};


const PopupDataSegment = ({ window, initialDataSegment }) => {
    const [dataSegments, setDataSegments] = useState([]);
    const [contractOwnerId, setContractOwnerId] = useState('');
    const initialDataSegmentLabel = Object.keys(dataSegmentMapping).find(key => dataSegmentMapping[key] === initialDataSegment);
    const [dataSegment, setDataSegment] = useState(initialDataSegmentLabel || '');

    console.log("initial data segment value is",initialDataSegment);
    //  const [dataSegment, setDataSegment] = useState(''); 
    const [results, setResults] = useState([]);


    const handleContractOwnerChange = (event) => {
        setContractOwnerId(event.target.value.replace(/\D/g, ''));
    };

    const handleDataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    };

    const fetchData = async () => {
        try {
            const requestBody = {
                "DATA_SEG": initialDataSegment,
                "OWNR_PROV_ID": contractOwnerId
            };
            const response = await axios.post('https://aksecap-dev.optum.com/ecap-gui-api/provider/history/search_contract_popup', requestBody);
            setResults(response.data);
        } catch (error) {
            console.error("There was an error fetching the data: ", error);
        }
    };
    const handleSubmit = () => {
        // Trigger the API call  
        fetchData();
    };

    const handleClick = (choice) => {
        window.opener.postMessage(choice);
        window.close();
    }
    const handleline = (choice) => {
        window.opener.postMessage(choice);
        window.close();
    }

    return (

        <div>
            <TableContainer>
                <Table style={{ width: '100%', textAlign: 'left' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Data Segment
                            </TableCell>
                            <TableCell>
                                CONTRACT OWNER MPIN
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                              
                                    {/* Reduced maxWidth to 200 */}
                                    <TextField
                                        required
                                        id='datasegment-id'
                                        value={dataSegment}
                                        onChange={handleDataSegmentChange}
                                        variant="standard"
                                    />
                            </TableCell>
                            <TableCell>
                               
                                    {/* Reduced maxWidth to 200 */}
                                    <TextField
                                        required
                                        id='contract-id'
                                        value={contractOwnerId}
                                        onChange={handleContractOwnerChange}
                                        variant="standard"
                                    />
                               
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleSubmit} style={{ backgroundColor: "#b0c0da" }}>
                                    Submit
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br></br>
               
                <Table style={{ width: '100%', textAlign: 'left' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Contract Number
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Contract Name
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Tax ID
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Contract Line#
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Contract Type
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.length > 0 ? (
                            results.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell><a href='#' onClick={() => handleClick(row.CONTR_ID)}>{row.CONTR_ID}</a></TableCell>
                                    <TableCell>{row.CONTR_NM}</TableCell>
                                    <TableCell>{row.PROV_TIN}</TableCell>
                                    <TableCell><a href='#' onClick={() => handleline(row.CONTR_LN_ID)}>{row.CONTR_LN_ID}</a></TableCell>
                                    <TableCell>{row.CONTR_TYP_CD}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2} style={{ textAlign: 'center', color: '#FF0000' }}></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {noResults && <div style={{ width: '100%', textAlign: 'center', color: '#FF0000' }}>No Record Found</div>}
        </div>
    );
}

export function testContract(dataSegment) {
    console.log("data segment render is ",dataSegment);
    // for some reason putting selectID as an input to the segment becomes undefined after mapping, this works though
    //  selectID = inputSelectID;
    const newWindow = window.open('', 'new', 'width=10vw,resizable=yes,status=yes');
    newWindow.document.write("<html><body><div id='root'></div></body></html>");
    ReactDOM.render(<PopupDataSegment initialDataSegment={dataSegment} results={results} window={newWindow} />, newWindow.document.getElementById('root'));
}
