import { useEffect, useState } from 'react';

import { ReportsTable1 } from '../ReportsTable1';

function createData(reportID, reportName) {
    return { reportID, reportName};
}

const rows = [
    createData('EC0200MB', 'CAPITATION MEMBER CHANGES ERRORS'),
    createData('EC0210MB', 'CAPITATION PREPROCESSOR MEMBER EXTRACT ERRORS'),
    createData('EC0220MB', 'CAPITATION PREPROCESSOR MEMBER CHANGES ERRORS'),
    createData('EC0270MB', 'CAPITATION PROCESS/POST MEMBER EXTRACT ERRORS'),
    createData('EC0280MB', 'CAPITATION PROCESS/POST MEMBER CHANGES EXTRACT ERRORS'),
    createData('EC0540MB', 'MEMBER CHANGES OUTSIDE RETRO WINDOW'),
];

const viewButtonV = [true, true, true, false, false, true];
const downloadButtonV = [false, false, false, true, true, false];

export const ReportsMember = ({inputDSeg}) => {
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        setDataSegments(inputDSeg);
    }, [inputDSeg]);

    return (
        <ReportsTable1 rows={rows} inputDSeg={dataSegments} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV}/>
    );
};
