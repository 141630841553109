import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Grid,
} from "@mui/material";
import { formatDateTime } from "../utils/formatFunctions";
import { useState } from "react";
import { FilterComp } from "./FilterComp";
import { ComparePopUp } from "./popups/ComparePopUp";
import { ErrorPopUp } from "./popups/ErrorPopUp";
import { createWindow } from "../utils/createWindow";
import { Button } from "react-bootstrap";
export const TransHist = (props) => {
  const memberDetailsResult = props.memberDetailsResult;
  const transactionHistory = memberDetailsResult.transactionHistory;
  const compareHistory = memberDetailsResult.compareHistory;
  const segementName = props.segmentName;
  const [filteredMtcn, setFilteredMtcn] = useState(
    props.memberDetailsResult.transactionHistory
  );
  const data = filteredMtcn;
  const columns = [
    {
      Header: "Processed",
      accessor: "processed",
      format: (cell) => formatDateTime(cell),
      minWidth: 200,
    },
    {
      Header: "MTCN",
      accessor: "mtcn",
      minWidth: 75,
    },
    {
      Header: "Transaction Count",
      accessor: "transactionCount",
      minWidth: 125,
    },
  ];
  const setSpecificMtcn = (mtcn) => {
    let mtcnArr = mtcn.replace(/\s/g, "").split(",");
    console.log(mtcnArr);
    if (mtcnArr.includes("")) {
      setFilteredMtcn(transactionHistory);
    } else {
      setFilteredMtcn(
        transactionHistory.filter((item) =>
          mtcnArr.includes(item.mtcn.toString())
        )
      );
    }
  };
  const getTopXMtcn = (x) => {
    const array = x.split(",");
    const length = array.length;
    if (x === "" || length === 0) {
      setFilteredMtcn(memberDetailsResult.transactionHistory);
      return;
    }

    setFilteredMtcn(memberDetailsResult.transactionHistory.slice(0, length));
  };
  const changeCompareContent = (e) => {
    const compareContent = (
      <>
        <ComparePopUp
          memberDetailResult={memberDetailsResult}
          data={compareHistory}
          transData={transactionHistory}
          segmentName={segementName}
        />
      </>
    );
    createWindow(compareContent);
  };
  const changeErrorContent = (e) => {
    const errorContent = (
      <>
        <ErrorPopUp
          memberDetailResult={memberDetailsResult}
          data={compareHistory}
          transData={transactionHistory}
          segmentName={segementName}
        />
      </>
    );
    createWindow(errorContent);
  };
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={4}
      >
        <TableContainer
          style={{
            overflowX: "auto",
            overflowY: "auto",
            maxHeight: "45vh",
            minHeight: "45vh",
          }}
        >
          <Table style={{ minHeight: "40vh" }}>
            <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.Header}
                    style={{
                      backgroundColor: "#b0c0da",
                      minWidth: column.minWidth,
                      padding: "4px",
                    }}
                  >
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.Header}
                      style={{
                        minWidth: column.minWidth,
                        padding: "4px",
                      }}
                    >
                      {column.format
                        ? column.format(row[column.accessor])
                        : row[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          style={{ width: "25%", marginLeft: "5vw", marginRight: "1vw" }}
          onClick={(e) => changeCompareContent(e)}
        >
          Compare
        </Button>
        <Button
          style={{ width: "25%", marginLeft: "1vw", marginRight: "1vw" }}
          onClick={(e) => changeErrorContent(e)}
        >
          Error
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FilterComp
          setSpecificMtcn={setSpecificMtcn}
          memberDetailsResult={memberDetailsResult}
          getTopXMtcn={getTopXMtcn}
        />
      </Grid>
    </Grid>
  );
};
