import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import ReportingOverridesSearchTable from './ReportOverrideSearchTable';
import { ReportingOverridesUpdateTable } from './ReportingOverridesUpdateTable';
import { Select, MenuItem } from '@mui/material';
import { testPDSeg } from '../../../testPopupDataSegment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReportOverrideApi } from "./ReportOverrideUpdateApi";
import { ReportOverrideDocumentApi } from "./ReportOverrideUpdateApi";


const arrangementOptions = [
    'RA',
    'NB',
    'CHECK-PL',
    'PC',
    'PI',
    'SC',
    'TP'
]

const DistributionMode = {
    "B":"BOTH",
    "I": "INTERNAL",
    "P": "PRINT",
    "S": "SUPPRESS",
    "V": "PORTAL"
};

export const ReportingOverridesSearch = (inputDataSegments) => {
    const [contractNumber, setContractNumber] = React.useState('');
    const [AssociateContract, setAssociateContract] = React.useState('');
    const [ContractLine, setContractLine] = React.useState('');
    const [AssociateContractLine, setAssociateContractLine] = React.useState('');
    const [MPIN, setMPIN] = React.useState('');
    const [AssociateMPIN, setAssociateMPIN] = React.useState('');
    const [CapProcessMonth, setCapProcessMonth] = React.useState('');
    const [showSearch, setShowSearch] = React.useState(true);
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegmentError, setDataSegmentError] = useState(false);
    const [dataSegments, setDataSegments] = useState(['']);
    const [dataSegmentIds, setDataSegmentIds] = useState();
    const [processMonth, setProcessMonth] = useState(null);
    const [ssmk, setSsmk] = useState('');
    const [ssmkError, setSsmkError] = useState(false);
    const [documentIdOptions, setDocumentIdOptions] = useState([]);
    const [selectedDocumentId, setSelectedDocumentId] = useState('');
    const [selectedDistributionMode, setSelectedDistributionMode] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [showTable, setShowTable] = React.useState(false); // State to control table visibility
    const [tableData, setTableData] = useState();
    const [showUpdateTable, setShowUpdateTable] = useState(false);

    // At the beginning of your component where other states are defined  
    const [distributionModeError, setDistributionModeError] = useState(false);


    const distributionModeOptions = Object.entries(DistributionMode).map(([key, value]) => ({
        id: key,
        label: `${key}:${value}`, // This will format the label as "I:INTERNAL"  
    }));


    function ssmkChange(event) {
        setSsmk(event.target.value);
    }

    const handleMPINChange = (event) => {
        setMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleAssociateMPINChange = (event) => {
        setAssociateMPIN(event.target.value.replace(/\D/g, ''));
    };

    const handleDocumentIdChange = (event) => {
        setSelectedDocumentId(event.target.value);
    };

    const handleCapProcessMonthChange = (event) => {
        setCapProcessMonth(event.target.checked.replace(/\D/g, ''));
    };
    function processMonthChange(date) {
        setProcessMonth(date);
    }


    // In the parent component (ReportingOverridesSearch)  
    // In the ReportingOverridesSearch component  
    const handleShowUpdateTable = (selectedRowIds) => {
        if (selectedRowIds.length > 0) {
            const selectedRowsData = tableData.filter(row =>
                selectedRowIds.includes(row.uniqueId) // Use the correct identifier for your rows  
            );
            setShowUpdateTable(true); // Show the update table  
            setShowTable(false); // Hide the search table  
            setSelectedRows(selectedRowsData); // Set the selected rows with the correct filtered data  
        } else {
            alert("Please select at least one row to update.");
        }
    };

    const handleCancelSearchTable = () => {  
        setShowTable(false); // Use the state that controls the table's visibility  
    }; 




    const handleHideUpdateTable = () => {
        setShowUpdateTable(false); // Hide the update table  
        setShowTable(true); // Show the search table  
        setSelectedRows([]); // Clear the selected rows  
        //  setTableData([]); // Clear the table data  
    };






    // error messages when none of the fields are filled in 

    const [errorMessage, setErrorMessage] = useState('');

    // Handler for individual row checkboxes  

    const handleSubmit = () => {
        if (dataSegment === "") {
            setErrorMessage("Please enter all required fields: Data Segment, Document ID, Distribution Mode, and Process Month.");
            setShowTable(false);
        } else {
            setErrorMessage('');
            const requestBody = {
                "DATA_SEG_ID": dataSegmentIds[dataSegment],
                "DOC_ID": selectedDocumentId,             // Include the selected document ID  
                "distribution_mode": selectedDistributionMode, // Include the selected distribution mode key  
                "PROV_ID": ssmk,
                "PROC_YR_MO": ""
            };

            console.log("request body for report override", requestBody);

            // Call the API function and handle the response  
            ReportOverrideApi(requestBody).then(response => {
                if (response.status === 200 && response.data.length > 0) {
                    // Set the table data with the entire response data  
                    const newData = response.data.map((item, index) => ({  
                        ...item,  
                        uniqueId: `${item.someField}-${index}` // Create a unique identifier  
                      }));   
                    setTableData(newData);
                    setShowTable(true);
                } else if (response.status !== 200) {
                    setErrorMessage(`Error: Server responded with status code ${response.status}`);
                    setShowTable(false);
                } else {
                    setErrorMessage('No matching records found.');
                    setShowTable(false);
                }
            }).catch(error => {
                console.error(error);
                if (error.response) {
                    // The request was made and the server responded with a status code  
                    // that falls out of the range of 2xx  
                    setErrorMessage(`Error: Server responded with status code ${error.response.status}`);
                } else if (error.request) {
                    // The request was made but no response was received  
                    setErrorMessage('Error: The request was made but no response was received');
                } else {
                    // Something happened in setting up the request that triggered an Error  
                    setErrorMessage('Error: An error occurred while setting up the request.');
                }
                setShowTable(false);
            });
        }
    };



    useEffect(() => {
        if (dataSegment) {
            const requestBody = {
                "data_segment": dataSegmentIds[dataSegment],
            };

            ReportOverrideDocumentApi(requestBody).then(response => {
                if (response.status === 200) {
                    const uniqueIdSubTypePairs = new Set();

                    const documentIdOptions = response.data.map(item => {
                        const idSubTypePair = `${item.DOC_ID}-${item.DOC_SUB_TYP_CD}`;

                        if (!uniqueIdSubTypePairs.has(idSubTypePair)) {
                            uniqueIdSubTypePairs.add(idSubTypePair);
                            return {
                                id: idSubTypePair,  // Using the combined ID and subtype as the value  
                                label: idSubTypePair  // Using the hyphen format for the label  
                            };
                        }
                        return null;
                    }).filter(option => option !== null);

                    setDocumentIdOptions(documentIdOptions);
                } else {
                    setErrorMessage(`Error: Server responded with status code ${response.status}`);
                }
            }).catch(error => {
                console.error('Error fetching document IDs and subtypes:', error);
                setErrorMessage('An error occurred while fetching the document IDs and subtypes.');
            });
        }
    }, [dataSegment, dataSegmentIds]);



    useEffect(() => {
        if (tableData !== undefined) {
            // eslint-disable-next-line
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);

    const handleReset = () => {
        // Reset all form fields to their initial values  
        setContractNumber('');
        setAssociateContract('');
        setContractLine('');
        setAssociateContractLine('');
        setMPIN('');
        setAssociateMPIN('');
        setCapProcessMonth('');
        setDataSegment('');
        setProcessMonth(null);
        setSsmk('');
        setSelectedDocumentId('');
        setSelectedDistributionMode('');
        setDataSegmentError(false);
        setSsmkError(false);
        setDistributionModeError(false);

        // Clear the table data  
        setTableData([]);

        // Hide the table and update table if they're currently being shown  
        setShowUpdateTable(false); // Hide the update table  
        setShowTable(true); // Show the search table  
        setSelectedRows([]); // Clear the selected rows  
        setTableData([]); // 
        // ...  
    };


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }
    function dataSegmentChange(event) {
        setDataSegment(event.target.value);
    }

    useEffect(() => {

        console.log('inputDataSegments prop:', inputDataSegments);
        if (inputDataSegments && inputDataSegments['inputDataSegments']) {
            var dataIds = {};
            var dataList = [];
            var temp = inputDataSegments['inputDataSegments'];
            for (let index in temp) {
                dataIds[temp[index]['NAME']] = temp[index]['DATA_SEG_ID'];
                dataList.push(temp[index]['NAME']);
            }
            setDataSegmentIds(dataIds);
            setDataSegments(dataList);
        }
    }, [inputDataSegments])

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    useEffect(() => {
        console.log('tableData:', tableData);
    }, [tableData]);

    // In ReportingOverridesSearch component  



    return (
        <div style={{ width: 'calc(98.5vw)', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '315px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <Select
                                size='small'
                                required
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                            >
                                {dataSegments.map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                            </Select>

                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>

                    <Box sx={{ display: 'flex', mb: 0.5, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Contract Owner</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>MPIN</Typography>
                        </div>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='payment-mpin'
                                value={ssmk}
                                onChange={(event) => ssmkChange(event)}
                                fullWidth
                                size="small"
                                error={ssmkError}
                                multiline
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Document Id</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <Select
                                size='small'
                                style={{ width: '100%' }}
                                value={selectedDocumentId}
                                onChange={(event) => setSelectedDocumentId(event.target.value)}
                                id='document-id-select'
                                error={dataSegmentError}
                            >
                                {documentIdOptions.map((document) => (
                                    <MenuItem
                                        value={document.id} // Now 'id' is the combined string EC7280-D05  
                                        key={document.id}
                                    >
                                        {document.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>


                    </Box>

                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>
                            <Box>
                                Process Month
                            </Box>
                        </Typography>
                        <div style={{ width: '180px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => processMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={processMonth}
                                    value={processMonth}
                                />
                            </LocalizationProvider>
                        </div>
                    </Box>

                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>
                            Distribution Mode
                        </Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <Select
                                size='small'
                                style={{ width: '100%' }}
                                value={selectedDistributionMode}
                                onChange={(event) => setSelectedDistributionMode(event.target.value)}
                                id='distribution-mode-select'
                                error={distributionModeError}
                            >
                                {distributionModeOptions.map((mode) => (
                                    <MenuItem
                                        value={mode.id}
                                        key={mode.id}
                                    >
                                        {mode.label} {/* This will display as "I:INTERNAL" */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>




                    <Button
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            }


            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            <div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '1rem', border: 'none' }}>

                <ReportingOverridesSearchTable
                    showTable={showTable}
                    inputTableData={tableData}
                    onShowUpdateTable={handleShowUpdateTable} // New prop  
                    onCancel={handleCancelSearchTable}


                />
                {showUpdateTable && (
                    <ReportingOverridesUpdateTable
                        rowData={selectedRows}
                        dataSegmentId={dataSegmentIds[dataSegment]}
                        onCancel={handleHideUpdateTable}

                    />
                )}

            </div>


            {errorMessage && (
                <div style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'red',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {errorMessage}
                </div>
            )}

        </div>
    );
};