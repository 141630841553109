import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { FactorsSearchApi } from "./FactorsSearchApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import FactorsSearchTable from './FactorsSearchTable';
import {FactorsUpdateTable} from './FactorsUpdateTable'
import { Select, MenuItem } from '@mui/material';
import { testPDSeg } from '../../../testPopupDataSegment';



const factoryNameCodeMap = {
    "ALL": "ALL",
    "SYSTEM ADJ PREMIUM TAX": "ADJPREMTAX",
    "ESRD RA FACTOR": "ESRD RA FACTOR",
    "HOSPICE RA FACTOR": "HOSPICE RA FACTOR",
    "PREMIUM TAX": "PREM TAX",
    "SEQUESTRATION": "SEQ FEE",
    "USER FEE": "USER FEE",
    "ESRD MSP POST-GRAFT FCT": "WAESRDPFCT",
    "ESRD MSP TRANSPLNT FCT": "WAESRDTFCT",
    "AGED DISABLED MSP FCT": "WAFCT"
};



export const FactorsSearch = (inputDataSegments) => {

    const [showSearch, setShowSearch] = React.useState(true);
    const [dataSegment, setDataSegment] = useState('');
    const [factorname, setfactorname] = useState('ALL');
    const [dataSegments, setDataSegments] = useState(['']);
    const [dataSegmentIds, setDataSegmentIds] = useState();
    const [activeChecked, setActiveChecked] = useState(true);
    const [showUpdateTable, setShowUpdateTable] = useState(false); 

    const FactoryNameoptions = ["ALL", ...Object.keys(factoryNameCodeMap).filter(key => key !== "ALL")];


    const handlefactornameChange = (event) => {
        setfactorname(event.target.value);
    };

    const handleActiveChange = (event) => {
        setActiveChecked(event.target.checked);
    };

    const handleCancel = () => {  
        setSelectedRowData(null);  
        setShowUpdateTable(false);  
    }; 


    const [showTable, setShowTable] = React.useState(true); // State to control table visibility
    const [tableData, setTableData] = useState();
    const [selectedRowData, setSelectedRowData] = useState(null);  

    // error messages when none of the fields are filled in 

    const [errorMessage, setErrorMessage] = useState('');


    const handleSubmit = () => {
        if (dataSegment === "") {
            setErrorMessage("Please enter Data segment and factor name.");
          //  setShowTable(false);
        } else {
            setErrorMessage('');
            const requestBody = {
                "data_segment": dataSegmentIds[dataSegment],
            };

            FactorsSearchApi(requestBody).then(response => {
                if (response.data.length > 0) {
                    // Filter the data based on the selected factor name, if not 'ALL'    
                    let filteredData = factorname === 'ALL' ? response.data : response.data.filter(item => {  
                        return item.FACTOR_NAME === factoryNameCodeMap[factorname];  
                    });
                    
                    if (activeChecked) {  
                        filteredData = filteredData.filter(item => item.ACTIVE.toLowerCase() === "active");  
                    }  
                    if (filteredData.length > 0) {
                        setTableData(filteredData);
                        setShowTable(true);
                    } else {
                        setErrorMessage('No matching records found for the selected factor name.');
                        setShowTable(false);
                    }
                } else {
                    setErrorMessage('No matching records found.');
                    setShowTable(false);
                }
            }).catch(error => {
                console.error(error);
                setErrorMessage('An error occurred while fetching records.');
                setShowTable(false);
            });
        }
    };





    // useEffect(() => {
    //     if (tableData !== undefined) {
    //         // eslint-disable-next-line
    //         if (tableData != [] && tableData.length > 0) {
    //             setShowTable(true);
    //         } else {
    //             setShowTable(false);
    //         }
    //     }
    // }, [tableData]);


    const handleReset = () => {  
        // Clear all search fields    
        setDataSegment('');  
        setfactorname('ALL');  
        setActiveChecked(true);  
      
        // Reset table data and hide the search table  
        setTableData([]);
        setShowTable(true);  
      
        // Clear selected row data to hide the update table  
        setSelectedRowData(null);  
    };  
    


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }
    function dataSegmentChange(event) {
        setDataSegment(event.target.value);
    }

    const handleRowClick = (rowData) => {  
        console.log('Row data:', rowData);
       const rowsData = Array.isArray(rowData) ? rowData : [rowData];  
    setSelectedRowData(rowsData);  
    setShowTable(!showTable);  
    }; 

    // const handleLinkClick = (rowData) => (event) => {  
    //     event.preventDefault(); // Prevent the default link behavior  
    //     onRowClick(rowData); // Call the function passed via props with the row data  
    // }; 

    useEffect(() => {

        console.log('inputDataSegments prop:', inputDataSegments);
        if (inputDataSegments && inputDataSegments['inputDataSegments']) {
            var dataIds = {};
            var dataList = [];
            var temp = inputDataSegments['inputDataSegments'];
            for (let index in temp) {
                dataIds[temp[index]['NAME']] = temp[index]['DATA_SEG_ID'];
                dataList.push(temp[index]['NAME']);
            }
            setDataSegmentIds(dataIds);
            setDataSegments(dataList);
        }
    }, [inputDataSegments])

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '300px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 300, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <Select
                                size='small'
                                required
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                            //error={dataSegmentError}
                            >
                                {dataSegments.map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Factor Name<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <Select
                                id='error-Category'
                                required
                                size="small"
                                fullWidth
                                value={factorname}
                                onChange={(event) => handlefactornameChange(event)}


                            >
                                {FactoryNameoptions.map((option) => (
                                    <MenuItem name='error-option' value={option} key={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1 }}>Show Active Only</Typography>
                        <Checkbox
                            checked={activeChecked}
                            onChange={handleActiveChange}
                        />
                    </Box>
                    <Button
                        id='search-button'
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => handleSubmit()}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {!errorMessage && tableData && tableData.length > 0 &&  (<div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '1rem' }}>
                
                <FactorsSearchTable showTable={showTable} inputTableData={tableData} onRowClick={handleRowClick} onCancel={handleReset} />
                

               

                {selectedRowData && (  
                    <FactorsUpdateTable rowData={selectedRowData} onCancel={handleCancel}  />  
                )}  

            </div>)}
            {errorMessage && (
                <div style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'red',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {errorMessage}
                </div>
            )}

        </div>
    );
};

export default FactorsSearch;