import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowRight, KeyboardArrowLeft, FormatListNumberedOutlined } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Select, MenuItem } from '@mui/material';
import { testPDSeg } from '../../../testPopupDataSegment';
import { testContract } from '../../../PopupContract';
import { SearchApi } from './SearchApi';
import { HistorySearchTable } from './HistorySearchTable';


const arrangementOptions = [
    'UHUHSCS',
    'UHUHSM3',
    'UHPCPMR',
    'UHNHPCO',
    'UHUHSMR',
    'UHUHSCO',
    'MAMDICO'
]

const HistorySearch = ({ handleTabChange, inputDataSegments,setDataSegmentGlobal }) => {
    const [showSearch, setShowSearch] = useState(true);
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegmentIds, setDataSegmentIds] = useState();
    const [dataSegments, setDataSegments] = useState(['']);
    const [processMonth, setProcessMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [ssmk, setSsmk] = useState('');
    const [contractId, setcontractId] = useState('');
    const [contractOwnerId, setcontractOwnerId] = useState('');
    const [contractLineId, setcontractLineId] = useState('');
    const [arrangement, setArrangement] = useState('ALL');
    const [memberHistoryData, setMemberHistoryData] = useState();
    const [filteredMemberHistoryData, setFilteredMemberHistoryData] = useState();
    const [showTable, setShowTable] = useState(false);
    const [noRecordsFound, setNoRecordsFound] = useState(false);
    const [dataSegmentError, setDataSegmentError] = useState(false);
    const [processMonthError, setProcessMonthError] = useState(false);
    const [endMonthError, setEndMonthError] = useState(false);
    const [ssmkError, setSsmkError] = useState(false);
    const [contractIdError, setcontractIdError] = useState(false);
    const [missingDataSegmentMessage, setMissingDataSegmentMessage] = useState(false);
    const [missingSSMKcontractId, setMissingSSMKcontractId] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = () => {
            // This will clear the session storage when the page is refreshed or closed  
            sessionStorage.clear();
        };

        // Add the event listener  
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener on cleanup  
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);


    // send to session storage
    function storeAll() {
        // console.log('storing into session storage');
        sessionStorage.setItem('memberProviderHistorySearchDataSegment', dataSegment);
        // sessionStorage.setItem('memberProviderHistorySearchProcessMonth', processMonth); // need to figure out how to set datepicker
        // sessionStorage.setItem('memberProviderHistorySearchEndMonth', endMonth); // need to figure out how to set datepicker
        sessionStorage.setItem('memberProviderHistorySearchSourceSystemMemberKey', ssmk);
        sessionStorage.setItem('memberProviderHistorySearchcontractId', contractId);
        sessionStorage.setItem('memberProviderHistorySearchcontractOwnerId', contractOwnerId);
        sessionStorage.setItem('memberProviderHistorySearchArrangement', arrangement);
        sessionStorage.setItem('memberProviderHistorySearchMemberHistoryData', JSON.stringify(memberHistoryData));
        sessionStorage.setItem('memberProviderHistorySearchShowTable', showTable);
        // clear after 5 min
        setTimeout(function () {
            sessionStorage.clear();
        }, 300000);
    }

    // clear session storage because sessionStorage.clear() is iffy sometimes
    function clearSessionStorage() {
        console.log('clearing session storage');
        sessionStorage.setItem('memberProviderHistorySearchDataSegment', null);
        sessionStorage.setItem('memberProviderHistorySearchProcessMonth', null); // need to figure out how to set datepicker
        sessionStorage.setItem('memberProviderHistorySearchEndMonth', null); // need to figure out how to set datepicker
        sessionStorage.setItem('memberProviderHistorySearchSourceSystemMemberKey', null);
        sessionStorage.setItem('memberProviderHistorySearchcontractId', null);
        sessionStorage.setItem('memberProviderHistorySearchcontractOwnerId', null);
        sessionStorage.setItem('memberProviderHistorySearchArrangement', null);
        sessionStorage.setItem('memberProviderHistorySearchMemberHistoryData', null);
        sessionStorage.setItem('memberProviderHistorySearchShowTable', null);
    }

    useEffect(() => {
        if (memberHistoryData !== undefined && memberHistoryData !== null) {
            if (memberHistoryData.length > 0) {
                storeAll();
            }
        }
    }, [memberHistoryData])

    // grab from sessionstorage
    useEffect(() => {
        // console.log('grabbing from session storage');
        // values for: data segment, process month, end month, ssmk, alt id, arrangement, memberhistorydata, showtable
        if (sessionStorage.getItem('memberProviderHistorySearchDataSegment') !== 'null') {
            setDataSegment(sessionStorage.getItem('memberProviderHistorySearchDataSegment'));
        }
        // if (sessionStorage.getItem('memberProviderHistorySearchProcessMonth') !== 'null' && sessionStorage.getItem('memberProviderHistorySearchProcessMonth') !== null) {
        //     console.log('setting to:', moment(new Date(sessionStorage.getItem('memberProviderHistorySearchProcessMonth'))));
        //     setProcessMonth(moment(new Date(sessionStorage.getItem('memberProviderHistorySearchProcessMonth'))));
        // }
        // if (sessionStorage.getItem('memberProviderHistorySearchEndMonth') !== null) {
        //     console.log('setting to:', moment(new Date(sessionStorage.getItem('memberProviderHistorySearchProcessMonth'))));
        //     setProcessMonth(moment(new Date(sessionStorage.getItem('memberProviderHistorySearchProcessMonth'))));
        // }
        if (sessionStorage.getItem('memberProviderHistorySearchSourceSystemMemberKey') !== 'null') {
            setSsmk(sessionStorage.getItem('memberProviderHistorySearchSourceSystemMemberKey'));
        }
        if (sessionStorage.getItem('memberProviderHistorySearchcontractId') !== 'null') {
            setcontractId(sessionStorage.getItem('memberProviderHistorySearchcontractId'));
        }
        if (sessionStorage.getItem('memberProviderHistorySearchcontractOwnerId') !== 'null') {
            setcontractOwnerId(sessionStorage.getItem('memberProviderHistorySearchcontractOwnerId'));
        }
        if (sessionStorage.getItem('memberHistorySearchcontractLineId') !== 'null') {
            setcontractLineId(sessionStorage.getItem('memberHistorySearchcontractLineId'));
        }
        if (sessionStorage.getItem('memberProviderHistorySearchArrangement') !== null && sessionStorage.getItem('memberProviderHistorySearchArrangement') !== "null") {
            setArrangement(sessionStorage.getItem('memberProviderHistorySearchArrangement'));
        }
        if (sessionStorage.getItem('memberProviderHistorySearchMemberHistoryData') !== null) {
            setMemberHistoryData(JSON.parse(sessionStorage.getItem('memberProviderHistorySearchMemberHistoryData')));
        }
        if (sessionStorage.getItem('memberProviderHistorySearchShowTable') !== 'null') {
            setShowTable(sessionStorage.getItem('memberProviderHistorySearchShowTable'));
        }
    }, [])

    useEffect(() => {  
        // Function to check if there is any relevant data in session storage  
        function hasSessionData() {  
            return sessionStorage.getItem('memberProviderHistorySearchDataSegment') ||  
                   sessionStorage.getItem('memberProviderHistorySearchSourceSystemMemberKey') ||  
                   sessionStorage.getItem('memberHistorySearchAltId') ||  
                   sessionStorage.getItem('memberProviderHistorySearchArrangement') ||  
                   sessionStorage.getItem('memberProviderHistorySearchMemberHistoryData') ||  
                   sessionStorage.getItem('memberProviderHistorySearchShowTable');  
        }  
      
        // Function to clear/reset all state and session storage  
        function resetAllData() {  
            // Reset all state  
            setDataSegment('');  
            setProcessMonth(null);  
            setEndMonth(null);  
            setSsmk('');  
            setArrangement('ALL');  
            setMemberHistoryData([]);  
            setShowTable(false);  
            setNoRecordsFound(false);  
            setDataSegmentError(false);  
            setSsmkError(false);   
            setMissingDataSegmentMessage(false); 
            setProcessMonthError(false);  
            setEndMonthError(false);  
      
            // Clear session storage  
            clearSessionStorage();  
        }  
      
        // Only reset data if there's no session data (i.e., this is a first-time visit)  
        if (!hasSessionData()) {  
            resetAllData();  
        }  
      
        // Add any other code you want to run on component mount  
      
    }, []); // Empty dependency array ensures this runs only once on mount  
    

    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    function dataSegmentChange(event) {
        setDataSegment(event.target.value);
    }

    function processMonthChange(date) {
        setProcessMonth(date);
    }

    function endMonthChange(date) {
        setEndMonth(date);
    }

    function ssmkChange(event) {
        setSsmk(event.target.value);
    }

    function contractIdChange(event) {
        setcontractId(event.target.value.replace(/\D/g, ''));
    }

    function contractOwnerChange(event) {
        setcontractId(event.target.value.replace(/\D/g, ''));
    }

    function contractLineChange(event) {
        setcontractId(event.target.value.replace(/\D/g, ''));
    }


    useEffect(() => {
        if (memberHistoryData !== undefined && memberHistoryData !== null && memberHistoryData.length > 0) {
            setShowTable(true);
            if (arrangement === 'ALL') {
                setFilteredMemberHistoryData(memberHistoryData);
            } else {
                var filteredList = []
                for (let index in memberHistoryData) {
                    if (memberHistoryData[index].PROV_ARNG_CD === arrangement) {
                        filteredList.push(memberHistoryData[index]);
                    }
                }
                setFilteredMemberHistoryData(filteredList);
            }
        } else {
            setShowTable(false);
        }
    }, [memberHistoryData, arrangement])

    useEffect(() => {
        if (inputDataSegments != []) {
            var dataSegmentIds = {}
            var dataSegmentList = []
            for (let index in inputDataSegments) {
                dataSegmentIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
                dataSegmentList.push(inputDataSegments[index]['NAME']);
            }
            setDataSegmentIds(dataSegmentIds);
            setDataSegments(dataSegmentList);
        }
    }, [inputDataSegments])

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    const dataSegmentMapping = {
        "PHSCACO": 1,
        "PHSORCO": 2,
        "PHSWACO": 3,
        "PHSTXCO": 4,
        "PHSCOCO": 5,
        "PHSNVCO": 6,
        "UHUHSM3": 7,
        "UHUHSM2": 8,
        "MAOCICO": 9,
        "MAMDICO": 10,
        "UHUHSCO": 12,
        "UHUHSMR": 13,
        "UHNHPCO": 14,
        "UHMHPMR": 15,
        "UHPCPMR": 16,
        "UHUHSCS": 17,
        "UHUHSM3": 18,
        // ... other mappings  
    };

    function openPopupContract() {
        if (dataSegment) {
            // Get the value associated with the Data Segment label    
            const dataSegmentValue = dataSegmentMapping[dataSegment];

            // Check if we found a value for the given Data Segment label    
            if (dataSegmentValue) {
                // Data Segment is selected, and we have its value, proceed with opening the popup      
                testContract(dataSegmentValue); // Pass the selected dataSegment value    
                window.onmessage = (event) => {
                    try {
                        setcontractId(event.data);
                    } catch {
                        console.log('popup receive message error');
                    }
                };
            } else {
                // Handle the case where the Data Segment label does not have an associated value    
                alert('Invalid Data Segment selected. Please select a valid Data Segment.');
            }
        } else {
            // Data Segment is not selected, display an error message      
            alert('Please select a Data Segment before proceeding.');
        }
    }

    function formatYearMonth(dateString) {
        if (dateString === null || dateString === '' || dateString === undefined) {
            return ''
        }
        const date = new Date(dateString);
        const year = date.getFullYear(); // e.g., 2023  
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // e.g., "07" (getMonth() is zero-based)  
        return `${year}${month}`; // e.g., "202307"  
    };

    function handleSubmit() {
        setNoRecordsFound(false);
        setMemberHistoryData([]);
        var notEnoughFilters = false;
        if (dataSegment === undefined || dataSegment === null || dataSegment === '') {
            //    setDataSegmentError(true);
            setMissingDataSegmentMessage(true);
            notEnoughFilters = true;
        } else {
            setDataSegmentError(false);
            setMissingDataSegmentMessage(false);
        }
        if ((ssmk === undefined || ssmk === null || ssmk === '') && (contractId === undefined || contractId === null || contractId === '')) {
            setSsmkError(true);
            setcontractIdError(true);
            setMissingSSMKcontractId(true);
            notEnoughFilters = true;
        } else {
            setSsmkError(false);
            setcontractIdError(false);
            setMissingSSMKcontractId(false);
        }
        if (notEnoughFilters) {
            console.log('not enough filters');
        } else {
            var requestBody = {}

            requestBody['DATA_SEGMENT'] = dataSegmentIds[dataSegment];

            setDataSegmentGlobal(requestBody['DATA_SEGMENT']);
            // requestBody['member_partition_id'] = dataSegmentIds[dataSegment];
            // requestBody['pay_partition_id'] = dataSegmentIds[dataSegment];

            //  var splitSsmk;
            if (ssmk !== undefined && ssmk !== null && ssmk !== '') {
                requestBody['PAY_TO_PROV_ID'] = ssmk;
                requestBody['OWNR_PROV_ID'] = ssmk;
            }


            requestBody['PROCESS_MON1'] = formatYearMonth(processMonth);
            requestBody['PROCESS_MON2'] = formatYearMonth(endMonth);


            if (contractId !== undefined && contractId !== '') {
                requestBody['CONTR_ID'] = contractId;
            }

            SearchApi(requestBody).then(response => {
                if (response.data.length > 0) {
                    clearSessionStorage();
                    setShowTable(true);
                    setNoRecordsFound(false);
                    setMemberHistoryData(response.data);
                } else {
                    setNoRecordsFound(true);
                }
            }).catch(error => {
                console.log('error with search api request');
                console.log('requestyBody:', requestBody);
            });
        }
    }

    function handleReset() {
        clearSessionStorage();
        setDataSegment('');
        setProcessMonth(null);
        setEndMonth(null);
        setSsmk('');
        setcontractId('');
        setArrangement('ALL');
        setMemberHistoryData([]);
        setShowTable(false);
        setNoRecordsFound(false);
        setDataSegmentError(false);
        setSsmkError(false);
        setcontractIdError(false);
        setMissingDataSegmentMessage(false);
        setMissingSSMKcontractId(false);


    }

    useEffect(() => {
        if (processMonth !== null && isNaN(Date.parse(processMonth))) {
            console.log('process month error');
            setProcessMonthError(true);
        } else {
            setProcessMonthError(false);
        }
    }, [processMonth])

    useEffect(() => {
        if (endMonth !== null && isNaN(Date.parse(endMonth))) {
            console.log('process month error');
            setEndMonthError(true);
        } else {
            setEndMonthError(false);
        }
    }, [endMonth])

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '300px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 300, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <Select
                                size='small'
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                                error={dataSegmentError}
                            >
                                {dataSegments.map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Process Month</Typography>
                        <div style={{ width: '180px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => processMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={processMonth}
                                    value={processMonth}
                                />
                            </LocalizationProvider>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>End Month</Typography>
                        <div style={{ width: '180px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    id='end-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => endMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={endMonth}
                                    value={endMonth}
                                />
                            </LocalizationProvider>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.5, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Payment MPIN<span style={{ color: 'red' }}>*</span></Typography>
                        </div>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='payment-mpin'
                                value={ssmk}
                                onChange={(event) => ssmkChange(event)}
                                fullWidth
                                size="small"
                                error={ssmkError}
                                multiline
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.5, alignItems: 'center' }}>
                        <Box style={{ flex: 0.6 }}></Box>
                        <Box style={{ flex: 1, textAlign: 'center' }}>or</Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract#<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='contract-id'
                                value={contractId}
                                onChange={(event) => contractIdChange(event)}
                                fullWidth
                                size="small"
                                error={contractIdError}
                            />
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Contract"
                                onClick={() => openPopupContract()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Contract Owner</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>MPIN</Typography>
                        </div>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='contract-id'
                                value={contractOwnerId}
                                onChange={(event) => contractOwnerChange(event)}
                                fullWidth
                                size="small"
                                error={contractIdError}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract Line#</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='contract-id'
                                value={contractLineId}
                                onChange={(event) => contractLineChange(event)}
                                fullWidth
                                size="small"
                                error={contractIdError}
                            />
                        </Box>
                    </Box>

                    {/* Search Button */}

                    <Button
                        id='search-button'
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => handleSubmit()}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {showTable && filteredMemberHistoryData &&
                <div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '1.2rem' }}>
                    <HistorySearchTable searchResults={filteredMemberHistoryData} handleTabChange={handleTabChange} />
                </div>
            }
            {noRecordsFound &&
                <div style={{ display: 'flex', flexDirection: 'column', color: 'red', fontWeight: 'bold', paddingLeft: '40rem', paddingTop: '1rem', width: '100%', textAlign: 'center' }}>
                    No Records Found
                </div>
            }
            {(missingDataSegmentMessage || missingSSMKcontractId || processMonthError || endMonthError) &&
                <div style={{ display: 'flex', flexDirection: 'column', color: 'red', fontWeight: 'bold', paddingLeft: '40rem', paddingTop: '1rem', width: '100%', textAlign: 'center' }}>
                    {missingDataSegmentMessage &&
                        <div>
                            Please select a Data Segment.
                        </div>
                    }
                    {missingSSMKcontractId &&
                        <div>
                            Payment MPIN ID OR Contract# is required.
                        </div>
                    }
                    {processMonthError &&
                        <div>
                            Process Month incomplete and will not be used in query
                        </div>
                    }
                    {endMonthError &&
                        <div>
                            End Month incomplete and will not be used in query
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default HistorySearch;
