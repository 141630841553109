import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import {
    AppBar,
    Container,
    Toolbar,
    Paper,
    TextField,
    Grid,
    Typography,
    Link,
    Button,
    Box,
    Select,
    MenuItem,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio,
    Stepper,
    Step,
    StepLabel
} from '@mui/material';
import ProviderMemberOptions from './ProviderMemberOptions';
import PaymentAdjustmentForm from './PaymentAdjustmentForm';


export const Add = () => {

    const [selectTypeOption, setSelectTypeOption] = React.useState('paymentAdj');

    const handleTypeOptionChange = (event) => {
        setSelectTypeOption(event.target.value);
    };

    // const { handleSubmit, register } = useForm();
    // const onSubmit = (value) => {
    //     alert(JSON.stringify(value));
    // };

    return (
        <Grid container spacing={2} style={{ margin: '15px 0px 0px 0px' }} sx={{ my: 1, maxHeight: '800px', overflowY: 'auto', padding: 2,  }}>
            <Grid xs={12} sm={12} md={12} lg={12}>
                {/* <form onSubmit={handleSubmit(onSubmit)}>
                    <React.Fragment> */}
                <Container component='main' maxWidth='md' sx={{ mb: 4 }}>
                    <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Box sx={{ my: 1 }}>
                            <Typography>Type</Typography>
                            <RadioGroup
                                row
                                name = "type-option"
                                defaultValue= {selectTypeOption}
                                value={selectTypeOption}
                                onChange={handleTypeOptionChange}
                                // {...register('type')}
                            >
                                <FormControlLabel value='paymentAdj' control={<Radio />} label='PAYMENT ADJ'/>
                                <FormControlLabel value='capAdj' control={<Radio />} label='CAP ADJ'/>
                            </RadioGroup>
                        </Box>
                        {selectTypeOption === 'capAdj' && (
                             <ProviderMemberOptions />
                        )}
                        {selectTypeOption === 'paymentAdj' && (
                            <PaymentAdjustmentForm />
                        )}
                    </Paper>
                </Container>
                {/* </React.Fragment>
                </form> */}
            </Grid>
        </Grid>
    );
};
export default Add;