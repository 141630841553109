import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { yyyymmddToMmddyyyy, checkNone } from '../../../helperFunctions';

const ProviderSearchTable = ({
    inputTableData,
    contractLink,
    setPublicContractName,
    setPublicContractEffDate,
    setPublicContractNumber,
    setPublicPri,
    setContrSrcSysCd
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleRowClick(id, name, effDate, endDate, pri, contrSrcSysCd) {
        setPublicContractNumber(id);
        setPublicContractName(name);
        setPublicContractEffDate(effDate);
        setPublicPri(pri);
        setContrSrcSysCd(contrSrcSysCd);
        if (contrSrcSysCd === undefined || contrSrcSysCd === '' ) {
            setContrSrcSysCd("NDB");
        }
        contractLink();
    }

    return (
        <Paper sx={{ boxShadow: 'none' }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead style={{ backgroundColor: '#b0c0da' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract#</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract Name</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>CRT ID</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract Owner MPIN</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>MPIN</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Tax ID</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract Org Code</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Business Segment Code</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor:'#b0c0da'}}>Legacy Provider#</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Legacy Vendor#</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract Eff Date</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Contract End Date</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>Pay to Ind</TableCell>
                            <TableCell style={{ whiteSpace:'nowrap', backgroundColor: '#b0c0da' }}>PRI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={row.CONTR_ID + '_' + index}>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}><a href='#' onClick={() => handleRowClick(row.CONTR_ID, row.CONTR_NM, row.CONTR_EFF_DT, row.CONTR_END_DT, row.PRDCT_RDY_CD, row.CONTR_SRC_SYS_ID)}>{row.CONTR_ID}</a></TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.CONTR_NM}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{checkNone(row.CONTR_SRC_SYS_ID) === '' ? '0' : row.CONTR_SRC_SYS_ID}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.OWNR_PROV_ID}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.PROV_ID}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.PROV_TIN}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.CONTR_ORG_CD}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.BUS_SEG_CD}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.LGCY_PROV_NBR}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.LGCY_VEND_NBR}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{yyyymmddToMmddyyyy(row.CONTR_EFF_DT)}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{yyyymmddToMmddyyyy(row.CONTR_END_DT)}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.PAY_TO_IND}</TableCell>
                                        <TableCell sx={{ whiteSpace:'nowrap' }}>{row.PRDCT_RDY_CD}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                    },
                }}
            />

        </Paper>
    );
};

export default ProviderSearchTable;

