import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
    TablePagination,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { providerContractRateDetailApi } from './providerRateApi';
import { yyyymmddToMmddyyyy, stringDecimals, checkNone } from '../../../helperFunctions';

export const ProviderContractRateDetail = ({
    searchLink,
    contractLink,
    lineLink,
    regularLink,
    inputContractName,
    inputContractNumber,
    inputContractTypeCd,
    inputCalcMethCd,
    inputContractLineId,
    inputContractSublineID,
    selectedDataSegmentString,
    inputContrSrcSysCd,
    inputPublicPri,
    inputRateEffDate,
    inputMsbp
}) => {
    // State variables
    const [tableData, setTableData] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();

    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [pri, setPri] = useState();
    const [rateEffDate, setRateEffDate] = useState();
    const [msbp, setMsbp] = useState();

    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName])
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber])
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd])
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId])
    useEffect(() => {
        setContractSublineID(inputContractSublineID);
    }, [inputContractSublineID])
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd])
    useEffect(() => {
        setPri(inputPublicPri);
    }, [inputPublicPri])
    useEffect(() => {
        setRateEffDate(inputRateEffDate);
    }, [inputRateEffDate])
    useEffect(() => {
        setMsbp(inputMsbp);
    }, [inputMsbp])

    useEffect(() => {
        if (contrSrcSysCd && contractNumber && contractLineId && contractSublineID && pri && rateEffDate) {
            const requestBody = {
                "CONTR_SRC_SYS_CD": contrSrcSysCd,
                "CONTR_ID": contractNumber,
                "CONTR_LN_ID": contractLineId,
                "CONTR_SUBL_ID": contractSublineID,
                "PRDCT_RDY_CD": pri,
                "RT_EFF_DT": rateEffDate
            }
            providerContractRateDetailApi(requestBody).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    setTableData(response.data[0]);
                } else {
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                console.log('Error with providerContractRateDetailApi', error);
            });
        }
    }, [contrSrcSysCd, contractNumber, contractLineId, contractSublineID, pri, rateEffDate]);

    // Table cell style                      
    const tableCellStyle = {
        fontSize: "0.875rem",
        padding: "1px",
    };

    // Row colors for alternating rows                      
    const rowColors = ["#b8c4dc", "white"];

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // ProviderContractRateDetail component JSX                      
    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {contractTypeCd} | Calc Method: {calcMethCd}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {contractNumber} | Contract Line#: {contractLineId} | Contract Subline ID: {contractSublineID} | Rate Effective Date: {yyyymmddToMmddyyyy(rateEffDate)} | MBSP: {msbp}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => contractLink()}>Contract</a> {">"} <a href='#' onClick={() => lineLink()}>Contract Line</a> {">"} <a href='#' onClick={() => regularLink()}>Rate</a> {">"} Rate Detail
            </Typography>
            {!loadingTable && !errorMessage &&
                <Grid container spacing={2} sx={{ marginTop: '.5rem' }}>
                    <Grid item xs={4}>
                        <Stack spacing={.5}>
                            <TableContainer component={Paper}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Working Aged Adjustment Indicator</TableCell>
                                            <TableCell>{checkNone(tableData.working_aged_adjustment_indicator)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>User Fee Adjustment Indicator</TableCell>
                                            <TableCell>{checkNone(tableData.user_fee_adjustment_indicator)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Subline Fee Indicator</TableCell>
                                            <TableCell>{checkNone(tableData.qmb_slmb_indicator)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>Threshold Type Code</TableCell>
                                            <TableCell>{checkNone(tableData.threshold_type_code)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Threshold Watermark Type Code</TableCell>
                                            <TableCell>{checkNone(tableData.threshold_watermark_type_code)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={0.5}>
                            <TableContainer component={Paper}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Contract Rate Premium%</TableCell>
                                            <TableCell>{stringDecimals(tableData.contract_rate_premium_percent, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>Member/Employer Liable Premium%</TableCell>
                                            <TableCell>{stringDecimals(tableData.plan_member_liable_premium_percent, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Premium 01%</TableCell>
                                            <TableCell>{stringDecimals(tableData.commercial_pharmacy_permium_percent, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>Premium 02%</TableCell>
                                            <TableCell>{stringDecimals(tableData.part_d_member_liable_premium_percent, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Premium 03%</TableCell>
                                            <TableCell>{stringDecimals(tableData.rider_premium_percent, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>Premium 04%</TableCell>
                                            <TableCell>{stringDecimals(tableData.cms_part_d_premium_percent, 1)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={0.5}>
                            <TableContainer component={Paper}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>UHG Premium Retention</TableCell>
                                            <TableCell>{stringDecimals(tableData.uhg_premium_retention, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[1] }}>
                                            <TableCell>Billing/Eligible Membr Indicator</TableCell>
                                            <TableCell>{checkNone(tableData.billing_eligible_member_indicator)}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: rowColors[0] }}>
                                            <TableCell>Estimated Premium Indicator</TableCell>
                                            <TableCell>{checkNone(tableData.estimated_premium_indicator)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Grid>
                </Grid>
            }
            {loadingTable &&
                <LinearProgress sx={{ mt: 2 }} />
            }
            {errorMessage &&
                <Box sx={{ width:'100%', textAlign:'center', color:'red', mt: 2 }}>
                    {errorMessage}
                </Box>
            }
        </div>
    );
};

