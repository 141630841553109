import {
  memberDetailsAxiosClient,
  memberDetailsJsonSchema,
} from "../MemberSearchConstant";
import { useEffect, useState } from "react";
import { CircularProgress, Box, Stack } from "@mui/material";
import CustInfo from "./CustInfo";
import { FactHist } from "./FactHist";
import { PcpHist } from "./PcpHist";
import { EligHist } from "./EligHist";
import { TransHist } from "./TransHist";

export const ProfileHome = (row) => {
  const [loading, setLoading] = useState(true);
  const [memberDetailsResult, setMemberDetailsResult] = useState([]);
  useEffect(() => {
    const requestBody = memberDetailsJsonSchema;
    requestBody.sourceSystem = row.row.sourceSystem;
    requestBody.segmentName = row.row.segmentName;
    requestBody.sourceSystemMemberId = row.row.sourceSystemMemberId;
    requestBody.alternateId = row.row.alternateId;
    requestBody.lastName = row.row.lastName;
    requestBody.firstName = row.row.firstName;
    memberDetailsAxiosClient
      .post("", requestBody)
      .then((response) => {
        setMemberDetailsResult(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <Stack spacing={3}>
            <h4>Current Information</h4>
            <CustInfo custInfo={memberDetailsResult.customerInformation} />
            <h4>Factor History</h4>
            <FactHist factHist={memberDetailsResult.factorHistory} />
            <h4>PCP Assignment History</h4>
            <PcpHist pcpHist={memberDetailsResult.pcpAssignmentHistory} />
            <h4>Eligibility History</h4>
            <EligHist eligHist={memberDetailsResult.eligibilityHistory} />
            <h4>Transaction History</h4>
            <TransHist
              memberDetailsResult={memberDetailsResult}
              segmentName={row.row.segmentName}
            />
          </Stack>
        </>
      )}
    </>
  );
};
