import React from "react";

const styles = {
  footer: {
    position: 'absolute',
    bottom: '0',
    color: 'white',
    backgroundColor: 'black',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}

const Footer = () => {
  return (
    <div style={styles.footer}>
      <div style={{fontSize: '12px'}}>: FAQs  :  See Frontier UnitedHealth Group Intranet site for the latest Privacy Policy  :  Feedback  :  </div>
      <div>©2006 UnitedHealth Group. All rights reserved</div>
    </div>
  );
};
export default Footer;
