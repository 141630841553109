import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Box, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { providerContractRateRegularApi } from './providerRateApi';
import { getContractRateNormalResults, storeContractRateNormalResults } from '../providerContractPersistence';
import { yyyymmddToMmddyyyy, stringDecimals } from '../../../helperFunctions';

export const ProviderContractRateRegular = ({
    searchLink,
    contractLink,
    lineLink,
    detailLink,
    scheduleLink,
    inputContractName,
    inputContractNumber,
    inputContractTypeCd,
    inputCalcMethCd,
    inputContractLineId,
    inputContractSublineID,
    selectedDataSegmentString,
    inputContrSrcSysCd,
    inputPublicPri,
    setPublicRateEffDate,
    setPublicScheduleId,
    setPublicScheduleName,
    setPublicScheduleEffDate,
    setPublicSchedulePri
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const theme = useTheme();

    const [contractName, setContractName] = useState();
    const [contractNumber, setContractNumber] = useState();
    const [contractTypeCd, setContractTypeCd] = useState();
    const [calcMethCd, setCalcMethCd] = useState();
    const [contractLineId, setContractLineId] = useState();
    const [contractSublineID, setContractSublineID] = useState();
    const [contrSrcSysCd, setContrSrcSysCd] = useState();
    const [pri, setPri] = useState();

    const [loadingTable, setLoadingTable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setContractName(inputContractName);
    }, [inputContractName])
    useEffect(() => {
        setContractNumber(inputContractNumber);
    }, [inputContractNumber])
    useEffect(() => {
        setContractTypeCd(inputContractTypeCd);
    }, [inputContractTypeCd])
    useEffect(() => {
        setCalcMethCd(inputCalcMethCd);
    }, [inputCalcMethCd])
    useEffect(() => {
        setContractLineId(inputContractLineId);
    }, [inputContractLineId])
    useEffect(() => {
        setContractSublineID(inputContractSublineID);
    }, [inputContractSublineID])
    useEffect(() => {
        setContrSrcSysCd(inputContrSrcSysCd);
    }, [inputContrSrcSysCd])
    useEffect(() => {
        setPri(inputPublicPri);
    }, [inputPublicPri])

    useEffect(() => {
        var temp = getContractRateNormalResults();
        if (temp) {
            setTableData(temp);
            if (temp.length == 0) {
				setErrorMessage('No data found');
			}
        } else if (contrSrcSysCd && contractNumber && contractLineId && contractSublineID && pri) {
            setLoadingTable(true);
            const requestBody = {
                "CONTR_SRC_SYS_CD": contrSrcSysCd,
                "CONTR_ID": contractNumber,
                "CONTR_LN_ID": contractLineId,
                "CONTR_SUBL_ID": contractSublineID,
                "PRDCT_RDY_CD": pri
            }
            providerContractRateRegularApi(requestBody).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    var temp = response.data;
                    temp.sort(function (first, second) {
						return Number(first.eff_date.split('-').join('')) - Number(second.eff_date.split('-').join(''));
					});
                    setTableData(temp);
                    storeContractRateNormalResults(temp);
                } else {
                    storeContractRateNormalResults([]);
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                console.log('Error with providerContractRateApi', error);
            });
        }
    }, [contrSrcSysCd, contractNumber, contractLineId, contractSublineID, pri]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function handleDetailClick(rateEffDate) {
        setPublicRateEffDate(rateEffDate);
        detailLink();
    }

    function handleScheduleClick(scheduleId, scheduleName, scheduleEffDate, pri) {
        setPublicScheduleId(scheduleId);
        setPublicScheduleName(scheduleName);
        setPublicScheduleEffDate(scheduleEffDate);
        setPublicSchedulePri(pri);
        scheduleLink();
    }

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Type: {contractTypeCd} | Calc Method: {calcMethCd}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {contractNumber} | Contract Line#: {contractLineId} | Contract Subline ID: {contractSublineID}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} <a href='#' onClick={() => contractLink()}>Contract</a> {">"} <a href='#' onClick={() => lineLink()}>Contract Line</a> {">"} Rate
            </Typography>
            {!loadingTable && !errorMessage && tableData.length > 0 &&
                <Paper sx={{ mt: 2, borderRadius: '10px' }}>
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'auto' }}>
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell colSpan={1} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', border: '.5px solid white' }}></TableCell>
                                    <TableCell colSpan={3} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'center', border: '.5px solid white' }}>RATE</TableCell>
                                    <TableCell colSpan={5} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'center', border: '.5px solid white' }}>RATE TIER</TableCell>
                                    <TableCell colSpan={1} style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', border: '.5px solid white' }}></TableCell>
                                </TableRow>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left', border: '.5px solid white' }}>Rate Explosion</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Eff Date</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>End Date</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Info Date</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left', borderLeft: '.5px solid white' }}>PRI</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Sched ID</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Base Amt</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Sched Eff Date</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left' }}>Mbr Cnt</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#dde3ed', textAlign: 'left', border: '.5px solid white' }}>PRI</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ overflowX: 'auto' }}>
                                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={'rateRow_' + index}>
                                            <TableCell sx={{ textAlign: 'left' }}>View</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}><a href='#' onClick={() => handleDetailClick(row.eff_date)}>{yyyymmddToMmddyyyy(row.eff_date)}</a><br></br></TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.end_date)}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.info_date.slice(0, 10))}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{row.pri}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}><a href='#' onClick={() => handleScheduleClick(row.schedule_id, row.schedule_name, row.schedule_eff_date, row.pri)}>{row.schedule_id}</a></TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{stringDecimals(row.base_amt, 2)}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{yyyymmddToMmddyyyy(row.schedule_eff_date)}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{row.mbr_cnt_from_nbr}-{row.mbr_cnt_to_nbr}</TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>{row.pri}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
                    />
                </Paper>
            }
            {loadingTable &&
                <LinearProgress sx={{ mt: 2 }} />
            }
            {errorMessage &&
                <Box sx={{ width:'100%', textAlign:'center', color:'red', mt: 2 }}>
                    {errorMessage}
                </Box>
            }
        </div>
    );
};  
