import axios from 'axios';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { checkNone } from './helperFunctions';
import { contractMpinApi } from './contractMpinApi';

const PopupContractMpinComponent = ({
    dataSegment,
    newWindow
}) => {
    const [searchMpin, setSearchMpin] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [data, setData] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    async function handleSubmit() {
        setErrorMessages([]);
        setData([]);
        if (userInput) {
            if (!/^\d+$/.test(userInput)) {
                setErrorMessages([
                    '* Contract#/MPIN should be numeric.'
                ]);
            } else {
                if (searchMpin) {
                    var requestBody = {
                        "CONTR_MPIN": userInput
                    }
                } else {
                    var requestBody = {
                        "contr_id": userInput
                    }
                }
                contractMpinApi(requestBody).then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
            }
        } else {
            setErrorMessages([
                '* Please enter the Contract# or MPIN.',
                '* Contract#/MPIN should be numeric.'
            ]);
        }
    }

    function mpinClick(event) {
        newWindow.opener.postMessage(event.target.textContent);
        newWindow.close();
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: '1' }}>
                    Data Segment: {dataSegment}
                </div>
                <div style={{ flex: '0.5', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <input
                            type="checkbox"
                            id="contractCheckbox"
                            checked={!searchMpin}
                            onClick={() => setSearchMpin(false)}
                            onChange={() => {}}
                        />
                        <label htmlFor="contractCheckbox">Contract#</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="mpinCheckbox"
                            checked={searchMpin}
                            onClick={() => setSearchMpin(true)}
                            onChange={() => {}}
                        />
                        <label htmlFor="mpinCheckbox">MPIN</label>
                    </div>
                </div>
                <div style={{ flex: '0.5' }}>
                    <input
                        type="text"
                        value={userInput}
                        onChange={(event) => setUserInput(event.target.value)}
                    />
                </div>
                <div style={{ flex: '1', alignContent: 'center', width: '100%', textAlign: 'center' }}>
                    <button
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <table style={{ width: '100%', boxSizing: 'border-box', marginTop: '1rem' }}>
                <thead style={{ backgroundColor: '#b0c0da' }}>
                    <tr>
                        <td>MPIN</td>
                        <td>Last Name</td>
                        <td>First Name</td>
                        <td>Middle Name</td>
                        <td>Effective Date</td>
                        <td>Cancel Date</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr>
                            <td><a href='#' onClick={(event) => mpinClick(event)}>{row.MPIN}</a></td>
                            <td>{checkNone(row.LASTNAME)}</td>
                            <td>{checkNone(row.FIRSTNAME)}</td>
                            <td>{checkNone(row.MIDDLENAME)}</td>
                            <td>{row.EFFECDATE}</td>
                            <td>{row.CANCDATE}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ width:'100%', color:'red', textAlign:'center'}}>
                {errorMessages.map((message, index) => (
                    <div>
                        {message}
                    </div>
                ))}
            </div>
        </div>
    )
}

export function PopupContractMpin(dataSegment) {
    const newWindow = window.open('', 'new', 'width=10vw, resizable=yes, status=yes');
    newWindow.document.write("<html><head></head><body><div id='root'></div></body></html>");
    ReactDOM.render(
        <PopupContractMpinComponent
            dataSegment={dataSegment}
            newWindow={newWindow}
        />,
        newWindow.document.getElementById('root')
    );
}