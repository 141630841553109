import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import memberTabReducer from "./components/MemberTab/memberTabReducers";
import userReducer from "./components/Home/userReducer";
import adminTabReducer from "./components/AdminTab/adminTabReducer";
import { combineReducers } from "redux";
import logger from "redux-logger";

const rootReducer = combineReducers({
  membertab: memberTabReducer,
  user: userReducer,
  admin: adminTabReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
