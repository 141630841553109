import axios from "axios";
const AGING_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/aging`;
const AGING_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/aging_detail`;

export const AgingApi = (requestBody) => {
	return axios.post(
		AGING_ENDPOINT,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
			},
			validateStatus: function (status) {
				return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
			},
		})
}


export const AgingDetailApi = (requestBody) => {
	return axios.post(
		AGING_DETAIL_ENDPOINT,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
			},
			validateStatus: function (status) {
				return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
			},
		})
}
