import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  MenuList,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { formatDates } from "../utils/formatFunctions";
import EligPopUp from "./popups/EligPopUp";
import PcpPopUp from "./popups/PcpPopUp";
import PopUpInfo from "./popups/PopUpInfo";
import FactorPopUp from "./popups/FactorPopUp";
import {
  timelineFilter,
  eligTimelineMerger,
  pcpTimelineMerger,
  factTimelineMerger,
} from "../utils/filterFunctions";
import {
  EligDownloadHeaders,
  PCPDownloadHeaders,
  FactDownloadHeaders,
} from "./popups/downloadHeaders";
import { createWindow } from "../utils/createWindow";
export const FilterComp = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mtcn, setMtcn] = useState("");
  const [mtcnFromList, setmtcnFromList] = useState("");
  const [disableButtons, setDisableButtons] = useState(true);
  var specificMTCN = "";
  const memberDetailsResult = props.memberDetailsResult;
  const transactionHistory = memberDetailsResult.transactionHistory;
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "mtcnsList") {
      setmtcnFromList(e.target.value);
      props.getTopXMtcn(e.target.value);
    }
    if (id === "specificMTCN") {
      setMtcn(e.target.value);
      props.setSpecificMtcn(e.target.value);
    }
  };
  const clearData = () => {
    setEndDate();
    setStartDate();
    setMtcn("");
    props.setSpecificMtcn("");
    setDisableButtons(true);
  };
  const getOptions = (timelines) => {
    const allValidMtcn = [];
    for (let i = 0; (i + 1) * 5 < timelines.length && i < 5; i++) {
      const end = (i + 1) * 5;
      const result = [];
      for (let itr = 0; itr < end; itr++) {
        result.push(timelines[itr].mtcn);
      }
      allValidMtcn.push(result);
    }
    return allValidMtcn.map((mtcn, index) => {
      var val = (index + 1) * 5;
      return <option value={mtcn}>{val}</option>;
    });
  };
  const handleChangeMtcn = (e) => {
    setMtcn(e.target.value);
  };
  const fetchData = (e) => {
    let beginFrom = formatDates(startDate);
    let beginTo = formatDates(endDate);
    if (mtcn !== "") {
      props.setSpecificMtcn(mtcn);
    }
    if (beginFrom != beginTo) {
      setDisableButtons(false);
    }
  };
  const changeEligContent = (e) => {
    const timelines = eligTimelineMerger(
      memberDetailsResult.eligibilityHistory,
      memberDetailsResult.compareHistory,
      memberDetailsResult.customerInformation,
      memberDetailsResult.transactionHistory
    );
    const eligContainer = (
      <>
        <PopUpInfo
          custInfo={memberDetailsResult.customerInformation}
          timelines={timelineFilter(
            timelines,
            new Date(formatDates(startDate)).getTime(),
            new Date(formatDates(endDate)).getTime()
          )}
          downloadHeader={EligDownloadHeaders}
          header='Eligibility History: '
        />
        <EligPopUp
          data={timelines}
          start={new Date(formatDates(startDate)).getTime()}
          end={new Date(formatDates(endDate)).getTime()}
        />
      </>
    );
    createWindow(eligContainer);
  };
  const changePCPContent = (e) => {
    const timelines = pcpTimelineMerger(
      memberDetailsResult.compareHistory,
      memberDetailsResult.customerInformation,
      memberDetailsResult.pcpAssignmentHistory,
      memberDetailsResult.transactionHistory
    );
    const pcpContainer = (
      <>
        <PopUpInfo
          custInfo={memberDetailsResult.customerInformation}
          timelines={timelineFilter(
            timelines,
            new Date(formatDates(startDate)).getTime(),
            new Date(formatDates(endDate)).getTime()
          )}
          downloadHeader={PCPDownloadHeaders}
          header='PCP History: '
        />

        <PcpPopUp
          data={timelines}
          start={new Date(formatDates(startDate)).getTime()}
          end={new Date(formatDates(endDate)).getTime()}
        />
      </>
    );
    createWindow(pcpContainer);
  };
  const changeFactContent = (e) => {
    const timelines = factTimelineMerger(
      memberDetailsResult.compareHistory,
      memberDetailsResult.customerInformation,
      memberDetailsResult.transactionHistory
    );
    const factorContainer = (
      <>
        <PopUpInfo
          custInfo={memberDetailsResult.customerInformation}
          timelines={timelineFilter(
            timelines,
            new Date(formatDates(startDate)).getTime(),
            new Date(formatDates(endDate)).getTime()
          )}
          downloadHeader={FactDownloadHeaders}
          header='Factor History: '
        />
        <FactorPopUp
          data={timelines}
          start={new Date(formatDates(startDate)).getTime()}
          end={new Date(formatDates(endDate)).getTime()}
        />
      </>
    );
    createWindow(factorContainer);
  };

  return (
    <Table style={{ padding: "0", overflowX: "auto", maxWidth: "20vw" }}>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingRight: "0" }}>
            <Typography variant='subtitle1'>From:</Typography>
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </TableCell>
          <TableCell style={{ paddingRight: "0" }}>
            <Typography variant='subtitle1'>To:</Typography>
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingRight: "0", width: "20vw" }}>
            <Typography variant='subtitle1'>Most recent MTCNS:</Typography>
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <select
              id='mtcnsList'
              name='mtcnsList'
              value={mtcnFromList}
              onChange={(e) => handleChange(e)}
            >
              <option value={""}>All</option>
              {getOptions(transactionHistory)}
            </select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className='filtersRow'
            style={{ paddingRight: "0", minWidth: 250 }}
          >
            <Typography variant='subtitle1'>
              and/or Specific Member MTCN:
            </Typography>
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <input
              type='text'
              id='specificMTCN'
              name='specificMTCN'
              value={mtcn}
              onChange={handleChangeMtcn}
            />
          </TableCell>
          <TableCell style={{ padding: "0" }}>
            <div style={{ display: "flex", paddingLeft: "20px" }}>
              <Button
                variant='contained'
                color='primary'
                onClick={(e) => fetchData(e)}
                style={{ marginRight: "10px" }}
              >
                Retrieve
              </Button>
              <Button
                id='clearButton'
                variant='contained'
                onClick={() => clearData()}
              >
                Clear
              </Button>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell style={{ padding: "0" }}></TableCell> */}
          <TableCell
            colSpan={2}
            style={{ padding: "0" }}
          >
            <Button
              disabled={disableButtons}
              variant='contained'
              color='primary'
              style={{ marginLeft: "1vw" }}
              onClick={(e) => changeEligContent(e)}
            >
              Mbr Eligibility
            </Button>
            <Button
              disabled={disableButtons}
              variant='contained'
              color='primary'
              style={{ marginLeft: "1vw" }}
              onClick={(e) => changePCPContent(e)}
            >
              Mbr PCP
            </Button>
            <Button
              disabled={disableButtons}
              variant='contained'
              color='primary'
              style={{ marginLeft: "1vw" }}
              onClick={(e) => changeFactContent(e)}
            >
              Mbr Factor
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
