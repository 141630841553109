import { Typography } from "@mui/material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import { DemographicsProviderApi } from './DemographicsProviderApi';

export const DemographicsProviderDetail = ({ inputProvId, inputProvTaxIdNbr, inputProvTaxIdTypCd, inputeffectivedate , inputcorpmpin, inputenddate, inputlastname}) => {
	const [data, setData] = useState([]);

	const getValueOrDefault = (value) => {
        // Check for both null and undefined explicitly  
        if (value === null || value === undefined || value === 'None') {
            return '';
        }
        return value;
    };


	useEffect(() => {
		
		if (inputProvId && inputProvTaxIdTypCd) {
			const requestBody = {
				"PROV_TAX_ID_TYP_CD": inputProvTaxIdTypCd,
				"PROV_ID": inputProvId,
				"PROV_TAX_ID_NBR": inputProvTaxIdNbr,
				"PROV_EFF_DT": inputeffectivedate,
				"CORP_MPIN": inputcorpmpin,
				"PROV_END_DT": inputenddate,
				"LST_NM": inputlastname
			};
			DemographicsProviderApi(requestBody).then(response => {
				console.log(response.data);
				setData(response.data);
			}).catch(error => {
				console.error('There was an error fetching the Provider details:', error);
			});
		}
	}, [inputProvId, inputProvTaxIdNbr, inputProvTaxIdTypCd, inputeffectivedate,inputcorpmpin, inputenddate, inputlastname]);

	const formatAddress = (address) => {
		const cleanAdress = address.replace(/new_line/g, '').trim();
		const newLineIndex = address.indexOf('new_line');
		if (newLineIndex !== -1) {
			const firstPart = address.substring(0, newLineIndex).trim();
			const secondPart = address.substring(newLineIndex + 8).trim();
			return [firstPart, secondPart];
		}	
		return [cleanAdress, ''];
	}

	return (
		<div>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Source System ID: NDB | Provider ID: {inputProvId} | Corp MPIN#: {inputcorpmpin } | Name: {inputlastname }|
				Tax ID#: {inputProvTaxIdNbr} | Tax ID Type: {inputProvTaxIdTypCd === 'TIN' ? 'TAX IDENTIFICATION NUMBER' : inputProvTaxIdTypCd} | Provider PCP Ind:{ } | Effective Date: { inputeffectivedate} | End Date: {inputenddate }
			</Typography>
			<br/>
			<div style={{ overflowY:'auto', maxHeight:'75vh' }}>
				{data.map((row) => (
					<div style={{ width: '600px' }}>
						<TableContainer >
							<Table size='small'>
								<TableBody>
									<TableRow style={{ backgroundColor: "#b0c0da" }}>
										<TableCell>Address Type Code</TableCell>
										<TableCell>{row["PROV_ADR_TYP_CD  "]}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Address Identifier</TableCell>
										<TableCell>{row.PROV_ADR_ID}</TableCell>
									</TableRow>
									<TableRow style={{ backgroundColor: "#b0c0da" }}>
										<TableCell>Effective Date</TableCell>
										<TableCell>{row.PROV_ADR_EFF_DT}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>End Date</TableCell>
										<TableCell>{row.PROV_ADR_END_DT}</TableCell>
									</TableRow>
									<TableRow style={{ backgroundColor: "#b0c0da" }}>
										<TableCell>Info Date</TableCell>
										<TableCell>{row.INFO_DATE}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>In Care of Name</TableCell>
										<TableCell>{getValueOrDefault(row.IN_CARE_OF_NM)}</TableCell>
									</TableRow>
									<TableRow style={{ backgroundColor: "#b0c0da" }}>
										<TableCell>Address</TableCell>
										<TableCell>{formatAddress(row.Address)[0]}<br /> {formatAddress(row.Address)[1]}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>County Name</TableCell>
										<TableCell>{row.CNTY_NM}</TableCell>
									</TableRow>
									<TableRow style={{ backgroundColor: "#b0c0da" }}>
										<TableCell>Address Seq#</TableCell>
										<TableCell>{row.PROV_ADR_SEQ_NBR}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				))}
			</div>
		</div>
	);

};
