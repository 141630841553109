import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { RetroSearchApi } from "./RetroSearchApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import RetroPeriodSearchTable from './RetroPeriodSearchTable';
import { Select, MenuItem } from '@mui/material';
import { testPDSeg } from '../../testPopupDataSegment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getDataSegmentsDict } from '../../Home/dataSegmentApi';



export const RetroPeriodSearch = ({
    setSelectedUpdateRows,
    switchTabs
}) => {
    const [showSearch, setShowSearch] = useState(true);
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegments, setDataSegments] = useState({});
    const [processMonth, setProcessMonth] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [tableData, setTableData] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setDataSegments(getDataSegmentsDict());
    }, [])

    function processMonthChange(date) {
        setProcessMonth(date);
    }

    const handleSubmit = () => {
        console.log("")
        if (dataSegment === "") {
            setErrorMessage("Please enter Data segment and factor name.");
            setShowTable(false);
        } else {
            setErrorMessage('');
            const requestBody = {
                'data_segment': Object.keys(dataSegments).find(key => dataSegments[key] === dataSegment)
            };

            RetroSearchApi(requestBody).then(response => {
                if (response.status === 200 && response.data.length > 0) {
                    let temp = response.data;
                    temp = temp.map(row => ({
                        ...row,
                        'DATA_SEG_ID': dataSegment
                    }));
                   // Parse processMonth to get year and month  
                   const tempProcMo = new Date(processMonth);  
                   const tempProcMoYearMonth = tempProcMo.getFullYear() * 100 + (tempProcMo.getMonth() + 1);  
                     
                   temp = temp.filter(row => row['BEGIN_EFF'] == tempProcMoYearMonth);  
           
     
               // If temp is empty after filtering, display the unfiltered response.data  
               // Otherwise, display the filtered temp  
               setTableData(temp.length > 0 ? temp : response.data);  
               setShowTable(true);
           } else {  
               setErrorMessage(`Error: Server responded with status code ${response.status}`);  
               setShowTable(false);  
           } 
            }).catch(error => {
                console.error(error);
                if (error.response) {
                    // The request was made and the server responded with a status code  
                    // that falls out of the range of 2xx  
                    setErrorMessage(`Error: Server responded with status code ${error.response.status}`);
                } else if (error.request) {
                    // The request was made but no response was received  
                    setErrorMessage('Error: The request was made but no response was received ${error.response.status}');
                } else {
                    // Something happened in setting up the request that triggered an Error  
                    setErrorMessage('Error: An error occurred while setting up the request.');
                }
                setShowTable(false);
            });
        }
    };

    useEffect(() => {
        if (tableData !== undefined) {
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);


    const handleReset = () => {
        // Clear all search fields  
        setDataSegment('');
        setProcessMonth('');

        // Hide the table if it's currently being shown  
        setShowTable(false);
    };


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }
    function dataSegmentChange(event) {
        setDataSegment(event.target.value);
    }

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '300px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 300, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <Select
                                size='small'
                                required
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                            //error={dataSegmentError}
                            >
                                {Object.values(dataSegments).map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>
                            <Box>
                                Begin Effective
                            </Box>
                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                Period
                            </Box>
                        </Typography>
                        <div style={{ width: '180px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => processMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={processMonth}
                                    value={processMonth}
                                />
                            </LocalizationProvider>
                        </div>
                    </Box>
                    <Button
                        id='search-button'
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {showTable && (
                <div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '1rem' }}>
                    <RetroPeriodSearchTable
                        showTable={showTable}
                        inputTableData={tableData}
                        setSelectedUpdateRows={setSelectedUpdateRows}
                        switchTabs={switchTabs}
                    />
                </div>
            )}
            {errorMessage && (
                <div style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'red',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {errorMessage}
                </div>
            )}

        </div>
    );
};

