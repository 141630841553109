import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import { Button, Box, Skeleton } from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  adminCodesGetSearchAxiosClient,
  adminCodesAddAxiosClient,
} from "./CodesSearchApi";
import { set } from "react-hook-form";

export const CodesAdd = () => {
  const [Codetype, setCodetype] = useState("");
  const permissions = useSelector((store) => store.user.permissions);
  const updatePermission = permissions.admin_codes.add === "Y" ? true : false;
  // const username = useSelector((store) => store.user.user.username);
  const handleCodetypeChange = (event) => {
    setCodetype(event.target.value);
  };

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [saved, setSaved] = useState(false);
  useEffect(() => {
    adminCodesGetSearchAxiosClient
      .post("", {})
      .then((response) => {
        const data = response.data;
        const options = data.map((obj) => obj.ECAP_TYP_CD);
        console.log(options);
        setOptions(options);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [rowData, setRowData] = useState([]);
  const handleRowInputChange = (index, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index] = {
      ...updatedRowData[index],
      [field]: value,
    };
    setRowData(updatedRowData);
  };
  const handleCodeChange = (event, index) => {
    const value = event.target.value;
    handleRowInputChange(index, "Code", value);
  };
  const handleShortDescriptionChange = (event, index) => {
    const value = event.target.value;
    handleRowInputChange(index, "shortdescription", value);
  };
  const handleLongDescriptionChange = (event, index) => {
    const value = event.target.value;
    handleRowInputChange(index, "longdescription", value);
  };
  const clear = () => {
    setRowData([]);
    setSaved(false);
  };
  const saveToDatabase = () => {
    rowData.forEach((row) => {
      const requestBody = {
        ecap_type_code: Codetype,
        ecap_code: row.Code || "",
        short_description: row.shortdescription || "",
        long_description: row.longdescription || "",
        user: localStorage.getItem('sub')
      };
      if (
        row.Code != "" &&
        row.shortdescription != "" &&
        row.longdescription != ""
      ) {
        adminCodesAddAxiosClient.post("", requestBody).then((response) => {
          console.log(response);
        });
      }
      setSaved(true);
    });
  };
  const rows = [0, 1, 2];
  return (
    <Box sx={{ width: "100%", maxHeight: "87vh", overflowY: "scroll" }}>
      <Box sx={{ mt: "1rem", display: "flex", flexDirection: "row" }}>
        <Typography sx={{ color: "red", mr: "0.1rem" }}>*</Typography>
        <Typography sx={{ mr: "1rem", fontWeight: "bold" }}>
          Code Type
        </Typography>
        {loading ? (
          <Skeleton
            animation='wave'
            variant='text'
            sx={{ width: "10rem", height: "2rem" }}
          />
        ) : (
          <select
            width='10rem'
            onChange={handleCodetypeChange}
          >
            {options.map((role) => (
              <option s>{role}</option>
            ))}
          </select>
        )}
      </Box>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
            >
              Code
            </TableCell>
            <TableCell
              style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
            >
              Short Description
            </TableCell>
            <TableCell
              style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
            >
              Long Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row}>
              <TableCell>
                <input
                  type='text'
                  maxLength='6'
                  style={{ width: "6rem" }}
                  id='Code'
                  value={rowData[index]?.Code || ""}
                  onChange={(event) => handleCodeChange(event, index)}
                />
              </TableCell>
              <TableCell>
                <input
                  type='text'
                  maxLength='12'
                  style={{ width: "12rem", height: "40px" }}
                  id='shortdescription'
                  value={rowData[index]?.shortdescription || ""}
                  onChange={(event) =>
                    handleShortDescriptionChange(event, index)
                  }
                />
              </TableCell>
              <TableCell>
                <input
                  type='text'
                  maxLength='200'
                  style={{ width: "40rem", height: "60px" }}
                  id='longdescription'
                  value={rowData[index]?.longdescription || ""}
                  onChange={(event) =>
                    handleLongDescriptionChange(event, index)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <br />
      <Box
        display='flex'
        gap={3}
      >
        <Button
          id='search-button'
          size='medium'
          variant='contained'
          endIcon={<SendIcon />}
          onClick={saveToDatabase}
          disabled={!updatePermission}
        >
          Save
        </Button>

        <Button
          id='clear-button'
          size='medium'
          variant='contained'
          endIcon={<SendIcon />}
          onClick={clear}
        >
          Clear
        </Button>
        {saved && (
          <Typography sx={{ color: "green", fontWeight: "bold" }}>
            Saving
          </Typography>
        )}
      </Box>
    </Box>
  );
};
