import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  LinearProgress,
  Button,
} from "@mui/material";
import { useState } from "react";
import { createWindow } from "../utils/createWindow";
import { ProfileHome } from "../MemberDetailsPage/ProfileHome";
const columns = [
  {
    id: "sourceSystemMemberId",
    label: "Source System Member Key",
    minWidth: 250,
  },
  {
    id: "memberId",
    label: "Member ID",
    minWidth: 125,
  },
  {
    id: "memberIdSuffix",
    label: "Suffix",
    minWidth: 100,
  },
  {
    id: "lastName",
    label: "Last Name",
    minWidth: 100,
  },
  {
    id: "firstName",
    label: "First Name",
    minWidth: 100,
  },
  {
    id: "alternateId",
    label: "Alt ID	",
    minWidth: 100,
  },
  {
    id: "medicareId",
    label: "Medicare ID",
    minWidth: 125,
  },
  {
    id: "medicaidId",
    label: "Medicaid ID",
    minWidth: 125,
  },
  {
    id: "details",
    label: "Details",
    minWidth: 50,
    align: "center",
    format: (row) => (
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          const profileHomeContainer = (
            <>
              <ProfileHome row={row} />
            </>
          );

          createWindow(profileHomeContainer);
        }}
      >
        view
      </Button>
    ),
  },
];

export const MemberTable = ({ rows, loading, showTable }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {!showTable ? (
        <></>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "75vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#b0c0da",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align='center'
                    >
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {rows.length === 0 ? (
                  <></>
                ) : (
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                              >
                                {column.format ? column.format(row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length < 10 ? (
            <></>
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      )}
    </>
  );
};
