import { useEffect, useState } from "react";
import { Navbar } from "./Navbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { InnerNav } from "./InnerNav";
import { storeDataSegments } from "./dataSegmentApi";
import { providerContractClearAll } from "../ProviderTab/Contract/providerContractPersistence";
import { clearAllErrors } from "../ErrorsTab/errorPersistence";

export default function Home() {
	const [setShowForm] = useState(null);
	const [showInnerNav, setShowInnerNav] = useState(false);
	const [showTab, setTab] = useState(false);
	useEffect(() => {
		resetSessionStorage();
		storeDataSegments();
	}, []);
	function changeTab(newTab) {
		console.log(newTab);
		if (newTab !== "Contract") {
			sessionStorage.setItem('demographicsCorpMPIN', null);
			sessionStorage.setItem('demographicsTaxID', null);
		}
		if (newTab !== "Demographics") {
			sessionStorage.setItem('demographicsprovidername', null);
			sessionStorage.setItem('demographicsprovideridmpin', null);
			sessionStorage.setItem('demographicstaxid', null);
			sessionStorage.setItem('demographicsfirstname', null);
			sessionStorage.setItem('demographicscorpmpin', null);
			sessionStorage.setItem('demographicstaxidtype', null);
			sessionStorage.setItem('demographicsShowTable', null);
			sessionStorage.setItem('demographicsTableData', null);
		}
		if (newTab !== "Errors") {
			clearAllErrors();
		}
		setTab(newTab);
	}
	function resetSessionStorage() {
		sessionStorage.clear();
	}
	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12} >
						<Navbar
							setShowForm={setShowForm}
							setShowInnerNav={setShowInnerNav}
							setTab={changeTab}
						/>{" "}
					</Grid>
					<Grid item xs={12} md={12} lg={12} key={showTab} >
						<InnerNav
							showTab={showTab}
							setShowform={setShowForm}
							showInnerNav={showInnerNav}
							setTab={changeTab}
						/>
					</Grid>
					<Grid item xs={12} md={12} lg={12} sx={{ marginTop: "5vh" }} />
				</Grid>
			</Box>
		</>
	);

}
