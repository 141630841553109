import { Typography } from "@mui/material";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import * as Constants from "./CoverageConstants";
import React, { useState, useEffect } from 'react';
import { MemberCoverageHistoryApi } from "./MemberCoverageHistoryApi";

// Initialize the cache outside of the component  
const apiCache = new Map();

const MemberCoverageHistory = ({ memberData }) => {

    const [coverageData, setCoverageData] = useState([]);

    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        let newDate = `${month}/${day}/${year}`;
        return newDate;
    };

    useEffect(() => {
        if (memberData) {
            const { DATA_SEG_ID, ECAP_MBR_KEY } = memberData;
            const cacheKey = `${DATA_SEG_ID}-${ECAP_MBR_KEY}`;

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data  
                setCoverageData(apiCache.get(cacheKey));
            } else {
                MemberCoverageHistoryApi(DATA_SEG_ID, ECAP_MBR_KEY)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data); 
                        setCoverageData(response.data);
                    })
                    .catch(error => {
                        console.error("Error fetching coverage data:", error);
                    });
            }
          }
        }, [memberData]);

    return (
        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>

            <div style={{ marginTop: "2rem" }}>

                {/* <Paper sx={{ width: '171.8%' }}> */}
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead>

                            <TableRow>
                                <Constants.StyledTableCell>Effective Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>End Effective Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Info Date</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Employer Group#</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Benefit Plan ID</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Employer Group Name</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <Constants.StripedTableBody>
                            {coverageData.map((item, index) => (
                                <TableRow key={index}>

                                    <TableCell>{formatDate(item.COV_EFF_DT)}</TableCell>
                                    <TableCell>{formatDate(item.COV_END_DT)}</TableCell>
                                    <TableCell>{formatDate(item.UPDT_DTTM.split(' ')[0])}</TableCell>
                                    <TableCell>{item.POL_ID}</TableCell>
                                    <TableCell>{item.CAP_BEN_PLN_ID}</TableCell>
                                    <TableCell>{item.POL_NM}</TableCell>
                                </TableRow>
                            ))}
                        </Constants.StripedTableBody>
                    </Table>
                </TableContainer>

                {/* </Paper> */}
            </div>
        </div>
    );
};

export default MemberCoverageHistory;
