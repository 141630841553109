import axios from 'axios';  
const MEMBER_HISTORY_PROVIDER_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/provider`;
// SearchHandler.js  
export const ProvdierHistoryApi = (
pay_partition_id,
  ecap_mbr_key

) => {
  // Prepare request body
  const requestBody = {
    pay_partition_id: pay_partition_id,
    ecap_mbr_key: ecap_mbr_key
  };


  return axios.post(
    MEMBER_HISTORY_PROVIDER_ENDPOINT,
    requestBody,
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
};
