import { useState, useEffect } from 'react';
import { Grid, Typography, Breadcrumbs, Link } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ParametersSeach from './Search/ParametersSearch';
import ParametersUpload from './Upload/ParametersUpload';
import ParametersAdd from './Add/ParametersAdd';
import ParametersDownload from './Download/ParametersDownload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const sections = [
    "Search",
    "Add",
    "Download",
    "Upload",
];

export const MaintenanceParameters = ({subTabName}) => {

    const [value, setValue] = useState(0);
    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTabs = (newValue) => {
        setValue(newValue);
    };
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return <ParametersSeach  switchTabs={switchTabs}/>;
        }
        if (value === 1) {
            return <ParametersAdd  switchTabs={switchTabs}/>;
        }
        if (value === 2) {
            return <ParametersDownload switchTabs={switchTabs}/>;
        }
        if (value === 3) {
            return <ParametersUpload switchTabs={switchTabs}/>
        }
    };

    return (
        <Grid container spacing={1} style={{ margin: '10px 0px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link color='inherit' underline="hover" href='/'>
                    Maintenance
                </Link>
                <Typography color="navy">{subTabName}</Typography>
            </Breadcrumbs>
            <Grid sx={12} lg={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
                >
                    {sections.map((section) => (
                        <Tab label={section} />
                    ))}
                </Tabs>
                <MakeTabComponent />
            </Grid>
        </Grid>
    );
};
