import { createSlice } from "@reduxjs/toolkit";
const userInputSlice = createSlice({
  name: "userinput",
  initialState: "",
  reducers: {
    pushInputs: (state, action) => {
      return action.payload;
    },
    clearInputs: (state) => {
      state = "";
      return state;
    },
  },
});

export const { pushInputs, clearInputs } = userInputSlice.actions;

export default userInputSlice;
