import React from "react";
import { formatDateTime } from "../utils/formatFunctions";
import { Paper } from "@mui/material";

export const CustInfo = (props) => {
  const data = props.custInfo;

  return (
    <div style={{ backgroundColor: "#b0c0da" }}>
      <table
        className='curInfo'
       style={{
          textAlign: "centre",
          width: "100%",
          borderSpacing: "0px",
          borderCollapse: "collapse",
          fontSize: "16px",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          <tr>
            <td>
              <b> Member ID &emsp; &ensp;: </b> {""}
              {data.memberId}
            </td>
            <td>
              <b> Name &emsp; {""}: </b> {""}
              {data.name}
            </td>
            <td>
              <b> Updated {""}: </b> {""}
              {formatDateTime(data.updated)}
            </td>
          </tr>
          <tr>
            <td>
             <b> Alt Member ID &nbsp;: </b>
              {""}
              {data.altID}
            </td>
            <td>
              <b> Address &nbsp;: </b> {""}
              {data.addressLine1_2}
            </td>
          </tr>
          <tr>
            <td>
              <b> Medicare ID &emsp; :</b> {""}
              {data.medicareId}
            </td>
            <td
              className='address2'
              style={{ paddingLeft: "80px" }}
            >
              {data.cityStateZipCode}
            </td>
          </tr>
          <tr>
            <td>
              <b> Medicaid ID &emsp; : </b>
              {""}
              {data.medicaidId}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CustInfo;
