import { Box, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

export const SearchTable = ({
    inputTableData,
    handleInvoiceClick
}) => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (inputTableData.length > 0) {
            setTableData(inputTableData);
        }
    }, [inputTableData]);

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Data Segment</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Type</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Process Month</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Contract Owner MPIN</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Contract#</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Contract Line#</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>PA</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Member ID</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Invoice#</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}><Box sx={{ whiteSpace:'nowrap' }}>Pay Now /</Box>Reverse</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Amount</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}><Box sx={{ whiteSpace:'nowrap' }}>Prepared /</Box><Box sx={{ whiteSpace:'nowrap' }}>Reviewed /</Box>Approved By</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Status</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Adjustment code</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) =>
                        <TableRow key={index}>
                            <TableCell>{row.DATA_SEGMENT}</TableCell>
                            <TableCell>{row.TYPE}</TableCell>
                            <TableCell>{row.PROC_YR_MONTH}</TableCell>
                            <TableCell>{row.CONTRACT_OWNER_MPIN}</TableCell>
                            <TableCell>{row.CONTRACT_NUMBER}</TableCell>
                            <TableCell>{row.CONTRACT_LN_ID}</TableCell>
                            <TableCell>{row.PA}</TableCell>
                            <TableCell>{row.MEMBER_ID === "None" ? '--': row.MEMBER_ID}</TableCell>
                            <TableCell><a href='#' onClick={() => handleInvoiceClick(row)}>{row.INVOICE_NUMBER}</a></TableCell>
                            <TableCell>{row['PAYNOW/REVERSE']}</TableCell>
                            <TableCell>{row.AMOUNT}</TableCell>
                            <TableCell>{row.ENTERED} / {row.REVIEWED} / {row.APPROVED}</TableCell>
                            <TableCell>{row.STATUS}</TableCell>
                            <TableCell>{row.ADJUSTMENT_CODE}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}
