import React from "react";
import { ProviderContractTab } from "./Contract/ProviderContractTab";
import { ProviderDemographics } from "./Demographics/ProviderDemographics";
import { ProviderHistoryTab } from "./History/ProviderHistoryTab";
import { ProviderCRT } from "./CRT/CRT";
import { ProviderAssociation } from "./Association/ProviderAssociation";

export const ProviderTab = ({subTabName, setTab}) => {
    return (
        <>
            {subTabName === 'Contract' && <ProviderContractTab />}
            {subTabName === 'Demographics' && <ProviderDemographics setTab={setTab}/>}
            {subTabName === 'History' && <ProviderHistoryTab/>}
            {subTabName === 'CRT' && <ProviderCRT/>}
            {subTabName === 'Association' && <ProviderAssociation/>}
        </>
    )
}