import axios from 'axios';
const PROVIDER_ID_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/demographics/provider_id`;

export const DemographicsProviderApi = (requestBody) => {
	return axios.post(
		PROVIDER_ID_ENDPOINT,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
			},
		});
}
