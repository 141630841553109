import { useEffect, useState } from "react"
import { DailyInterface } from "./DailyInterface";
import { DailyList } from "./DailyList";
import { DailyDetail } from "./DailyDetail";
import { clearAfterErrorsDaily, clearDailyDetail } from "../errorPersistence";

export const ErrorsDaily = () => {
    const [renderChoice, setRenderChoice] = useState(0);
    const [listData, setListData] = useState({});
    const [detailData, setDetailData] = useState({});

    function adjustListData(key, value) {
        var temp = listData;
        temp[key] = value;
        setListData(temp);
    }

    function adjustDetailData(key, value) {
        var temp = detailData;
        temp[key] = value;
        setDetailData(temp);
    }

    if (renderChoice === 0) {
        clearAfterErrorsDaily();
        return <DailyInterface
            setRenderChoice={setRenderChoice}
            adjustListData={adjustListData}
        />
    } else if (renderChoice === 1) {
        clearDailyDetail();
        return <DailyList
            setRenderChoice={setRenderChoice}
            inputListData={listData}
            setDetailData={adjustDetailData}
        />
    } else if (renderChoice === 2) {
        return <DailyDetail
            setRenderChoice={setRenderChoice}
            inputDetailData={detailData}
        />
    }
}