// src/ProviderSearch.js
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ProviderSearchTable from './ProviderSearchTable'; // Import the table
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { providerContractSearchApi } from "./providerContractSearchApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { storeContractSearchFilters, getContractSearchFilters, storeContractSearchResults, getContractSearchResults } from '../providerContractPersistence';

export const ProviderContractSearch = ({
    contractLink,
    setPublicContractName,
    setPublicContractEffDate,
    setPublicContractNumber,
    setPublicPri,
    setContrSrcSysCd
}) => {
    const [contractNumber, setContractNumber] = useState('');
    const [contractOwnerMPIN, setContractOwnerMPIN] = useState('');
    const [taxID, setTaxID] = useState('');
    const [contractName, setContractName] = useState('');
    const [providerID, setProviderID] = useState('');
    const [legacyProviderNumber, setLegacyProviderNumber] = useState('');
    const [priChecked, setPriChecked] = useState(true);
    const [showSearch, setShowSearch] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleContractNumberChange = (event) => {
        setContractNumber(event.target.value);
    };

    const handleContractOwnerMPINChange = (event) => {
        setContractOwnerMPIN(event.target.value);
    };

    const handleTaxIDChange = (event) => {
        setTaxID(event.target.value);
    };

    const handleContractNameChange = (event) => {
        setContractName(event.target.value);
    };

    const handleProviderIDChange = (event) => {
        setProviderID(event.target.value);
    };

    const handleLegacyProviderNumberChange = (event) => {
        setLegacyProviderNumber(event.target.value);
    };

    const handlePriChange = (event) => {
        setPriChecked(event.target.checked);
    };

    const handleSubmit = () => {
        setTableData([]);
        if (contractNumber === "" &&
            contractOwnerMPIN === "" &&
            taxID === "" &&
            providerID === ""
        ) {
            setErrorMessage("Fill in at least one of the following fields: Contract#, Corp MPIN, Tax ID#, or Provider ID.");
        } else {
            setErrorMessage('');
            setLoadingTable(true);
            const requestBody = {
                "CONTR_ID": `${contractNumber}`,
                "OWNR_PROV_ID": `${contractOwnerMPIN}`,
                "PROV_TIN": `${taxID}`,
                "CONTR_NM": `${contractName}`,
                "PROV_ID": `${providerID}`,
                "LGCY_PROV_NBR": `${legacyProviderNumber}`,
                "PRDCT_RDY_CD": priChecked ? 'P' : ""
            }
            providerContractSearchApi(requestBody).then
                (response => {
                    setLoadingTable(false);
                    if (response.data.length < 1) {
                        setErrorMessage('System did not find any records matching the entered selection criteria');
                    } else {
                        setTableData(response.data);
                        storeContractSearchFilters({
                            "CONTR_ID": contractNumber,
                            "OWNR_PROV_ID": contractOwnerMPIN,
                            "PROV_TIN": taxID,
                            "CONTR_NM": contractName,
                            "PROV_ID": providerID,
                            "LGCY_PROV_NBR": legacyProviderNumber,
                            "PRDCT_RDY_CD": priChecked
                        })
                        storeContractSearchResults(response.data);
                    }
                }).catch(error => {
                    setLoadingTable(false);
                    console.log(error);
                });
        }
    };

    const handleReset = () => {
        // Clear all search fields  
        setContractNumber('');
        setContractOwnerMPIN('');
        setTaxID('');
        setContractName('');
        setProviderID('');
        setLegacyProviderNumber('');
        setPriChecked(true);
        // clear table data
        setTableData([]);
        setLoadingTable(false);
        setErrorMessage('');
    };


    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    useEffect(() => {
        var instantSearch = false;
        var tempTaxID = JSON.parse(sessionStorage.getItem('demographicsTaxID'));
        var tempCorpMPIN = JSON.parse(sessionStorage.getItem('demographicsCorpMPIN'));
        if (JSON.parse(sessionStorage.getItem('demographicsTaxID')) !== null) {
            setTaxID(tempTaxID);
            instantSearch = true;
        }
        if (JSON.parse(sessionStorage.getItem('demographicsCorpMPIN')) !== null) {
            setContractOwnerMPIN(tempCorpMPIN);
            instantSearch = true;
        }
    }, []);

    // session storage stuff
    useEffect(() => {
        var temp = getContractSearchResults();
        if (temp) {
            setTableData(temp);
        }
        temp = getContractSearchFilters();
        if (temp) {
            setContractNumber(temp['CONTR_ID']);
            setContractName(temp['CONTR_NM']);
            setLegacyProviderNumber(temp['LGCY_PROV_NBR']);
            setContractOwnerMPIN(temp['OWNR_PROV_ID']);
            setPriChecked(temp['PRDCT_RDY_CD']);
            setProviderID(temp['PROV_ID']);
            setTaxID(temp['PROV_TIN']);
        }
    }, [])

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('demographicsTaxID')) || JSON.parse(sessionStorage.getItem('demographicsCorpMPIN'))) {
            handleSubmit();
        }
    }, [taxID, contractOwnerMPIN]);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: '315px', display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract #</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="contractNumberInput"
                                value={contractNumber}
                                onChange={handleContractNumberChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography component={'span'} variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>
                            <Box>
                                Contract Owner
                            </Box>
                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                MPIN
                            </Box>
                        </Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="contractOwnerMPINInput"
                                value={contractOwnerMPIN}
                                onChange={handleContractOwnerMPINChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1 }}>Tax ID#</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="taxIDInput"
                                value={taxID}
                                onChange={handleTaxIDChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract Name</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="contractNameInput"
                                value={contractName}
                                onChange={handleContractNameChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Provider ID</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                required
                                id="providerIDInput"
                                value={providerID}
                                onChange={handleProviderIDChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Legacy Provider#</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <TextField
                                id="legacyProviderNumberInput"
                                value={legacyProviderNumber}
                                onChange={handleLegacyProviderNumberChange}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1 }}>PRI</Typography>
                        <Checkbox
                            checked={priChecked}
                            onChange={handlePriChange}
                        />
                    </Box>
                    <Button
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {tableData.length > 0 &&
                <div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '3.5rem' }}>
                    <ProviderSearchTable
                        inputTableData={tableData}
                        contractLink={contractLink}
                        setPublicContractName={setPublicContractName}
                        setPublicContractEffDate={setPublicContractEffDate}
                        setPublicContractNumber={setPublicContractNumber}
                        setPublicPri={setPublicPri}
                        setContrSrcSysCd={setContrSrcSysCd}
                    />
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ ml: '1rem', mt: '3.5rem' }} />
                </Box>
            }
            {errorMessage &&
                <Box sx={{ ml: '1rem', mt: '3.5rem', width: '100%', color: 'red', textAlign: 'center' }}>
                    {errorMessage}
                </Box>
            }
        </div>
    );
};
