import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  adminApprovalAxiosClient,
  adminApprovalUpdateAxiosClient,
} from "./approvalApi";
import { Button, Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";

function createData(ApprovalProcessId) {
  return { ApprovalProcessId };
}
// const rows = [createData("1"), createData("2"), createData("3")];

export const AdminApproval = () => {
  const permissions = useSelector((store) => store.user.permissions);
  const updatePermission =
    permissions.admin_roles.update === "Y" ? true : false;
  const [rows, setRows] = useState([
    { APRV_PROC_ID: 1, THRESH_AMT: 0 },
    { APRV_PROC_ID: 2, THRESH_AMT: 0 },
    { APRV_PROC_ID: 3, THRESH_AMT: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    adminApprovalAxiosClient.post("", {}).then((response) => {
      setRows(response.data);
      setLoading(false);
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRows((prev) =>
      prev.map((row) => {
        if (row.APRV_PROC_ID === name) {
          return { ...row, THRESH_AMT: value };
        }
        return row;
      })
    );
  };
  const makeUpdate = () => {
    setLoading(true);
    adminApprovalAxiosClient.post("", {}).then((response) => {
      var tempData = response.data;
      const differences = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].THRESH_AMT !== tempData[i].THRESH_AMT) {
          differences.push({
            APRV_PROC_ID: rows[i].APRV_PROC_ID,
            THRESH_AMT: rows[i].THRESH_AMT,
          });
        }
      }
      console.log("differences", differences);
      differences.map((difference) => {
        const requestBody = difference;
        adminApprovalUpdateAxiosClient
          .post("", requestBody)
          .then((response) => {});
      });
      setLoading(false);
    });
  };
  return (
    <div style={{ overflowY: "auto", marginTop: "4rem", paddingLeft: "8px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#b0c0da" }}>
                Approval Process Id
              </TableCell>
              <TableCell style={{ backgroundColor: "#b0c0da" }}>
                Threshold Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.APRV_PROC_ID}>
                <TableCell>{row.APRV_PROC_ID}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton
                      variant='text'
                      width='8rem'
                      height={24}
                    />
                  ) : (
                    <div>
                      $
                      <input
                        type='text'
                        maxLength='8'
                        name={row.APRV_PROC_ID}
                        value={row.THRESH_AMT}
                        onChange={handleChange}
                        style={{ width: "8rem" }}
                      ></input>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Box
        display='flex'
        gap={3}
      >
        <Button
          id='save-button'
          size='medium'
          variant='contained'
          disabled={!updatePermission}
          onClick={makeUpdate}
          endIcon={<SendIcon />}
          dis
        >
          Save
        </Button>

        <Button
          id='cancel-button'
          size='medium'
          variant='contained'
          endIcon={<SendIcon />}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
};
