import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
import { formatDate } from "../utils/formatFunctions";
export const EligHist = (props) => {
  const data = props.eligHist;
  const columns = [
    {
      Header: "Begin",
      accessor: "begin",
      format: (cell) => formatDate(cell),
      minWidth: 100,
    },
    {
      Header: "End",
      accessor: "end",
      format: (cell) => formatDate(cell),
      minWidth: 100,
    },
    {
      Header: "Source",
      accessor: "source",
      minWidth: 75,
    },
    {
      Header: "Policy",
      accessor: "policy",
      minWidth: 125,
    },
    {
      Header: "Name",
      accessor: "name",
      minWidth: 175,
    },
    {
      Header: "Plan Variation",
      accessor: "planVariation",
      minWidth: 100,
    },
    {
      Header: "Report Code",
      accessor: "reportCode",
      minWidth: 100,
    },
    {
      Header: "CMS Contract ID",
      accessor: "cmsContractID",
      minWidth: 150,
    },
    {
      Header: "PBP",
      accessor: "pbp",
      minWidth: 75,
    },
    {
      Header: "PBP Segment",
      accessor: "pbpSegId",
      minWidth: 100,
    },
    {
      Header: "State of Issue",
      accessor: "statOfIssue",
      minWidth: 150,
    },
  ];
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "40vh" }}>
      <Table>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{
                    minWidth: column.minWidth,
                    padding: "4px",
                  }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
