import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b0c0da",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StripedTableBody = styled(TableBody)(({ theme }) => ({
    '& tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '& tr:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));
