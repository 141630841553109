import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { AssociationSearch} from './AssociationSearch'; 
import {AssociationDownload} from './AssociationDownload';


const sections = [
    "Search",
    "Download",
];

export const ProviderAssociation = () => {
    const [value, setValue] = useState(0);
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        false,
      
    ]);
    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTabs = (newValue) => {
        setValue(newValue);
    };
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return <AssociationSearch switchTabs={switchTabs}/>;
        }
        if (value === 1) {
            return < AssociationDownload/>;
        }
      
    };
    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section, index) => (
                    <Tab
                        label={section}
                        disabled={disabledTabs[index]}
                    />
                ))}
            </Tabs>
            <MakeTabComponent />
        </div>
    );
};

