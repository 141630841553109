import axios from 'axios';

const OVERRIDES_POPULATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/populate`;
const OVERRIDES_SAVE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/save`;

export function overridesPopulateApi(requestBody) {
    return axios.post(
        OVERRIDES_POPULATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        }
    );
};

export function overridesSaveApi(requestBody) {
    return axios.post(
        OVERRIDES_SAVE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        }
    );
};