import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "../../../../store";
export const createWindow = (passedContainer) => {
  const mywindow = window.open(
    "",
    "",
    "menubar=yes,location=no,resizable=yes,scrollbar=yes,status=no,height=400px, width=1200px,screenY=200, screenX=300"
  );
  mywindow.document.documentElement.style.zoom = "67%";
  mywindow.document.write("<!DOCTYPE html>");
  mywindow.document.write("<html>");
  mywindow.document.write("<head>");
  mywindow.document.write("</head>");
  mywindow.document.write("<body >");
  mywindow.document.write("<div id='root'></div>");
  mywindow.document.write("</body>");
  mywindow.document.write("</html>");
  const updateHead = () => {
    const parentHead = window.document.querySelector("head").childNodes;
    parentHead.forEach((item) => {
      mywindow.document.head.appendChild(item.cloneNode(true));
    });
  };
  const container = mywindow.document.getElementById("root");
  const root = createRoot(container);
  root.render(<Provider store={store}>{passedContainer}</Provider>);
  setTimeout(updateHead, 2000);
};
