import { Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Constants from "../../../MemberTab/MemberHistoryTab/MemberAdjustments/AdjustmentsConstants";
import { HistorydjustmentsApi } from "./HistorydjustmentsApi";
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'
import { formatDollars } from '../../../helperFunctions';

// Initialize the cache outside of the component  
const apiCache = new Map();

const HistoryAdjustments = ({
    memberData,
    dataSegmentInfo,
    contractNameInfo,
    switchTabs,
    inputDataSeg,
    inputContractInfo,
    dataSegmentGlobal
}) => {

    const [AdjustmentsData, setAdjustmentsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSeg, setDataSeg] = useState();
    const [contractInfo, setContractInfo] = useState();

    useEffect(() => {
        setDataSeg(inputDataSeg);
    }, [inputDataSeg])
    useEffect(() => {
        setContractInfo(inputContractInfo);
    }, [inputContractInfo])

    const formatMemberResponse = (value) => {
        if (value === null || value === undefined || value.trim() === '') {
            return '$0.00';
        } else {
            // Assuming value is a number or a string that can be converted to a number    
            // Format the number as a currency string with 2 decimal places    
            const numberValue = parseFloat(value);
            return `$${numberValue.toFixed(2)}`;
        }
    };

    const formatMemberCount = (value) => {
        if (value === null || value === undefined || value.trim() === '') {
            return '$0.00';
        } else {
            // Assuming value is a number or a string that can be converted to a number    
            // Format the number as a currency string with 2 decimal places    
            const numberValue = parseFloat(value);
            return `$${numberValue.toFixed(2)}`;
        }
    };

    const handleDownload = () => {

        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'ProviderAdjustment.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    useEffect(() => {
        if (memberData) {

            const CONTR_PARTN_ID = dataSegmentGlobal;
            const PAY_PARTN_ID = dataSegmentGlobal;
            setIsLoading(true);

            const { PROC_YR_MO, CONTR_ID, CONTR_LN_ID, ASSOC_PROV_ID } = memberData;
            const cacheKey = `${PROC_YR_MO}-${CONTR_ID}-${CONTR_LN_ID}-${CONTR_PARTN_ID}-${PAY_PARTN_ID}-${ASSOC_PROV_ID}`;

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data 
                const cachedData = apiCache.get(cacheKey);
                setAdjustmentsData(cachedData.sort((a, b) => a.CAP_PERIOD.localeCompare(b.CAP_PERIOD)));
                setIsLoading(false);
            } else {
                HistorydjustmentsApi(PROC_YR_MO, CONTR_ID, CONTR_LN_ID, PAY_PARTN_ID, CONTR_PARTN_ID, ASSOC_PROV_ID)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data);
                        const sortedData = response.data.sort((a, b) => a.CAP_PERIOD.localeCompare(b.CAP_PERIOD));
                        setAdjustmentsData(sortedData);
                    })
                    .catch(error => {
                        console.error("Error fetching coverage data:", error);
                    })
                    .finally(() => {
                        setIsLoading(false); // Stop loading after fetching data or if an error occurs  
                    });
            }
        }
    }, [memberData]);



    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {memberData.CONTR_ID} | Contract Line#: {memberData.CONTR_LN_ID} Data Segment: {dataSeg}   | Arrangement: {memberData.PROV_ARNG_CD}|  Cap Contract Type: {memberData.CONTR_TYP_CD}|  Total Cap: <a href='#' onClick={() => switchTabs(2, dataSegmentInfo)} style={{ cursor: 'pointer' }}>{`${formatDollars(parseFloat(memberData.TOTAL_CAP).toFixed(2))}`}</a>
                <br />
                Contact Name: {contractInfo} | Process Month: {memberData.PROC_YR_MO}
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleDownload}
                    id='download-button'
                >
                    Download
                </Button>
            </div>

            {/* Loading buffer image */}
            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <CircularProgress />
                </div>
            )}

            {/* Conditionally render "No record found" message */}
            {!isLoading && AdjustmentsData.length === 0 && (
                <Typography
                    sx={{
                        fontSize: "1.0rem",
                        textAlign: "center",
                        marginTop: "2rem",
                        color: "red"
                    }}
                >
                    No record found.
                </Typography>
            )}

            <div style={{ marginTop: "2rem" }}>

                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>

                        <TableHead>

                            <TableRow>
                                <Constants.StyledTableCell>Show All</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Assoc Group</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Adj Category</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Adj Code</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Cap period</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Capped Count</Constants.StyledTableCell>
                                <Constants.StyledTableCell>Adj Amount</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <Constants.StripedTableBody>
                            {AdjustmentsData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.ASSOC_GROUP}</TableCell>
                                    <TableCell>{item.ASSOC_GROUP}</TableCell>
                                    <TableCell>{item.ADJ_CATEGORY}</TableCell>
                                    <TableCell>{item.ADJ_CODE}</TableCell>
                                    <TableCell>{item.CAP_PERIOD}</TableCell>
                                    <TableCell>
                                        {typeof item.CAPPED_COUNT === 'number'
                                            ? item.CAPPED_COUNT.toFixed(2)
                                            : item.CAPPED_COUNT && !isNaN(parseFloat(item.CAPPED_COUNT))
                                                ? parseFloat(item.CAPPED_COUNT).toFixed(2)
                                                : '0.00'}
                                    </TableCell>


                                    <TableCell>{formatDollars(parseFloat(item.ADJ_AMOUNT))}</TableCell>

                                </TableRow>
                            ))}
                        </Constants.StripedTableBody>
                    </Table>
                </TableContainer>


            </div>

        </div>
    );
};

export default HistoryAdjustments;
