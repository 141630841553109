import { Box, Button, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { testPDSeg } from '../../testPopupDataSegment';
import TextField from '@mui/material/TextField';
import { retroConfigureApi } from './RetroPeriodConfigureAPI';
import { RetroSegmentApi } from './RetroPeriodConfigureAPI';
import { getDataSegmentIntFromString } from "../../Home/dataSegmentApi";

const RetroPeriodConfigure = ({inputDataSegments, switchTabs }) => {

    const [dataSegments, setDataSegments] = useState(['']);
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegmentIds, setDataSegmentIds] = useState();
    const [dataSegmentError, setDataSegmentError] = useState(false);
    const [addId, setaddId] = useState(Array(10).fill(''));
    const [termId, settermId] = useState(Array(10).fill(''));
    const [permiumId, setpremiumId] = useState(Array(10).fill(''));
    const [contractId, setcontractId] = useState(Array(10).fill(''));
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDataSegments, setSelectedDataSegments] = useState(Array(10).fill('select'));

    function dataSegmentChange(event, index) {
        setDataSegment(event.target.value);
        console.log(event.target.value, index);
        var requestBody = {
            'DATA_SEG_ID': getDataSegmentIntFromString(event.target.value)
        }
        RetroSegmentApi(requestBody).then(response => {
            if (response.data.length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                    console.log(response.data[i][1].split(':')[1], response.data[i][2].split(':')[1])
                    if (response.data[i][1].split(':')[1].trim() === 'ADD') {
                        setaddId([
                            ...addId.slice(0, index),
                            response.data[i][2].split(':')[1].trim(),
                            ...addId.slice(index + 1)
                        ])
                    } else if (response.data[i][1].split(':')[1].trim() === 'TERM') {
                        settermId([
                            ...termId.slice(0, index),
                            response.data[i][2].split(':')[1].trim(),
                            ...termId.slice(index + 1)
                        ])
                    } else if (response.data[i][1].split(':')[1].trim() === 'PREMCHG') {
                        setpremiumId([
                            ...permiumId.slice(0, index),
                            response.data[i][2].split(':')[1].trim(),
                            ...permiumId.slice(index + 1)
                        ])
                    } else if (response.data[i][1].split(':')[1].trim() === 'CONTRCHG') {
                        setcontractId([
                            ...contractId.slice(0, index),
                            response.data[i][2].split(':')[1].trim(),
                            ...contractId.slice(index + 1)
                        ])
                    }
                }
                setErrorMessages([]);
            } else {
                console.log(response.data.length, response.data);
                setErrorMessages(['No data found for the selected Data Segment']);
            }
        }).catch(error => {
            console.log(error);
 
        })
    }

    function addIdChange(event, index) {
        const value = event.target.value;
        const processedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
        setaddId([
            ...addId.slice(0, index),
            processedValue,
            ...termId.slice(index + 1)
        ])
    }
    

    function termIdChange(event, index) {
        const value = event.target.value;
        const processedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
        settermId([
            ...termId.slice(0, index),
            processedValue,
            ...termId.slice(index + 1)
        ])
    }


    function premiumIdChange(event, index) {
        const value = event.target.value;
        const processedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
        setpremiumId([
            ...permiumId.slice(0, index),
            processedValue,
            ...permiumId.slice(index + 1)
        ])
    }


    function contractIdChange(event, index) {
        const value = event.target.value;
        const processedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
        setcontractId([
            ...contractId.slice(0, index),
            processedValue,
            ...contractId.slice(index + 1)
        ])
    }

    useEffect(() => {
        if (inputDataSegments != []) {
            var dataSegmentIds = {}
            var dataSegmentList = []
            for (let index in inputDataSegments) {
                dataSegmentIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
                dataSegmentList.push(inputDataSegments[index]['NAME']);
            }
            setDataSegmentIds(dataSegmentIds);
            setDataSegments(dataSegmentList);
        }
    }, [inputDataSegments])

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    const handleSave = () => {
        var tempErrorMessages = [];
        setErrorMessages([]);
        var data = Array(10);
        var rows = [];
        for (let i = 0; i < 10; i++) {
            var temp = {
                "DATA_SEG_ID": "",
                "ADD": "",
                "TERM": "",
                "PREMCHG": "",
                "CONTRCHG": "",
            }
            data[i] = temp;
        }
        for (let i = 0; i < 10; i++) {
            if (selectedDataSegments[i] !== 'select') {
                data[i]["dataSegment"] = selectedDataSegments[i];
                data[i]["add"] = addId[i];
                data[i]["term"] = termId[i];
                data[i]["premium"] = permiumId[i];
                data[i]["contract"] = contractId[i];
                rows.push(i);
            }
        }
        var hasNoData = true; // no data
        var blankDataSegment = false; // blank data segment
        if(rows.length > 0) {
            hasNoData = false;
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i]["dataSegment"] === '') {
                blankDataSegment = true;
                tempErrorMessages.push('* Please select a Data Segment for row ' + (i + 1));
            }

            if (data[i]["dataSegment"]) {
                let rowErrors = '';
                if (!data[i].add) {
                    rowErrors += '* Error: ADD field cannot be empty for row ' + (i + 1) + '. ';
                }
                if (!data[i].term) {
                    rowErrors += '* Error: TERM field cannot be empty for row ' + (i + 1) + '. ';
                }
                if (!data[i].premium) {
                    rowErrors += '* Error: PREMCHG field cannot be empty for row ' + (i + 1) + '. ';
                }
                if (!data[i].contract) {
                    rowErrors += '* Error: CONTRCHG field cannot be empty for row ' + (i + 1) + '. ';
                }
                if (rowErrors) {
                    tempErrorMessages.push(rowErrors.trim());
                }
            }

           
        }
        if (blankDataSegment) {
            setErrorMessages(tempErrorMessages);
            return;
        }
        if (hasNoData) {
            tempErrorMessages.push('* Please enter at least one row of data.');
        }

        if (tempErrorMessages.length === 0) {
            setLoading(true);
            setErrorMessages([]);
            var requestBody = []

            for (let i = 0; i < data.length; i++) {

                if (data[i]["dataSegment"]) {
                    var temp = {
                        "DATA_SEG_ID": getDataSegmentIntFromString(data[i]["dataSegment"]),
                        "ADD": data[i]["add"],
                        "TERM": data[i]["term"],
                        "PREMCHG": data[i]["premium"],
                        "CONTRCHG": data[i]["contract"],
                        "USER_ID": localStorage.getItem('sub')
                    
                    }
                    
                    console.log("inserting record::", temp);
                    requestBody.push(temp);
                }
                
            }
            console.log("requestBody::", requestBody);
        
            retroConfigureApi(requestBody)
                .then(response => {
                    if (response.data) {
                        const error = response.data;
                        rows.reverse();
                        for (let i = 0; i < error.length; i++) {
                            if (error[i].includes('successfully')) {
                                tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' - Inserted successfully');
                            } else if (error[i].includes('failed')) {
                                tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' - failed to update');
                            }
                        }
                    } else {
                        tempErrorMessages.push('* Error adding records');
                    }
                    setLoading(false);
                    setErrorMessages(tempErrorMessages);
                })
                .catch(error => {
                    setLoading(false);
                    if (error.response && error.response.data) {
                        setErrorMessages([`* Error: ${error.response.data}`]);
                    } else {
                        setErrorMessages(['* Error adding records']);
                    }
                });
            } else {    
                    setErrorMessages(tempErrorMessages);
                }        
        }
        const resetFields = () => {
            setDataSegment('');
            setDataSegmentError(false);
            setaddId(Array(10).fill(''));
            settermId(Array(10).fill(''));
            setpremiumId(Array(10).fill(''));
            setcontractId(Array(10).fill(''));
            setErrorMessages([]);
            setSelectedDataSegments(Array(10).fill('select'));
            setLoading(false);
        };

    return (
        
        <Box sx={{ width: 'calc(100vw - 24px)' }}>
        {errorMessages.length > 0 && errorMessages.map((temp, index) => (
            <Box key={'errorMessage_' + index} sx={{ mb: '1rem', width: '100%', textAlign: 'center', color: 'red' }}>
                {temp}
            </Box>
        ))}
        {loading && <LinearProgress sx={{ mt: '1rem', width: '100%' }} />}
        <Box sx={{ maxHeight: '68vh', overflow: 'auto' }}>
            <Table stickyHeader>
                <TableHead>

                            <TableRow>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left',  width: '40%' }}>Data Segment</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left'}}>Add</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Term</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left'}}>Premium Change</TableCell>
                                <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left'}}>Contract Change</TableCell>
                            </TableRow>
                        </TableHead>
                                <TableBody>
                        {Array(10).fill().map((_, index) => (
                            <TableRow key={'row_' + index}>
                                <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Select
                                size='small'
                                // onChange={(event) => dataSegmentChange(event, index)}
                                onChange={(event) => setSelectedDataSegments((prev) => {
                                    const temp = [...prev];
                                    temp[index] = event.target.value;
                                    dataSegmentChange(event, index);
                                    return temp;
                                })}
                                style={{ width: 150 }}
                                value={selectedDataSegments[index]}
                                id='data-seg-select'
                                error={dataSegmentError}
                            >
                                <MenuItem value='select' disabled></MenuItem>
                                {dataSegments.map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                                 <MenuItem
                                            value='select'
                                        >
                                            ---Select---
                                        </MenuItem>
                            </Select>
                            <Box
                                        sx={{ ml: '0.5rem', padding: '5px', height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center' }}
                                    >
                                        <img
                                            style={{ height: '30px', width: '30px' }}
                                            src="Binoculars-icon.png"
                                            title="Filtering Data segment"
                                        onClick={() => openPopupDataSegment()}
                                        ></img>
                                    </Box>
                                </TableCell>
                        <TableCell>   <Box sx={{ flex: 2, minWidth: 80, maxWidth: 100 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='alt-id'
                                value={addId[index]}
                                onChange={(event) => addIdChange(event, index)}
                                maxLength={2}
                                size="small"
                            />
                        </Box></TableCell>
                                    <TableCell> <Box sx={{ flex: 2, minWidth: 80, maxWidth: 100 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='term-id'
                                value={termId[index]}
                                onChange={(event) => termIdChange(event, index)}
                                maxLength={2}
                                size="small"
                            />
                        </Box></TableCell>
                        <TableCell> <Box sx={{ flex: 2, minWidth: 80, maxWidth: 100 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='premium-id'
                                value={permiumId[index]}
                                onChange={(event) => premiumIdChange(event, index)}
                                maxLength={2}
                                size="small"
                            />
                        </Box></TableCell>
                        <TableCell><Box sx={{ flex: 2, minWidth: 80, maxWidth: 100 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='contract-id'
                                value={contractId[index]}
                                onChange={(event) => contractIdChange(event, index)}
                                maxLength={2}
                                size="small"/>
                        </Box></TableCell>
                       
                       </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
        <Box sx={{ mt: '1rem', display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Button variant="contained" onClick={() => handleSave()}>Save</Button>
            <Button variant="contained" color="error" onClick={() => switchTabs(0)}>Cancel</Button>
            <Button variant="contained" color="success" onClick={resetFields}>Reset</Button>
        </Box>
    </Box >
  )
} 
                
export default RetroPeriodConfigure;
