import axios from 'axios';
const CRT_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/crt/search`;

export const crtSearch = (requestBody) => {
    return axios.post(
        CRT_SEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}