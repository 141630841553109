import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
import { formatDate } from "../utils/formatFunctions";
export const FactHist = (props) => {
  const data = props.factHist;
  const columns = [
    {
      Header: "Begin",
      accessor: "begin",
      format: (cell) => formatDate(cell),
      minWidth: 125,
    },
    {
      Header: "End",
      accessor: "end",
      format: (cell) => formatDate(cell),
      minWidth: 125,
    },
    {
      Header: "Gender",
      accessor: "gender",
      minWidth: 75,
    },
    {
      Header: "Rel",
      accessor: "rel",
      minWidth: 50,
    },
    {
      Header: "BirthDate",
      accessor: "birthdate",
      format: (cell) => formatDate(cell),
      minWidth: 125,
    },
    {
      Header: "County",
      accessor: "county",
      minWidth: 75,
    },
    {
      Header: "State",
      accessor: "state",
      minWidth: 50,
    },
    {
      Header: "Zip",
      accessor: "zip",
      minWidth: 50,
    },
    {
      Header: "Medicare ID",
      accessor: "medicareId",
      minWidth: 150,
    },
    {
      Header: "Hospice Indicator",
      accessor: "hospiceIndic",
      minWidth: 250,
    },
  ];
  return (
    <TableContainer style={{ overflowX: "auto", maxHeight: "40vh" }}>
      <Table>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  backgroundColor: "#b0c0da",
                  minWidth: column.minWidth,
                  padding: "4px",
                }}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.Header}
                  style={{
                    minWidth: column.minWidth,
                    padding: "4px",
                  }}
                >
                  {column.format
                    ? column.format(row[column.accessor])
                    : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
