import { useState } from "react"
import { MonthlyProcessed } from "./MonthlyProcessed"
import { MonthlyGroup } from "./MonthlyGroup"
import { MonthlyList } from "./MonthlyList"
import { MonthlyDetail } from "./MonthlyDetail"
import { clearAfterErrorsMonthlyProcessed, clearMonthlyList } from "../errorPersistence"

export const ErrorsMonthly = () => {
    const [renderChoice, setRenderChoice] = useState(0);
    const [groupData, setGroupData] = useState({});
    const [listData, setListData] = useState({});
    const [detailData, setDetailData] = useState({});

    function adjustGroupData(key, value) {
        var temp = groupData;
        temp[key] = value;
        setGroupData(temp);
    }

    function adjustListData(key, value) {
        var temp = listData;
        temp[key] = value;
        setListData(temp);
    }

    function adjustDetailData(key, value) {
        var temp = detailData;
        temp[key] = value;
        setDetailData(temp);
    }

    if (renderChoice === 0) {
        clearAfterErrorsMonthlyProcessed();
        return <MonthlyProcessed
            setRenderChoice={setRenderChoice}
            adjustGroupData={adjustGroupData}
        />
    } else if (renderChoice === 1) {
        clearMonthlyList();
        return <MonthlyGroup
            setRenderChoice={setRenderChoice}
            inputGroupData={groupData}
            adjustListData={adjustListData}
        />
    } else if (renderChoice === 2) {
        return <MonthlyList
            setRenderChoice={setRenderChoice}
            inputListData={listData}
            adjustDetailData={adjustDetailData}
        />
    } else if (renderChoice === 3) {
        return <MonthlyDetail
            setRenderChoice={setRenderChoice}
            inputDetailData={detailData}
        />
    }
}