import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
const DATASEGMENT_POPUP_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg_popup`;
 
const getPopupData = () => {
    return axios.get(
        DATASEGMENT_POPUP_ENDPOINT,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    });
};
 
function createData(dataSegment, legalEntity, capProductCategory, regulatorySegment, majorSharedArrgCode) {
    return { dataSegment, legalEntity, capProductCategory, regulatorySegment, majorSharedArrgCode };
}
 
// change this when api is done
var allData = [
    createData('MAMDICO', 'MDIPA', 'CO', 'H', 'M100'),
    createData('MAOCICO', 'OCI', 'CO', 'H', 'M100'),
    createData('PHSCACO', 'PHSCA', 'CO', 'H', 'U099'),
    createData('PHSCOCO', 'PHSCOCO', 'CO', 'H', 'U099'),
    createData('PHSNVCO', 'PHSNVCO', 'CO', 'H', 'U099'),
]
 
const selectIds = ['legalEntity', 'capProductCategory', 'regulatorySegment', 'majorSharedArrgCode'];
 
var results = [];
var selectID = '';
 
function fillData(popupData) {
    allData = [];
    for (let index in popupData) {
        let row = popupData[index];
        allData.push(createData(row.SHRT2_9_, row.ECAP4_9_, row.ECAP5_9_, row.ECAP6_9_, row.ECAP7_9_));
    }
}
 
async function resetPage() {
    for (let index in selectIds) {
        try {
            results = [];
            document.getElementById(selectIds[index]).value = '--Select--';
        } catch (error) {
            //console.log('Already reset');
        }
    }
}
 
function filterDistinct(rows, key) {
    const set = new Set(rows.map((row) => row[key]));
    return ['--Select--'].concat(Array.from(set));
}
 
function showResults(window) {
    const selectValues = {};
    // get filter options (current select choices)
    selectIds.forEach((id) => {
        const selectElement = window.document.getElementById(id);
        const selectedOption = selectElement.options[selectElement.selectedIndex].value;
        selectValues[id] = selectedOption;
    });
    // get results that match filter options
    results = allData.filter((dict) => {
        for (let index in selectIds) {
            var key = selectIds[index];
            if (selectValues[key] !== dict[key] && selectValues[key] !== '--Select--') {
                //console.log(selectValues[key], dict[key]);
                return false;
            }
        }
        return true;
    });
    if (results.length === 0) {
        console.log('no results');
        noResults = true;
    } else {
        noResults = false;
    }
    // re-render to show new results
    ReactDOM.render(<PopupDataSegment allData={allData} results={results} window={window} />, window.document.getElementById('root'));
}
 
var noResults = false;
 
const handleClick = (window, choice) => {
    window.opener.postMessage(selectID + ' ' + choice, window.location.origin);
    window.close();
};
 
const PopupDataSegment = ({ allData, results, window }) => {
    const [dataSegments, setDataSegments] = useState([]);
    useEffect(() => {
        getPopupData().then(response => {
            setDataSegments(response.data);
            fillData(response.data);
        }).catch(error => {
            console.log("There was an error fetching popup data");
        });
    }, []);
    return (
        <div>
            <TableContainer>
                <Table style={{ width: '100%', textAlign: 'left' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Legal Entity
                            </TableCell>
                            <TableCell>
                                Cap Product Category
                            </TableCell>
                            <TableCell>
                                Regulatory Segment
                            </TableCell>
                            <TableCell>
                                Major Shared Arrg Code
                            </TableCell>
                            <TableCell>
 
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <select id='legalEntity'>
                                    {filterDistinct(dataSegments, 'ECAP4_9_').map((element) => (
                                        <option value={element}>
                                            {element}
                                        </option>
                                    ))}
                                </select>
                            </TableCell>
                            <TableCell>
                                <select id='capProductCategory'>
                                    {filterDistinct(dataSegments, 'ECAP5_9_').map((element) => (
                                        <option value={element}>
                                            {element}
                                        </option>
                                    ))}
                                </select>
                            </TableCell>
                            <TableCell>
                                <select id='regulatorySegment'>
                                    {filterDistinct(dataSegments, 'ECAP6_9_').map((element) => (
                                        <option value={element}>
                                            {element}
                                        </option>
                                    ))}
                                </select>
                            </TableCell>
                            <TableCell>
                                <select id='majorSharedArrgCode'>
                                    {filterDistinct(dataSegments, 'ECAP7_9_').map((element) => (
                                        <option value={element}>
                                            {element}
                                        </option>
                                    ))}
                                </select>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => showResults(window)} style={{ backgroundColor: "#b0c0da" }}>
                                    Submit
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br></br>
                <Table style={{ width: '100%', textAlign: 'left' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Data Segment
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Legal Entity
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Cap Product Category
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Regulatory Segment
                            </TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>
                                Major Shared Arrg Code
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!noResults &&
                        (results.map((row) => (
                            <TableRow>
                                <TableCell>
                                    <a href="#" onClick={() => handleClick(window, row.dataSegment)} style={{ textDecoration: 'none' }}>{row.dataSegment}</a>
                                </TableCell>
                                <TableCell>
                                    {row.legalEntity}
                                </TableCell>
                                <TableCell>
                                    {row.capProductCategory}
                                </TableCell>
                                <TableCell>
                                    {row.regulatorySegment}
                                </TableCell>
                                <TableCell>
                                    {row.majorSharedArrgCode}
                                </TableCell>
                            </TableRow>
                        )))}
                    </TableBody>
                </Table>
            </TableContainer>
            {noResults && <div style={{ width: '100%', textAlign:'center', color: '#FF0000' }}>No Record Found</div>}
        </div>
    );
}
 
export function testPDSeg(inputSelectID) {
    // for some reason putting selectID as an input to the segment becomes undefined after mapping, this works though
    resetPage();
    selectID = inputSelectID;
    const newWindow = window.open('', 'new', 'width=10vw,resizable=yes,status=yes');
    newWindow.document.write("<html><body><div id='root'></div></body></html>");
    ReactDOM.render(<PopupDataSegment allData={allData} results={results} window={newWindow} />, newWindow.document.getElementById('root'));
}