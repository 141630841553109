import axios from 'axios';
const FORCE_ADD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/force_cap/save`;

export const ForceAddApi = (requestBody) => {
    return axios.post(
        FORCE_ADD_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
}
